import PropTypes from 'prop-types';
import React from 'react';

import ia from '../../../../common/config/Analytics';
import PaymentCard from './PaymentCard';
import PaymentOptions from './PaymentOptions';

const mixpanelPropsMapping = {
  upi: 'UPI QR',
  non_upi: 'Other Payment Options',
  international_payments: 'International Payments',
};

const PaymentGroup = (props) => {
  const {
    selectedPaymentMode,
    openedPaymentCardIndex,
    paymentOptions,
    order,
  } = props.finalizePurchase;

  const { cardHeading, paymentGroupIndex, paymentOptionGroup, username } = props;
  return (
    <>
      <PaymentCard
        cardHeading={cardHeading}
        isDetailViewOpen={openedPaymentCardIndex === paymentGroupIndex}
        onClick={() => {
          if (openedPaymentCardIndex !== paymentGroupIndex) {
            props.handleResetSelectedPaymentMode();
            props.handlePaymentGroupCardClick(paymentGroupIndex);
            ia.sendEvent('Viewed Payment Options', {
              mode_list: Object.keys(paymentOptions).map(
                (option) => paymentOptions[option].name,
              ),
              seller_username: username,
              order_id: order,
              view: paymentOptionGroup === 'upi' ? 'QR View' : 'List View',
              payment_option: mixpanelPropsMapping[paymentOptionGroup], // mixpanel prop to track which particular payment option was expanded
            });
          }
        }}
        className="soft--bottom"
      >
        <div className="soft--sides">
          {selectedPaymentMode ? (
            props.renderSelecedPaymentOption()
          ) : (
            <div>
              {paymentOptionGroup === 'non_upi' ? (
                <div className="push--bottom">More payment options</div>
              ) : null}
              <PaymentOptions
                paymentOptionGroup={paymentOptionGroup}
                finalizePurchase={props.finalizePurchase}
                handleSelectPaymentOption={(selectedPaymentDetails) =>
                  props.handleSelectPaymentOption(selectedPaymentDetails)
                }
                handleManageSavedCards={(payload) =>
                  props.handleManageSavedCards(payload)
                }
                handleDeleteSavedCard={(cardToBeDeleted) =>
                  props.handleDeleteSavedCard(cardToBeDeleted)
                }
                handleFetchConvenienceFeesForQR={() =>
                  props.handleFetchConvenienceFeesForQR()
                }
                pollForUPIAcceptance={(statusCheckUrl) =>
                  props.pollForUPIAcceptance(statusCheckUrl)
                }
                handlePaypalSuccessfulCallback={() =>
                  props.handlePaypalSuccessfulCallback()
                }
                handlePaypalSDKError={() => props.handlePaypalSDKError()}
              />
            </div>
          )}
        </div>
      </PaymentCard>
    </>
  );
};

PaymentGroup.propTypes = {
  username: PropTypes.string,
  cardHeading: PropTypes.string,
  paymentGroupIndex: PropTypes.number.isRequired,
  paymentOptionGroup: PropTypes.string.isRequired,
  finalizePurchase: PropTypes.object.isRequired,
  handlePaymentGroupCardClick: PropTypes.func.isRequired,
  handleManageSavedCards: PropTypes.func.isRequired,
  handleResetSelectedPaymentMode: PropTypes.func.isRequired,
  handleDeleteSavedCard: PropTypes.func.isRequired,
  handleFetchConvenienceFeesForQR: PropTypes.func.isRequired,
  pollForUPIAcceptance: PropTypes.func.isRequired,
  handleSelectPaymentOption: PropTypes.func.isRequired,
  renderSelecedPaymentOption: PropTypes.func.isRequired,
  handlePaypalSuccessfulCallback: PropTypes.func.isRequired,
  handlePaypalSDKError: PropTypes.func.isRequired,
};

export default PaymentGroup;
