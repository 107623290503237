import ia from '../../../common/config/Analytics';
import { convertUndefinedToNullInFlattenedObject } from '../../../common/config/utils';

export const checkFormErrors = (obj) => {
  return (
    obj &&
    Object.keys(obj).length &&
    Object.keys(obj).filter((key) => obj[key].length).length
  );
};

export const MIXPANEL_PAYMENT_EVENT = {
  ENTERED_INSTRUMENT_DETAILS: 'ENTERED_INSTRUMENT_DETAILS',
};

export const invokeMixpanelEvent = (eventName, mixpanelData, store) => {
  switch (eventName) {
    case MIXPANEL_PAYMENT_EVENT.ENTERED_INSTRUMENT_DETAILS: {
      let bank_name = undefined;

      const {
        selectedBankId,
        order: order_id,
        orderAmount: order_amount,
        selectedPaymentMode: payment_mode,
        selectedPaymentObject: { nbList = [], nbGridList = [] } = {},
      } = store.cart ? store.cart.finalizePurchase || {} : store.finalizePurchase || {};

      const { seller: { username } = {} } = store.cart
        ? store.cart.settings || {}
        : store.settings || {};

      const { form: { email: buyer_email, phone: buyer_phone } = {} } = store.cart
        ? store.cart.shipping || {}
        : store.initializePurchase || {};

      const { shippingCharges: shipping_charges } = store.cart
        ? store.cart.otherCartProps || {}
        : store.finalizePurchase || {};

      if (payment_mode === 'nb') {
        const selectedBank = [...nbGridList, ...nbList].find(
          (bank) => bank.id === selectedBankId,
        );
        bank_name = selectedBank ? selectedBank.name : undefined;
      }

      mixpanelData = {
        ...mixpanelData,
        order_id,
        order_amount,
        bank_name,
        payment_mode,
        username,
        buyer_email,
        buyer_phone,
        shipping_charges,
      };

      mixpanelData = convertUndefinedToNullInFlattenedObject(mixpanelData);

      ia.sendEvent('Entered Instrument Details', mixpanelData);
      return;
    }
    default:
      return;
  }
};
