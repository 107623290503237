import PropTypes from 'prop-types';
import React from 'react';

import { Form, FormField, FormFieldWithLabel, FormFieldWrapper } from '../FormComponents';
import { Columns, Row } from '../Grid';
import SubmitButton from '../SubmitButton';

const EditOtp = ({
  phoneNumber,
  handleValidatePhoneNumberAndSendOTP,
  handlePhoneChange,
  isOTPLimitExceeded,
  errors,
}) => (
  <>
    <h6 className="no-margin">Edit Phone Number</h6>
    <p className="text-grey f14 push--bottom">
      We will send an OTP to your phone number.
    </p>
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleValidatePhoneNumberAndSendOTP(true);
      }}
    >
      <FormFieldWrapper columns="columns small-12 medium-12">
        <FormFieldWithLabel label="Enter 10-digit Phone Number" errors={errors.phone}>
          <Row className="collapse prefix-radius">
            <Columns className="small-2">
              <span className="prefix">+91</span>
            </Columns>
            <Columns className="small-10">
              <input
                type="number"
                value={phoneNumber}
                onChange={(e) => handlePhoneChange(e.target.value)}
              />
            </Columns>
          </Row>
        </FormFieldWithLabel>
        <FormField className="hard--bottom push--bottom">
          <div className="small-12">
            <SubmitButton
              disabled={isOTPLimitExceeded}
              value="Send OTP"
              className="btn-green btn-block"
            />
          </div>
        </FormField>
      </FormFieldWrapper>
    </Form>
  </>
);

EditOtp.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  handleValidatePhoneNumberAndSendOTP: PropTypes.func.isRequired,
  handlePhoneChange: PropTypes.func.isRequired,
  isOTPLimitExceeded: PropTypes.bool,
  errors: PropTypes.object,
};

export default EditOtp;
