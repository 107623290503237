import PropTypes from 'prop-types';
import React from 'react';

const ModalContent2 = ({ children, className }) => (
  <div className={`modal-content-2-wrapper soft ${className}`}>{children}</div>
);

ModalContent2.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  className: PropTypes.string,
};

ModalContent2.defaultProps = {
  className: '',
};

export default ModalContent2;
