// DIGITAL_ALT is used in EditProduct, DIGITAL is used in CreateProduct, backend sends 'digital' as offerType, thus two keywords
export const DIGITAL_ALT = 'digital';
export const DIGITAL = 'offer';
export const PHYSICAL = 'physical';
export const SERVICE = 'membership';
export const EVENT = 'event';

export const productToMixpanelNameMap = {
  [DIGITAL]: 'Digital Goods',
  [PHYSICAL]: 'Physical Goods',
  [SERVICE]: 'Services/Membership',
  [EVENT]: 'Event Tickets',
};
