import PropTypes from 'prop-types';
import React from 'react';

import { InputNumber } from '../../components/FormComponents';
import { Columns, Row } from '../../components/Grid';

class PrefixedInput extends React.Component {
  render() {
    const { prefix, ...others } = this.props;
    return (
      <Row className="collapse prefix-radius">
        <Columns className="small-2">
          <span
            className="prefix"
            style={{
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px',
              borderTopRightRadius: '0px',
              borderBottomRightRadius: '0px',
            }}
          >
            {prefix}
          </span>
        </Columns>
        <Columns className="small-10">
          {others.type === 'number' ? <InputNumber {...others} /> : <input {...others} />}
        </Columns>
      </Row>
    );
  }
}

PrefixedInput.propTypes = {
  prefix: PropTypes.any.isRequired,
};

export default PrefixedInput;
