import React from 'react';

import Loading from '../../../../common/components/Loading';
import TrustIcons from './TrustIcons';

const PaymentCompletionLoader = () => (
  <div className="payments-form soft--top soft-quarter--bottom">
    <Loading className="big" />
    <div className="text-center font-rc push-double--bottom" style={{ color: '#3EC47B' }}>
      Waiting for Payment Confirmation...
    </div>
    <TrustIcons />
  </div>
);

export default PaymentCompletionLoader;
