import PropTypes from 'prop-types';
import React from 'react';

import MojoPopup from '../../../../common/components/MojoPopup';
import { isSmallDevice } from '../../../../common/config/utils';
import { findUpiInfo } from '../../config/Upi';
import FindUpi from './FindUpi';

const UpiLabelPopup = ({ closeUpiPopup, showUpiPopup, selectedUPIOption }) => (
  <div>
    <MojoPopup
      show={showUpiPopup}
      onClose={() => closeUpiPopup(false)}
      showCloseModalIcon={true}
      contentContainerStyle={isSmallDevice() ? { width: '320px' } : { width: '440px' }}
    >
      <div className="soft-double hard--bottom">
        <p style={{ color: '#2C475D' }}>Where can I find my UPI ID?</p>
        <FindUpi obj={findUpiInfo} selectedUPIOption={selectedUPIOption} />
      </div>
    </MojoPopup>
  </div>
);

UpiLabelPopup.propTypes = {
  closeUpiPopup: PropTypes.func,
  showUpiPopup: PropTypes.bool,
  selectedUPIOption: PropTypes.string,
};

export default UpiLabelPopup;
