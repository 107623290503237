import '../../styles/payment-options.scss';

import PropTypes from 'prop-types';
import React from 'react';

import TOSModal from '../initialize/TOSModal';
import DynamicQR from './render-payment-option/DynamicQR';
import MobileUPIIntent from './render-payment-option/MobileUPIIntent';
import PayPalSDK from './render-payment-option/PayPalSDK';
import SavedCards from './SavedCards';

export default class PaymentOptions extends React.Component {
  renderPaymentOption(paymentOptionData) {
    const { name, type, submissionUri, ...others } = paymentOptionData;
    const { paymentOptions: { dynamic_qr_qr, dynamic_qr_initiate_qr_init = false } = {}, upiOptions } = this.props.finalizePurchase;
    const upiIntentPositionOutside = upiOptions?.upiIntentPosition === 'outside' || false;
    // This check is used when payment_groups are not present.
    const showPaymentOption = (submissionUri  // submissionURI is required for every method except QR/UPIIntent
    && (name === 'UPI' ? !dynamic_qr_initiate_qr_init && !dynamic_qr_qr?.isUpiIntentEnabled : true))  // UPI will not be shown when UPIIntent is enabled
    || (name === 'QR' && dynamic_qr_qr?.isUpiIntentEnabled);  // QR doesn't have submissionURI, hence a separate check

    return (
      <>
        {this.props.finalizePurchase.paymentGroups && others.group === 'upi' ? (
          name === 'QR' ? ( // incase of dynamic QR
            <DynamicQR
              key={name}
              dynamicQrDetails={this.props.finalizePurchase.dynamicQrDetails}
              convenienceFeeVersion={this.props.finalizePurchase.convenienceFeeVersion}
              handleFetchConvenienceFeesForQR={() =>
                this.props.handleFetchConvenienceFeesForQR()
              }
              pollForUPIAcceptance={(statusCheckUrl) =>
                this.props.pollForUPIAcceptance(statusCheckUrl)
              }
              {...others}
            />
          ) : null
        ) : others.group === 'international_payments' ? ( // Adding this for the International payments flow(below block of code)
          this.props.finalizePurchase.paymentGroups ? ( // When payment groups exist, we want to show a group flow
            // --------------- International Payments block start -----------------------
            <>
              <div className="push--bottom">International Payments</div>
              <PayPalSDK
                totalAmount={this.props.finalizePurchase.totalAmount}
                showPaypalSuccessPage={this.props.finalizePurchase.showPaypalSuccessPage}
                showPaypalErrorBlock={this.props.finalizePurchase.showPaypalErrorBlock}
                handlePaypalSuccessfulCallback={() =>
                  this.props.handlePaypalSuccessfulCallback()
                }
                handlePaypalSDKError={() => this.props.handlePaypalSDKError()}
                {...others}
              />
            </> // When payment groups dont exist, we want to show it as part of the payment options
          ) : (
            <>
              <p className="soft-half--bottom">Are you paying using a non-Indian card?</p>
              <li key={name}>
                <a
                  className="btn btn-block text-left text-transform-none"
                  onClick={() => this.props.handleSelectPaymentOption({ name, type })}
                >
                  <div>
                    <span className={`payment-option-${type} left`} />
                    <span className="soft--left left" style={{ lineHeight: '24px' }}>
                      {' '}
                      International Payments{' '}
                    </span>
                  </div>
                </a>
              </li>
            </>
          )
        ) : (
          // --------------- International Payments block end -----------------------
          <li key={name}>
            {showPaymentOption ? (
              <>
                {(name === 'QR' && upiIntentPositionOutside) ? (
                  <MobileUPIIntent
                    finalizePurchase={this.props.finalizePurchase}
                    username={this.props.username}
                    showConvenienceFee={this.props.showConvenienceFee}
                    upiIntentPositionOutside={upiIntentPositionOutside}
                  />
                ) : (
                    <a
                    className="btn btn-block text-left text-transform-none"
                    style={{
                      fontWeight: 'normal',
                      marginBottom: '6px',
                      verticalAlign: 'center',
                    }}
                    onClick={() => this.props.handleSelectPaymentOption({ name, type })}
                    >
                    {((name === 'QR' || name === 'QR_INIT') && !upiIntentPositionOutside) || name === 'UPI' ? (
                      // to render it in the non_upi grouping
                      <div
                        style={{
                          display: 'table-cell',
                          verticalAlign: 'middle',
                          height: '24px',
                          margin: 'auto',
                        }}
                      >
                        <span
                          className="payment-option-upi_group_color"
                          style={{ display: 'inherit' }}
                        />
                      </div>
                    ) : (
                      <div>
                        <span className={`payment-option-${type} left`} />
                        <span className="soft--left left" style={{ lineHeight: '24px' }}>
                          {' '}
                          {name}{' '}
                        </span>
                      </div>
                    )}
                    </a>
                  )
                }
              </>
            ) : null}
          </li>
        )}
      </>
    );
  }

  render() {
    const {
      paymentOptions,
      showCardDeletionOption,
      paymentGroups,
    } = this.props.finalizePurchase;

    const { paymentOptionGroup } = this.props;

    const paymentOptionsToRender = paymentGroups
      ? paymentGroups[paymentOptionGroup] || (paymentOptionGroup === 'non_upi' && paymentGroups['upi'])
      : paymentOptions;
    // making sure that all payment options are considered in case of no grouping
    const isGroupNotUPI = paymentOptionGroup === 'non_upi' || paymentGroups === null;
    return Object.keys(paymentOptionsToRender).length ? (
      <div>
        {isGroupNotUPI ? (
          <SavedCards
            savedCards={paymentOptions['saved_cards_savedcards']}
            finalizePurchase={this.props.finalizePurchase}
            handleSelectPaymentOption={(selectedPaymentDetails) =>
              this.props.handleSelectPaymentOption(selectedPaymentDetails)
            }
            handleManageSavedCards={(payload) =>
              this.props.handleManageSavedCards(payload)
            }
            handleDeleteSavedCard={(cardToBeDeleted) =>
              this.props.handleDeleteSavedCard(cardToBeDeleted)
            }
          />
        ) : null}
        {!showCardDeletionOption ? (
          <div>
            {paymentGroups === null ? (
              <p className="text-light-grey">Select a Payment Method</p>
            ) : null}
            <ul className="push-half--top">
              {this.props.finalizePurchase.paymentGroups && (
                <li className='hide-for-small-only'>
                  <a
                    className="btn btn-block text-left text-transform-none"
                    style={{
                      fontWeight: 'normal',
                      marginBottom: '6px',
                      verticalAlign: 'center',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                    }}
                    onClick={() => this.props.handleSelectPaymentOption({ name: 'UPI', type: 'upi_qr' })}
                  >
                    {(
                      <div
                        style={{
                          display: 'table-cell',
                          verticalAlign: 'middle',
                          height: '24px',
                          margin: 'auto',
                        }}
                      >
                        <span
                          className="payment-option-upi_group_color"
                          style={{ display: 'inherit' }}
                        />
                      </div>
                    )}
                  </a>
                </li>
              )}
              {Object.keys(paymentOptionsToRender).map((optionKey) => this.renderPaymentOption(paymentOptionsToRender[optionKey]))}
            </ul>
          </div>
        ) : null}
        <TOSModal />
      </div>
    ) : null;
  }
}

PaymentOptions.propTypes = {
  pollForUPIAcceptance: PropTypes.func,
  handleFetchConvenienceFeesForQR: PropTypes.func,
  finalizePurchase: PropTypes.object,
  paymentOptionGroup: PropTypes.string,
  handleSelectPaymentOption: PropTypes.func,
  handleManageSavedCards: PropTypes.func,
  handleDeleteSavedCard: PropTypes.func,
  handlePaypalSuccessfulCallback: PropTypes.func,
  handlePaypalSDKError: PropTypes.func,
  username: PropTypes.string,
  showConvenienceFee: PropTypes.bool,
  upiOptions: PropTypes.object,
};
