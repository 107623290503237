import PropTypes from 'prop-types';
import React from 'react';

export class Row extends React.Component {
  render() {
    const p = { ...this.props };
    const { className, tag } = p;
    const rowClasses = `row ${className}`;
    delete p.className;
    delete p.tag;
    return React.createElement(tag, { className: rowClasses, ...p }, this.props.children);
  }
}

Row.defaultProps = {
  className: '',
  tag: 'div',
};

Row.propTypes = {
  children: PropTypes.node,
};

export class Columns extends React.Component {
  render() {
    const p = { ...this.props };
    const { className } = p;
    const columnClasses = `columns ${className}`;
    delete p.className;
    return (
      <div className={columnClasses} {...p}>
        {this.props.children}
      </div>
    );
  }
}

Columns.defaultProps = {
  className: '',
};

Columns.propTypes = {
  children: PropTypes.node,
};
