import PropTypes from 'prop-types';
import React from 'react';

import { isSmallDevice, objectWithout } from '../../config/utils';

class SingleOtpInput extends React.Component {
  componentDidMount() {
    const {
      input,
      props: { focus, shouldautofocus },
    } = this;

    if (input && focus && shouldautofocus) {
      input.focus();
      input.select();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      input,
      props: { focus },
    } = this;

    // Check if focusedInput changed
    // Prevent calling function if input already in focus
    if (prevProps.focus !== focus && input && focus) {
      input.focus();
    }
  }

  getClasses = (...classes) => classes.filter((c) => c !== false).join(' ');

  render() {
    const {
      separator,
      isLastChild,
      inputClassnames,
      inputStyle,
      focus,
      isDisabled,
      hasErrored,
      errorClassnames,
      errorStyle,
      focusClassnames,
      focusStyle,
      disabledClassnames,
      disabledStyle,
      isInputNum,
      value,
      shouldautofocus,
      ...rest
    } = this.props;

    const rest1 = objectWithout(rest, shouldautofocus);
    const numValueLimits = isInputNum ? { min: 0, max: 9 } : {};

    return (
      <div style={{ display: 'flex' }}>
        <input
          style={Object.assign(
            {
              textAlign: 'center',
              width: isSmallDevice() ? '37px' : '44px',
              height: isSmallDevice() ? '37px' : '44px',
              borderRadius: '2px',
              border: '1px solid rgba(78,78,145,0.25)',
            },
            inputStyle,
            focus &&
              Object.assign(
                {
                  border: '1px solid #4e4e91',
                },
                focusStyle,
              ),
            value && {
              backgroundColor: '#f8f9fa',
            },
            isDisabled && disabledStyle,
            hasErrored && errorStyle,
          )}
          className={this.getClasses(
            'strong',
            inputClassnames,
            focus && focusClassnames,
            isDisabled && disabledClassnames,
            hasErrored && errorClassnames,
          )}
          type={isInputNum ? 'number' : 'tel'}
          {...numValueLimits}
          maxLength="1"
          ref={(input) => {
            this.input = input;
          }}
          disabled={isDisabled}
          value={value ? value : ''}
          {...rest1}
        />
        {!isLastChild && separator}
      </div>
    );
  }
}

SingleOtpInput.propTypes = {
  focus: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onInput: PropTypes.func,
  onPaste: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  separator: PropTypes.node,
  inputStyle: PropTypes.object,
  inputClassnames: PropTypes.string,
  focusStyle: PropTypes.object,
  focusClassnames: PropTypes.string,
  isLastChild: PropTypes.bool,
  isDisabled: PropTypes.bool,
  disabledStyle: PropTypes.object,
  disabledClassnames: PropTypes.string,
  hasErrored: PropTypes.bool,
  errorStyle: PropTypes.object,
  errorClassnames: PropTypes.string,
  shouldautofocus: PropTypes.bool,
  isInputNum: PropTypes.bool,
};

export default SingleOtpInput;
