import PropTypes from 'prop-types';
import React from 'react';

class InputToggleCheckbox extends React.Component {
  render() {
    const {
      label,
      className,
      style,
      uniqueId,
      isDisabled,
      checkboxClassName,
      ...others
    } = this.props;

    const additionalClassName = isDisabled ? 'tgl-disabled' : '';
    return (
      <label className={className} style={style}>
        <input
          type="checkbox"
          id={uniqueId}
          className={`tgl tgl-light push-half--right ${checkboxClassName}`}
          {...others}
        />
        <label className={`tgl-btn ${additionalClassName}`} htmlFor={uniqueId} />
        {label}
      </label>
    );
  }
}

InputToggleCheckbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  uniqueId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  isDisabled: PropTypes.bool,
  checkboxClassName: PropTypes.string,
};

InputToggleCheckbox.defaultProps = {
  checkboxClassName: '',
};

export default InputToggleCheckbox;
