/* eslint no-console: 0 */
export default function serviceWorkerRegistration() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/sw.js', { scope: '/' })
      .then((registration) => {
        console.log('Service Worker Registered', registration);
        registration.onupdatefound = function () {
          const { installing: installingWorker } = registration;
          installingWorker.onstatechange = function () {
            switch (installingWorker.state) {
              case 'installed':
                if (navigator.serviceWorker.controller) {
                  // At this point, the old content would have been purged
                  // and fresh content added to the cache
                  // perfect time to show the user toast to refresh page
                  console.log('New and Updated Content is now Available');
                } else {
                  // No existing controller
                  // Everything has been cached.
                  // Can show a message "Content cached for offline use".
                  console.log('Content is now available Offline!');
                }
                break;

              case 'redundant':
                console.log('The installing service worker became redundant');
            }
          };
        };
      })
      .catch((e) => {
        console.error('Error during Service Worker registration', e);
      });

    navigator.serviceWorker.ready.then((registration) => {
      console.log('Service Worker Ready', registration);
    });
  }
}

export function sendMessage(message) {
  // This wraps the message posting/response in a promise, which will resolve if the response doesn't
  // contain an error, and reject with the error if it does. If you'd prefer, it's possible to call
  // controller.postMessage() and set up the onmessage handler independently of a promise, but this is
  // a convenient wrapper.
  return new Promise((resolve, reject) => {
    const messageChannel = new MessageChannel();
    messageChannel.port1.onmessage = function (event) {
      if (event.data.error) {
        reject(event.data.error);
      } else {
        resolve(event.data);
      }
    };

    // This sends the message data as well as transferring messageChannel.port2 to the service worker.
    // The service worker can then use the transferred port to reply via postMessage(), which
    // will in turn trigger the onmessage handler on messageChannel.port1.
    // See https://html.spec.whatwg.org/multipage/workers.html#dom-worker-postmessage
    navigator.serviceWorker.controller.postMessage(message, [messageChannel.port2]);
  });
}
