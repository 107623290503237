import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import apiMiddleware from '../../common/middlewares/api';
import formMiddleware from '../../common/middlewares/form';
import instapayRootReducer from '../../payments/payments/reducers/instapay';
import linksRootReducer from '../../payments/payments/reducers/links';
import responseRootReducer from '../../payments/payments/reducers/paymentResponse';
import offersRootReducer from './rootReducer';

const logger = createLogger({
  level: 'info',
  collapsed: false,
});

const createStoreWithMiddleware = compose(
  applyMiddleware(thunkMiddleware, apiMiddleware, formMiddleware),
  applyMiddleware(logger),
)(createStore);

/**
 * Create a preconfigured store with middlewares
 */
export default function configureStore() {
  /* globals INITIAL_STATE */
  let rootReducer;
  if (INITIAL_STATE.is_payment_response) {
    rootReducer = responseRootReducer;
  } else if (INITIAL_STATE.is_link_payment) {
    rootReducer = linksRootReducer;
  } else if (INITIAL_STATE.is_instapay_payment) {
    rootReducer = instapayRootReducer;
  } else {
    rootReducer = offersRootReducer;
  }

  return createStoreWithMiddleware(rootReducer);
}
