import PropTypes from 'prop-types';
import React from 'react';

class AccordionCard extends React.PureComponent {
  render() {
    return (
      <div
        onClick={(...args) => this.props.onClick(...args)}
        className={this.props.className}
        style={this.props.isDetailViewOpen ? { cursor: 'default' } : {}}
      >
        {this.props.cardHeading}
        {this.props.isDetailViewOpen ? this.props.children : null}
      </div>
    );
  }
}

AccordionCard.propTypes = {
  isDetailViewOpen: PropTypes.bool,
  cardHeading: PropTypes.node,
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default AccordionCard;
