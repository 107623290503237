import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  handleFetchConvenienceFees,
  handleSelectWallet,
  handleWalletPaymentRequest,
  validateWalletPaymentRequest,
} from '../../../actions/FinalizePurchaseActions';
import GridPaymentLayout from './GridPaymentLayout';

class Wallet extends React.Component {
  render() {
    const {
      errors,
      selectedWalletId,
      selectedPaymentObject,
    } = this.props.finalizePurchase;
    const { isWalletPaymentRequestSubmitted } = this.props.finalizePurchase;

    const { walletList } = selectedPaymentObject;
    return (
      <GridPaymentLayout
        errorMessage={errors.selectedWallet}
        selectedPaymentOptionId={selectedWalletId}
        optionsList={walletList}
        paymentOptionKey={'walletId'}
        submitKey={'wallet'}
        handlePaymentRequest={() => this.props.handleWalletPaymentRequest()}
        handleSelectPaymentOption={(selectedWalletData) =>
          this.props.handleSelectWallet(selectedWalletData)
        }
        isPaymentRequestSubmitted={isWalletPaymentRequestSubmitted}
        finalizePurchase={this.props.finalizePurchase}
        showConvenienceFee={this.props.showConvenienceFee}
        handleFetchConvenienceFees={() => this.props.handleFetchConvenienceFees()}
        validateForm={(reportFailureOnly) =>
          this.props.validateWalletPaymentRequest(reportFailureOnly)
        }
      />
    );
  }
}

Wallet.propTypes = {
  finalizePurchase: PropTypes.object,
  handleWalletPaymentRequest: PropTypes.func,
  handleFetchConvenienceFees: PropTypes.func,
  validateWalletPaymentRequest: PropTypes.func,
  handleSelectWallet: PropTypes.func,
  showConvenienceFee: PropTypes.bool,
};

export default connect(() => ({}), {
  handleWalletPaymentRequest,
  handleSelectWallet,
  handleFetchConvenienceFees,
  validateWalletPaymentRequest,
})(Wallet);
