import Rupee from '@instamojo/rupee';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  FormFieldWithLabel as FormField,
  FormFieldWrapper as FormFieldsWrapper,
} from '../../../../../common/components/FormComponents';
import { Columns, Row } from '../../../../../common/components/Grid';
import Icon from '../../../../../common/components/Icon';
import {
  beneficiaryInfo,
  handleNeftBankError,
  handleNeftBankOption,
  showNeftCredentials,
} from '../../../actions/FinalizePurchaseActions';
import { neftBankList } from '../../../config/Neft';

class BankTransfer extends React.Component {
  renderNeftBankGrid({ bank }) {
    const { selectedNeftBankId, order } = this.props.finalizePurchase;
    return (
      <Columns key={bank.id} className="small-6 grid__item end">
        <div
          onClick={() =>
            this.props.handleNeftBankOption(bank.id, this.props.username, order)
          }
          className={classNames('grid__item--unit', {
            'bank-selected': bank.id === selectedNeftBankId,
          })}
        >
          <div style={{ height: '40px', display: 'table', width: '100%' }}>
            <div
              style={{
                display: 'table-cell',
                verticalAlign: 'middle',
                textAlign: 'center',
              }}
            >
              <span
                className={`banks-sprite banks-bank-${bank.id}`}
                style={{ verticalAlign: 'middle' }}
              />
              <span className="bank-list__bank-name">{bank.name}</span>
            </div>
          </div>
        </div>
      </Columns>
    );
  }

  renderBankOption(bank) {
    return (
      <option key={bank.id} value={bank.id}>
        {bank.name}
      </option>
    );
  }

  render() {
    const {
      neftDetails: { ifscCode, bankName, accountNumber, beneficiaryName } = {},
      selectedPaymentObject,
      selectedNeftBankId,
      showNeftDetails,
      errors,
    } = this.props.finalizePurchase;

    const { totalAmount } = selectedPaymentObject;

    let accountNumberArr = [];
    if (accountNumber) {
      accountNumberArr = accountNumber.toString().match(/.{1,5}/g);
    }

    const titleClass = 'text-light-grey text-medium';
    const valueClass = 'soft--bottom zeta';

    return (
      <div className="soft--ends">
        {showNeftDetails ? (
          <div>
            <div
              className="soft--bottom"
              style={{ fontSize: '15px', lineHeight: '1.5', color: 'rgb(67, 74, 84)' }}
            >
              Beneficiary Details
            </div>
            <div className="soft--top" style={{ borderBottom: '1px solid #eee' }}>
              <p className={titleClass}> Account Number </p>
              <p
                className={valueClass}
                style={{
                  WebkitFontSmoothing: 'subpixel-antialiased',
                  fontFamily: 'PT Mono',
                }}
              >
                {accountNumberArr.map((n, i) => (
                  <span key={i} style={{ paddingRight: '10px' }}>
                    {n}
                  </span>
                ))}
              </p>
              <Columns className="small-5 hard--left">
                <p className={titleClass}> IFSC Code </p>
                <p
                  className={valueClass}
                  style={{
                    WebkitFontSmoothing: 'subpixel-antialiased',
                    fontFamily: 'PT Mono',
                    letterSpacing: '1px',
                  }}
                >
                  {' '}
                  {ifscCode}{' '}
                </p>
              </Columns>
              <Columns className="small-7 soft-double--left">
                <p className={titleClass}> Bank Name </p>
                <p className={`${valueClass} text-overflow-ellipsis`}>
                  {' '}
                  <abbr title={bankName} /> {bankName}{' '}
                </p>
              </Columns>
              <p className={titleClass}> Beneficiary Name </p>
              <p className={valueClass}> {beneficiaryName} </p>
            </div>
            <div className="push--top">
              <p> Amount to be paid </p>
              <p className={valueClass}>
                {' '}
                <Rupee value={totalAmount} />{' '}
              </p>
            </div>
            <div>
              {selectedNeftBankId != 9999 ? (
                <FormFieldsWrapper>
                  <FormField>
                    <a
                      onClick={() => this.props.beneficiaryInfo(true)}
                      className="btn-block btn-green"
                    >
                      Add beneficiary
                    </a>
                  </FormField>
                </FormFieldsWrapper>
              ) : (
                <div>
                  <a
                    className="block btn soft push--bottom"
                    style={{
                      cursor: 'default',
                      textAlign: 'left',
                      fontSize: '14px',
                      fontWeight: '600',
                      textTransform: 'none',
                      background: '#E6EFFA',
                    }}
                  >
                    Please go to your Bank&rsquo;s website & pay using NEFT.
                  </a>
                  <a
                    className="btn push--bottom soft-double--bottom block"
                    onClick={() => this.props.beneficiaryInfo(true, true)}
                  >
                    <span className="left">How to pay using NEFT?</span>
                    <span className="right">
                      <Icon
                        className="arrows-1_minimal-right"
                        style={{ fontSize: '12px' }}
                      />
                    </span>
                  </a>
                </div>
              )}
              <div className="text-center push-half--bottom">
                <a onClick={() => this.props.showNeftCredentials(false)}>
                  <Icon className="arrows-1_minimal-left" style={{ fontSize: '12px' }} />
                  Back
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <Row className="banks--grid soft--sides">
              {neftBankList.slice(0, 6).map((bank) =>
                this.renderNeftBankGrid({
                  bank,
                }),
              )}
              <FormFieldsWrapper>
                <FormField
                  columns="columns small-12 medium-12 large-12"
                  errors={errors.selectedBank}
                >
                  <select
                    value={selectedNeftBankId ? selectedNeftBankId : ''}
                    onChange={(e) =>
                      this.props.handleNeftBankOption(
                        e.target.value,
                        this.props.username,
                        this.props.finalizePurchase.order,
                      )
                    }
                  >
                    <option value="" disabled>
                      Choose another Bank
                    </option>
                    {neftBankList.map((bank) => this.renderBankOption(bank))}
                  </select>
                </FormField>
              </FormFieldsWrapper>
            </Row>
            <FormFieldsWrapper>
              <FormField>
                <a
                  onClick={() => {
                    selectedNeftBankId
                      ? this.props.showNeftCredentials(true)
                      : this.props.handleNeftBankError();
                  }}
                  className="btn-block btn-green"
                  style={{ marginBottom: 7 }}
                >
                  NEXT
                </a>
              </FormField>
            </FormFieldsWrapper>
          </div>
        )}
      </div>
    );
  }
}

BankTransfer.propTypes = {
  username: PropTypes.string,
  finalizePurchase: PropTypes.object,

  handleNeftBankOption: PropTypes.func,
  beneficiaryInfo: PropTypes.func,
  showNeftCredentials: PropTypes.func,
  handleNeftBankError: PropTypes.func,
};

export default connect(() => ({}), {
  handleNeftBankOption,
  beneficiaryInfo,
  showNeftCredentials,
  handleNeftBankError,
})(BankTransfer);
