import ErrorsHigherOrderComponent from '@instamojo/errors-hoc';
import classnames from 'classnames';
import React from 'react';

export default class InputTextWithErrors extends ErrorsHigherOrderComponent {
  render() {
    const { className, errors, ...others } = this.props;
    const inputClassNames = classnames({
      className,
      'can-has-errors': 'errors' in this.props,
      'has-errors':
        (Array.isArray(errors) && errors.filter((error) => error).length) ||
        (typeof errors === 'string' && errors.length),
    });
    return (
      <div className={inputClassNames}>
        <input type="text" {...others} />
        {errors ? this.getErrorsBlock(errors) : null}
      </div>
    );
  }
}
