import PropTypes from 'prop-types';
import React from 'react';
import { Motion, presets, spring } from 'react-motion';

const BreadcrumbAnimation = (props) => (
  <Motion
    defaultStyle={{ opacity: 0.1, x: props.isBackwardMotion ? -10 : 10 }}
    style={{ opacity: spring(1, presets.gentle), x: spring(0) }}
  >
    {(config) => (
      <div style={{ opacity: config.opacity, transform: `translateX(${config.x}px)` }}>
        {props.children}
      </div>
    )}
  </Motion>
);

export default BreadcrumbAnimation;

BreadcrumbAnimation.propTypes = {
  isBackwardMotion: PropTypes.bool,
  children: PropTypes.node,
};
