import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../../components/Icon';

const ModalClose = ({ onRequestClose, className, style }) => (
  <div
    className={className}
    style={{
      position: 'absolute',
      right: '50%',
      top: '6px',
      color: '#fff',
      cursor: 'pointer',
      ...style,
    }}
    onClick={() => {
      onRequestClose();
    }}
  >
    <Icon className="ui-1_circle-remove_modal medium" />
  </div>
);

ModalClose.defaultProps = {
  style: {},
};

ModalClose.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
};

export default ModalClose;
