/*eslint no-unused-vars: ["error", { "vars": "local" }]*/
/*eslint no-global-assign: 0 */

if (process.env.NODE_ENV !== 'development') {
  /* globals __webpack_public_path__ */
  __webpack_public_path__ = `${window.STATIC_URL}assets/js/bundle/`;
}

import render from '../../common/utils/renderToDOM';
import Root from './root';

render(Root, 'payment-links-area');
