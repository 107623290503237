import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../../components/Icon';

const ModalClose2 = ({ onRequestClose, closeIconStyles = {} }) => (
  <div
    style={{
      position: 'absolute',
      right: '6px',
      top: '11px',
      color: 'red',
      cursor: 'pointer',
    }}
    onClick={() => onRequestClose()}
  >
    <Icon className="ui-1_circle-remove_modal medium" style={closeIconStyles} />
  </div>
);

ModalClose2.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  closeIconStyles: PropTypes.object,
};

export default ModalClose2;
