import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
export default class Form extends Component {
  constructor() {
    super();
  }

  render() {
    const columns = this.props.columns
      ? `columns ${this.props.columns}`
      : 'columns small-12';
    let rowClassName = this.props.noWrapInRow ? '' : 'row';
    const listWrapperClassName =
      'form-items-container ' +
      (this.props.listWrapperClassName ? this.props.listWrapperClassName : '');
    rowClassName = classNames(rowClassName, this.props.className);

    return (
      <div className={rowClassName}>
        <div className={columns}>
          <ul className={listWrapperClassName}>{this.props.children}</ul>
        </div>
      </div>
    );
  }
}

Form.propTypes = {
  columns: PropTypes.string,
  noWrapInRow: PropTypes.bool,
  listWrapperClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};
