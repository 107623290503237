import '../../../../../assets/scss/components/_react-input-range.scss';

import PropTypes from 'prop-types';
import React from 'react';
import InputRange from 'react-input-range';

const InputSlider = (props) => {
  const { minValue, maxValue, value, onChange, onChangeComplete } = props;
  const parsedValue = value ? parseFloat(value) : 0; // To make sure a number is passed down to the InputRange
  return (
    <InputRange
      maxValue={maxValue}
      minValue={minValue}
      value={parsedValue}
      onChange={(changedValue) => onChange(changedValue)}
      onChangeComplete={(changedValue) => onChangeComplete(changedValue)}
    />
  );
};

InputSlider.propTypes = {
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeComplete: PropTypes.func,
};

export default InputSlider;
