import PropTypes from 'prop-types';
import React from 'react';

const ItemDetails = (props) => (
  <div>
    <p className="f14 break-long-words soft--right">{props.title}</p>
    {props.variants.length ? (
      <p className="f12 break-long-words soft--right" style={{ color: '#839AAD' }}>
        {' '}
        {props.variants.map((variant, index) => (
          <span key={index}>
            <span>
              {variant.categoryName}: {variant.title}
            </span>
            {index === props.variants.length - 1 ? '' : ', '}
          </span>
        ))}
      </p>
    ) : null}
    {props.customFields.length ? (
      <p className="f12 break-long-words soft--right" style={{ color: '#839AAD' }}>
        {' '}
        {props.customFields.map((field, index) => (
          <span key={index}>
            <span>
              {field.label}: {field.value}
            </span>
            {index === props.customFields.length - 1 ? '' : ', '}
          </span>
        ))}
      </p>
    ) : null}
    {props.readOnly && props.shipsIn ? (
      <p className="f12 text-green">Ships in {props.shipsIn} days</p>
    ) : null}
  </div>
);

ItemDetails.propTypes = {
  readOnly: PropTypes.string,
  shipsIn: PropTypes.string,
  customFields: PropTypes.object,
  variants: PropTypes.object,
  title: PropTypes.string,
};

export default ItemDetails;
