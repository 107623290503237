export const TABS = {
  OFFER_DETAILS: 'OfferDetails',
  INITIALIZE: 'Initialize',
  REFINE: 'Refine',
  FINALIZE: 'Finalize',
};

export const paymentModesNameMappings = {
  cards: 'Debit and Credit Cards',
  emi: 'EMI',
  international_payment: 'International Payments',
  net_banking: 'Net Banking',
  paylater: 'Paylater',
  upi: 'UPI',
  qr: 'UPI QR Code',
  wallet: 'Wallets',
};
