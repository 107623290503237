import transactionCategoryConstants from '../../../common/analytics/transactionCategoryConstants';

function getAmountAnalytics(amount) {
  return {
    amount: amount || 0,
    transaction_category: amount
      ? transactionCategoryConstants.INR
      : transactionCategoryConstants.Free,
  };
}

export default getAmountAnalytics;
