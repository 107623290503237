/* globals paypal STATIC_URL INITIAL_STATE */
import PropTypes from 'prop-types';
import React from 'react';
import $script from 'scriptjs';

import { Columns, Row } from '../../../../../common/components/Grid';
import { ModalContent2, MojoPopup2 } from '../../../../../common/components/MojoPopup2';
import ia from '../../../../../common/config/Analytics';

const trackPayPalButtonClickEvent = () => {
  ia.sendEvent('Selected Paypal Option', {
    payment_mode: 'paypal',
  });
};

const trackSuccessfulPaymentCaptureEvent = (details) => {
  ia.sendEvent('Received Integration Payment Capture', {
    integration_name: 'paypal',
    capture_status: 'success',
    buyer_email: details.payer.email_address,
    buyer_first_name: details.payer.name.given_name,
    buyer_last_name: details.payer.name.surname,
    buyer_country_code: details.payer.address.country_code,
    seller_id: details.purchase_units[0].payee.merchant_id,
    seller_email: details.purchase_units[0].payee.email_address,
    amount: details.purchase_units[0].amount.value,
    currency: details.purchase_units[0].amount.currency_code,
  });
};

class PayPalSDK extends React.Component {
  componentDidMount() {
    const clientId = this.props.details.clientId;
    const totalAmount = Number(this.props.totalAmount);
    const handlePaypalSuccessfulCallback = this.props.handlePaypalSuccessfulCallback;
    const handlePaypalSDKError = this.props.handlePaypalSDKError;
    const inrUsdConversionRate = Number(this.props.details.inrUsdConversionRate);

    // Below script is according to PayPalSDK's SDK usage
    $script([`https://www.paypal.com/sdk/js?client-id=${clientId}&currency=USD`], () => {
      try {
        paypal
          .Buttons({
            createOrder: function (data, actions) {
              trackPayPalButtonClickEvent(); // To track that the paypal option was selected in the SDK
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: (totalAmount * inrUsdConversionRate).toFixed(2),
                    },
                  },
                ],
              });
            },
            // OnApprove is triggered after successful transaction
            onApprove: function (data, actions) {
              return actions.order.capture().then((details) => {
                trackSuccessfulPaymentCaptureEvent(details); // To track the successful payments made by buyers using PayPal
                handlePaypalSuccessfulCallback(); // This will trigger the PayPal Success PopUp
              });
            },
          })
          .render('#paypal-button-container');
      } catch (err) {
        handlePaypalSDKError();
      }
    });
  }

  render() {
    const inrUsdConversionRate = Number(this.props.details.inrUsdConversionRate);
    const totalAmount = Number(this.props.totalAmount);
    return (
      <div>
        <div id="paypal-button-container" />
        <MojoPopup2 // This is the PayPal Success PopUp
          isModalOpen={this.props.showPaypalSuccessPage}
          onCloseModal={() => null} // Dont want to allow popup to be closed
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          shouldShowCloseIcon={false}
        >
          <div className="payments-header">
            <div
              className="soft border-radius banner-background"
              style={{ backgroundColor: '#617389' }}
            >
              <Row>
                <Columns className="small-10">
                  <p className="flush--bottom break-long-words soft-half--top white">
                    {INITIAL_STATE?.seller?.name}
                  </p>
                </Columns>
              </Row>
            </div>
          </div>
          <ModalContent2 className="font-rc">
            <div className="text-center">
              <img
                className="soft--ends"
                src={`${STATIC_URL}assets/images/tick.png`}
                alt="success"
              />
              <h3 className="text-green font-rc">Payment Successful!</h3>
            </div>
            <hr className="divider-line" />
            <table
              className="table-compact table-vertical-center table-colorless text-medium"
              style={{ width: '100%' }}
            >
              <tbody>
                <tr>
                  <td className="text-bold">Amount</td>
                  <td className="float--right">
                    $ {(totalAmount * inrUsdConversionRate).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="text-bold">Paid to</td>
                  <td
                    title={INITIAL_STATE?.seller?.name}
                    width="210px"
                    className="float--right right text-ellipsis"
                  >
                    {INITIAL_STATE?.seller?.name}
                  </td>
                </tr>
              </tbody>
            </table>
            <hr className="divider-line" />
            <div className="text-medium">
              <div className="soft-half--sides">
                You will receive an email from PayPal about your payment.
              </div>
              <hr className="divider-line" />
              <div className="text-center">
                <p className="soft-half--bottom">
                  Reach out to PayPal in case of queries.
                </p>
                <a
                  href="https://www.paypal.com/in/smarthelp/contact-us"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="soft-half--bottom"
                >
                  Contact PayPal
                </a>
              </div>
            </div>
          </ModalContent2>
        </MojoPopup2>
        {this.props.showPaypalErrorBlock ? (
          <div className="push--ends error-message-block">
            We are facing issue with the seller&apos;s PayPal account.
            <br />
            <br />
            Please try again later or contact the seller.
          </div>
        ) : null}
      </div>
    );
  }
}

PayPalSDK.propTypes = {
  details: PropTypes.object,
  totalAmount: PropTypes.string,
  showPaypalSuccessPage: PropTypes.bool,
  showPaypalErrorBlock: PropTypes.bool,
  handlePaypalSuccessfulCallback: PropTypes.func,
  handlePaypalSDKError: PropTypes.func,
};

export default PayPalSDK;
