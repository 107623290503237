export const neftBankList = [
  {
    id: 1,
    name: 'SBI',
    url: 'https://retail.onlinesbi.com/retail/login.htm',
    value: 'SBI',
  },
  {
    id: 2,
    name: 'ICICI',
    url:
      'https://infinity.icicibank.com/corp/AuthenticationController?FORMSGROUP_ID__=AuthenticationFG&__START_TRAN_FLAG__=Y&FG_BUTTONS__=LOAD&ACTION.LOAD=Y&AuthenticationFG.LOGIN_FLAG=1&BANK_ID=ICI',
    value: 'ICICI',
  },
  {
    id: 3,
    name: 'HDFC',
    url: 'https://netbanking.hdfcbank.com/netbanking/',
    value: 'HDFC',
  },
  {
    id: 4,
    name: 'AXIS',
    url:
      'https://retail.axisbank.co.in/wps/portal/rBanking/axisebanking/AxisRetailLogin/!ut/p/a1/04_Sj9CPykssy0xPLMnMz0vMAfGjzOKNAzxMjIwNjLwsQp0MDBw9PUOd3HwdDQwMjIEKIoEKDHAARwNC-sP1o_ArMYIqwGNFQW6EQaajoiIAVNL82A!!/dl5/d5/L2dBISEvZ0FBIS9nQSEh/?_ga=2.51232302.607649930.1531992148-306655537.1531992148',
    value: 'AXIS',
  },
  {
    id: 5,
    name: 'KOTAK',
    url: 'https://www.kotak.com/j1001mp/netapp/MainPage.jsp',
    value: 'KOTAK',
  },
  {
    id: 6,
    name: 'PNB',
    url:
      'https://netbanking.netpnb.com/corp/AuthenticationController?FORMSGROUP_ID__=AuthenticationFG&__START_TRAN_FLAG__=Y&__FG_BUTTONS__=LOAD&ACTION.LOAD=Y&AuthenticationFG.LOGIN_FLAG=1&BANK_ID=024',
    value: 'PNB',
  },
  {
    id: 7,
    name: 'IDBI (Industrial Development Bank of India)',
    url:
      'https://inet.idbibank.co.in/corp/BANKAWAY?Action.RetUser.Init.001=Y&AppSignonBankId=IBKL&AppType=corporate',
    value: 'IDBI',
  },
  {
    id: 8,
    name: 'Yes Bank',
    url: 'https://www.yesbank.in/digital-banking/online-banking/netbanking-services',
    value: 'YBL',
  },
  {
    id: 9,
    name: 'Standard Chartered Bank',
    url: 'https://ibank.standardchartered.co.in/nfs/login.htm',
    value: 'SCB',
  },
  {
    id: 10,
    name: 'Indian Overseas Bank',
    url: 'https://www.iobnet.co.in/ibanking/login.do',
    value: 'IOB',
  },
  {
    id: 11,
    name: 'Canara Bank',
    url: 'https://netbanking.canarabank.in/entry/ENULogin.jsp',
    value: 'CNB',
  },
  {
    id: 12,
    name: 'Indian Bank',
    url: 'https://www.indianbank.net.in:8443/jsp/startIBPreview.jsp',
    value: 'INB',
  },
  {
    id: 13,
    name: 'Corporation Bank',
    url: 'https://www.corpretail.com/RetailBank/',
    value: 'CORP',
  },
  {
    id: 14,
    name: 'Federal Bank',
    url:
      'https://www.fednetbank.com/corp/AuthenticationController?__START_TRAN_FLAG__=Y&FORMSGROUP_ID__=AuthenticationFG&__EVENT_ID__=LOAD&FG_BUTTONS__=LOAD&ACTION.LOAD=Y&AuthenticationFG.LOGIN_FLAG=1&BANK_ID=049&LANGUAGE_ID=001',
    value: 'FBL',
  },
  {
    id: 15,
    name: 'Union Bank of India',
    url:
      'https://www.unionbankonline.co.in/corp/AuthenticationController?__START_TRAN_FLAG__=Y&FORMSGROUP_ID__=AuthenticationFG&__EVENT_ID__=LOAD&FG_BUTTONS__=LOAD&ACTION.LOAD=Y&AuthenticationFG.LOGIN_FLAG=1&BANK_ID=026&LANGUAGE_ID=001',
    value: 'UBI',
  },
  {
    id: 16,
    name: 'IndusInd Bank',
    url:
      'https://indusnet.indusind.com/corp/BANKAWAY?Action.RetUser.Init.001=Y&AppSignonBankId=234&AppType=corporate&CorporateSignonLangId=001',
    value: 'INDB',
  },
  {
    id: 17,
    name: 'Bank of Baroda',
    url: 'https://www.bobibanking.com/',
    value: 'BOB',
  },
  {
    id: 18,
    name: 'Bank of India',
    url: 'https://www.bankofindia.co.in/english/Int-Banking.aspx',
    value: 'BOI',
  },
  {
    id: 19,
    name: 'Central Bank of India',
    url: 'https://www.centralbank.net.in/jsp/startMain.jsp',
    value: 'CBI',
  },
  {
    id: 20,
    name: 'Oriental Bank of Commerce',
    url:
      'https://www.obconline.co.in/corp/AuthenticationController?FORMSGROUP_ID__=AuthenticationFG&__START_TRAN_FLAG__=Y&__FG_BUTTONS__=LOAD&ACTION.LOAD=Y&AuthenticationFG.LOGIN_FLAG=1&BANK_ID=022&AuthenticationFG.USER_TYPE=1/',
    value: 'OBC',
  },
  {
    id: 9999,
    name: 'Other Banks',
    value: 'Others',
  },
];
