import PropTypes from 'prop-types';
import React from 'react';

import { BreadcrumbItem, BreadcrumbNav } from '../../../../common/components/Breadcrumb';
import { TABS } from '../../constants/SettingsConstants';

const PaymentBreadcrumsNavbar = ({
  settings: {
    isShowRefinePurchaseBox,
    isShowFinalizePurchaseBox,
    isInitializeFormOpen,
    isInitializeFormOpenable,
    initializeHeading,
    isRefineFormOpen,
    isRefineFormOpenable,
    refineHeading,
    isFinalizeFormOpen,
    isFinalizeFormOpenable,
    finalizeHeading,
    isShowInitializePurchaseBox,
    isLinkPayment,
    isInstapayPayment,
    showConvenienceFee,
    showConvenienceFeeForm,
  },
  handleToggleOpenState,
}) => {
  if (showConvenienceFeeForm) {
    return null;
  }

  const showBreadcrumbNavTopBorder =
    isLinkPayment || isInstapayPayment || (showConvenienceFee && isFinalizeFormOpen);

  return (
    <BreadcrumbNav
      style={
        showBreadcrumbNavTopBorder
          ? {
              borderTop: '1px solid  rgba(227, 236, 242, 0.5)',
            }
          : null
      }
    >
      {isShowInitializePurchaseBox ? (
        <BreadcrumbItem
          heading={initializeHeading}
          isActive={isInitializeFormOpen}
          isActivable={isInitializeFormOpenable}
          isCompleted={!isInitializeFormOpen}
          onToggleActiveState={(isOpen) => handleToggleOpenState(TABS.INITIALIZE, isOpen)}
        />
      ) : null}

      {isShowRefinePurchaseBox ? (
        <BreadcrumbItem
          heading={refineHeading}
          isActive={isRefineFormOpen}
          isActivable={isRefineFormOpenable}
          isCompleted={!isInitializeFormOpen && !isRefineFormOpen}
          onToggleActiveState={(isOpen) => handleToggleOpenState(TABS.REFINE, isOpen)}
        />
      ) : null}

      {isShowFinalizePurchaseBox ? (
        <BreadcrumbItem
          heading={finalizeHeading}
          isActive={isFinalizeFormOpen}
          isActivable={isFinalizeFormOpenable}
          onToggleActiveState={(isOpen) => handleToggleOpenState(TABS.FINALIZE, isOpen)}
        />
      ) : null}
    </BreadcrumbNav>
  );
};

PaymentBreadcrumsNavbar.propTypes = {
  settings: PropTypes.object,
  handleToggleOpenState: PropTypes.func,
};

export default PaymentBreadcrumsNavbar;
