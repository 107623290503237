// This component has been forked and modified from https://github.com/devfolioco/react-otp-input version 1.0.1.
import PropTypes from 'prop-types';
import React from 'react';

import SingleOtpInput from './SingleOtpInput';

// keyCode constants
const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;
const LETTER_E = 69;

class OtpInput extends React.Component {
  constructor() {
    super();
    this.state = {
      activeInput: 0,
    };
  }

  // Focus on input by index
  focusInput = (input) => {
    const { numInputs } = this.props;
    const activeInput = Math.max(Math.min(numInputs - 1, input), 0);
    this.setState({ activeInput });
  };

  // Focus on next input
  focusNextInput = () => {
    const { activeInput } = this.state;
    this.focusInput(activeInput + 1);
  };

  // Focus on previous input
  focusPrevInput = () => {
    const { activeInput } = this.state;
    this.focusInput(activeInput - 1);
  };

  // Change OTP value at focused input
  changeCodeAtFocus = (value) => {
    const { activeInput } = this.state;

    const otp = this.props.value;
    otp[activeInput] = value[0] || '';
    this.props.onChange(otp);
    this.focusInput(activeInput); // To re-render the component when otp code changes
  };

  // Handle pasted OTP
  handleOnPaste = (e) => {
    e.preventDefault();
    const { numInputs, value: otp, isInputNum } = this.props;
    const { activeInput } = this.state;

    // Get pastedData in an array of max size (num of inputs - current position)
    const pastedData = e.clipboardData
      .getData('text/plain')
      .slice(0, numInputs - activeInput)
      .split('');

    // Paste data from focused input onwards
    for (let pos = 0; pos < numInputs; ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        const valueAtPos = pastedData.shift();
        if (isInputNum && !(valueAtPos >= 0 && valueAtPos <= 9)) {
          otp[pos] = '';
        } else {
          otp[pos] = valueAtPos;
        }
      }
    }

    this.props.onChange(otp);
    this.focusInput(activeInput); // To re-render the component when otp code changes
  };

  handleOnChange = (e) => {
    this.changeCodeAtFocus(e.target.value);
    this.focusNextInput();
  };

  // Handle cases of backspace, delete, left arrow, right arrow
  handleOnKeyDown = (e) => {
    const { isInputNum, value: otp } = this.props;
    const { activeInput } = this.state;
    if (isInputNum && e.keyCode === LETTER_E) {
      e.preventDefault();
    } else if (
      e.keyCode === BACKSPACE ||
      e.key === 'Backspace' ||
      e.keyCode === DELETE ||
      e.key === 'Delete'
    ) {
      e.preventDefault();
      const isFocusedInputEmpty = otp[activeInput] === '';
      this.changeCodeAtFocus('');
      if (isFocusedInputEmpty) {
        // focus on previous input only when current input is empty
        this.focusPrevInput();
      }
    } else if (e.keyCode === LEFT_ARROW || e.key === 'ArrowLeft') {
      e.preventDefault();
      this.focusPrevInput();
    } else if (e.keyCode === RIGHT_ARROW || e.key === 'ArrowRight') {
      e.preventDefault();
      this.focusNextInput();
    } else if (otp[activeInput] === e.key) {
      // if the same key in the otp input is entered again, go to next input.
      e.preventDefault();
      this.focusNextInput();
    }
  };

  checkLength = (e) => {
    if (e.target.value.length > 1) {
      e.preventDefault();
      this.focusNextInput();
    }
  };

  renderInputs = () => {
    const { activeInput } = this.state;
    const {
      numInputs = 4,
      inputStyle = {},
      inputClassnames,
      focusStyle = {},
      focusClassnames,
      separator = '',
      isDisabled = false,
      disabledStyle = {},
      disabledClassnames,
      hasErrored = false,
      errorStyle = {},
      errorClassnames,
      shouldautofocus = false,
      isInputNum = false,
      value: otp,
    } = this.props;
    const inputs = [];

    for (let i = 0; i < numInputs; i++) {
      inputs.push(
        <SingleOtpInput
          key={i}
          focus={activeInput === i}
          value={otp && otp[i]}
          onChange={this.handleOnChange}
          onKeyDown={this.handleOnKeyDown}
          onInput={this.checkLength}
          onPaste={this.handleOnPaste}
          onFocus={(e) => {
            this.setState({ activeInput: i });
            e.target.select();
          }}
          onBlur={() => {
            this.setState({ activeInput: -1 });
          }}
          separator={separator}
          inputStyle={inputStyle}
          inputClassnames={inputClassnames}
          focusStyle={focusStyle}
          focusClassnames={focusClassnames}
          isLastChild={i === numInputs - 1}
          isDisabled={isDisabled}
          disabledStyle={disabledStyle}
          disabledClassnames={disabledClassnames}
          hasErrored={hasErrored}
          errorStyle={errorStyle}
          errorClassnames={errorClassnames}
          shouldautofocus={shouldautofocus}
          isInputNum={isInputNum}
        />,
      );
    }

    return inputs;
  };

  render() {
    const { containerStyle, containerClassnames } = this.props;

    return (
      <div
        style={Object.assign(
          {
            display: 'flex',
            justifyContent: 'center',
          },
          containerStyle,
        )}
        className={containerClassnames}
      >
        {this.renderInputs()}
      </div>
    );
  }
}

OtpInput.propTypes = {
  numInputs: PropTypes.number,
  onChange: PropTypes.func,
  separator: PropTypes.node,
  containerClassnames: PropTypes.string,
  containerStyle: PropTypes.object,
  inputClassnames: PropTypes.string,
  inputStyle: PropTypes.object,
  focusClassnames: PropTypes.string,
  focusStyle: PropTypes.object,
  isDisabled: PropTypes.bool,
  disabledClassnames: PropTypes.string,
  disabledStyle: PropTypes.object,
  hasErrored: PropTypes.bool,
  errorClassnames: PropTypes.string,
  errorStyle: PropTypes.object,
  shouldautofocus: PropTypes.bool,
  isInputNum: PropTypes.bool,
  value: PropTypes.array.isRequired,
};

export default OtpInput;
