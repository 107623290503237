import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { handleChangeInstapayForm } from '../../actions/InstapayActions';
import LinksMerchantHeader from '../links/LinksMerchantHeader';

const InstapayMerchantHeader = (props) => {
  return (
    <LinksMerchantHeader
      {...props}
      isInstapayPayment={true}
      isRAP={props.isRAP}
      onChangeInstapayForm={() => props.handleChangeInstapayForm()}
    />
  );
};

InstapayMerchantHeader.propTypes = {
  isRAP: PropTypes.bool,
  handleChangeInstapayForm: PropTypes.func,
};

const mapStateToProps = ({ instapayForm, settings, retry }) => ({
  isEditing: settings.isRetrying ? retry.instapayForm.isEditing : instapayForm.isEditing,
  isRAP: settings.isRAP,
});

export default connect(mapStateToProps, {
  handleChangeInstapayForm,
})(InstapayMerchantHeader);
