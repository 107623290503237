import Rupee from '@instamojo/rupee';
import PropTypes from 'prop-types';
import React from 'react';

import { Columns, Row } from '../../../../common/components/Grid';

const AmountBreakup = ({ shippingCharges, shouldShowShippingCharges, totalAmount }) => (
  <div className="push--bottom">
    {shouldShowShippingCharges ? (
      <Row className="text-grey micro push--bottom">
        <Columns className="small-6">Shipping Charges :</Columns>
        <Columns className="text-right small-6">
          <Rupee value={shippingCharges} className="push-half--left" />
        </Columns>
      </Row>
    ) : null}
    <Row>
      <Columns className="small-6">Total Amount :</Columns>
      <Columns className="text-right small-6">
        <Rupee value={totalAmount} className="push-half--left" />
      </Columns>
    </Row>
  </div>
);

AmountBreakup.propTypes = {
  shippingCharges: PropTypes.string,
  shouldShowShippingCharges: PropTypes.bool,
  totalAmount: PropTypes.string,
};

export default AmountBreakup;
