import root from 'window-or-global';

const eventMethod = root.addEventListener ? 'addEventListener' : 'attachEvent';
const removeEventMethod = root.removeEventListener
  ? 'removeEventListener'
  : 'detachEvent';

const isAttachEventAvailable = () => eventMethod === 'attachEvent';

const keypressEvent = isAttachEventAvailable() ? 'onkeypress' : 'keypress';
const keyupEvent = isAttachEventAvailable() ? 'onkeyup' : 'keyup';
const keydownEvent = isAttachEventAvailable() ? 'onkeydown' : 'keydown';
const submitEvent = isAttachEventAvailable() ? 'onsubmit' : 'submit';
const blurEvent = isAttachEventAvailable() ? 'onblur' : 'blur';
const messageEvent = isAttachEventAvailable() ? 'onmessage' : 'message';

export {
  blurEvent,
  eventMethod,
  keydownEvent,
  keypressEvent,
  keyupEvent,
  messageEvent,
  removeEventMethod,
  submitEvent,
};
