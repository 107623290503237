import Rupee from '@instamojo/rupee';
import PropTypes from 'prop-types';
import React from 'react';

import { Columns, Row } from '../../components/Grid';
import InputNumber from './InputNumber';

const InputRupee = ({ amount, onChangeInput, ...props }) => (
  <Row className="collapse prefix-radius">
    <Columns className="small-1">
      <span className="prefix" style={{ backgroundColor: '#FAFBFD', color: '#93A0AF' }}>
        <Rupee />
      </span>
    </Columns>
    <Columns className="small-11">
      <InputNumber
        value={amount}
        onFocus={(e) => e.target.select()}
        onClick={(e) => e.target.select()}
        onChange={(e) =>
          onChangeInput({
            amount: e.target.value,
          })
        }
        {...props}
      />
    </Columns>
  </Row>
);

InputRupee.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChangeInput: PropTypes.func.isRequired,
};

export default InputRupee;
