import Rupee from '@instamojo/rupee';
import PropTypes from 'prop-types';
import React from 'react';

import { FormFieldWithLabel } from '../../../../common/components/FormComponents';
import StatefulSubmitButton from '../../../../common/components/StatefulSubmitButton';

const SubmitInitializeFormButton = ({
  totalAmount,
  hasAdditionalInfoTab,
  minPrice,
  disabled,
}) => {
  return (
    <FormFieldWithLabel>
      <StatefulSubmitButton
        disabled={disabled}
        data-disabled={disabled}
        value={
          totalAmount || hasAdditionalInfoTab ? (
            'Next'
          ) : minPrice && totalAmount ? (
            <span>
              Min <Rupee value={minPrice} />
            </span>
          ) : (
            'Claim for Free'
          )
        }
      />
    </FormFieldWithLabel>
  );
};

SubmitInitializeFormButton.propTypes = {
  totalAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hasAdditionalInfoTab: PropTypes.bool,
  minPrice: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SubmitInitializeFormButton;
