import ProfileImage from '@instamojo/profile-image';
import Rupee from '@instamojo/rupee';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { Columns, Row } from '../../../../common/components/Grid';
import { getFullName } from '../../../../common/config/utils';
import ConvenienceFeesAccordion from '../convenience-fee/ConvenienceFeesAccordion';

const ProductsMerchantHeader = ({
  preview,
  sellerAvatar,
  seller,
  title,
  totalAmountFormatted,
  showDeferred,
  showConvenienceFee,
  showConvenienceFeeForm,
  isFinalizeFormOpen,
  convenienceFeeVersion,
}) => (
  <div
    className={classNames(
      'payments-header is--product-payment',
      preview ? 'has--preview' : null,
      sellerAvatar ? 'has--avatar' : null,
    )}
    style={
      preview && showDeferred
        ? {
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(${preview})`,
          }
        : null
    }
  >
    {sellerAvatar ? (
      <div className="payments-header--avatar-container sticky">
        <ProfileImage
          alt=""
          className="payments-header--avatar"
          height={60}
          width={60}
          src={showDeferred ? sellerAvatar : null}
        />
      </div>
    ) : null}

    <div className="offer--merchant">
      <Row>
        <Columns className="small-12 center">{getFullName(seller)}</Columns>
      </Row>
    </div>

    <div className="offer--specs">
      <div className="soft--sides" style={{ wordWrap: 'break-word', lineHeight: '1.2' }}>
        {title}
      </div>
    </div>
    {showConvenienceFee && !showConvenienceFeeForm && isFinalizeFormOpen ? (
      <div className="background-white soft--sides soft--top text-black">
        <div className="text-medium">
          {convenienceFeeVersion != 'v2' || !isFinalizeFormOpen ? (
            <div>
              <span>Amount</span>
              <span className="right">
                <Rupee value={totalAmountFormatted} />
              </span>
            </div>
          ) : null}
          <ConvenienceFeesAccordion />
        </div>
      </div>
    ) : null}
  </div>
);

ProductsMerchantHeader.propTypes = {
  preview: PropTypes.string,
  sellerAvatar: PropTypes.string,
  seller: PropTypes.object,
  title: PropTypes.string,
  totalAmountFormatted: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showDeferred: PropTypes.bool,
  showConvenienceFee: PropTypes.bool,
  showConvenienceFeeForm: PropTypes.bool,
  isFinalizeFormOpen: PropTypes.bool,
  convenienceFeeVersion: PropTypes.string,
};

const mapStateToProps = ({ settings: { isFinalizeFormOpen, showConvenienceFee } }) => {
  return {
    isFinalizeFormOpen,
    showConvenienceFee,
  };
};

export default connect(mapStateToProps)(ProductsMerchantHeader);
