export function getVariantCategoryName(categoryId) {
  return `category_${categoryId}`;
}

import root from 'window-or-global';

const localToServerMapping = {
  name:
    root.INITIAL_STATE && root.INITIAL_STATE.is_link_payment
      ? 'buyer_name'
      : 'billing_address_first_name',
  email: 'customer_email',
  phone: 'customer_phone',
  discountCode: 'discount_code',
  linkDiscountCode: 'link_discount_code',
  amount: 'total_amount',
  readOnly: 'read_only',
};

const serverToLocalMapping = {
  billing_address_first_name: 'name',
  billingAddressFirstName: 'name',
  customer_email: 'email',
  customerEmail: 'email',
  customer_phone: 'phone',
  customerPhone: 'phone',
  discount_code: 'discountCode',
  discountCode: 'discountCode',
  total_amount: 'amount',
  totalAmount: 'amount',
  link_discount_code: 'linkDiscountCode',
};

export function mapPayloadForServer(payload) {
  if (typeof payload !== 'object') {
    return localToServerMapping[payload] || payload;
  }

  return Object.keys(payload).reduce((s, p) => {
    if (Object.keys(localToServerMapping).indexOf(p) !== -1) {
      s[localToServerMapping[p]] = payload[p];
      return s;
    }

    s[p] = payload[p];
    return s;
  }, {});
}

export function mapPayloadForClient(payload = {}) {
  return Object.keys(payload).reduce((s, p) => {
    if (Object.keys(serverToLocalMapping).indexOf(p) !== -1) {
      s[serverToLocalMapping[p]] = payload[p];
      return s;
    }

    s[p] = payload[p];
    return s;
  }, {});
}

export function range(number) {
  const array = [];
  let i = 0;
  while (i !== number) {
    array[++i] = i;
  }
  return array;
}
