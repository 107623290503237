import { chakra } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import {
  Form,
  FormFieldWrapper as FormFieldsWrapper,
} from '../../../common/components/FormComponents';
import { getCookie } from '../../../common/config/utils';
import {
  handleApplyDiscount,
  handleApplyLinkDiscount,
  handleChangeInput,
  handleRemoveDiscount,
  handleRemoveLinkDiscount,
  handleSetTotalAmount,
  handleSubmitInitializePurchase,
  showDiscountCodeBox,
} from '../actions/InitializePurchaseActions';
import { TOTAL_AMOUNT_CAN_CHANGE } from '../constants/GlobalConstants';
import scrollController from '../decorators/ScrollController';
import {
  DiscountCode,
  Email,
  FullName,
  PayableAmount,
  PayWhatYouWant,
  Phone,
  Quantity,
  SubmitInitializeFormButton,
  Variants,
} from './initialize';
import LinkDiscountCode from './initialize/LinkDiscountCode';
import TOSModal from './initialize/TOSModal';

class InitializePurchase extends React.PureComponent {
  componentDidMount() {
    setTimeout(() => {
      try {
        ReactDOM.findDOMNode(this.userForm).querySelector('input').focus();
      } catch (e) {
        /* continue regardless of error */
      }
    }, 0);
  }

  componentDidUpdate() {
    const { isLinkPayment, isInstapayPayment } = this.props;

    if (isLinkPayment || isInstapayPayment) {
      return;
    }

    const {
      form: { claimUrl },
      isFormServerValidated,
    } = this.props;

    const serverForm = this.serverForm;
    if (isFormServerValidated && claimUrl && serverForm) {
      serverForm.submit();
    }
  }

  renderUserForm() {
    const {
      form,
      offer,
      form: {
        name,
        email,
        phone,
        readOnly,
        isQuantityApplicable,
        errors,
        claimUrl,
        discountCode,
        isDiscountApplicable,
        isDiscountBeingEdited,
        isDiscountEditable,
        isDiscountApplied,
        isLinkDiscountApplicable,
        linkDiscountCode,
        isLinkDiscountApplied,
        linkDiscountAmountOff,
      },
      offer: { minPrice, variantCategories, priceBoost, hasAdditionalInfoTab },
      totalAmount,
      subtotal,
      isFormServerValidated,
    } = this.props;
    const isSubmitButtonDisabled =
      !(totalAmount || hasAdditionalInfoTab) && isFormServerValidated && claimUrl;

    // to show discount breakdown for links
    const showAmountBreakdown = Boolean(
      isLinkDiscountApplied && subtotal && linkDiscountAmountOff,
    );

    return (
      <Form
        id="userFormId"
        ref={(c) => (this.userForm = c)}
        errors={errors.All_}
        onSubmit={(e) => {
          e.preventDefault();
          this.props.handleSubmitInitializePurchase();
        }}
      >
        <FormFieldsWrapper className="hard">
          {/* Buyer's Full Name */}
          <FullName
            name={name}
            errors={errors}
            readOnly={readOnly}
            onChange={(payload) => this.props.handleChangeInput(payload)}
          />

          {/* Buyer's Email ID */}
          <Email
            email={email}
            errors={errors}
            readOnly={readOnly}
            onChange={(payload) => this.props.handleChangeInput(payload)}
          />

          {/* Buyer's Phone Number */}
          <Phone
            phone={phone}
            isInternationalNumberEnabled={this.props.isInternationalNumberEnabled}
            readOnly={readOnly}
            errors={errors}
            onChange={(payload) => this.props.handleChangeInput(payload)}
          />

          {/* Variant Categories */}
          <Variants
            variantCategories={variantCategories}
            form={form}
            onChangeInput={(payload) =>
              this.props.handleChangeInput(payload, TOTAL_AMOUNT_CAN_CHANGE)
            }
          />

          {/* Quantity Field */}
          <Quantity
            isQuantityApplicable={isQuantityApplicable}
            quantity={form.quantity}
            errors={form.errors}
            maxQuantity={offer.maxQuantity}
            onChangeInput={(payload) =>
              this.props.handleChangeInput(payload, TOTAL_AMOUNT_CAN_CHANGE)
            }
          />

          {/* Pay What You Want Amount Box */}
          <PayWhatYouWant
            priceBoost={priceBoost}
            basePrice={offer.basePrice}
            form={form}
            //This is to change the price boost amount.
            onChangeInput={(payload) =>
              this.props.handleChangeInput(payload, TOTAL_AMOUNT_CAN_CHANGE)
            }
          />

          {/* Discount Code needs to be enabled per offer. So is not always available */}
          <DiscountCode
            shouldShowDiscountCode={isDiscountApplicable && minPrice}
            discountCode={discountCode}
            isDiscountEditable={isDiscountEditable}
            isDiscountBeingEdited={isDiscountBeingEdited}
            isDiscountApplied={isDiscountApplied}
            errors={errors}
            onChangeInput={this.props.handleChangeInput}
            handleApplyDiscount={this.props.handleApplyDiscount}
            handleRemoveDiscount={this.props.handleRemoveDiscount}
            showDiscountCodeBox={this.props.showDiscountCodeBox}
          />

          {/* Discount Code on links */}
          <LinkDiscountCode
            shouldShowDiscountCode={isLinkDiscountApplicable}
            discountCode={linkDiscountCode}
            isDiscountApplied={isLinkDiscountApplied}
            errors={errors}
            onChangeInput={this.props.handleChangeInput}
            handleApplyDiscount={this.props.handleApplyLinkDiscount}
            handleRemoveDiscount={this.props.handleRemoveLinkDiscount}
          />

          <PayableAmount
            showConvenienceFee={this.props.showConvenienceFee}
            totalAmountFormatted={this.props.totalAmountFormatted}
            showAmountBreakdown={showAmountBreakdown}
            subtotal={subtotal}
            amountOff={linkDiscountAmountOff}
          />
          <chakra.div className="push--bottom">
            <TOSModal />
          </chakra.div>

          <SubmitInitializeFormButton
            totalAmount={totalAmount}
            hasAdditionalInfoTab={hasAdditionalInfoTab}
            minPrice={minPrice}
            disabled={isSubmitButtonDisabled}
          />
        </FormFieldsWrapper>
      </Form>
    );
  }

  renderHiddenServerForm() {
    const {
      form: { claimUrl },
      isFormServerValidated,
    } = this.props;
    return claimUrl && isFormServerValidated ? (
      <form ref={(ref) => (this.serverForm = ref)} action={claimUrl} method="POST">
        <input type="hidden" name="csrfmiddlewaretoken" value={getCookie('csrftoken')} />
      </form>
    ) : null;
  }

  render() {
    return (
      <div>
        {this.renderUserForm()}
        {this.renderHiddenServerForm()}
      </div>
    );
  }
}

InitializePurchase.propTypes = {
  isOpenable: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  offerDetails: PropTypes.object,
  totalAmountFormatted: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showConvenienceFee: PropTypes.bool,
  isInternationalNumberEnabled: PropTypes.bool,
  handleChangeInput: PropTypes.func,
  showDiscountCodeBox: PropTypes.func,
  handleApplyDiscount: PropTypes.func,
  handleRemoveDiscount: PropTypes.func,
  handleApplyLinkDiscount: PropTypes.func,
  handleRemoveLinkDiscount: PropTypes.func,
  handleSubmitInitializePurchase: PropTypes.func,
  handleSetTotalAmount: PropTypes.func,
  isMobileDevice: PropTypes.bool,
  isLinkPayment: PropTypes.bool,
  isInstapayPayment: PropTypes.bool,
  form: PropTypes.object,
  isFormServerValidated: PropTypes.bool,
  offer: PropTypes.object,
  totalAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subtotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

InitializePurchase.defaultProps = {
  isOpenable: true,
  isOpen: true,
  isAnimated: false,
};

function mapStateToProps(state) {
  const {
    initializePurchase: {
      isLinkPayment,
      isInstapayPayment,
      form,
      isFormServerValidated,
      offer,
      totalAmount,
      subtotal,
    },
    settings: { isMobileDevice, showConvenienceFee, isInternationalNumberEnabled },
    finalizePurchase: { totalAmountFormatted },
    offerDetails,
  } = state;

  return {
    isLinkPayment,
    isInstapayPayment,
    form,
    isFormServerValidated,
    offer,
    totalAmount,
    subtotal,
    isMobileDevice,
    showConvenienceFee,
    isInternationalNumberEnabled,
    totalAmountFormatted,
    offerDetails,
  };
}

export default connect(mapStateToProps, {
  handleChangeInput,
  showDiscountCodeBox,
  handleApplyDiscount,
  handleRemoveDiscount,
  handleSubmitInitializePurchase,
  handleSetTotalAmount,
  handleApplyLinkDiscount,
  handleRemoveLinkDiscount,
})(scrollController(InitializePurchase));
