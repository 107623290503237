import PropTypes from 'prop-types';
import React from 'react';

import { FormFieldWithLabel } from '../../../../common/components/FormComponents';
import { getVariantCategoryName } from '../../utils/InitializePurchaseUtils';

const Variants = ({ variantCategories, form, onChangeInput }) => {
  if (!variantCategories) {
    return null;
  }

  const { errors } = form;

  return (
    <div>
      {variantCategories.map((variantCategory) => {
        const variantFieldName = getVariantCategoryName(variantCategory.id);
        return (
          <FormFieldWithLabel
            key={variantCategory.id}
            label={variantCategory.name}
            errors={errors[variantFieldName] || errors.Variant}
          >
            <select
              onChange={(e) =>
                onChangeInput({
                  [variantFieldName]: e.target.value,
                })
              }
              value={form[variantFieldName]}
            >
              <option value="">Select an Option</option>
              {variantCategory.variants.map((variant) => (
                <option value={variant.id} key={variant.id}>
                  {variant.title}
                </option>
              ))}
            </select>
          </FormFieldWithLabel>
        );
      })}
    </div>
  );
};

Variants.propTypes = {
  variantCategories: PropTypes.array,
  form: PropTypes.object,
  onChangeInput: PropTypes.func,
};

export default Variants;
