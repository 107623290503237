import PropTypes from 'prop-types';
import React, { Component } from 'react';
export default class FormFieldLabel extends Component {
  constructor() {
    super();
  }

  render() {
    const { children, label } = this.props;
    const renderLabel = children ? children : label;

    return (
      <label
        className={this.props.className}
        htmlFor={this.props.htmlFor}
        style={this.props.style}
      >
        {renderLabel}
      </label>
    );
  }
}

FormFieldLabel.defaultProps = {
  className: '',
  htmlFor: '',
};

FormFieldLabel.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  htmlFor: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
};
