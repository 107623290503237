export const Modes = {
  FULL: 'FULL',
  SUMMARY: 'SUMMARY',
};

export const LayoutStyles = {
  width: '80%',
  maxWidth: '620px',
  minWidth: '320px',
};
