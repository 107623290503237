import Rupee from '@instamojo/rupee';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../../../../common/components/Icon';
import getAmountAnalytics from '../../analytics/getAmountAnalytics';
import { Modes } from '../../config';
import ItemDetails from './ItemDetails';

const qtyArray = (maxQuantity) => [...Array(maxQuantity).keys()].map((x) => x + 1);

const QuantityEditor = (props) => (
  <select
    className={props.className}
    value={props.quantity}
    disabled={props.isEditingQuantity}
    onChange={(e) =>
      props.onEditQuantity({
        id: props.id,
        quantity: e.target.value,
      })
    }
  >
    {' '}
    {qtyArray(props.maxQuantity).map((qty, i) => (
      <option key={i} value={qty}>
        {qty}
      </option>
    ))}
  </select>
);

QuantityEditor.propTypes = {
  className: PropTypes.string,
  quantity: PropTypes.string,
  isEditingQuantity: PropTypes.bool,
  onEditQuantity: PropTypes.func,
  maxQuantity: PropTypes.string,
  id: PropTypes.string,
};

const CartItemFull = (props) => (
  <div className="soft cart-item--wrapper">
    <div className="relative">
      <div className="flex-space-between">
        <div className={classnames('small-11', props.readOnly ? 'medium-9' : 'medium-8')}>
          <div className="media">
            <img
              className="media__img"
              src={props.image}
              alt={props.title}
              width="40px"
              height="40px"
            />
            <div className="media__body">
              <ItemDetails {...props} />
            </div>
          </div>
          {!props.readOnly && props.errors.length ? (
            <p className="f12" style={{ color: '#E25465', marginTop: '5px' }}>
              {' '}
              {props.errors.map((error, index) => (
                <div key={index}>
                  <span>{error}</span>
                </div>
              ))}
            </p>
          ) : null}
        </div>
        <div
          className={classnames(
            'hide-for-small-only',
            props.readOnly ? 'medium-1' : 'medium-2',
          )}
        >
          {!props.readOnly ? (
            <QuantityEditor className="f12 medium-8 large-6 cart--select" {...props} />
          ) : (
            <p className="f12">{props.quantity}</p>
          )}
        </div>
        <div className="hide-for-small-only medium-2">
          <p className="f12 text-right soft--sides">
            <strong>
              <Rupee value={props.amount} />
            </strong>
          </p>
        </div>
        {!props.readOnly ? (
          <div>
            <a
              className="text-light-grey"
              onClick={() => {
                props.onRemoveItem({
                  id: props.id,
                  slug: props.slug,
                  analytics: getAmountAnalytics(props.amount),
                });
              }}
            >
              <Icon className="ui-1_circle-remove_modal esm" />
            </a>
          </div>
        ) : null}
      </div>

      <div className="show-for-small-only push--top flex-space-between">
        <div>
          {!props.readOnly ? (
            <QuantityEditor className="f12 cart--select" {...props} />
          ) : (
            <p className="f12">Quantity: {props.quantity}</p>
          )}
        </div>
        <div>
          <p className="f12 text-right">
            <strong>
              <Rupee value={props.amount} />
            </strong>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default CartItemFull;

CartItemFull.propTypes = {
  mode: PropTypes.oneOf([Modes.FULL, Modes.SUMMARY]),
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  seller: PropTypes.string,
  image: PropTypes.string.isRequired,
  unitPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  amount: PropTypes.number.isRequired,
  variants: PropTypes.array,
  slug: PropTypes.string,
  customFields: PropTypes.array.isRequired,
  quantity: PropTypes.number.isRequired,
  maxQuantity: PropTypes.number,
  onRemoveItem: PropTypes.func,
  onEditQuantity: PropTypes.func,
  isEditingQuantity: PropTypes.bool,
  errors: PropTypes.array,
  readOnly: PropTypes.bool,
};
