import { combineReducers } from 'redux';

import { pageState } from '../../common/reducers/PageStateReducers';
import finalizePurchase from '../../payments/payments/reducers/FinalizePurchaseReducers';
import initializePurchase from '../../payments/payments/reducers/InitializePurchaseReducers';
import offerDetails from '../../payments/payments/reducers/OfferDetailsReducers';
import refinePurchase from '../../payments/payments/reducers/RefinePurchaseReducers';
import settings from '../../payments/payments/reducers/SettingsReducers';

const rootReducer = combineReducers({
  settings,
  initializePurchase,
  refinePurchase,
  finalizePurchase,
  pageState,
  offerDetails,
});

export default rootReducer;
