import ErrorsHigherOrderComponent from '@instamojo/errors-hoc';
import classNames from 'classnames';
import React from 'react';

import FormField from './FormField';
import FormFieldWrapper from './FormFieldWrapper';

export default class Form extends ErrorsHigherOrderComponent {
  constructor() {
    super();
  }

  render() {
    let { errors } = this.props;
    errors = typeof errors === 'string' && errors.length ? [errors] : errors;
    const classesFromParent = this.props.className || '';

    return (
      <form
        className={classNames('form', classesFromParent)}
        onSubmit={this.props.onSubmit}
        noValidate
      >
        {errors && Array.isArray(errors) ? (
          <FormFieldWrapper columns="small-12">
            <FormField className="alert-banner area--red push--bottom soft-half">
              {this.getErrorsBlock(errors)}
            </FormField>
          </FormFieldWrapper>
        ) : null}
        {this.props.children}
      </form>
    );
  }
}
