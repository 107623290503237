/* globals INITIAL_STATE */
import { getUrlParameterByName, objectWithout } from '../../../common/config/utils';
import * as ActionTypes from '../actions/InstapayActions';

function getInitialPurpose() {
  return getUrlParameterByName('purpose');
}

function getInitialAmount() {
  return getUrlParameterByName('amount');
}

const initialState = {
  purpose: getInitialPurpose(),
  amount: getInitialAmount(),
  shouldJumpToNextStep: getInitialPurpose() && getInitialAmount(),
  errors: {},
  isEditing: false,
  maxAmount: INITIAL_STATE.seller ? INITIAL_STATE.seller.limit : null,
};

const instapayForm = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.HANDLE_CHANGE_INSTAPAY_INPUT: {
      return {
        ...state,
        ...action.payload,
        errors: objectWithout(state.errors, Object.keys(action.payload)[0]),
      };
    }

    case ActionTypes.HANDLE_SUBMIT_INSTAPAY_SUCCESS: {
      return {
        ...state,
        isEditing: false,
        errors: {},
      };
    }

    case ActionTypes.HANDLE_SUBMIT_INSTAPAY_FAILURE: {
      return {
        ...state,
        isEditing: true,
        errors: {
          ...action.errors,
        },
      };
    }

    case ActionTypes.HANDLE_CHANGE_INSTAPAY_FORM: {
      return {
        ...state,
        isEditing: true,
      };
    }

    default:
      return state;
  }
};

export default instapayForm;
