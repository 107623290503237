import { isAndroidDevice, isIOS_Device } from '@instamojo/agent-utils';
import LGTM from 'lgtm';

import { getCurrentLocationPathname } from '../../../common/config/utils';
import { POST_API } from '../../../common/middlewares/form';
import { calculateTotalAmount, getAccordianHeading } from '../utils/GlobalUtils';
import { getCustomFields, offerData } from '../utils/GlobalUtils';
/**
 * Called when Editing anything in the Initialize Form
 * @param {Object} changedData - {name: 'Aakash'}
 */

export const HANDLE_IP_CHANGE_INPUT = 'HANDLE_IP_CHANGE_INPUT';
export function handleChangeInput(changedData, totalAmountCanChange) {
  return (dispatch, getState, isOfferPage) => {
    dispatch({
      type: HANDLE_IP_CHANGE_INPUT,
      payload: {
        changedData,
      },
    });
    const state = isOfferPage ? getState().payments : getState();

    if (totalAmountCanChange) {
      setTotalAmountAndAccordianHeading(
        dispatch,
        calculateTotalAmount(state.initializePurchase),
      );
    }
  };
}

function setTotalAmountAndAccordianHeading(dispatch, totalAmount) {
  dispatch(handleSetTotalAmount(totalAmount));
  dispatch(handleSetAccordianHeading(totalAmount));
}

/**
 * Called when clicking link which says "I have a discount code"
 */

export const SHOW_DISCOUNT_CODE_BOX = 'SHOW_DISCOUNT_CODE_BOX';
export function showDiscountCodeBox() {
  return {
    type: SHOW_DISCOUNT_CODE_BOX,
  };
}

/**
 * [same endpoint used by both apply and remove discount code API]
 * @return {[String]} [/<username>/<slug>/discount/]
 */

const discountEndpoint = (store) => {
  const { slug } = store.initializePurchase.offer;
  return `/webapi/checkout${slug}discount/`;
};

/**
 * Called to when applying Discount
 */
const discountCodeValidator = LGTM.validator()
  .validates('discountCode')
  .required('Enter a discount code')
  .build();

export const HANDLE_APPLY_DISCOUNT_REQUEST = 'HANDLE_APPLY_DISCOUNT_REQUEST';
export const HANDLE_APPLY_DISCOUNT_SUCCESS = 'HANDLE_APPLY_DISCOUNT_SUCCESS';
export const HANDLE_APPLY_DISCOUNT_FAILURE = 'HANDLE_APPLY_DISCOUNT_FAILURE';
export function handleApplyDiscount() {
  return {
    [POST_API]: {
      types: [
        HANDLE_APPLY_DISCOUNT_REQUEST,
        HANDLE_APPLY_DISCOUNT_SUCCESS,
        HANDLE_APPLY_DISCOUNT_FAILURE,
      ],
      endpoint: discountEndpoint,
      validator: discountCodeValidator,
      toValidate: (store) => {
        const { discountCode } = store.initializePurchase.form;
        return { discountCode };
      },
      failureActionErrors: (response) => {
        return {
          discountCode: [response.errors.discount],
        };
      },
      onSuccess: (response, state, dispatch) => {
        setTotalAmountAndAccordianHeading(
          dispatch,
          calculateTotalAmount(state.initializePurchase),
        );
      },
      payload: (store) => {
        const { discountCode } = store.initializePurchase.form;
        return {
          discount: discountCode,
        };
      },
    },
  };
}

/**
 * Called when removing discount
 */

export const HANDLE_REMOVE_DISCOUNT_REQUEST = 'HANDLE_REMOVE_DISCOUNT_REQUEST';
export const HANDLE_REMOVE_DISCOUNT_SUCCESS = 'HANDLE_REMOVE_DISCOUNT_SUCCESS';
export const HANDLE_REMOVE_DISCOUNT_FAILURE = 'HANDLE_REMOVE_DISCOUNT_FAILURE';
export function handleRemoveDiscount() {
  return {
    [POST_API]: {
      types: [
        HANDLE_REMOVE_DISCOUNT_REQUEST,
        HANDLE_REMOVE_DISCOUNT_SUCCESS,
        HANDLE_REMOVE_DISCOUNT_FAILURE,
      ],
      endpoint: discountEndpoint,
      payload: {
        discount: '',
      },
      onSuccess: (response, state, dispatch) => {
        setTotalAmountAndAccordianHeading(
          dispatch,
          calculateTotalAmount(state.initializePurchase),
        );
      },
    },
  };
}

/**
 * Called when Submitting Initialize Purchase Form
 */
import { getVariantCategoryName } from '../utils/InitializePurchaseUtils';
import { unbuiltInitializeFormValidator } from './initialize/validator';

function getSelectedVariants(variantCategories, form) {
  return variantCategories.reduce((validationFields, variantCategory) => {
    const variantFieldName = getVariantCategoryName(variantCategory.id);
    validationFields[variantFieldName] = form[variantFieldName];
    return validationFields;
  }, {});
}

export const HANDLE_SUBMIT_INITIALIZE_PURCHASE_REQUEST =
  'HANDLE_SUBMIT_INITIALIZE_PURCHASE_REQUEST';
export const HANDLE_SUBMIT_INITIALIZE_PURCHASE_SUCCESS =
  'HANDLE_SUBMIT_INITIALIZE_PURCHASE_SUCCESS';
export const HANDLE_SUBMIT_INITIALIZE_PURCHASE_FAILURE =
  'HANDLE_SUBMIT_INITIALIZE_PURCHASE_FAILURE';
let initialPayload = {};
import { getOrderEndpoint } from '../utils/GlobalUtils';
import {
  mapPayloadForClient,
  mapPayloadForServer,
} from '../utils/InitializePurchaseUtils';
import { storeFinalPayload } from './FinalizePurchaseActions';
import { storeInitialPayload } from './RefinePurchaseActions';

export const initializePurchaseValidator = ({ store, initialValidator }) => {
  if (!initialValidator) {
    initialValidator = LGTM.validator();
  }

  const { variantCategories, priceBoost, basePrice } = store.initializePurchase.offer;
  const { minimumPriceBoostAmount } = store.initializePurchase.form;

  if (priceBoost) {
    initialValidator = initialValidator
      .validates('amount')
      .using(
        (amount) =>
          minimumPriceBoostAmount
            ? amount >= minimumPriceBoostAmount
            : amount >= basePrice,
        `Minimum amount is Rs.${minimumPriceBoostAmount || basePrice}`,
      );
  }

  if (variantCategories && variantCategories.length) {
    variantCategories.forEach((variantCategory) => {
      initialValidator = initialValidator
        .validates(getVariantCategoryName(variantCategory.id))
        .required('Please select an option');
    });
  }
  return initialValidator.build();
};

export const initializePurchaseToValidate = ({ store, initialToValidate }) => {
  if (!initialToValidate) {
    initialToValidate = {};
  }

  const {
    form,
    form: { amount },
    offer: { variantCategories, priceBoost },
  } = store.initializePurchase;

  let toValidate = {
    ...initialToValidate,
  };

  if (priceBoost) {
    toValidate = {
      ...toValidate,
      amount,
    };
  }

  if (variantCategories && variantCategories.length) {
    const variantValidations = getSelectedVariants(variantCategories, form);
    toValidate = {
      ...toValidate,
      ...variantValidations,
    };
  }

  return toValidate;
};

export const initializePurchasePayload = ({ store, isDirectPurchase }) => {
  const {
    form,
    form: {
      name,
      email,
      phone,
      discountCode,
      isQuantityApplicable,
      quantity,
      isClaimFreeChecked,
      amount,
      readOnly,
      smartPricingLineItems,
      linkDiscountCode,
    },
    offer: { variantCategories, priceBoost, basePrice, title },
    prefilledHiddenCustomFields,
    signature,
    affiliate,
    isInstapayPayment,
    isRAP,
  } = store.initializePurchase;

  let tentativePayload = isDirectPurchase
    ? {
      name,
      email,
      phone,
      amount: amount ? amount : basePrice,
      device_type: isIOS_Device ? 'ios' : isAndroidDevice ? 'android' : 'others',
    }
    : {
      device_type: isIOS_Device ? 'ios' : isAndroidDevice ? 'android' : 'others',
    };

  if (isRAP) {
    return {
      ...tentativePayload,
      purpose: title,
    };
  }

  if (isInstapayPayment) {
    if (title) {
      tentativePayload = {
        ...tentativePayload,
        purpose: title,
      };
    }

    return {
      ...tentativePayload,
    };
  }

  let confirmedPayload = {
    ...tentativePayload,
    ...prefilledHiddenCustomFields,
  };

  if (affiliate) {
    confirmedPayload = {
      ...confirmedPayload,
      affiliate,
    };
  }

  if (discountCode) {
    confirmedPayload = {
      ...confirmedPayload,
      discountCode,
    };
  }

  if (linkDiscountCode) {
    confirmedPayload = {
      ...confirmedPayload,
      linkDiscountCode,
    };
  }

  if (isQuantityApplicable) {
    confirmedPayload = {
      ...confirmedPayload,
      quantity,
    };
  }

  if (priceBoost) {
    if (basePrice > 0 || (basePrice === 0 && !isClaimFreeChecked)) {
      confirmedPayload = {
        ...confirmedPayload,
        amount,
      };
    }
  }

  if (variantCategories && variantCategories.length) {
    const variantPayload = getSelectedVariants(variantCategories, form);
    confirmedPayload = {
      ...confirmedPayload,
      ...variantPayload,
    };
  }

  if (signature) {
    confirmedPayload = {
      ...confirmedPayload,
      signature,
      readOnly: JSON.stringify(
        mapPayloadForServer(
          Object.keys(readOnly).reduce((o, r) => {
            o[r] = form[r];
            return o;
          }, {}),
        ),
      ),
      readOnlyFieldsNetBanking: Object.keys(readOnly).map((field) => `data_${field}`),
      hiddenCustomFieldsNetBanking: Object.keys(prefilledHiddenCustomFields).map(
        (field) => `data_${field}`,
      ),
    };
  }

  if (smartPricingLineItems) {
    const customPayload = {};
    const smartPricingPayload = {};
    smartPricingPayload['items'] = smartPricingLineItems;
    customPayload['additional_fields'] = JSON.stringify(smartPricingPayload);

    confirmedPayload = {
      ...confirmedPayload,
      ...customPayload,
    };
  }

  // if custom fields for smart links are there (Shipping address/ Billing address etc)
  if (getCustomFields().length > 0) {
    confirmedPayload = {
      ...confirmedPayload,
      save: 0,
    };
  }
  initialPayload = confirmedPayload;
  return mapPayloadForServer(confirmedPayload);
};

export function handleSubmitInitializePurchase() {
  return {
    [POST_API]: {
      types: [
        HANDLE_SUBMIT_INITIALIZE_PURCHASE_REQUEST,
        HANDLE_SUBMIT_INITIALIZE_PURCHASE_SUCCESS,
        HANDLE_SUBMIT_INITIALIZE_PURCHASE_FAILURE,
      ],
      endpoint: getOrderEndpoint(),
      validator: (store) => {
        return initializePurchaseValidator({
          store,
          initialValidator: unbuiltInitializeFormValidator(),
        });
      },
      toValidate: (store) => {
        const {
          form: { name, email, phone },
        } = store.initializePurchase;

        const initialToValidate = { name, email, phone };

        return initializePurchaseToValidate({ store, initialToValidate });
      },
      payloadAsIs: true,
      payload: (store) =>
        initializePurchasePayload({
          store,
          isDirectPurchase: true,
        }),
      failureActionErrors: (response) => {
        return mapPayloadForClient(response.errors);
      },
      onSuccess: (response, store, dispatch) => {
        const {
          initializePurchase: { offer: { basePrice } = {} },
          settings: { isShowRefinePurchaseBox },
        } = store;

        const { details: { linkDiscount } = {} } = response;

        if (isShowRefinePurchaseBox) {
          return dispatch(storeInitialPayload(initialPayload));
        }

        // Updates discount details when user does not click on apply and enters a valid discount code
        if (linkDiscount) {
          dispatch(handleUpdateLinkDiscountDetails(linkDiscount, basePrice));
        }
        dispatch(storeFinalPayload(initialPayload));
      },
    },
  };
}

/**
 * Called to set the total amount of the payment
 */
export const HANDLE_SET_TOTAL_AMOUNT = 'HANDLE_SET_TOTAL_AMOUNT';
export function handleSetTotalAmount(totalAmount) {
  return {
    type: HANDLE_SET_TOTAL_AMOUNT,
    payload: {
      totalAmount: totalAmount ? Number(totalAmount) : 0,
    },
  };
}

/**
 * Called to set the total amount of the payment
 */
export const HANDLE_SET_ACCORDIAN_HEADING = 'HANDLE_SET_ACCORDIAN_HEADING';
export function handleSetAccordianHeading(totalAmount) {
  return {
    type: HANDLE_SET_ACCORDIAN_HEADING,
    payload: {
      heading: getAccordianHeading('InitializePurchase', totalAmount),
    },
  };
}

/**
 * Called when applying Discount on links
 */
const linkDiscountCodeValidator = LGTM.validator()
  .validates('linkDiscountCode')
  .required('Enter a discount code')
  .build();

export const HANDLE_APPLY_LINK_DISCOUNT_REQUEST = 'HANDLE_APPLY_LINK_DISCOUNT_REQUEST';
export const HANDLE_APPLY_LINK_DISCOUNT_SUCCESS = 'HANDLE_APPLY_LINK_DISCOUNT_SUCCESS';
export const HANDLE_APPLY_LINK_DISCOUNT_FAILURE = 'HANDLE_APPLY_LINK_DISCOUNT_FAILURE';
export function handleApplyLinkDiscount() {
  const link_id = getCurrentLocationPathname().replace(/\/$/, '').split('/').pop();
  const { isCustomDomain, baseURL } = offerData();
  return {
    [POST_API]: {
      types: [
        HANDLE_APPLY_LINK_DISCOUNT_REQUEST,
        HANDLE_APPLY_LINK_DISCOUNT_SUCCESS,
        HANDLE_APPLY_LINK_DISCOUNT_FAILURE,
      ],
      endpoint: isCustomDomain ? `${baseURL}/webapi/links2/domain-path/${link_id}/discounts/verify/` : `/webapi/links2/${link_id}/discounts/verify/`,
      validator: linkDiscountCodeValidator,
      toValidate: (store) => {
        const { linkDiscountCode } = store.initializePurchase.form;
        return { linkDiscountCode };
      },
      payload: (store) => {
        const { linkDiscountCode } = store.initializePurchase.form;
        return {
          discountCode: linkDiscountCode,
        };
      },
      failureActionErrors: (response) => {
        return {
          linkDiscountCode: [response.errors.discountCode],
        };
      },
      onSuccess: (response, state, dispatch) => {
        setTotalAmountAndAccordianHeading(
          dispatch,
          calculateTotalAmount(state.initializePurchase),
        );
      },
    },
  };
}

/**
 * Called when removing discount on links
 */

export const HANDLE_REMOVE_LINK_DISCOUNT = 'HANDLE_REMOVE_LINK_DISCOUNT';
export const handleRemoveLinkDiscount = () => (dispatch, store) => {
  dispatch({ type: HANDLE_REMOVE_LINK_DISCOUNT });
  setTotalAmountAndAccordianHeading(
    dispatch,
    calculateTotalAmount(store().initializePurchase),
  );
};

export const HANDLE_UPDATE_LINK_DISCOUNT_DETAILS = 'HANDLE_UPDATE_LINK_DISCOUNT_DETAILS';
export const handleUpdateLinkDiscountDetails = (linkDiscountAmount, basePrice) => ({
  type: HANDLE_UPDATE_LINK_DISCOUNT_DETAILS,
  linkDiscountAmount,
  basePrice,
});
