import PropTypes from 'prop-types';
import React from 'react';

import ConvenienceFees from '../convenience-fee/ConvenienceFees';
import BankTransfer from './render-payment-option/BankTransfer';
import Card from './render-payment-option/Card';
import CashOnDelivery from './render-payment-option/CashOnDelivery';
import EMI from './render-payment-option/EMI';
import MobileUPIIntent from './render-payment-option/MobileUPIIntent';
import MobileUPIIntentV2 from './render-payment-option/MobileUPIIntentV2';
import NetBanking from './render-payment-option/NetBanking';
import PayLater from './render-payment-option/PayLater';
import PayPalSDK from './render-payment-option/PayPalSDK';
import UPI from './render-payment-option/UPI';
import UpiQr from './render-payment-option/UpiQr';
import Wallet from './render-payment-option/Wallet';
import UpiLoader from './UpiLoader';
// import GrabonOffer from './GrabonOffer';

class RenderPaymentOption extends React.Component {
  renderSelectedPaymentMode() {
    const {
      selectedSavedCard,
      selectedPaymentObject: selectedPaymentOption,
    } = this.props.finalizePurchase;

    const showConvenienceFeeForm =
      selectedPaymentOption?.convenienceFeeObject &&
      selectedPaymentOption.convenienceFeeObject.showConvenienceFeeForm;

    // The selectedPaymentOption object will not be populated in the case of UPI Intent/QR.
    // We will not arrive at this component in case of QR.
    const type = selectedPaymentOption?.type || 'dynamic_qr';
    switch (type) {
      case 'card': {
        return (
          <Card
            finalizePurchase={this.props.finalizePurchase}
            submissionUri={selectedPaymentOption.submissionUri}
          />
        );
      }

      case 'saved_cards': {
        return (
          <Card
            finalizePurchase={this.props.finalizePurchase}
            submissionUri={selectedSavedCard.uri}
          />
        );
      }

      case 'emi': {
        return (
          <EMI
            finalizePurchase={this.props.finalizePurchase}
            showConvenienceFeeForm={showConvenienceFeeForm}
          />
        );
      }

      case 'nb': {
        return (
          <NetBanking
            finalizePurchase={this.props.finalizePurchase}
            showConvenienceFee={this.props.showConvenienceFee}
          />
        );
      }

      case 'wallet': {
        return (
          <Wallet
            finalizePurchase={this.props.finalizePurchase}
            showConvenienceFee={this.props.showConvenienceFee}
          />
        );
      }

      case 'paylater': {
        return (
          <PayLater
            finalizePurchase={this.props.finalizePurchase}
            showConvenienceFee={this.props.showConvenienceFee}
          />
        );
      }

      case 'dynamic_qr': {
        // We will land here only when it is an AndroidDevice and if UPIIntent is enabled.
        return (
          <MobileUPIIntent
            finalizePurchase={this.props.finalizePurchase}
            username={this.props.username}
            showConvenienceFee={this.props.showConvenienceFee}
          />
        );
      }
      case 'dynamic_qr_initiate': {
        // We will land here only when it is an AndroidDevice and if UPIIntent is enabled.
        return (
          <MobileUPIIntentV2
            finalizePurchase={this.props.finalizePurchase}
            username={this.props.username}
            showConvenienceFee={this.props.showConvenienceFee}
            handleNewApiForUpi
          />
        );
      }
      case 'upi': {
        return (
          <UPI
            finalizePurchase={this.props.finalizePurchase}
            username={this.props.username}
            showConvenienceFee={this.props.showConvenienceFee}
          />
        );
      }

      case 'bank_transfer': {
        return (
          <BankTransfer
            finalizePurchase={this.props.finalizePurchase}
            username={this.props.username}
          />
        );
      }

      case 'cash_on_delivery': {
        return (
          <CashOnDelivery
            finalizePurchase={this.props.finalizePurchase}
            showConvenienceFee={this.props.showConvenienceFee}
          />
        );
      }
      case 'international_payment_provider': {
        return (
          <PayPalSDK
            totalAmount={this.props.finalizePurchase.totalAmount}
            showPaypalSuccessPage={this.props.finalizePurchase.showPaypalSuccessPage}
            showPaypalErrorBlock={this.props.finalizePurchase.showPaypalErrorBlock}
            handlePaypalSuccessfulCallback={() =>
              this.props.handlePaypalSuccessfulCallback()
            }
            handlePaypalSDKError={() => this.props.handlePaypalSDKError()}
            {...selectedPaymentOption}
          />
        );
      }

      case 'upi_qr': {
        return (
          <UpiQr {...this.props} />
        );
      }
    }
  }

  render() {
    const {
      isSelectedPaymentModeLoading,
      selectedPaymentObject,
      selectedPaymentObject: { type: selectedPaymentMethodType } = {},
      selectedUPIOption = '',
      isUPIRequestSubmitted,
      convenienceFeeVersion,
      dynamicQrDetails,
    } = this.props.finalizePurchase;

    const showConvenienceFeeForm =
      selectedPaymentObject?.convenienceFeeObject &&
      selectedPaymentObject.convenienceFeeObject.showConvenienceFeeForm;
    const showUpiIntentConvenienceFeeForm = dynamicQrDetails?.showConvenienceFeeForm;
    const showConvenienceFeeComponent =
      convenienceFeeVersion === 'v2' &&
      ((showConvenienceFeeForm &&
      !selectedPaymentObject?.convenienceFeeObject.isMojocard ) || showUpiIntentConvenienceFeeForm);

    // UPI Intent is attached to QR object, but we want to see it as a UPI payment option, hence, the below check.
    const selectedPaymentMethodName = selectedPaymentMethodType === 'dynamic_qr' ? 'UPI' : selectedPaymentObject?.name;
    return (
      <div>
        <div className={showConvenienceFeeComponent ? 'hidden' : ''}>
          {/* <div>
            {
              selectedPaymentObject.name === 'UPI' ?
              <GrabonOffer
                showLearnMore={true}
              /> :
              null
            }
          </div> */}
          {!(isSelectedPaymentModeLoading || showConvenienceFeeForm || showUpiIntentConvenienceFeeForm) ? (
            <div className="soft--bottom">
              <span className="text-light-grey">
                {selectedPaymentMethodName === 'QR_INIT' ? 'UPI' : selectedPaymentMethodName}
              </span>
              <a
                className="right"
                onClick={() => this.props.handleResetSelectedPaymentMode()}
              >
                Change
              </a>
            </div>
          ) : null}

          <div className="relative">
            <div className={isSelectedPaymentModeLoading ? 'loading--wrapper' : null}>
              {isSelectedPaymentModeLoading ? (
                <div>
                  <div className="loading--animation" />
                  <p>Connecting to secure servers...</p>
                </div>
              ) : null}
              <div className="loading--content">{this.renderSelectedPaymentMode()}</div>
            </div>
          </div>
        </div>
        {showConvenienceFeeComponent ? (
          selectedPaymentMethodType === 'upi' && isUPIRequestSubmitted ? (
            <UpiLoader selectedUPIOption={selectedUPIOption} />
          ) : (
            <ConvenienceFees />
          )
        ) : null}
      </div>
    );
  }
}

RenderPaymentOption.propTypes = {
  username: PropTypes.string,
  showConvenienceFee: PropTypes.bool,
  finalizePurchase: PropTypes.object,
  handleResetSelectedPaymentMode: PropTypes.func,
  handlePaypalSuccessfulCallback: PropTypes.func,
  handlePaypalSDKError: PropTypes.func,
};

export default RenderPaymentOption;
