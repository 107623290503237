import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal as ReactOverlay } from 'react-overlays';

import ModalClose from './modal/ModalClose';
import ModalContent, { contentWrapperStyles } from './modal/ModalContent';
export { contentWrapperStyles, ModalClose, ModalContent };

import { isIOS_Device } from '../config/utils';

export default class Modal extends Component {
  constructor(props) {
    super(props);
    if (isIOS_Device) {
      this.state = {
        reactOverlayMaxHeight: 0,
      };
    }
  }

  componentDidUpdate(prevProps) {
    setTimeout(() => this.updateBodyHeight(), 0);
    if (prevProps.show == true && this.props.show == false) {
      this.restoreScrolling();
    }
  }

  componentWillUnmount() {
    this.restoreScrolling();
  }

  restoreScrolling() {
    document.getElementsByTagName('body')[0].style.height = 'auto';
    document.getElementsByTagName('html')[0].style.overflowY = 'auto';
  }

  updateBodyHeight() {
    if (isIOS_Device) {
      /* Needed for scrolling to work properly */
      const modal = document.getElementsByClassName('react-overlays-modal__content')[0];
      if (modal) {
        const modalHeight = modal.clientHeight + 200;
        if (this.state.reactOverlayMaxHeight < modalHeight) {
          this.setState({
            reactOverlayMaxHeight: modalHeight,
          });

          const bodyHeight = document.body.clientHeight;

          if (bodyHeight < modalHeight) {
            document.getElementsByTagName('body')[0].style.height = `${modalHeight}px`;
          }
        }
      }
    }
  }

  render() {
    const { show, onShow, modalStyle, backdropStyle, isUpiIntentIOSWaitModal = false, ...others } = this.props;
    const defaultModalStyle = {
      display: 'block',
      zIndex: 999,
      top: '0px',
      left: '0px',
      position: isIOS_Device && !isUpiIntentIOSWaitModal ? 'absolute' : 'fixed',
      height: isIOS_Device && !isUpiIntentIOSWaitModal ? 'auto' : '100%',
      width: '100%',
      backfaceVisibility: 'hidden',
      WebkitOverflowScrolling: 'touch',
      overflowY: 'visible',
    };
    return (
      <ReactOverlay
        {...others}
        show={show}
        className="react-overlays-modal-portal"
        containerClassName="react-overlays-modal__body--open"
        style={{ ...defaultModalStyle, ...modalStyle }}
        backdropStyle={{ ...defaultBackdropStyle, ...backdropStyle }}
        onShow={() => {
          document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
          onShow();
        }}
      >
        {this.props.children}
      </ReactOverlay>
    );
  }
}

Modal.defaultProps = {
  modalStyle: {},
  backdropStyle: {},
  show: false,
  onShow: () => {
    /* Do nothing */
  },
};

Modal.propTypes = {
  modalStyle: PropTypes.object,
  backdropStyle: PropTypes.object,
  show: PropTypes.bool.isRequired,
  onShow: PropTypes.func.isRequired,
  children: PropTypes.node,
  isUpiIntentIOSWaitModal: PropTypes.bool,
};

const defaultBackdropStyle = {
  minHeight: '100%',
  transition: '0.3s ease-out',
  position: 'fixed',
  top: '0px',
  left: '0px',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.95)',
};
