/* globals INITIAL_STATE */

import root from 'window-or-global';

import { camelCaseKeys } from '../../../common/config/utils';
import { objectWithout } from '../../../common/config/utils';
import * as PostPurchaseActionTypes from '../actions/PostPurchaseActions';

function sanitizeSuborder(order) {
  return {
    ...order,
    amount: Number(order.amount),
    quantity: order.quantity ? Number(order.quantity) : 1,
    image: order.image || `${root.STATIC_URL}assets/images/no-image.png`,
    variants: Object.keys(order.variants || {}).map((v) => ({
      categoryName: order.variants[v].variantCategory,
      title: v,
    })),
    customFields: Object.keys(order.customFields || {}).map((c) => ({
      label: order.customFields[c].label,
      value: order.customFields[c].value,
    })),
  };
}

const postPurchase = (state = getInitialState(INITIAL_STATE), action) => {
  switch (action.type) {
    case PostPurchaseActionTypes.HANDLE_POST_PURCHASE_DATA_UPDATE: {
      return {
        ...state,
        [action.formField]: action.formFieldData,
        errors: objectWithout(state.errors, [action.formField]),
      };
    }
    case PostPurchaseActionTypes.FILE_PROTECTION_EMAIL_VERIFICATION_SUCCESS: {
      return {
        ...state,
        fileProtectionStatus: 'unlocked',
      };
    }
    case PostPurchaseActionTypes.FILE_PROTECTION_EMAIL_VERIFICATION_FAILURE: {
      const {
        errors: { isVerificationFailed },
        errors,
      } = action;
      if (isVerificationFailed) {
        return {
          ...state,
          fileProtectionStatus: 'locked',
        };
      }
      return {
        ...state,
        errors: errors,
      };
    }
    case PostPurchaseActionTypes.HANDLE_SHOW_PANIC_FAQ: {
      return {
        ...state,
        showPanicFaq: true,
      };
    }

    default:
      return state;
  }
};

export default postPurchase;

const getInitialState = (state) => {
  const {
    tick_image: tickImage,
    post_transaction_interstitial: postTransactionInterstitial,
    payment: {
      date,
      status,
      is_free: isFree,
      offer: {
        download_link: downloadLink,
        download_expired: isDownloadProtected,
        upload,
        note,
        note_name: noteName,
        preview,
        redirect,
        title: productTitle,
      },
      link: { note: linkNote, redirect: linkRedirectUrl },
      suborders,
      buyer_email: buyerEmail,
      buyer_phone: buyerPhone,
      purpose: title,
      amount,
      id: paymentId,
      case: { url } = {},
    },
    seller: { username, avatar, email, fullname, is_store_enabled: isStoreEnabled },
  } = state;

  let orderId = '';
  if (state.payment && state.payment.payment_forms) {
    orderId = state.payment.payment_forms.order;
  }

  return {
    status,
    tickImage,
    payment: {
      postTransactionInterstitial: camelCaseKeys(postTransactionInterstitial),
      paymentId,
      date,
      orderId,
      isFree,
      subOrders: suborders ? camelCaseKeys(suborders).map(sanitizeSuborder) : null,
      offer: {
        downloadLink,
        isDownloadProtected,
        upload,
        note,
        noteName,
        preview,
        redirect,
        title: productTitle,
      },
      seller: {
        avatar,
        username,
        email,
        fullname,
        isStoreEnabled,
      },
      link: {
        title,
        amount,
        linkNote,
        linkRedirectUrl,
      },
      buyer: {
        email: buyerEmail,
        phone: buyerPhone,
      },
      case: {
        url,
      },
    },
    isVerifyingBuyerForFileDownload: false,
    fileProtectionStatus: 'initial',
    emailAddressToVerify: '',
    errors: {},
    showPanicFaq: '',
  };
};

export const getResponseState = (state) => state;
