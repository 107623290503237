import PropTypes from 'prop-types';
import React from 'react';

import PaymentGroup from './PaymentGroup';

const cardHeadings = {
  upi: 'Scan UPI QR and pay',
  non_upi: 'More payment options',
  international_payments: 'International Payments',
};

// This is required to make sure that the groups are rendered in this order
// Any new group will be required to be added here.
const paymentGroupsOrderArray = ['non_upi', 'international_payments'];

class PaymentGroupsWrapper extends React.PureComponent {
  render() {
    const { paymentGroups } = this.props.finalizePurchase;
    return (
      <>
        {paymentGroups
          ? paymentGroupsOrderArray.map((optionKey, index) => {
              if (paymentGroups[optionKey] || (optionKey === 'non_upi' && paymentGroups['upi'])) {
                // extra check in case that group does not exist in the paymentGroups (no payment options enabled from that group)
                return (
                  <>
                    {optionKey === 'international_payments' ? ( // Want to show additional line for international payment
                      <p className="soft-half--bottom">
                        Are you paying using a non-Indian card?
                      </p>
                    ) : null}
                    <PaymentGroup
                      key={index}
                      paymentOptionGroup={optionKey}
                      paymentGroupIndex={index}
                      username={this.props.username}
                      cardHeading={cardHeadings[optionKey]}
                      finalizePurchase={this.props.finalizePurchase}
                      handlePaymentGroupCardClick={(cardIndex) =>
                        this.props.handlePaymentGroupCardClick(cardIndex)
                      }
                      handleManageSavedCards={(payload) =>
                        this.props.handleManageSavedCards(payload)
                      }
                      handleDeleteSavedCard={(cardToBeDeleted) =>
                        this.props.handleDeleteSavedCard(cardToBeDeleted)
                      }
                      handleFetchConvenienceFeesForQR={() =>
                        this.props.handleFetchConvenienceFeesForQR()
                      }
                      pollForUPIAcceptance={(statusCheckUrl) =>
                        this.props.pollForUPIAcceptance(statusCheckUrl)
                      }
                      handleSelectPaymentOption={(selectedPaymentDetails) =>
                        this.props.handleSelectPaymentOption(selectedPaymentDetails)
                      }
                      handleResetSelectedPaymentMode={() =>
                        this.props.handleResetSelectedPaymentMode()
                      }
                      renderSelecedPaymentOption={() =>
                        this.props.renderSelecedPaymentOption()
                      }
                      handlePaypalSuccessfulCallback={() =>
                        this.props.handlePaypalSuccessfulCallback()
                      }
                      handlePaypalSDKError={() => this.props.handlePaypalSDKError()}
                    />
                  </>
                );
              }
            })
          : null}
      </>
    );
  }
}

PaymentGroupsWrapper.propTypes = {
  username: PropTypes.string,
  finalizePurchase: PropTypes.object.isRequired,
  handlePaymentGroupCardClick: PropTypes.func.isRequired,
  handleManageSavedCards: PropTypes.func.isRequired,
  handleResetSelectedPaymentMode: PropTypes.func.isRequired,
  handleDeleteSavedCard: PropTypes.func.isRequired,
  handleFetchConvenienceFeesForQR: PropTypes.func.isRequired,
  pollForUPIAcceptance: PropTypes.func.isRequired,
  handleSelectPaymentOption: PropTypes.func.isRequired,
  renderSelecedPaymentOption: PropTypes.func.isRequired,
  handlePaypalSuccessfulCallback: PropTypes.func,
  handlePaypalSDKError: PropTypes.func,
};

export default PaymentGroupsWrapper;
