import PropTypes from 'prop-types';
import React from 'react';

import TOSModal from '../initialize/TOSModal';

const TnCText = ({ styles = {} }) => (
  <p style={{ ...styles }}>
    <TOSModal />
  </p>
);

TnCText.propTypes = {
  styles: PropTypes.object,
};

export default TnCText;
