import PropTypes from 'prop-types';
import React from 'react';

import {
  FormFieldTextArea,
  FormFieldWithLabel as FormField,
  FormFieldWrapper as FormFieldsWrapper,
  InputCheckbox,
} from '../../../../common/components/FormComponents';
import { Columns, Row } from '../../../../common/components/Grid';
import { camelCaseKeys } from '../../../../common/config/utils';

const CustomFieldsForLink = ({ form, link, onChangeInput }) => {
  const {
    customFields,
    customFields: { shippingAddress, billingAddress, gstin, additionalCustomFields },
  } = link;
  const {
    customFields: {
      billingAddress: billingAddressForm,
      shippingAddress: shippingAddressForm,
      companyName: companyNameForm,
      gstin: gstinForm,
      billingSameAsShipping,
      showGstField,
      additionalCustomFields: additionalCustomFieldsForm,
    },
  } = form;
  return (
    <FormFieldsWrapper>
      {shippingAddress ? (
        <div>
          <p className="soft-half--bottom">Shipping Address</p>
          <CustomAddressFields
            errors={form.errors}
            keyName="shippingAddress"
            customFields={shippingAddress}
            addressFormFields={shippingAddressForm}
            onChangeInput={onChangeInput}
          />
        </div>
      ) : null}
      {billingAddress ? (
        <div>
          <p className="soft-half--bottom">Billing Address</p>
          {customFields.shippingAddress ? (
            <InputCheckbox
              className="soft--bottom"
              checkboxClassName="push-half--bottom"
              label={'Same as shipping address'}
              checked={billingSameAsShipping}
              onChange={(e) =>
                onChangeInput({ ['billingSameAsShipping']: e.target.checked })
              }
            />
          ) : null}
          {!billingSameAsShipping ? (
            <CustomAddressFields
              errors={form.errors}
              keyName="billingAddress"
              customFields={billingAddress}
              addressFormFields={billingAddressForm}
              onChangeInput={onChangeInput}
            />
          ) : null}
        </div>
      ) : null}
      {gstin ? (
        <div>
          <p className="soft--bottom">Business Details</p>
          <InputCheckbox
            className="soft--bottom"
            checkboxClassName="push-half--bottom"
            label={'Use GST Number for this Payment'}
            checked={showGstField}
            onChange={(e) => onChangeInput({ ['showGstField']: e.target.checked })}
          />
          <div hidden={!showGstField}>
            <FormField label="GST Number" errors={form.errors.gstin}>
              <input
                type="text"
                value={gstinForm}
                onChange={(e) => onChangeInput({ ['gstin']: e.target.value })}
              />
            </FormField>
            <FormField label="Company Name" errors={form.errors.companyName}>
              <input
                type="text"
                value={companyNameForm}
                onChange={(e) => onChangeInput({ ['companyName']: e.target.value })}
              />
            </FormField>
          </div>
        </div>
      ) : null}
      {additionalCustomFields ? (
        <div>
          {Object.keys(additionalCustomFieldsForm).sort().map((fieldName, index) => {
            return (
              <FormField
                label={
                  <span className="break-long-words">
                    {additionalCustomFields[fieldName].label}
                    {!additionalCustomFields[fieldName].required ? (
                      <span> (optional)</span>
                    ) : null}
                  </span>
                }
                errors={
                  form.errors[`additional_${additionalCustomFields[fieldName].name}`]
                }
                key={index}
              >
                <input
                  type="text"
                  value={additionalCustomFieldsForm[fieldName]}
                  onChange={(e) =>
                    onChangeInput({
                      [fieldName]: e.target.value,
                      isAdditionalField: true,
                    })
                  }
                />
              </FormField>
            );
          })}
        </div>
      ) : null}
    </FormFieldsWrapper>
  );
};

CustomFieldsForLink.propTypes = {
  form: PropTypes.object,
  link: PropTypes.object,
  onChangeInput: PropTypes.func,
};

export default CustomFieldsForLink;

const CustomAddressFields = ({
  errors,
  keyName,
  customFields,
  addressFormFields,
  onChangeInput,
}) => {
  const { address, city, state, zip } = addressFormFields;

  const errorKey = keyName.split('Address')[0];

  const {
    city: { label: cityLabel },
    state: { label: stateLabel, choices: stateList },
  } = customFields;

  addressFormFields = camelCaseKeys(addressFormFields);
  return (
    <div>
      <FormFieldTextArea
        label={'Address'}
        errors={errors[errorKey + 'Address']}
        value={address}
        rows={2}
        onChange={(e) =>
          onChangeInput({ keyName, field: 'address', value: e.target.value })
        }
        style={{ resize: 'vertical' }}
        placeholder="House number, building name, street name etc."
      />
      <FormField label={stateLabel} errors={errors[errorKey + 'State']}>
        <select
          value={state}
          onChange={(e) =>
            onChangeInput({ keyName, field: 'state', value: e.target.value })
          }
        >
          <option value="">Select an option</option>
          {stateList.map((stateName) => (
            <option key={stateName[0]} value={stateName[0]}>
              {stateName[1]}
            </option>
          ))}
        </select>
      </FormField>
      <FormField>
        <Row>
          <Columns className="small-6 left">
            <FormFieldsWrapper>
              <FormField label={cityLabel} errors={errors[errorKey + 'City']}>
                <input
                  type="text"
                  value={city}
                  onChange={(e) =>
                    onChangeInput({ keyName, field: 'city', value: e.target.value })
                  }
                />
              </FormField>
            </FormFieldsWrapper>
          </Columns>
          <Columns className="small-6">
            <FormFieldsWrapper>
              <FormField label={'Pincode'} errors={errors[errorKey + 'Zip']}>
                <input
                  type="text"
                  value={zip}
                  onChange={(e) =>
                    onChangeInput({ keyName, field: 'zip', value: e.target.value })
                  }
                />
              </FormField>
            </FormFieldsWrapper>
          </Columns>
        </Row>
      </FormField>
    </div>
  );
};

CustomAddressFields.propTypes = {
  errors: PropTypes.object,
  keyName: PropTypes.string,
  customFields: PropTypes.object,
  addressFormFields: PropTypes.object,
  onChangeInput: PropTypes.func,
};
