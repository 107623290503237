import PropTypes from 'prop-types';
import React from 'react';

import TrustIcons from './TrustIcons';

export default class FinalizeFooter extends React.Component {
  render() {
    const { paymentOptions } = this.props.finalizePurchase;

    return (
      <div>
        {Object.keys(paymentOptions).length ? (
          <div>
            <TrustIcons />
          </div>
        ) : (
          <p className="soft--bottom">
            Sorry, You can&apos;t pay for this. Email us at support@instamojo.com for
            further assistance
          </p>
        )}
      </div>
    );
  }
}

FinalizeFooter.propTypes = {
  finalizePurchase: PropTypes.object,
};
