/*
 * This file is used as a global
 * export house for all the utils files
 * for easy access
 */
import React from 'react';

export {
  isAndroidDevice,
  isAndroidWebview,
  isInternetExplorerBrowser,
  isIOS_Device,
  isIOS_Webview,
  isNewWindowFormSubmissionSupportedByDevice,
  isRunningInIframe,
  isSmallDevice,
  isUC_Browser,
  isWindowsPhoneDevice,
  openNewWindowAndGetTarget,
} from '@instamojo/agent-utils';
export {
  getCookie,
  getFileExtension,
  hideDropdown,
  sanitizeHtmlInput,
  scrollTop,
  triggerEvent,
} from '@instamojo/document-utils';
export {
  createGroups,
  decimalPlaces,
  formatAmountToDecimalPlaces,
  getCurrentFinancialYear,
  getCurrentUTCTimestamp,
  getDifferenceInCalendarDays,
  getFormattedDate,
  getFormattedDateWithCurrentDateDifference,
  getFormattedDateWithDayOfWeek,
  getFormattedDateWithTime,
  getFormattedTime,
  getFullName,
  sanitizePhoneNumber,
  toTwoDecimalPlaces,
} from '@instamojo/format-utils';
export {
  arrayContains,
  camelCaseKeys,
  closest,
  closestAttr,
  convertUndefinedToNullInFlattenedObject,
  emptyFunction,
  generateFormDataForJSON,
  getSelectedOptionLabel,
  objectWithout,
  removeDuplicatesAndSortArray,
  snakeCaseKeys,
  stringifyKeys,
} from '@instamojo/object-utils';
export {
  camelCaseString,
  capitalizeFirstLetters,
  encodeString,
  nullToEmptyString,
  stringCompare,
  upperCaseString,
} from '@instamojo/string-utils';
export {
  addTrailingSlashToUrl,
  facebookUrlRegex,
  getCurrentLocationPathname,
  getUrlParameterByName,
  getURLStringForAppendingParams,
  instagramUrlRegex,
  isValidFacebookUrl,
  isValidInstagramUrl,
  isValidUrl,
  isValidYoutubeEmbedLink,
  isValidYoutubeUrl,
  matchPattern,
  parseLinks,
  removeTrailingSlash,
  urlRegex,
  urlRegexWithOptionalProtocol,
  youtubeEmbedUrlRegex,
  youtubeUrlRegex,
} from '@instamojo/url-utils';

export { countingSort } from './utils/arrayUtils';

export const ACCEPT_STAFF_ACTION_NAME = 'accept';

//For testing on staging0, the project ID would be ittsuz55hw
export const getMSClarityScript = () => {
  return `<script type="text/javascript">
          (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "iyiy9wv5dr");
          </script>`;
        };

const OptionalDocumentList = ['gstRegistrationCertificate'];

export const renderOptionalTag = (document) => {
  return (
    <>
    { OptionalDocumentList.includes(document) &&
      <span className="text-light-grey push-half--left position--relative" style={{ fontSize: '14px', bottom: '3px' }}>
        (Optional)
      </span>
    }
    </>
  );
};

export const convertCamelCaseToTitleCase = (text) => {
  if (text !== undefined) {
    const result = text.toString().replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1).replace('File', '');
  }
};

export const submittedKYCDocs = (resubmissionForm) => {
  const documentNameToBeChecked = [
    'addressProof',
    'addressProofOfDirector',
    'addressProofOfPartner',
    'addressProofOfSecondDirector',
    'addressProofOfSecondPartner',
    'addressProofOfSecondTrustee',
    'addressProofOfTheBusiness',
    'addressProofOfTrustee',
    'affiliationCertificate',
    'amfiRegistration',
    'anyRegistrationDocument',
    'articlesOfAssociation',
    'auditedBalanceSheet',
    'authorizedSignatoryAddressProofDocument',
    'bankStatement',
    'bbpsLicense',
    'bisCertificate',
    'boardApprovedAuthorisationLetter',
    'businessLicense',
    'businessOperationAddress',
    'businessRegistrationCertificate',
    'businessRegistrationDocument',
    'certificateIssuedByTaxAuthority',
    'certificateOfIncorporation',
    'certificate_12a',
    'certificate_80g',
    'creditRating',
    'cstVatPt',
    'electionCommissionCertificate',
    'fcraCertificate',
    'fssaiCertificate',
    'gstRegistrationCertificate',
    'iataIatoRecognition',
    'incorporationOrRegistrationDocument',
    'investmentAdvisorCertificate',
    'irdaLicense',
    'licenseIssuedByRegisteringAuthority',
    'listOfTrustees',
    'listOfUltimateBeneficiaries',
    'medicalCouncilCertificate',
    'memorandumOfAssociation',
    'nbfcLicense',
    'ngoRegistrationCertificate',
    'otherDocument',
    'panCard',
    'panDetailsOfAuthorisedSignatory',
    'panDetailsOfDirector',
    'panDetailsOfPartner',
    'panDetailsOfSecondAuthorisedSignatory',
    'panDetailsOfSecondDirector',
    'panDetailsOfSecondPartner',
    'panDetailsOfSecondTrustee',
    'panDetailsOfTrustee',
    'partnershipDeed',
    'professionalCertificate',
    'proprietorshipCertificate',
    'rbiRegistration',
    'registrationCertificateOfProprietorship',
    'reraCertificate',
    'sebiLicense',
    'secondAuthorizedSignatoryAddressProofDocument',
    'shopAndEstablishmentLicense',
    'statuatoryCertificate',
    'taxReturnInTheNameOfProprietorship',
    'tourismDepartmentCertificate',
    'traiRegistration',
    'trustDeed',
    'udyamCertificate',
    'undertakingForIndividuals',
    'utilityBill',
    'validRentAgreement',
    'addressProofFile',
    'panCardFile',
    'bankStatementFile',
    'ngoRegistrationCertificateFile',
    'auditedBalanceSheetFile',
    'fcraCertificateFile',
    'trustDeedFile',
    'investmentAdvisorCertificateFile',
    'registrationCertificateOfProprietorshipFile',
    'otherDocumentFile',
    'certificate_80gFile',
    'certificate_12aFile',
    'sebiLicenseFile',
    'irdaLicenseFile',
    'affiliationCertificateFile',
    'fssaiCertificateFile',
    'electionCommissionCertificateFile',
    'nbfcLicenseFile',
    'bisCertificateFile',
    'listOfUltimateBeneficiariesFile',
    'panDetailsOfSecondTrusteeFile',
    'addressProofOfSecondTrusteeFile',
    'secondAuthorizedSignatoryAddressProofDocumentFile',
    'panDetailsOfSecondAuthorisedSignatoryFile',
    'gstRegistrationCertificateFile',
    'businessOperationAddressFile',
    'undertakingForIndividualsFile',
    'memorandumOfAssociationFile',
    'articlesOfAssociationFile',
    'panDetailsOfSecondDirectorFile',
    'panDetailsOfSecondPartnerFile',
    'addressProofOfSecondDirectorFile',
    'addressProofOfSecondPartnerFile',
    'incorporationOrRegistrationDocumentFile',
    'anyRegistrationDocumentFile',
    'listOfTrusteesFile',
    'panDetailsOfDirectorFile',
    'panDetailsOfPartnerFile',
    'panDetailsOfTrusteeFile',
    'businessRegistrationDocumentFile',
    'panDetailsOfAuthorisedSignatoryFile',
    'addressProofOfDirectorFile',
    'addressProofOfPartnerFile',
    'addressProofOfTrusteeFile',
    'authorizedSignatoryAddressProofDocumentFile',
    'boardApprovedAuthorisationLetterFile',
    'udyamCertificateFile',
    'certificateIssuedByTaxAuthorityFile',
    'shopAndEstablishmentLicenseFile',
    'taxReturnInTheNameOfProprietorshipFile',
    'licenseIssuedByRegisteringAuthorityFile',
    'statuatoryCertificateFile',
    'traiRegistrationFile',
    'amfiRegistrationFile',
    'rbiRegistrationFile',
    'creditRatingFile',
    'reraCertificateFile',
    'medicalCouncilCertificateFile',
    'tourismDepartmentCertificateFile',
    'iataIatoRecognitionFile',
    'apiProviderTieUpArrangementCopyFile',
    'apiPortalPostLoginScreenshotFile',
    'bbpsLicenseFile',
    'businessLicenseFile',
    'professionalCertificateFile',
    'certificateOfIncorporationFile',
    'businessRegistrationCertificateFile',
    'proprietorshipCertificateFile',
    'partnershipDeedFile',
    'utilityBillFile',
    'validRentAgreementFile',
    'cstVatPtFile',
  ];
  return documentNameToBeChecked.map((document) => {
    const documentNotNull = resubmissionForm[document];
      if (documentNotNull !== null && documentNotNull !== '') return convertCamelCaseToTitleCase(Object.keys(resubmissionForm).find((formDoc) => formDoc === document));
  }).filter((value) => value !== undefined);
};

export const snakeCaseString = (data) => {
  return data.replace(/([A-Z])/g, ($1) => {
    return '_' + $1.toLowerCase();
  });
};

