/* globals STATIC_URL */
import PropTypes from 'prop-types';
import React from 'react';

import { UPIOptionToVerificationTextMap } from '../../config/Upi';

const UpiLoader = ({ selectedUPIOption }) => (
  <div className="soft--top">
    <input
      className="btn-block btn-green disabled push--bottom"
      disabled
      value="Waiting..."
    />
    <div className="center">
      <img
        src={`${STATIC_URL}assets/images/payment/upi.png`}
        alt="upi"
        className="push--bottom"
      />
      <p className="push--bottom">
        Check your phone for verification notification on{' '}
        {UPIOptionToVerificationTextMap[selectedUPIOption] || ''}.
      </p>
    </div>
  </div>
);

UpiLoader.propTypes = {
  selectedUPIOption: PropTypes.string,
};

export default UpiLoader;
