import PropTypes from 'prop-types';
import React from 'react';
import Scroll from 'react-scroll';

export default function scrollController(Component) {
  class ScrollController extends Component {
    constructor(props) {
      super(props);
    }

    componentDidMount() {
      const root = this._root;
      if (!root || !this.props.isMobileDevice) {
        return;
      }
      const { scroller } = Scroll;
      scroller.register('root', root);
      scroller.scrollTo(
        'root',
        true /*shouldAnimate*/,
        300 /*duration in ms*/,
        -80 /* offset */,
      );
      scroller.unregister('root');
    }

    render() {
      return (
        <div ref={(ref) => (this._root = ref)}>
          <Component {...this.props} />
        </div>
      );
    }
  }

  ScrollController.propTypes = {
    isMobileDevice: PropTypes.bool.isRequired,
  };

  ScrollController.defaultProps = {
    isMobileDevice: false,
  };

  return ScrollController;
}
