import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import root from 'window-or-global';

import { getURLStringForAppendingParams } from '../../../../../common/config/utils';
import { isNewWindowFormSubmissionSupportedByDevice as shouldSubmitInNewWindow } from '../../../../../common/config/utils';
import {
  eventMethod,
  messageEvent,
  removeEventMethod,
} from '../../../../../common/utils/EventUtils';
import {
  handleHideLoader,
  handleUpdateConvenienceFeeObject,
} from '../../../actions/FinalizePurchaseActions';
import { handleAwaitingPaymentCompletion } from '../../../actions/SettingsActions';
import {
  handleRedirection,
  isSubmitTargetForcedToSelf,
} from '../../../utils/FinalizePurchaseUtils';
import { invokeMixpanelEvent, MIXPANEL_PAYMENT_EVENT } from '../../../utils/PaymentUtils';
import TnCText from '../TnCText';

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.cardIframeRef = React.createRef();
    this.messageListenerHandler = this.messageListenerHandler.bind(this);
  }

  componentDidMount() {
    const frameRef = this.cardIframeRef.current;

    const { selectedPaymentMode, selectedSavedCard } = this.props.finalizePurchase;

    root[eventMethod](messageEvent, this.messageListenerHandler);

    frameRef.onload = () => {
      if (selectedPaymentMode === 'saved_cards') {
        frameRef.contentWindow.postMessage(selectedSavedCard, '*');
      }
      this.props.handleHideLoader();
    };
  }

  componentWillUnmount() {
    root[removeEventMethod](messageEvent, this.messageListenerHandler);
  }

  invokeEnteredInstrumentDetailsMixpanelEvent() {
    const { store } = this.props;

    const mixpanelData = {
      amount: undefined,
      conv_fee_version: undefined,
    };

    invokeMixpanelEvent(
      MIXPANEL_PAYMENT_EVENT.ENTERED_INSTRUMENT_DETAILS,
      mixpanelData,
      store,
    );
  }

  messageListenerHandler(e) {
    const frameRef = this.cardIframeRef.current;
    const {
      data,
      data: { name, height },
    } = e;

    const { order, orderToken } = this.props.finalizePurchase;
    const isIMDomain = root.location.origin.includes('instamojo.com');
    const debitCardHeightForCustomDomain = !isIMDomain && height === 0;

    switch (name) {
      case 'iframeHeight': {
        frameRef.height = debitCardHeightForCustomDomain ? '260px' : height;
        frameRef.style.maxHeight = `${debitCardHeightForCustomDomain ? '260px' : height}px`;
        return;
      }

      case 'redirect': {
        handleRedirection(data);
        return;
      }

      case 'unexpected-pre-closure-of-payment-window': {
        root.location.href = `/order/status/${order}/?token=${orderToken}`;
        return;
      }

      case 'isMojocardConvenienceFeeBlockVisible': {
        this.props.handleUpdateConvenienceFeeObject({
          showConvenienceFeeForm: data.isMojocardConvenienceFeeBlockVisible,
          isMojocard: true,
        });
        if (data.isMojocardConvenienceFeeBlockVisible) {
          this.invokeEnteredInstrumentDetailsMixpanelEvent();
        }
        return;
      }

      case 'isAwaitingPaymentCompletion': {
        this.props.handleAwaitingPaymentCompletion();
        return;
      }

      default: {
        return;
      }
    }
  }

  render() {
    const { isMobileDevice } = this.props.finalizePurchase;

    const termsTextStyles = {
      marginTop: isMobileDevice ? -7 : -14,
      marginBottom: '1rem',
    };

    return (
      <div>
        <iframe
          className="animate-iframe--max-height"
          ref={this.cardIframeRef}
          src={`${getURLStringForAppendingParams(this.props.submissionUri)}newWindow=${
            shouldSubmitInNewWindow && !isSubmitTargetForcedToSelf()
          }`}
          height={292}
          width="100%"
          frameBorder="0"
          scrolling="no"
        />
        <TnCText styles={termsTextStyles} />
      </div>
    );
  }
}

Card.propTypes = {
  finalizePurchase: PropTypes.object,
  submissionUri: PropTypes.string,
  handleAwaitingPaymentCompletion: PropTypes.func,
  handleHideLoader: PropTypes.func,
  handleUpdateConvenienceFeeObject: PropTypes.func,
  store: PropTypes.object,
};

export default connect((store) => ({ store }), {
  handleHideLoader,
  handleAwaitingPaymentCompletion,
  handleUpdateConvenienceFeeObject,
})(Card);
