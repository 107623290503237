import LGTM from 'lgtm';

import { POST_API } from '../../../common/middlewares/form';

export const RETRY_FAILED_PAYMENT = 'RETRY_FAILED_PAYMENT';
export const retryFailedPayment = () => ({
  type: RETRY_FAILED_PAYMENT,
});

export const HANDLE_SHOW_PANIC_FAQ = 'HANDLE_SHOW_PANIC_FAQ';
export const handleShowPanicFaq = () => ({
  type: HANDLE_SHOW_PANIC_FAQ,
});

export const HANDLE_POST_PURCHASE_DATA_UPDATE = 'HANDLE_POST_PURCHASE_UPDATE_DATA_UPDATE';
export const handlePostPurchaseDataUpdate = (formField, formFieldData) => ({
  type: HANDLE_POST_PURCHASE_DATA_UPDATE,
  formField,
  formFieldData,
});

const fileProtectionValidator = LGTM.validator()
  .validates('emailAddressToVerify')
  .required('Enter Email Address')
  .email('Enter a valid email ID', { strictCharacters: true })
  .build();

export const FILE_PROTECTION_EMAIL_VERIFICATION_REQUEST =
  'FILE_PROTECTION_EMAIL_VERIFICATION_REQUEST';
export const FILE_PROTECTION_EMAIL_VERIFICATION_SUCCESS =
  'FILE_PROTECTION_EMAIL_VERIFICATION_SUCCESS';
export const FILE_PROTECTION_EMAIL_VERIFICATION_FAILURE =
  'FILE_PROTECTION_EMAIL_VERIFICATION_FAILURE';
export const handleFileProtectionEmailVerify = () => ({
  [POST_API]: {
    types: [
      FILE_PROTECTION_EMAIL_VERIFICATION_REQUEST,
      FILE_PROTECTION_EMAIL_VERIFICATION_SUCCESS,
      FILE_PROTECTION_EMAIL_VERIFICATION_FAILURE,
    ],
    endpoint: '/resend-download/',
    validator: fileProtectionValidator,
    toValidate: (store) => {
      const { emailAddressToVerify } = store.postPurchase;
      return {
        emailAddressToVerify,
      };
    },
    payload: (store) => {
      const {
        emailAddressToVerify,
        payment: { paymentId },
      } = store.postPurchase;
      return {
        email: emailAddressToVerify,
        paymentId,
      };
    },
    failureActionErrors: (response) => ({ ...response, isVerificationFailed: true }),
  },
});
