import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { FormFieldWithLabel } from '../../../../common/components/FormComponents';
import {
  handleFileProtectionEmailVerify,
  handlePostPurchaseDataUpdate,
} from '../../actions/PostPurchaseActions';

const VerificationPage = ({
  onPostPurchaseDataUpdate,
  onFileProtectionEmailVerify,
  errors,
}) => (
  <div>
    <div className="caps font-rc soft--bottom">Digital File Protection</div>
    <div className="text-medium soft--bottom" style={{ lineHeight: '1.3em' }}>
      To prevent unauthorized digital downloads, please enter the email used during
      purchase to verify yourself.
    </div>
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onFileProtectionEmailVerify();
        }}
      >
        <ul className="form-items-container">
          <FormFieldWithLabel
            label="Email Address"
            labelClassName="text-light-grey"
            errors={errors.emailAddressToVerify}
          >
            <input
              ref={(input) => input && input.focus()}
              type="text"
              onChange={(e) =>
                onPostPurchaseDataUpdate('emailAddressToVerify', e.target.value)
              }
              placeholder="username@domain.com"
            />
          </FormFieldWithLabel>
        </ul>
        <button className="btn btn-green btn-block">Verify</button>
      </form>
    </div>
  </div>
);

VerificationPage.propTypes = {
  onPostPurchaseDataUpdate: PropTypes.func,
  onFileProtectionEmailVerify: PropTypes.func,
  errors: PropTypes.object,
};

const VerificationSuccess = ({ emailAddressToVerify, tickImage }) => (
  <div>
    <div className="text-center">
      <img src={tickImage} alt="" />
      <h4 className="text-green font-rc push-half--top">Verification Successful</h4>
    </div>
    <div className="text-medium soft--ends text-center">
      A download link to the requested file has been mailed to {emailAddressToVerify}
    </div>
  </div>
);

VerificationSuccess.propTypes = {
  emailAddressToVerify: PropTypes.string,
  tickImage: PropTypes.string,
};

const VerificationFailed = ({ onPostPurchaseDataUpdate }) => (
  <div>
    <div className="text-center">
      <h3 className="text-red font-rc push-half--top">Verification Failed</h3>
    </div>
    <div className="text-medium soft--ends">
      The email you entered does not match with the buyer’s email.
    </div>
    <button
      className="btn btn-green btn-block push--top"
      onClick={() => {
        onPostPurchaseDataUpdate('emailAddressToVerify', '');
        onPostPurchaseDataUpdate('fileProtectionStatus', 'initial');
      }}
    >
      Retry
    </button>
  </div>
);

VerificationFailed.propTypes = {
  onPostPurchaseDataUpdate: PropTypes.func,
};

const DigitalFileProtection = ({
  fileProtectionStatus,
  emailAddressToVerify,
  tickImage,
  errors,
  handlePostPurchaseDataUpdate: onPostPurchaseDataUpdate,
  handleFileProtectionEmailVerify: onFileProtectionEmailVerify,
}) => (
  <div className="soft">
    {fileProtectionStatus === 'initial' ? (
      <VerificationPage
        onPostPurchaseDataUpdate={(formField, formFieldData) =>
          onPostPurchaseDataUpdate(formField, formFieldData)
        }
        onFileProtectionEmailVerify={() => onFileProtectionEmailVerify()}
        errors={errors}
      />
    ) : fileProtectionStatus === 'unlocked' ? (
      <VerificationSuccess
        emailAddressToVerify={emailAddressToVerify}
        tickImage={tickImage}
      />
    ) : (
      <VerificationFailed
        onPostPurchaseDataUpdate={(formField, formFieldData) =>
          onPostPurchaseDataUpdate(formField, formFieldData)
        }
      />
    )}
  </div>
);

DigitalFileProtection.propTypes = {
  fileProtectionStatus: PropTypes.string,
  emailAddressToVerify: PropTypes.string,
  tickImage: PropTypes.string,
  errors: PropTypes.object,
  handlePostPurchaseDataUpdate: PropTypes.func,
  handleFileProtectionEmailVerify: PropTypes.func,
};

const mapStateToProps = ({
  postPurchase: { fileProtectionStatus, emailAddressToVerify, tickImage, errors },
}) => ({
  fileProtectionStatus,
  emailAddressToVerify,
  tickImage,
  errors,
});

export default connect(mapStateToProps, {
  handlePostPurchaseDataUpdate,
  handleFileProtectionEmailVerify,
})(DigitalFileProtection);
