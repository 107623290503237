import PropTypes from 'prop-types';
import React from 'react';

import { FormFieldWithLabel, FormFieldWrapper } from '../FormComponents';

export default class FormFieldSelectDropdown extends React.Component {
  // loop over the list provided and create options accordingly
  renderOptions(listOfOptions, valueObjectKey, displayNameObjectKey) {
    return listOfOptions.map((curr, index) => (
      <option key={index} value={curr[valueObjectKey]}>
        {curr[displayNameObjectKey]}
      </option>
    ));
  }

  render() {
    const {
      className,
      dataDisabled,
      onChange,
      value,
      listOfOptions,
      valueObjectKey,
      displayNameObjectKey,
      disabled,
      errors,
      label,
      style,
    } = this.props;

    return (
      <FormFieldWrapper>
        <FormFieldWithLabel
          labelClassName="push-half--bottom"
          label={label}
          errors={errors}
        >
          <select
            className={className}
            data-disabled={dataDisabled}
            onChange={onChange}
            value={value}
            disabled={disabled}
            style={style ? style : {}}
          >
            <option value="" disabled>
              Select an Option
            </option>
            {this.renderOptions(listOfOptions, valueObjectKey, displayNameObjectKey)}
          </select>
        </FormFieldWithLabel>
      </FormFieldWrapper>
    );
  }
}

FormFieldSelectDropdown.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  listOfOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  valueObjectKey: PropTypes.string.isRequired,
  displayNameObjectKey: PropTypes.string.isRequired,
  className: PropTypes.string,
  dataDisabled: PropTypes.bool, //TODO explain what this means
  value: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  style: PropTypes.object,
};

FormFieldSelectDropdown.defaultProps = {
  dataDisabled: true,
  disabled: false,
};
