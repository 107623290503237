import TooltipComponent from '@instamojo/tooltip-component';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { FormField, FormFieldLabel, FormFieldWrapper } from '../FormComponents';
import { Columns, Row } from '../Grid';
import Icon from '../Icon';

const PhoneNumber = ({
  phoneNumber,
  isPhoneNumberEditable,
  handlePhoneInputEditMode,
}) => (
  <Row className="push--top">
    <Columns className="medium-12">
      <FormFieldWrapper columns="columns medium-12">
        <FormField className="soft--bottom">
          <FormFieldLabel>Phone Number</FormFieldLabel>
          <Row className="collapse">
            <Columns className="small-2">
              <span className="prefix" style={{ borderRadius: '4px 0px 0px 4px' }}>
                +91
              </span>
            </Columns>
            <Columns
              className={classNames(isPhoneNumberEditable ? 'small-9' : 'small-10')}
              style={
                !isPhoneNumberEditable
                  ? { borderRadius: '0px 4px 4px 0px', border: '1px solid #B5BFCE' }
                  : null
              }
            >
              <input
                type="number"
                defaultValue={phoneNumber}
                disabled
                data-disabled={true}
                style={{ borderRadius: '0', borderRight: 'none' }}
              />
            </Columns>
            {isPhoneNumberEditable ? (
              <Columns className="small-1">
                <span
                  className="postfix"
                  style={{
                    borderRadius: '0px 4px 4px 0px',
                    background: '#F8F9FA',
                    border: '1px solid #cacaca',
                  }}
                >
                  <TooltipComponent overlay="Edit Mobile Number">
                    <a
                      className="form-field-edit text-right float--right hard flush"
                      onClick={() => handlePhoneInputEditMode()}
                    >
                      <Icon className="ui-1_edit-73" />
                    </a>
                  </TooltipComponent>
                </span>
              </Columns>
            ) : null}
          </Row>
        </FormField>
      </FormFieldWrapper>
    </Columns>
  </Row>
);

PhoneNumber.propTypes = {
  phoneNumber: PropTypes.string,
  isPhoneNumberEditable: PropTypes.bool,
  handlePhoneInputEditMode: PropTypes.func,
};

export default PhoneNumber;
