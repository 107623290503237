import findIndex from 'lodash/findIndex';
import findLastIndex from 'lodash/findLastIndex';
import PropTypes from 'prop-types';
import React from 'react';

import {
  FormFieldWithLabel as FormField,
  FormFieldWrapper as FormFieldsWrapper,
  InputCheckbox,
} from '../../../../common/components/FormComponents';
import { Columns, Row } from '../../../../common/components/Grid';

const CustomFields = ({ form, offer, includeShipping, onChangeInput }) => {
  const { customFields } = offer;

  const cfKeys = includeShipping
    ? Object.keys(customFields)
    : Object.keys(customFields).filter((field) => !customFields[field].isShippingField);

  const firstGridIndex = findIndex(
    cfKeys.map((cf) => customFields[cf]),
    { grid: true },
  );

  const lastGridIndex = findLastIndex(
    cfKeys.map((cf) => customFields[cf]),
    { grid: true },
  );

  const initialNonGridItems =
    firstGridIndex >= 0 ? cfKeys.slice(0, firstGridIndex) : cfKeys;

  const gridItems =
    firstGridIndex >= 0 ? cfKeys.slice(firstGridIndex, lastGridIndex + 1) : [];

  const lastNonGridItems =
    firstGridIndex >= 0 ? cfKeys.slice(lastGridIndex + 1, cfKeys.length) : [];

  return (
    <FormFieldsWrapper>
      {initialNonGridItems.map((customField) => (
        <CustomField
          key={customField}
          form={form}
          offer={offer}
          customField={customField}
          onChangeInput={onChangeInput}
        />
      ))}
      {gridItems.length ? (
        <FormField>
          <Row style={{ marginBottom: '-1.5rem' }}>
            {gridItems.map((customField) => (
              <Columns className="small-6" key={customField}>
                <FormFieldsWrapper>
                  <CustomField
                    form={form}
                    offer={offer}
                    customField={customField}
                    onChangeInput={onChangeInput}
                  />
                </FormFieldsWrapper>
              </Columns>
            ))}
          </Row>
        </FormField>
      ) : null}
      {lastNonGridItems.map((customField) => (
        <CustomField
          key={customField}
          form={form}
          offer={offer}
          customField={customField}
          onChangeInput={onChangeInput}
        />
      ))}
    </FormFieldsWrapper>
  );
};

CustomFields.propTypes = {
  form: PropTypes.object,
  offer: PropTypes.object,
  includeShipping: PropTypes.bool,
  onChangeInput: PropTypes.func,
};

export default CustomFields;

const CustomField = ({ form, offer, customField, onChangeInput }) => {
  const { customFields: customFieldForm, readOnly = {} } = form;

  const { customFields } = offer;

  const customFieldProps = customFields[customField];
  const { name, label, type, placeholder } = customFieldProps;
  const value = customFieldForm[name];
  const errors = form.errors[name];

  if (readOnly[name]) {
    return (
      <FormField key={name} label={label}>
        <p>{value}</p>
      </FormField>
    );
  }

  switch (type) {
    case 'text': {
      return (
        <FormField key={name} label={label} errors={errors}>
          <textarea
            value={value}
            rows="2"
            onChange={(e) => onChangeInput({ [name]: e.target.value })}
            style={{ resize: 'vertical' }}
            placeholder={placeholder ? placeholder : ''}
          />
        </FormField>
      );
    }

    case 'choice': {
      // SELECT
      const { choices } = customFieldProps;
      return (
        <FormField key={name} label={label} errors={errors}>
          <select
            value={value}
            onChange={(e) => onChangeInput({ [name]: e.target.value })}
          >
            <option value="">Select an option</option>
            {choices.map((choice) => (
              <option key={choice[0]} value={choice[0]}>
                {choice[1]}
              </option>
            ))}
          </select>
        </FormField>
      );
    }

    case 'bool': {
      // SINGLE CHECKBOX
      return (
        <FormField key={name} errors={errors}>
          <InputCheckbox
            label={label}
            value={value}
            onChange={(e) => onChangeInput({ [name]: e.target.checked })}
          />
        </FormField>
      );
    }

    default: {
      // TEXT FIELD
      return (
        <FormField key={name} label={label} errors={errors}>
          <input
            type={
              type === 'email'
                ? 'email'
                : type === 'int' || type === 'float'
                  ? 'number'
                  : 'text'
            }
            value={value}
            onChange={(e) => onChangeInput({ [name]: e.target.value })}
          />
        </FormField>
      );
    }
  }
};

CustomField.propTypes = {
  form: PropTypes.object,
  offer: PropTypes.object,
  customField: PropTypes.string,
  onChangeInput: PropTypes.func,
};
