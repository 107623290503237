/* globals Sentry */
import root from 'window-or-global';

import { getCookie, getUrlParameterByName } from '../../../common/config/utils';
import { eventMethod, messageEvent } from '../../../common/utils/EventUtils';

export function getEMIAmount(p, r, n) {
  return ((p * r) / (1 - Math.pow(1 / (1 + r), n))).toFixed(2);
}

export function getTotalEMIAmount(emi, months) {
  return (emi * months).toFixed(2);
}

export const getPaymentOptionKey = ({ type, name }) =>
  `${type}_${name.toLowerCase().replace(/\s/g, '')}`;

export const getMatchingPaymentOption = (paymentOptions) => {
  const directPaymentMode = getUrlParameterByName('directPaymentMode');
  if (!directPaymentMode) {
    return null;
  }

  return paymentOptions[
    Object.keys(paymentOptions).filter(
      (currentOption) =>
        removeSpaceAndLowercaseSring(paymentOptions[currentOption]['name']) ===
        removeSpaceAndLowercaseSring(directPaymentMode),
    )[0]
  ];
};

const removeSpaceAndLowercaseSring = (textString) =>
  textString.replace(/\s/g, '').toLowerCase();

export const redirectToPostPurchasePageAfterPaymentAttempt = (
  windowObject,
  orderObject,
) => {
  const checkIsWindowClosed = setInterval(() => {
    if (windowObject?.closed) {
      clearInterval(checkIsWindowClosed);
      const { order, orderToken } = orderObject;
      root.location.href = `/order/status/${order}/?token=${orderToken}`;
    }
  }, 100);

  root[eventMethod](messageEvent, (e) => {
    const {
      origin,
      data,
      data: { name },
    } = e;

    // Accept messages from same origin only.
    if (origin != root.location.origin) {
      return;
    }

    switch (name) {
      case 'redirect': {
        handleRedirection(data);

        if (windowObject && !windowObject.closed) {
          clearInterval(checkIsWindowClosed);
          windowObject.close();
        }
        return;
      }
      default: {
        return;
      }
    }
  });
};

export const handleRedirection = (data) => {
  const { url, isExternalUrl } = data;

  if (url && root.location) {
    // For Setting up loader for online-store and im-embed
    if (root.parent !== root) {
      root.parent.postMessage('changingIframeUrl', '*');
    }

    if (isExternalUrl) {
      try {
        /*
          It can only redirect parent if the current window and parent window have same-origin.
          The same-origin window can set parent.location.href otherwise it throws error.
          This code runs without error for OnlineStore.
        */
        root.parent.location.href = url;
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setTag('error', 'Failed Redirecting after post-purchase');
          scope.setExtra('errorInfo', {
            isWindowEqualToParent: root === root.parent,
            parentUrl: root.parent.location.href,
            windowUrl: root.location.href,
          });
          Sentry.captureException(e);
        });

        /*
          This is for im-embed
          We can't redirect parent with different origin.
          We pass the message to parent with redirect URL.
          Our im-embed code captures this and redirects the Parent.
        */
        root.parent.postMessage(
          {
            name: 'externalRedirect',
            url: url,
          },
          '*',
        );
      }
    } else {
      root.location.href = url;
    }
  }
};

export const isSubmitTargetForcedToSelf = () => {
  if (getUrlParameterByName('submitTarget') === 'self') {
    const d = new Date();
    const minutes = 30;
    d.setTime(d.getTime() + minutes * 60 * 1000);
    document.cookie = `submitTarget=self;path=/;expires=${d.toGMTString()}`;
    return true;
  } else if (getCookie('submitTarget') === 'self') {
    return true;
  }
  return false;
};
