import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class Loading extends Component {
  render() {
    const { className, ...others } = this.props;
    return <div className={`pace-loading ${className}`} {...others} />;
  }
}

Loading.propTypes = {
  className: PropTypes.string,
};
