import '../../styles/InputRadioButton.scss';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/*
This component is the version 2 of the radio (as same the one we have in mojocommerce-dashboard).
As maintaining all the styles in a single component makes more cluttery,
introduced a new verison of file.
*/

class InputRadioButtonV2 extends React.Component {
  render() {
    const { label, labelClassName, radioClassName, ...others } = this.props;
    return (
      <label className={classnames('input-radio-v2', this.props.className)}>
        <div className="custom-radio border-blue">
          <input type="radio" {...others} className={`push-half--right ${radioClassName}`} />
          <span />
          <p style={{ display: 'inline-block', margin: '0' }} className={labelClassName}>{label}</p>
        </div>
      </label>
    );
  }
}

InputRadioButtonV2.propTypes = {
  label: PropTypes.any.isRequired,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  radioClassName: PropTypes.string,
};

InputRadioButtonV2.defaultProps = {
  className: '',
  labelClassName: '',
};

export default InputRadioButtonV2;
