/*globals STATIC_URL*/
import Rupee from '@instamojo/rupee';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { Columns, Row } from '../../../../common/components/Grid';
import Loading from '../../../../common/components/Loading';
import ia from '../../../../common/config/Analytics';
import {
  getFormattedDateWithTime,
  getFullName,
  getUrlParameterByName,
  isSmallDevice,
} from '../../../../common/config/utils';
import CartItemFull from '../../../../dashboard/cart/components/full/CartItemFull';
import { getStoreLink } from '../../../../dashboard/product/utils/ProductUtils';
import {
  handlePostPurchaseDataUpdate,
  retryFailedPayment,
} from '../../actions/PostPurchaseActions';
import DigitalFileProtection from './DigitalFileProtection';
import FailureResponse from './FailureResponse';
import PostPurchaseSignupLink from './PostPurchaseSignupLink';

const Tick = ({ tickImage, className }) => (
  <img className={className} src={tickImage} alt="success" />
);

Tick.propTypes = {
  tickImage: PropTypes.string,
  className: PropTypes.string,
};

const ContinueShoppingButton = ({ seller }) => (
  <div className="text-center">
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`${getStoreLink(seller.username)}`}
      onClick={() => {
        ia.sendEvent('Viewed Online Store', {
          source: 'payment_success_page',
        });
      }}
    >
      <div className="btn push-half--bottom">
        <strong>Continue Shopping</strong>{' '}
      </div>
    </a>
  </div>
);

ContinueShoppingButton.propTypes = {
  seller: PropTypes.object,
};

const PaymentSuccessMessage = () => (
  <>
    <h3 className="text-green font-rc">Payment Successful!</h3>
  </>
);

const Divider = () => <hr style={{ margin: '10px 0' }} />;

const PaymentSummary = ({ payment }) => (
  <table
    className="table-compact table-vertical-center table-colorless text-medium"
    style={{ width: '100%' }}
  >
    <tbody>
      <tr>
        <td width="90px" className="text-bold">
          Payment ID
        </td>
        <td className="float--right">{payment.paymentId}</td>
      </tr>
      {!payment.isFree ? (
        <tr>
          <td className="text-bold">Amount</td>
          <td className="float--right">
            <Rupee value={payment.link.amount} />
          </td>
        </tr>
      ) : null}
      <tr>
        <td className="text-bold">Paid to</td>
        <td
          title={payment.seller.fullname}
          width="210px"
          className="float--right right text-ellipsis"
        >
          {payment.seller.fullname}
        </td>
      </tr>
      <tr>
        <td className="text-bold">Paid on</td>
        <td className="float--right">
          {getFormattedDateWithTime(new Date(payment.date))}
        </td>
      </tr>
    </tbody>
  </table>
);

PaymentSummary.propTypes = {
  payment: PropTypes.object,
};

const ReceiptDetails = ({ payment }) => (
  <p className="break-long-words">
    We&apos;ve sent the receipt to {payment.buyer.email} & {payment.buyer.phone}
  </p>
);

ReceiptDetails.propTypes = {
  payment: PropTypes.object,
};

const CaseDetails = ({ payment }) => (
  <div>
    {payment.case.url ? (
      <p className="soft--bottom">
        For any problems,{' '}
        <a href={payment.case.url} target="_blank" rel="noopener noreferrer">
          create a case
        </a>
        .
      </p>
    ) : (
      <p className="soft--bottom">
        For questions related to payments,{' '}
        <a href="mailto:support@instamojo.com" target="_blank" rel="noopener noreferrer">
          support@instamojo.com
        </a>
        .
      </p>
    )}
  </div>
);

CaseDetails.propTypes = {
  payment: PropTypes.object,
};

const ShareIcon = ({ anchorLink, seller: { username }, shareMode }) => (
  <a
    href={anchorLink}
    target="_blank"
    rel="noopener noreferrer"
    className="push--sides"
    onClick={() => {
      ia.sendEvent('Shared Payment Success', {
        share_mode: shareMode,
        seller_username: username,
      });
    }}
  >
    <img
      alt=""
      src={`${STATIC_URL}assets/images/share/${shareMode}-new.png`}
      style={{ maxWidth: '32px' }}
    />
  </a>
);

ShareIcon.propTypes = {
  anchorLink: PropTypes.string,
  seller: PropTypes.object,
  shareMode: PropTypes.string,
};

const PostTransactionInterstitial = ({ payment }) => {
  const {
    postTransactionInterstitial: {
      showPostTransactionInterstitial,
      publicWebsite: publicWebsiteAddress,
      twitterHandle: twitterHandleName,
    } = {},
    seller: { fullname, username },
    seller,
  } = payment;

  const shareName = getFullName({ name: fullname, username });

  const twitterHandle = twitterHandleName ? `@${twitterHandleName}` : shareName;
  const twitterLink = `https://twitter.com/intent/tweet?text=I%20just%20paid%20${twitterHandle}%20via%20@instamojo%20%23digitalindia`;

  const facebookAppID = '1767574003460647';
  const facebookName = 'I PAID ONLINE %23DIGITALINDIA';
  const facebookDescription = `I just paid ${shareName} via www.instamojo.com. Instamojo made paying online easier than ever. %23digitalindia`;
  const facebookImage =
    'https://www.instamojo.com/blog/wp-content/uploads/2017/04/fb-share.png';
  const facebookCaption = 'Start using Instamojo to bring digital India dream come true.';
  const facebookURL = 'www.instamojo.com/?utm_source=fb-share-post-purchase';
  const facebookLink = `https://www.facebook.com/dialog/feed?app_id=${facebookAppID}&link=${facebookURL}&picture=${facebookImage}&name=${facebookName}&caption=${facebookCaption}&description=${facebookDescription}&redirect_uri=http://www.facebook.com`;

  const publicWebsite = publicWebsiteAddress ? `(${publicWebsiteAddress}) ` : '';
  const whatsappLink = !isSmallDevice()
    ? `https://web.whatsapp.com/send?text=I%20just%20paid%20${shareName}%20${publicWebsite}via%20https%3A%2F%2Fwww.instamojo.com`
    : `whatsapp://send?text=I%20just%20paid%20${shareName}%20${publicWebsite}via%20https%3A%2F%2Fwww.instamojo.com`;
  const emailLink = `mailto:?subject=${encodeURIComponent(
    'I just paid online via Instamojo',
  )}&body=${encodeURIComponent(
    `Instamojo made paying online easier than ever. I just paid ${shareName} ${publicWebsite}via www.instamojo.com`,
  )}`;

  return showPostTransactionInterstitial ? (
    <div>
      <Divider />
      <div>
        <p className="text-center soft--bottom">
          <b>Share your purchase experience with your friends:</b>
        </p>
        <div className="text-center soft--bottom">
          <ShareIcon seller={seller} anchorLink={facebookLink} shareMode="facebook" />
          <ShareIcon seller={seller} anchorLink={twitterLink} shareMode="twitter" />
          <ShareIcon seller={seller} anchorLink={whatsappLink} shareMode="whatsapp" />
          <ShareIcon seller={seller} anchorLink={emailLink} shareMode="email" />
        </div>
      </div>
    </div>
  ) : null;
};

PostTransactionInterstitial.propTypes = {
  payment: PropTypes.object,
};

const SuccessResponse = ({
  responseType,
  payment,
  tickImage,
  handlePostPurchaseDataUpdate: onPostPurchaseDataUpdate,
}) => {
  const {
    redirect,
    upload,
    note: offerNote,
    downloadLink,
    isDownloadProtected,
  } = payment.offer;

  const { linkNote, linkRedirectUrl } = payment.link;

  const { isStoreEnabled } = payment.seller;
  return (
    <div className="font-rc">
      <div className="center">
        {redirect || linkRedirectUrl ? (
          <Loading className="pace-loading huge slow" />
        ) : (
          <Tick tickImage={tickImage} className="soft--ends" />
        )}
        <PaymentSuccessMessage />
        {redirect || linkRedirectUrl ? (
          <p className="text-bold"> Please wait while we redirect you... </p>
        ) : null}
      </div>

      <Divider />
      <PaymentSummary payment={payment} />

      <Divider />

      <div className="soft-half--sides text-medium">
        {!upload ? <ReceiptDetails payment={payment} /> : null}
        {offerNote || linkNote ? (
          <div>
            <label className="text-bold">Note</label>
            <div className="yellow-message-block push-half--bottom">
              {/*
                  Added the following css properties to avoid the horizontal scroll for the thank you note for the product and payment link.
                */}
              <pre
                className="break-long-words"
                style={{
                  fontSize: '14px',
                  fontFamily: '"Roboto Condensed", sans-serif',
                  whiteSpace: 'break-spaces',
                  lineHeight: '1.6',
                  overflow: 'hidden',
                }}
              >
                {offerNote || linkNote}{' '}
              </pre>
            </div>
          </div>
        ) : null}
        {upload ? (
          <a
            href={!isDownloadProtected ? downloadLink : '#'}
            onClick={(e) => {
              if (isDownloadProtected) {
                e.preventDefault();
                onPostPurchaseDataUpdate('isVerifyingBuyerForFileDownload', true);
              }
            }}
            className="btn btn-green btn-block push--ends"
          >
            Download
          </a>
        ) : null}
        <CaseDetails payment={payment} />
        {responseType === 'product' && isStoreEnabled ? (
          <ContinueShoppingButton seller={payment.seller} />
        ) : null}
        <PostTransactionInterstitial payment={payment} />
        <Divider />
        <Row>
          <div className="center push--bottom">
            <p className="strong">Do you own a business ?</p>
            <div className="push--bottom push-half--top">
              <p className="soft-half--bottom">
                Start collecting payments with Instamojo today!
              </p>
              <PostPurchaseSignupLink />
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
};

SuccessResponse.propTypes = {
  payment: PropTypes.object,
  tickImage: PropTypes.string,
  handlePostPurchaseDataUpdate: PropTypes.func,
  responseType: PropTypes.string.isRequired,
};

const CartSuccessResponse = ({ payment, tickImage }) => (
  <div className="font-rc">
    <Row className="soft-half--top">
      <Columns className="small-12 medium-4">
        <div className="media soft--ends">
          <Tick className="media__img" tickImage={tickImage} />
          <div className="media__body">
            <h5 className="text-green font-rc push-half--top">Payment Successful</h5>
          </div>
        </div>
      </Columns>
      <Columns className="small-12 medium-8">
        <PaymentSummary payment={payment} />
      </Columns>
    </Row>

    <Divider />

    <ul className="cart--items full push-double--bottom">
      {payment.subOrders.map((order, index) => (
        <li key={index}>
          <CartItemFull readOnly={true} {...order} />
        </li>
      ))}
    </ul>

    <div className="soft--left">
      <ReceiptDetails payment={payment} />
      <CaseDetails payment={payment} />
    </div>
    <Divider />
    <div className="push-double--top push--bottom">
      {payment.seller.isStoreEnabled ? (
        <ContinueShoppingButton seller={payment.seller} />
      ) : null}
    </div>
    <Divider />
    <Row>
      <div className="push--sides push--bottom">
        <p className="strong">Do you own a business ?</p>
        <div className="push--bottom push-half--top">
          <p>
            Start collecting payments with Instamojo today!
            <PostPurchaseSignupLink />
          </p>
        </div>
      </div>
    </Row>
  </div>
);

CartSuccessResponse.propTypes = {
  payment: PropTypes.object,
  tickImage: PropTypes.string,
};

const CartFailureResponse = ({ payment }) => (
  <Row>
    <Columns className="small-12">
      <FailureResponse payment={payment} />
    </Columns>
  </Row>
);

CartFailureResponse.propTypes = {
  payment: PropTypes.object,
};

class PostPurchaseResponse extends React.Component {
  componentDidMount() {
    const {
      payment: {
        offer: { upload },
      },
      shouldShowPaymentOptionsDirectlyOnRetry,
    } = this.props;

    if (upload && getUrlParameterByName('expired') === 'true') {
      this.props.handlePostPurchaseDataUpdate('isVerifyingBuyerForFileDownload', true);
    }
    if (shouldShowPaymentOptionsDirectlyOnRetry) {
      this.props.retryFailedPayment();
    }
  }

  render() {
    const {
      isCartOrder,
      status,
      payment,
      tickImage,
      isVerifyingBuyerForFileDownload,
      responseType,
    } = this.props;
    return (
      <div
        className="soft-half"
        style={{
          background: '#fff',
          borderRadius: '0 0 4px 4px',
          borderWidth: '1px 1px 1px 1px',
          borderStyle: 'solid',
          borderColor: '#D1DBEB',
        }}
      >
        {!isVerifyingBuyerForFileDownload ? (
          <div>
            {status === 'success' ? (
              isCartOrder ? (
                <CartSuccessResponse
                  payment={payment}
                  tickImage={tickImage}
                />
              ) : (
                <SuccessResponse
                  payment={payment}
                  tickImage={tickImage}
                  responseType={responseType}
                  handlePostPurchaseDataUpdate={(formField, formFieldData) =>
                    this.props.handlePostPurchaseDataUpdate(formField, formFieldData)
                  }
                />
              )
            ) : isCartOrder ? (
              <CartFailureResponse payment={payment} />
            ) : (
              <FailureResponse payment={payment} />
            )}
          </div>
        ) : (
          <DigitalFileProtection />
        )}
      </div>
    );
  }
}

PostPurchaseResponse.propTypes = {
  status: PropTypes.string.isRequired,
  tickImage: PropTypes.string.isRequired,
  isCartOrder: PropTypes.bool.isRequired,
  responseType: PropTypes.string.isRequired,
  payment: PropTypes.shape({
    link: PropTypes.shape({
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired,
    offer: PropTypes.object,
    paymentId: PropTypes.string.isRequired,
    orderId: PropTypes.string.isRequired,
    seller: PropTypes.shape({
      fullname: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      isStoreEnabled: PropTypes.bool.isRequired,
    }).isRequired,
    buyer: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }),
    subOrders: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired,
        amount: PropTypes.number.isRequired,
        variants: PropTypes.arrayOf(
          PropTypes.shape({
            categoryName: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
          }),
        ).isRequired,
        customFields: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
          }),
        ).isRequired,
        shipsIn: PropTypes.number,
      }),
    ),
  }),
  shouldShowPaymentOptionsDirectlyOnRetry: PropTypes.bool,
  isVerifyingBuyerForFileDownload: PropTypes.bool,
  handlePostPurchaseDataUpdate: PropTypes.func,
  retryFailedPayment: PropTypes.func,
};

export default connect(() => ({}), {
  handlePostPurchaseDataUpdate,
  retryFailedPayment,
})(PostPurchaseResponse);
