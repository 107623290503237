/**
 * WHITELISTING APPROACH(DEFAULT)
 *  - This is when a permission is considered to be `DENY`(if not present),
 *  unless explicitly specified as `ALLOW`.
 *
 * BLACKLISTING APPROACH
 *  - This is when a permission is considered to be `ALLOW`(if not present),
 *  unless explicitly specified as `DENY`.
 */

let permissionsMap = new Map();

export const setPermissionsMap = (permissions, resetPermissions = false) => {
  if (resetPermissions) {
    permissionsMap = new Map();
  }
  permissions.forEach((permission) => {
    const permissionSplit = permission.split('.');
    permissionsMap.set(
      permissionSplit.slice(0, permissionSplit.length - 1).join('.'),
      permissionSplit.slice(-1).join(''),
    );
  });
};

export const isPermissionFound = (permissionName) => {
  return permissionsMap.has(permissionName);
};

export const isPermissionAllowed = (permissionName, useBlacklistingApproach = false) => {
  /**
   * Checks if a permission is allowed.
   * By default uses a whitelisting approach.
   * @param {String} permissionName - Name of the permission.
   * @param {Boolean} useBlacklistingApproach - When true it switches to using a blacklisting approach.
   * @returns {Boolean} - true if allowed, false otherwise.
   */
  if (permissionsMap.size === 0) {
    // Is Allowed if permissions does not exist (Ex. MDP)
    return true;
  }

  if (useBlacklistingApproach) {
    return (
      !isPermissionFound(permissionName) || permissionsMap.get(permissionName) === 'ALLOW'
    );
  }

  return permissionsMap.get(permissionName) === 'ALLOW';
};

export const isPermissionDeferred = (permissionName) => {
  /**
   * Checks if a permission is deferred.
   * @param {String} permissionName - Name of the permission.
   * @returns {Boolean} - true if deferred, false otherwise.
   */
  if (permissionsMap.size === 0) {
    // Not Deferred if permissions does not exist (Ex. MDP)
    return false;
  }

  return permissionsMap.get(permissionName) === 'DEFERRED';
};

// Permission is Denied when it is DENY or not found.
export const isPermissionDenied = (permissionName, useBlacklistingApproach = false) => {
  /**
   * Checks if a permission is denied.
   * By default uses a whitelisting approach.
   * @param {String} permissionName - Name of the permission.
   * @param {Boolean} useBlacklistingApproach - When true it switches to using a blacklisting approach.
   * @returns {Boolean} - true if denied, false otherwise.
   */
  if (permissionsMap.size === 0) {
    // Not Denied if permissions does not exist (Ex. MDP)
    return false;
  }

  if (useBlacklistingApproach) {
    return permissionsMap.get(permissionName) === 'DENY';
  }

  return (
    !isPermissionFound(permissionName) || permissionsMap.get(permissionName) === 'DENY'
  );
};

// Aliasing
export const isResourceActionAllowed = isPermissionAllowed;
