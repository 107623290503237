import root from 'window-or-global';

import { CALL_API } from '../../../common/middlewares/api';

export const HANDLE_TOGGLE_OPEN_STATE = 'HANDLE_TOGGLE_OPEN_STATE';
export const handleToggleOpenState = (tab, isOpen) => ({
  type: HANDLE_TOGGLE_OPEN_STATE,
  payload: {
    tab,
    isOpen,
  },
});

export const HANDLE_TOGGLE_PAYMENT_POPUP = 'HANDLE_TOGGLE_PAYMENT_POPUP';
export const HANDLE_TOGGLE_INITIALIZE_FORM_ANIMATED =
  'HANDLE_TOGGLE_INITIALIZE_FORM_ANIMATED';
export const handleTogglePaymentPopup = () => (dispatch, getState) => {
  let dispatchList = [
    dispatch({
      type: HANDLE_TOGGLE_PAYMENT_POPUP,
    }),
  ];

  if (!getState().settings.isMobileDevice) {
    /*
     * touch devices do not have the modal animation
     * so don't dispatch HANDLE_TOGGLE_INITIALIZE_FORM_ANIMATED
     * when isMobileDevice is true
     */

    dispatchList = [
      ...dispatchList,
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(
            dispatch({
              type: HANDLE_TOGGLE_INITIALIZE_FORM_ANIMATED,
            }),
          );
        }, 400 /* approx time the toggle payment popup animation will last */);
      }),
    ];
  }

  return Promise.all(dispatchList);
};

export const setOfferPagePaymentButtonClickListener = () => (dispatch) => {
  root.isPaymentsPopupReady = true;
  return new Promise((resolve) => {
    const intr = setInterval(() => {
      if (root.isPaymentButtonClicked) {
        clearInterval(intr);
        resolve(dispatch(handleTogglePaymentPopup()));
      }
    }, 100);
  });
};

export const SHOW_DEFERRED_MERCHANT_HEADER = 'SHOW_DEFERRED_MERCHANT_HEADER';
export const showDeferredMerchantHeader = () => ({
  type: SHOW_DEFERRED_MERCHANT_HEADER,
});

export const HANDLE_AWAITING_PAYMENT_COMPLETION = 'HANDLE_AWAITING_PAYMENT_COMPLETION';
export const handleAwaitingPaymentCompletion = (waitingStatus = true) => ({
  type: HANDLE_AWAITING_PAYMENT_COMPLETION,
  waitingStatus,
});


export const FETCH_REPORT_ABUSE_INFO_REQUEST = 'FETCH_REPORT_ABUSE_INFO_REQUEST';
export const FETCH_REPORT_ABUSE_INFO_SUCCESS = 'FETCH_REPORT_ABUSE_INFO_SUCCESS';
export const FETCH_REPORT_ABUSE_INFO_FAILURE = 'FETCH_REPORT_ABUSE_INFO_FAILURE';
export const fetchReportAbuseInfo = (seller, isCustomDomain, baseURL) => ({
  [CALL_API]: {
    endpoint: isCustomDomain ? `${baseURL}/webapi/report-abuse/${seller}/status/` : `/webapi/report-abuse/${seller}/status/`,
    types: [
      FETCH_REPORT_ABUSE_INFO_REQUEST,
      FETCH_REPORT_ABUSE_INFO_SUCCESS,
      FETCH_REPORT_ABUSE_INFO_FAILURE,
    ],
  },
});


export const FETCH_TOS_POLICIES_REQUEST = 'FETCH_TOS_POLICIES_REQUEST';
export const FETCH_TOS_POLICIES_SUCCESS = 'FETCH_TOS_POLICIES_SUCCESS';
export const FETCH_TOS_POLICIES_FAILURE = 'FETCH_TOS_POLICIES_FAILURE';
export const fetchTosPoliciesInfo = (seller, isCustomDomain, baseURL) => ({
  [CALL_API]: {
    endpoint: isCustomDomain ? `${baseURL}/webapi/tos/buyer/${seller}/` : `/webapi/tos/buyer/${seller}/`,
    types: [
      FETCH_TOS_POLICIES_REQUEST,
      FETCH_TOS_POLICIES_SUCCESS,
      FETCH_TOS_POLICIES_FAILURE,
    ],
  },
});
