import '../../styles/FormFieldInput2.scss';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { FormFieldWithLabel, FormFieldWrapper } from '../FormComponents';

export default class FormFieldInput2 extends React.Component {
  labelWithRemainingCharacters(label, maxCharacters, value) {
    const textLength = value.length;
    const textHasWarning =
      textLength > maxCharacters - 10 && textLength < maxCharacters + 1;
    const textHasError = textLength > maxCharacters;
    return (
      <div>
        <span>{label}</span>
        <span
          className={classnames('float--right', {
            'text-red': textHasError,
            'text-orange': textHasWarning,
          })}
        >
          {`${maxCharacters - textLength} characters left`}
        </span>
      </div>
    );
  }

  getHelperText = (helperText) => {
    return (
      <div className="helper-text">
        {helperText === null ? null : <span>{helperText}</span>}
      </div>
    );
  };

  // Handle all keyboard clicks here
  onKeyDown = (e) => {
    // Disallow use of up/down to change values
    if (e.keyCode === 38 || e.keyCode === 40) return e.preventDefault();

    // Disallow negative numbers
    if (this.props.type === 'number' && !this.props.allowNegatives && e.keyCode === 189)
      return e.preventDefault();
  };

  render() {
    const {
      placeholder,
      label,
      helperText,
      value,
      readOnly,
      type,
      autoComplete,
      disabled,
      onChange,
      errors,
      prefix,
      maxCharacters,
      className,
      inputClassName,
      postfix,
      autoFocus,
      rightLabel,
      style,
    } = this.props;

    const textLength = value ? value.length : 0;
    const textExceedsLength = maxCharacters ? textLength > maxCharacters : false;

    const basicInputHTML = (
      <input
        placeholder={placeholder}
        type={type}
        value={value}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        disabled={disabled}
        readOnly={readOnly}
        onChange={onChange}
        className={`${inputClassName} ${textExceedsLength ? 'text-red' : ''}`}
        onKeyDown={(e) => this.onKeyDown(e)}
        onWheel={(e) => {
          e.target.blur();
        }}
        style={style ? style : {}}
      />
    );

    const prefixInputHTML = (
      <div className="collapse prefixed-input">
        <span className="prefix">{prefix}</span>
        {basicInputHTML}
      </div>
    );

    const postfixInputHTML = (
      <div className="collapse postfixed-input">
        {basicInputHTML}
        <span
          className={classnames('postfix postfix-width', { 'postfix-error': errors })}
        >
          {postfix}
        </span>
      </div>
    );

    return (
      <FormFieldWrapper>
        <FormFieldWithLabel
          rightLabel={rightLabel}
          className={className}
          labelClassName="push-half--bottom"
          label={
            !maxCharacters
              ? label
              : this.labelWithRemainingCharacters(label, maxCharacters, value)
          }
          errors={errors}
        >
          {this.getHelperText(helperText)}
          {prefix ? prefixInputHTML : postfix ? postfixInputHTML : basicInputHTML}
        </FormFieldWithLabel>
      </FormFieldWrapper>
    );
  }
}

// Force for at least one out of 'placeholder' & 'value'
// address-level1 --> State
// address-level2 --> City
// tel-national --> Telephone number without country code
// https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofilling-form-controls:-the-autocomplete-attribute
FormFieldInput2.propTypes = {
  // placeholder: (props, propName, componentName) => {
  //   if (!props.placeholder && !props.value) {
  //     return new Error('One of props placeholder or value was not specified in ' + componentName);
  //   }
  // },

  placeholder: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  readOnly: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'number', 'tel', 'search', 'email']).isRequired,
  autoComplete: PropTypes.oneOf([
    'first-name',
    'last-name',
    'name',
    'contact-number',
    'street-address',
    'address-level1',
    'address-level2',
    'postal-code',
    'country-name',
    'tel-national',
    'email',
  ]),
  disabled: PropTypes.bool,
  rightLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  errors: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  postfix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  maxCharacters: PropTypes.number,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  allowNegatives: PropTypes.bool,
  style: PropTypes.object,
};

FormFieldInput2.defaultProps = {
  helperText: null,
  readonly: false,
  disabled: false,
  allowNegatives: false,
  rightLabel: null,
  autoFocus: false,
};
