import Rupee from '@instamojo/rupee';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  Form,
  FormFieldWithLabel as FormField,
  FormFieldWrapper as FormFieldsWrapper,
  InputNumber,
} from '../../../../common/components/FormComponents';
import { Columns, Row } from '../../../../common/components/Grid';
import StatefulSubmitButton from '../../../../common/components/StatefulSubmitButton';
import {
  handleChangeInstapayInput,
  handleSubmitInstapay,
} from '../../actions/InstapayActions';
import TOSModal from '../initialize/TOSModal';

class InstapayForm extends React.Component {
  componentDidMount() {
    const { shouldJumpToNextStep } = this.props.instapay;
    if (shouldJumpToNextStep) {
      this.props.handleSubmitInstapay();
      this.props.handleChangeInstapayInput({ shouldJumpToNextStep: false });
    }
  }

  render() {
    const { errors, purpose, amount } = this.props.instapay;
    return (
      <div className="payments-form soft--top">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            this.props.handleSubmitInstapay();
          }}
          style={{ paddingBottom: '0px' }}
        >
          <FormFieldsWrapper>
            <FormField label="Purpose of Payment" errors={errors.purpose}>
              <input
                type="text"
                autoFocus
                value={purpose}
                onChange={(e) =>
                  this.props.handleChangeInstapayInput({ purpose: e.target.value })
                }
              />
            </FormField>
            <FormField label="Amount" errors={errors.amount}>
              <Row className="collapse prefix-radius">
                <Columns className="small-1">
                  <span className="prefix">
                    <Rupee />
                  </span>
                </Columns>
                <Columns className="small-11">
                  <InputNumber
                    value={amount}
                    onChange={(e) =>
                      this.props.handleChangeInstapayInput({ amount: e.target.value })
                    }
                  />
                </Columns>
              </Row>
              <TOSModal />
            </FormField>
            <FormField>
              <StatefulSubmitButton value="Pay" />
            </FormField>
          </FormFieldsWrapper>
        </Form>
      </div>
    );
  }
}

InstapayForm.propTypes = {
  instapay: PropTypes.object,
  handleChangeInstapayInput: PropTypes.func,
  handleSubmitInstapay: PropTypes.func,
};

const mapStateToProps = ({ instapayForm, settings, retry }) => ({
  instapay: settings.isRetrying ? retry.instapayForm : instapayForm,
});

export default connect(mapStateToProps, {
  handleChangeInstapayInput,
  handleSubmitInstapay,
})(InstapayForm);
