import * as fromPostPurchaseReducers from './PostPurchaseReducers';
const getResponseState = (state) => ({
  settings: state.settings,
  postPurchase: fromPostPurchaseReducers.getResponseState(state.postPurchase),
});

const getLinksState = (state) => {
  const {
    settings,
    pageState,
    finalizePurchase: {
      totalAmountFormatted,
      selectedPaymentMode,
      convenienceFeeVersion,
      errors: finalizePurchaseFormErrors,
      neftDetails,
      selectedNeftBankUrl,
    },
    offerDetails: { title },
    refinePurchase: { form: { errors: refinePurchaseFormErrors } = {} } = {},
    initializePurchase: { form: { errors: initializePurchaseFormErrors } = {} } = {},
  } = state;

  return {
    settings,
    totalAmountFormatted,
    pageState,
    title,
    initializePurchaseFormErrors,
    refinePurchaseFormErrors,
    finalizePurchaseFormErrors,
    selectedPaymentMode,
    neftDetails,
    selectedNeftBankUrl,
    convenienceFeeVersion,
  };
};

const getProductsState = (state) => {
  const {
    settings,
    pageState,
    finalizePurchase: {
      errors: finalizePurchaseFormErrors,
      totalAmountFormatted,
      selectedPaymentMode,
      selectedPaymentObject,
      neftDetails,
      selectedNeftBankUrl,
      convenienceFeeVersion,
    },
    offerDetails: { title, preview },
    initializePurchase: { form: { errors: initializePurchaseFormErrors } = {} } = {},
    refinePurchase: { form: { errors: refinePurchaseFormErrors } = {} } = {},
  } = state;

  return {
    settings,
    totalAmountFormatted,
    pageState,
    title,
    preview,
    initializePurchaseFormErrors,
    refinePurchaseFormErrors,
    finalizePurchaseFormErrors,
    selectedPaymentMode,
    selectedPaymentObject,
    neftDetails,
    selectedNeftBankUrl,
    convenienceFeeVersion,
  };
};

const getInstapayState = (state) => {
  const {
    settings,
    pageState,
    offerDetails: { title },
    finalizePurchase: {
      totalAmountFormatted,
      errors: finalizePurchaseFormErrors,
      selectedPaymentMode,
      neftDetails,
      orderAmount,
      selectedNeftBankUrl,
      convenienceFeeVersion,
    },
  } = state;

  return {
    settings,
    title,
    totalAmountFormatted,
    pageState,
    finalizePurchaseFormErrors,
    selectedPaymentMode,
    neftDetails,
    orderAmount,
    selectedNeftBankUrl,
    convenienceFeeVersion,
  };
};

export const getPaymentsState = (state) => {
  const { settings: { isRetrying } = {} } = state;
  state = isRetrying ? state.retry : state;
  if (!state.settings) {
    throw new Error('Cannot initialize payments without Settings Reducer');
  }
  if (state.settings.isPaymentResponse) {
    return getResponseState(state);
  } else if (state.settings.isLinkPayment) {
    return getLinksState(state);
  } else if (state.settings.isInstapayPayment) {
    return getInstapayState(state);
  }
  return getProductsState(state);
};
