import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import ia from '../../../common/config/Analytics';
import {
  handleDeleteSavedCard,
  handleDynamicQrV2,
  handleFetchConvenienceFeesForQR,
  handleManageSavedCards,
  handlePaymentGroupCardClick,
  handlePaypalSDKError,
  handlePaypalSuccessfulCallback,
  handleResetSelectedPaymentMode,
  handleSelectPaymentOption,
  pollForUPIAcceptance,
} from '../actions/FinalizePurchaseActions';
import scrollController from '../decorators/ScrollController';
import { getMatchingPaymentOption } from '../utils/FinalizePurchaseUtils';
import FinalizeFooter from './finalize/FinalizeFooter';
import FinalizeHeader from './finalize/FinalizeHeader';
import PaymentOptionsWrapper from './finalize/PaymentOptionsWrapper';

class FinalizePurchase extends React.Component {
  componentDidMount() {
    const matchingPaymentOption = getMatchingPaymentOption(
      this.props.finalizePurchase.paymentOptions,
    );
    const { paymentOptions, order, paymentGroups, retriedFromEmail } = this.props.finalizePurchase;
    if (this.props.isFinalizeFormOpen && matchingPaymentOption) {
      this.props.handleSelectPaymentOption(matchingPaymentOption);
    }
    ia.sendEvent('Viewed Payment Options', {
      mode_list: Object.keys(paymentOptions).map((option) => paymentOptions[option].name),
      seller_username: this.props.username,
      order_id: order,
      view: paymentGroups ? 'QR View' : 'List View',
      payment_option: paymentGroups ? 'UPI QR' : null, // mixpanel prop to track if a particular payment option was expanded
    });
    if (this.props.isRetrying && retriedFromEmail) {
      ia.sendEvent('Retried Payment', {
        source: 'reminder_email',
        seller_username: this.props.username,
        order_id: order,
      });
    }
  }

  render() {
    return (
      <div>
        <FinalizeHeader
          finalizePurchase={this.props.finalizePurchase}
          handleSelectPaymentOption={(selectedPaymentDetails) =>
            this.props.handleSelectPaymentOption(selectedPaymentDetails)
          }
        />
        <PaymentOptionsWrapper
          username={this.props.username}
          showConvenienceFee={this.props.showConvenienceFee}
          finalizePurchase={this.props.finalizePurchase}
          handlePaymentGroupCardClick={(index) =>
            this.props.handlePaymentGroupCardClick(index)
          }
          handleManageSavedCards={(payload) => this.props.handleManageSavedCards(payload)}
          handleDeleteSavedCard={(cardToBeDeleted) =>
            this.props.handleDeleteSavedCard(cardToBeDeleted)
          }
          handleFetchConvenienceFeesForQR={() =>
            this.props.handleFetchConvenienceFeesForQR()
          }
          pollForUPIAcceptance={(statusCheckUrl) =>
            this.props.pollForUPIAcceptance(statusCheckUrl)
          }
          handleSelectPaymentOption={(selectedPaymentDetails) =>
            this.props.handleSelectPaymentOption(selectedPaymentDetails)
          }
          handleResetSelectedPaymentMode={() =>
            this.props.handleResetSelectedPaymentMode()
          }
          handlePaypalSuccessfulCallback={() =>
            this.props.handlePaypalSuccessfulCallback()
          }
          handlePaypalSDKError={() => this.props.handlePaypalSDKError()}
          handleDynamicQrV2={(submissionUrl, deviceType) => this.props.handleDynamicQrV2(submissionUrl, deviceType)}
        />
        <FinalizeFooter finalizePurchase={this.props.finalizePurchase} />
      </div>
    );
  }
}

FinalizePurchase.propTypes = {
  finalizePurchase: PropTypes.object,
  isFinalizeFormOpen: PropTypes.bool,
  username: PropTypes.string,
  showConvenienceFee: PropTypes.bool,
  isRetrying: PropTypes.bool,
  handleResetSelectedPaymentMode: PropTypes.func,
  handleSelectPaymentOption: PropTypes.func,
  handleManageSavedCards: PropTypes.func,
  handleDeleteSavedCard: PropTypes.func,
  handlePaymentGroupCardClick: PropTypes.func,
  pollForUPIAcceptance: PropTypes.func,
  handleFetchConvenienceFeesForQR: PropTypes.func,
  handlePaypalSuccessfulCallback: PropTypes.func,
  handlePaypalSDKError: PropTypes.func,
  handleDynamicQrV2: PropTypes.func,
};

function mapStateToProps(state) {
  if (state.cart) {
    const { finalizePurchase, settings } = state.cart;
    return {
      finalizePurchase,
      isMobileDevice: finalizePurchase.isMobileDevice,
      showConvenienceFee: settings.showConvenienceFee,
    };
  }

  const {
    finalizePurchase,
    settings: {
      isMobileDevice,
      isRetrying,
      showConvenienceFee,
      seller: { username },
      isFinalizeFormOpen,
    },
    retry: { finalizePurchase: retryFinalizePurchase } = {},
  } = state;
  return {
    finalizePurchase: isRetrying ? retryFinalizePurchase : finalizePurchase,
    isMobileDevice,
    username,
    isFinalizeFormOpen,
    showConvenienceFee,
    isRetrying,
  };
}

export default connect(mapStateToProps, {
  handleResetSelectedPaymentMode,
  handleSelectPaymentOption,
  handlePaymentGroupCardClick,
  handleManageSavedCards,
  handleDeleteSavedCard,
  handleFetchConvenienceFeesForQR,
  pollForUPIAcceptance,
  handlePaypalSuccessfulCallback,
  handlePaypalSDKError,
  handleDynamicQrV2,
})(scrollController(FinalizePurchase));
