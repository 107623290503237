import PropTypes from 'prop-types';
import React, { Component } from 'react';
export default class FormField extends Component {
  constructor() {
    super();
  }

  render() {
    return <li className={this.props.className}>{this.props.children}</li>;
  }
}

FormField.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
