import 'react-quill/dist/quill.snow.css';

import { Box, Button, chakra, Link, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';

import { ModalContent2, MojoPopup2 } from '../../../../common/components/MojoPopup2';
import ia from '../../../../common/config/Analytics';
import { getIsMobileDevice } from '../../../../common/utils/ModernizrUtils';

const TOSModal = ({ tosPolicies, isFetched }) => {
  const [showModal, setShow] = useState(false);
  const [isTos, setIsTos] = useState(true);
  const handleClose = () => setShow(false);
  const mandatoryPolicy = ['privacy', 'terms-and-conditions', 'cancel-and-refund'];
  const policyToShow = isTos
    ? ['privacy', 'terms-and-conditions', 'shipping-and-delivery']
    : ['cancel-and-refund'];
  const handleClick = (isTosSelected) => {
    setShow(true);
    setIsTos(isTosSelected);
    ia.sendEvent('Viewed Policy', {
      policy_name: isTosSelected ? 'Terms Of Service Policy' : 'Refund Policy',
      offer_creation_type: 'Payment',
      is_mojocommerce_order: false,
    });
  };
  if (!isFetched) {
    return null;
  }

  return (
    <>
      {showModal ? (
        <MojoPopup2
          isModalOpen={showModal}
          onCloseModal={handleClose}
          modalWidth="320px"
          shouldShowCloseIcon={false}
          contentStyle={{
            height: 'calc(80vh - 60px)',
            position: 'relative',
            overflowX: 'hidden',
          }}
        >
          <ModalContent2>
            <Text color="#4D4D6E" pb={isTos ? '16px' : '6px'} fontSize="18px">
              {isTos ? 'Terms of Service' : 'Cancellation and Refund Policy'}
            </Text>
            {Object.values(tosPolicies).map((item, index) => {
              return (
                <chakra.div key={index}>
                  {(item.status || mandatoryPolicy.includes(item.type)) &&
                  policyToShow.includes(item.type) ? (
                    <chakra.div fontSize="14px">
                      {isTos ? <chakra.b color="#696982">{item.name}</chakra.b> : null}
                      <chakra.div
                        sx={{
                          '.ql-tooltip': {
                            display: 'none',
                          },
                          '.ql-editor': {
                            padding: '0px',
                          },
                          '.ql-editor ol li': {
                            paddingTop: '.5em',
                          },
                          '.ql-editor li.ql-indent-1:not(.ql-direction-rtl)': {
                            paddingLeft: '2em',
                          },
                          '.ql-editor li.ql-indent-2:not(.ql-direction-rtl)': {
                            paddingLeft: '3em',
                          },
                          '.ql-editor li.ql-indent-3:not(.ql-direction-rtl)': {
                            paddingLeft: '4.5em',
                          },
                          ol: {
                            paddingLeft: '0',
                          },
                          ul: {
                            paddingLeft: '0',
                          },
                        }}
                        color="#696982"
                        py="10px"
                        fontSize="14px"
                      >
                        <ReactQuill
                          value={
                            item.content.replace('<p><br></p>', '') +
                            item.additionalContent
                          }
                          readOnly
                          theme="bubble"
                        />
                      </chakra.div>
                    </chakra.div>
                  ) : null}
                </chakra.div>
              );
            })}
          </ModalContent2>

          <Button
            variant="solid"
            bgColor="#4E4E91"
            color="white"
            onClick={handleClose}
            pos="fixed"
            size="md"
            zIndex="1000"
            bottom={getIsMobileDevice() ? 'calc(10vh)' : 'calc(20vh - 23px)'}
            width="320px"
            padding="10px"
          >
            Close
          </Button>
        </MojoPopup2>
      ) : (
        <Box textAlign="center" fontSize="10px" paddingTop="6px">
          <Link
            onClick={() => {
              handleClick(true);
            }}
            color="grey"
            textDecoration="underline"
          >
            Terms of service
          </Link>
          <chakra.span color="grey"> and </chakra.span>
          <Link
            onClick={() => {
              handleClick(false);
            }}
            color="grey"
            textDecoration="underline"
          >
            Refund policy
          </Link>
        </Box>
      )}
    </>
  );
};

TOSModal.propTypes = {
  tosPolicies: PropTypes.object,
  isFetched: PropTypes.bool,
};
const mapStateToProps = ({ settings }) => ({
  tosPolicies: settings?.tosPolicies,
  isFetched: settings?.isFetched,
});

export default connect(mapStateToProps)(TOSModal);
