import PropTypes from 'prop-types';
import React from 'react';

import { Columns, Row } from '../../../../common/components/Grid';
import { cardTypeImageMap, classifyCard } from '../../utils/SavedCardUtils';
import Icon from './../../../../common/components/Icon';

const CardItem = ({ firstSix, lastFour, showCardDeletionOption }) => (
  <div>
    <span style={{ lineHeight: '24px' }}>
      {`${firstSix.slice(0, 4)} **** **** ${lastFour}`}
    </span>
    <span className="float--right">
      {showCardDeletionOption ? (
        <Icon className="ui-1_trash text-red" style={{ lineHeight: '24px' }} />
      ) : (
        <img
          alt=""
          src={cardTypeImageMap[classifyCard(firstSix)]}
          style={{ height: '24px' }}
        />
      )}
    </span>
  </div>
);

CardItem.propTypes = {
  firstSix: PropTypes.string,
  lastFour: PropTypes.string,
  showCardDeletionOption: PropTypes.bool,
};

const SavedCards = ({
  savedCards,
  finalizePurchase: {
    selectedSavedCard,
    showCardDeletionOption,
    showConfirmCardDeletionOption,
    order: orderId,
  },
  handleSelectPaymentOption,
  handleManageSavedCards,
  handleDeleteSavedCard,
}) => {
  if (
    !savedCards ||
    (savedCards && savedCards.submissionUris && !savedCards.submissionUris.length)
  ) {
    return null;
  } else {
    const { name, type, submissionUris } = savedCards;
    return (
      <div>
        <div className="text-light-grey push--ends">
          {`${showCardDeletionOption ? 'Manage ' : ''}Saved Cards`}
          <span className="float--right text-light-blue pointer">
            {showCardDeletionOption ? (
              <span
                onClick={() => {
                  showConfirmCardDeletionOption
                    ? handleManageSavedCards({
                      mode: 2,
                      value: false,
                      selectedSavedCard: '',
                    })
                    : handleManageSavedCards({ mode: 1, value: false });
                }}
              >
                Back
              </span>
            ) : (
              <span onClick={() => handleManageSavedCards({ mode: 1, value: true })}>
                Manage Cards
              </span>
            )}
          </span>
        </div>
        {!showConfirmCardDeletionOption ? (
          <div>
            {submissionUris.map((card, index) => {
              const { firstSix, lastFour } = card;
              return (
                <a
                  key={index}
                  className="btn btn-block push--bottom text--left"
                  onClick={() => {
                    showCardDeletionOption
                      ? handleManageSavedCards({
                        mode: 2,
                        value: true,
                        selectedSavedCard: card,
                      })
                      : handleSelectPaymentOption({
                        name,
                        type,
                        selectedSavedCard: card,
                      });
                  }}
                >
                  <CardItem
                    firstSix={firstSix}
                    lastFour={lastFour}
                    showCardDeletionOption={showCardDeletionOption}
                  />
                </a>
              );
            })}
          </div>
        ) : (
          <div>
            <div className="text-red text-medium soft--bottom">
              Are you sure you want to delete this card?
            </div>
            <div className="card" style={{ paddingBottom: '16px' }}>
              <CardItem
                firstSix={selectedSavedCard.firstSix}
                lastFour={selectedSavedCard.lastFour}
              />
              <div className="push--top text-medium">
                Expires{' '}
                {`${selectedSavedCard.expiryMonth}/${selectedSavedCard.expiryYear}`}
              </div>
            </div>
            <Row className="soft--bottom">
              <Columns className="small-6 soft-half--right">
                <button
                  className="btn-red btn-block"
                  onClick={() => handleDeleteSavedCard({ orderId, ...selectedSavedCard })}
                >
                  Delete
                </button>
              </Columns>
              <Columns className="small-6 soft-half--left">
                <button
                  className="btn btn-block"
                  onClick={() =>
                    handleManageSavedCards({
                      mode: 2,
                      value: false,
                      selectedSavedCard: '',
                    })
                  }
                >
                  Cancel
                </button>
              </Columns>
            </Row>
          </div>
        )}
      </div>
    );
  }
};

SavedCards.propTypes = {
  savedCards: PropTypes.object,
  finalizePurchase: PropTypes.object,
  handleSelectPaymentOption: PropTypes.func,
  handleManageSavedCards: PropTypes.func,
  handleDeleteSavedCard: PropTypes.func,
};

export default SavedCards;
