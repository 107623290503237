import { snakeCaseKeys } from '../../../../common/config/utils';

const undoCasingCustomFieldsErrors = ({ errors }) =>
  Object.keys(errors).reduce((o, e) => {
    if (e.indexOf('shipping') !== -1) {
      // shipping custom field - snake case the key
      o = {
        ...o,
        ...snakeCaseKeys({ [e]: errors[e] }),
      };
    } else {
      o[e] = errors[e];
    }
    return o;
  }, {});

export default undoCasingCustomFieldsErrors;
