/* globals STATIC_URL */
import PropTypes from 'prop-types';
import React from 'react';

import { Columns, Row } from '../../../../common/components/Grid';
import Loading from '../../../../common/components/Loading';
import MojoPopup from '../../../../common/components/MojoPopup';

const DEFAULT_UPI_POLLING_TIMEOUT_LIMIT = 15 * 60 * 1000; // 15 minutes of time.
class UPIIntentWaitScreen extends React.PureComponent {
  constructor(props) {
    super(props);
    this.upiPollID = null;
    this.upiPollTimeout = null;
    this.state = {
      upiPollingActive: true,
      upiTimer: 0,
      loaderTimer: 0,
      triggeredManualApiCall: false,
    };
  }
  componentDidMount() {
    // Set to a default of 15 minutes in cases where it does not come from backend
    const {
      dynamicQrPollerTimeout,
      dynamicQrStatusCheckUrl,
    } = this.props.dynamicQrDetails;
    const UPIPollingTimeout = dynamicQrPollerTimeout
      ? dynamicQrPollerTimeout
      : DEFAULT_UPI_POLLING_TIMEOUT_LIMIT;
    // We will start calling api on component mount hoping for payment
    // using QR :)
    this.upiPollID = this.props.pollForUPIAcceptance(dynamicQrStatusCheckUrl);
    this.upiPollTimeout = setTimeout(() => {
      if (this.upiPollID) {
        // we will clear the api call once the component is inactive for long time (15 minutes)
        // as payment is no more happening via qr :(
        this.setState({ upiPollingActive: false });
        clearInterval(this.upiPollID);
      }
    }, UPIPollingTimeout);
    this.setState({ upiTimer: Math.round(UPIPollingTimeout / 1000) });
    this.timerInterval = setInterval(() => {
      const { upiTimer, loaderTimer } = this.state;
      if (upiTimer > 0) {
        this.setState({ upiTimer: upiTimer - 1 });
      }
      if (!document.hidden) {
        this.setState({ loaderTimer: loaderTimer + 1 });
      }
    }, 1000);
  }
  componentWillUnmount() {
    if (this.upiPollID) {
      // we will clear the api call once the component is unmounted
      // as payment is no more happening via qr :(
      clearInterval(this.upiPollID);
    }
    if (this.upiPollTimeout) {
      // clear the timeout so as to not trigger the updation of state once unmounted
      clearTimeout(this.upiPollTimeout);
    }
    if (this.timerInterval) {
      // clear interval for timer
      clearInterval(this.timerInterval);
    }
  }

  getTimerMinutes(seconds) {
    // minutes is seconds divided by 60, rounded down
    const minutes = Math.floor(seconds / 60);
    return minutes < 10 ? `0${minutes}` : minutes;
  }
  getTimerSeconds(seconds) {
    // take mins remaining (as seconds) away from total seconds remaining
    const minutes = Math.floor(seconds / 60);
    const finalSeconds = seconds - Math.round(minutes * 60);
    return finalSeconds < 10 ? `0${finalSeconds}` : finalSeconds;
  }

  render() {
    const {
      toggleUPIIntentWaitScreen,
      getUPIIntentStatusCheck,
      intentAppCalled,
      dynamicQrDetails: { dynamicQrStatusCheckUrl: statusCheckUrl } = {},
    } = this.props;
    const {
      upiPollingActive,
      upiTimer,
      loaderTimer,
      triggeredManualApiCall,
    } = this.state;
    const showLoader = loaderTimer < 4;

    return (
      <div>
        <MojoPopup
          show={true}
          onClose={() => toggleUPIIntentWaitScreen(false, '')}
          showCloseModalIcon={true}
          contentContainerStyle={{ width: '100%', position: 'absolute', bottom: 0 }}
          isUpiIntentIOSWaitModal
        >
          <div className="push--left push-half--top text-black strong">
            Complete Payment{' '}
          </div>
          <div className="text-red push--left text-small push--ends">
            {' '}
            Note: Please do not press the back button or close the screen until the
            payment is complete.
          </div>
          <Row>
            <Columns className="small-2 hard--right">
              <img
                alt="mobile-upi-intent-screen"
                src={`${STATIC_URL}assets/images/intent-mobile-wait-image.svg`}
                width={40}
              />
            </Columns>
            <Columns className="small-10">
              <div className="f12 push--bottom">
                Please authorise the payment on the {intentAppCalled} app
              </div>
              {upiPollingActive && upiTimer ? (
                <div style={{ color: '#003ae2' }}>
                  <div className="f12">This page expires in</div>
                  <div>
                    <strong>
                      {' '}
                      {this.getTimerMinutes(upiTimer)}:{this.getTimerSeconds(upiTimer)}{' '}
                    </strong>{' '}
                    mins
                  </div>
                </div>
              ) : null}
            </Columns>
          </Row>
          {!showLoader ? (
            <div>
              {triggeredManualApiCall ? (
                <div className="push--sides f12 text-red" style={{ marginTop: 16 }}>
                  We have not received payment confirmation yet. Please authorize your
                  payment and try again.
                </div>
              ) : (
                <div className="push-double--top push--left f12">
                  Done with payment but no response?{' '}
                </div>
              )}
              <button
                className="push--top push--left btn btn-green"
                style={{ width: '92%', height: 40 }}
                onClick={() => {
                  getUPIIntentStatusCheck(statusCheckUrl);
                  this.setState({ loaderTimer: 0 });
                  this.setState({ triggeredManualApiCall: true });
                }}
              >
                Confirm my payment
              </button>
            </div>
          ) : (
            <div style={{ height: 78 }}>
              <Loading />
            </div>
          )}
        </MojoPopup>
      </div>
    );
  }
}

UPIIntentWaitScreen.propTypes = {
  dynamicQrDetails: PropTypes.object,
  pollForUPIAcceptance: PropTypes.func,
  convenienceFeeVersion: PropTypes.string,
  toggleUPIIntentWaitScreen: PropTypes.func,
  intentAppCalled: PropTypes.string,
  getUPIIntentStatusCheck: PropTypes.func,
};

export default UPIIntentWaitScreen;
