import LGTM from 'lgtm';

import { snakeCaseKeys } from '../../../common/config/utils';
import { POST_API } from '../../../common/middlewares/form';
import { getOrderEndpoint } from '../utils/GlobalUtils';
import { mapPayloadForServer } from '../utils/InitializePurchaseUtils';
import { storeFinalPayload } from './FinalizePurchaseActions';
import { handleUpdateLinkDiscountDetails } from './InitializePurchaseActions';
import undoCasingCustomFieldsErrors from './refine/undoCasingCustomFieldsErrors';

export const HANDLE_CHANGE_RP_INPUT = 'HANDLE_CHANGE_RP_INPUT';
export const handleChangeInput = (changedData) => ({
  type: HANDLE_CHANGE_RP_INPUT,
  payload: {
    changedData,
  },
});

const getRequiredCustomFields = (customFields) => {
  return Object.keys(customFields).filter((f) => customFields[f].required);
};

const getRequiredAdditionalCustomFields = (customFields) => {
  const additionalCustomFields = customFields['additionalCustomFields'] || {};
  return Object.keys(additionalCustomFields).filter(
    (f) => additionalCustomFields[f].required,
  );
};

let finalPayload;

export const refinePurchaseValidator = ({
  store,
  initialValidator: validator,
  includeShipping,
}) => {
  if (!validator) {
    validator = LGTM.validator();
  }

  let customFields = {};
  if (store.refinePurchase.offer) {
    customFields = store.refinePurchase.offer.customFields;
  }
  if (store.refinePurchase.link) {
    customFields = store.refinePurchase.link.customFields;

    const addressFields = Object.keys(customFields)
      .filter((field) => customFields[field].shouldUseAsObject)
      .reduce((prev, customFieldKey) => {
        const keyName = customFieldKey.split('Address')[0];
        const {
          address,
          city,
          // country,
          state,
          zip,
        } = customFields[customFieldKey];

        prev[keyName + 'Address'] = address;
        prev[keyName + 'City'] = city;
        // prev[keyName + 'Country'] = country;
        prev[keyName + 'State'] = state;
        prev[keyName + 'Zip'] = zip;
        return prev;
      }, {});

    customFields = {
      ...customFields,
      ...addressFields,
    };
  }

  getRequiredCustomFields(customFields).forEach((customField) => {
    if (!includeShipping && customFields[customField].isShippingField) {
      return;
    }

    validator = validator
      .validates(customField)
      .required(customFields[customField].validationMessage || 'This field is required');

    if (customFields[customField].type === 'bool') {
      // this is a single tick checkbox - it HAS to be checked as it's required
      validator.using((value) => value, 'This field is required');
    }
    if (
      customFields[customField].hasCustomValidation &&
      customFields[customField].customValidation &&
      customFields[customField].customValidationMessage
    ) {
      //This adds the custom validation
      validator.using(
        (value) => customFields[customField].customValidation(value),
        customFields[customField].customValidationMessage,
      );
    }
  });

  getRequiredAdditionalCustomFields(customFields).forEach((customField) => {
    const field = customFields.additionalCustomFields[customField];
    validator = validator
      .validates(`additional_${field.name}`)
      .required('This field is required');
  });

  return validator.build();
};

export const refinePurchaseToValidate = ({ store, includeShipping }) => {
  let customFields = {};
  if (store.refinePurchase.offer) {
    customFields = store.refinePurchase.offer.customFields;
  }
  if (store.refinePurchase.link) {
    customFields = store.refinePurchase.link.customFields;

    const addressFields = Object.keys(customFields)
      .filter((field) => customFields[field].shouldUseAsObject)
      .reduce((prev, customFieldKey) => {
        const keyName = customFieldKey.split('Address')[0];
        const {
          address,
          city,
          // country,
          state,
          zip,
        } = customFields[customFieldKey];

        prev[keyName + 'Address'] = address;
        prev[keyName + 'City'] = city;
        // prev[keyName + 'Country'] = country;
        prev[keyName + 'State'] = state;
        prev[keyName + 'Zip'] = zip;
        return prev;
      }, {});
    customFields = {
      ...customFields,
      ...addressFields,
    };
  }

  const { customFields: customFieldsForm } = store.refinePurchase.form;

  const customFieldsToValidate = getRequiredCustomFields(customFields).reduce(
    (toValidate, field) => {
      if (!includeShipping && customFields[field].isShippingField) {
        return toValidate;
      } else if (
        store.refinePurchase.link &&
        (customFields[field].isShippingField || customFields[field].isBillingField)
      ) {
        // return toValidate;

        const fieldType = customFields[field].isShippingField ? 'shipping' : 'billing';

        //shippingCity => [shipping, City]
        const fieldKeyList = field.split(fieldType);
        fieldKeyList[0] = fieldType + 'Address';
        fieldKeyList[1] = fieldKeyList[1].toLowerCase();
        toValidate[field] = customFieldsForm[fieldKeyList[0]][fieldKeyList[1]];
        return toValidate;
      }

      toValidate[field] = customFieldsForm[field];
      return toValidate;
    },
    {},
  );

  // Adding 'additional' to identify errors of additional custom fields
  const additionalFieldsToValidate = getRequiredAdditionalCustomFields(
    customFields,
  ).reduce((fieldsToValidate, field) => {
    const fieldName = customFields.additionalCustomFields[field].name;
    fieldsToValidate[`additional_${fieldName}`] =
      customFieldsForm.additionalCustomFields[field];
    return fieldsToValidate;
  }, {});

  return {
    ...customFieldsToValidate,
    ...additionalFieldsToValidate,
  };
};

export const refinePurchasePayload = ({ store, includeShipping }) => {
  let customFields = {};
  const { customFields: customFieldsForm } = store.refinePurchase.form;
  let customFieldsPayload = {};

  //This is for refine purchase products.
  if (store.refinePurchase.offer) {
    customFields = store.refinePurchase.offer.customFields;
    customFieldsPayload = Object.keys(customFields).reduce((payload, customField) => {
      if (!includeShipping && customFields[customField].isShippingField) {
        return payload;
      }
      payload[customField] = customFieldsForm[customField];
      if (customFields[customField].type === 'bool') {
        // the validator will ensure it's checked. Hence, not checking the actual form value
        payload[customField] = 'on';
      }
      return payload;
    }, {});
  }

  //This is for refine purchase links.
  if (store.refinePurchase.link) {
    customFields = store.refinePurchase.link.customFields;
    let customPayload = {};
    customPayload = customFieldsForm;

    if (customPayload['shippingAddress']) {
      customPayload['shippingAddress']['country'] = 'India';
    }

    if (customPayload['billingAddress']) {
      customPayload['billingAddress']['country'] = 'India';
    }

    // //Get the address fields and create the custom object.
    // const filteredAddressObject = Object.keys(customFields)
    //   .filter(key => key.indexOf('billing') > -1 || key.indexOf('shipping') > -1)
    //   .reduce((obj, key) => {
    //     obj[key.split('_')[0]] = obj[key.split('_')[0]] || {};
    //     obj[key.split('_')[0]][key.split('_')[1]] = customFieldsForm[key];
    //     return obj;
    //   }, {});
    // Object.keys(filteredAddressObject).forEach(function(key) {
    //   //Shipping Address fields to be send with a key shipping_address and values address/pin etc.
    //   if(key === 'shipping'){
    //     customPayload['shipping_address'] = filteredAddressObject['shipping'];
    //   }
    //   if(key === 'billing'){
    //     customPayload['billing_address'] = filteredAddressObject['billing'];
    //   }
    // });
    //
    // //Get the other fields and create the custom object.
    // customPayload['gstin'] = customFieldsForm['gstin'];
    // customPayload['company_name'] = customFieldsForm['company_name'];

    //convert the object to json
    if (store.refinePurchase.initialPayload['additional_fields']) {
      const oldAdditionalFields = JSON.parse(
        store.refinePurchase.initialPayload['additional_fields'],
      );
      const newAdditionalFields = snakeCaseKeys(customPayload);
      if (newAdditionalFields.additional_custom_fields) {
        newAdditionalFields.additional_custom_fields = snakeCaseKeys(
          newAdditionalFields.additional_custom_fields,
        );
      }
      const updatedAditionalFields = {
        ...oldAdditionalFields,
        ...newAdditionalFields,
      };
      customFieldsPayload['additional_fields'] = JSON.stringify(updatedAditionalFields);
    } else {
      customFieldsPayload['additional_fields'] = JSON.stringify(
        snakeCaseKeys(customPayload),
      );
    }
  }

  finalPayload = {
    ...store.refinePurchase.initialPayload,
    ...customFieldsPayload,
  };

  if (store.refinePurchase.link) {
    finalPayload = {
      ...finalPayload,
      save: 1,
    };
  }

  return mapPayloadForServer(finalPayload);
};

export const HANDLE_SUBMIT_REFINE_PURCHASE_REQUEST =
  'HANDLE_SUBMIT_REFINE_PURCHASE_REQUEST';
export const HANDLE_SUBMIT_REFINE_PURCHASE_SUCCESS =
  'HANDLE_SUBMIT_REFINE_PURCHASE_SUCCESS';
export const HANDLE_SUBMIT_REFINE_PURCHASE_FAILURE =
  'HANDLE_SUBMIT_REFINE_PURCHASE_FAILURE';
export const handleSubmitRefinePurchase = () => ({
  [POST_API]: {
    types: [
      HANDLE_SUBMIT_REFINE_PURCHASE_REQUEST,
      HANDLE_SUBMIT_REFINE_PURCHASE_SUCCESS,
      HANDLE_SUBMIT_REFINE_PURCHASE_FAILURE,
    ],
    endpoint: getOrderEndpoint(),
    validator: (store) =>
      refinePurchaseValidator({
        store,
        includeShipping: true,
      }),
    toValidate: (store) =>
      refinePurchaseToValidate({
        store,
        includeShipping: true,
      }),
    failureActionErrors: (response) => {
      const { errors } = response;
      /**
       * the keys sent from the backend will be camelCased by the middleware
       * since we have hardcoded the shipping keys to be snakeCased in the reducer,
       * need to make an exception here for shipping fields
       */
      return undoCasingCustomFieldsErrors({ errors });
    },
    payloadAsIs: true,
    payload: (store) =>
      refinePurchasePayload({
        store,
        includeShipping: true,
      }),
    onSuccess: (response, store, dispatch) => {
      const { details: { linkDiscount } = {} } = response;

      const { initializePurchase: { offer: { basePrice } = {} } = {} } = store;
      // Updates discount details when user does not click on apply and enters a valid discount code
      if (linkDiscount) {
        dispatch(handleUpdateLinkDiscountDetails(linkDiscount, basePrice));
      }
      dispatch(storeFinalPayload(finalPayload));
    },
  },
});

export const STORE_INITIAL_PAYLOAD = 'STORE_INITIAL_PAYLOAD';
export const storeInitialPayload = (initialPayload) => ({
  type: STORE_INITIAL_PAYLOAD,
  payload: {
    initialPayload,
  },
});
