/* globals ga, pageReadyAt, MojoUser, INITIAL_STATE */

import Analytics from '../config/Analytics';
import { productToMixpanelNameMap } from '../config/ProductTypes';
import { getUrlParameterByName } from '../config/utils';
import transactionCategoryConstants from './transactionCategoryConstants';

const pathnameArray = location.pathname.split('/');

function getSellerUsername() {
  // for affiliate product page, the pathname is instamojo.com/affiliate/username/product-slug, hence the username is the second element instead of the first one
  const username = pathnameArray[1] === 'affiliate' ? pathnameArray[2] : pathnameArray[1];
  if (!username) {
    return '';
  }

  if (username.charAt(0) === '@') {
    return username.replace('@', '');
  }

  return username;
}

const parentRefStoreFlag = getUrlParameterByName('ref'),
  intent = getUrlParameterByName('intent'),
  embed = getUrlParameterByName('embed'),
  signature = getUrlParameterByName('data_sign'),
  selfFlag = getSellerUsername().toLowerCase() === MojoUser.username,
  remoteCheckoutFlag = getUrlParameterByName('checkout'),
  discountCode = getUrlParameterByName('discount'),
  isLinkPayment = INITIAL_STATE.is_link_payment,
  isInstapayPayment = INITIAL_STATE.is_instapay_payment,
  isRAPPayment = INITIAL_STATE.is_rap,
  offerType = INITIAL_STATE.offer ? INITIAL_STATE.offer.type : '';

function getTransactionCategory() {
  const paymentsTab = INITIAL_STATE.show_payment_tab;

  if (!paymentsTab) {
    return transactionCategoryConstants.Free;
  }
  return transactionCategoryConstants.INR;
}

const track = () => {
  ga((tracker) => {
    try {
      const paymentReadyAt = new Date().getTime();
      const paymentLoadTime = (paymentReadyAt - pageReadyAt) / 1000;
      Analytics.sendEvent('Viewed Offer Page', {
        seller_username: getSellerUsername(),
        // the offer_id becomes the third element for affiliate offer pages
        offer_id:
          (pathnameArray[1] === 'affiliate' ? pathnameArray[3] : pathnameArray[2]) ||
          null,
        parent_ref_store_flag: parentRefStoreFlag,
        ga_client_id: tracker.get('clientId'),
        remote_checkout_flag: remoteCheckoutFlag,
        intent: intent,
        embed: embed,
        self_flag: selfFlag,
        discount_id: discountCode,
        payment_signature_flag: !!signature,
        transaction_category: getTransactionCategory(),
        payment_form_load_time: paymentLoadTime,
        link_flag: isLinkPayment || isInstapayPayment,
        link_mode: isLinkPayment
          ? 'custom'
          : isRAPPayment
          ? 'rap'
          : isInstapayPayment
          ? 'default'
          : null,
        link_id: isLinkPayment ? pathnameArray[2] : null,
        offer_category: productToMixpanelNameMap[offerType] || '',
        // This is to identify whether the product page is reseller page or the orignal seller page
        reseller_flag: pathnameArray[1] === 'affiliate',
        reseller_username: MojoUser.username,
        reseller_account_id: MojoUser.id,
      });
    } catch (e) {
      /* continue regardless of error */
    }
  });
};

export const trackClickedPaymentButton = ({ label }) => {
  try {
    Analytics.sendEvent('Clicked on Offer', {
      seller_username: getSellerUsername(),
      offer_id: pathnameArray[2] || null,
      button_id: label,
      parent_ref_store_flag: parentRefStoreFlag,
      remote_checkout_flag: remoteCheckoutFlag,
      intent: intent,
      embed: embed,
      self_flag: selfFlag,
      discount_id: discountCode,
      payment_signature_flag: !!signature,
      transaction_category: getTransactionCategory(),
      link_flag: isLinkPayment || isInstapayPayment,
      link_mode: isLinkPayment
        ? 'custom'
        : isRAPPayment
        ? 'rap'
        : isInstapayPayment
        ? 'default'
        : null,
      link_id: isLinkPayment ? pathnameArray[2] : null,
    });
  } catch (e) {
    /* continue regardless of error */
  }
};

export default track;
