import root from 'window-or-global';

const userAgent = root.navigator.userAgent;

function isSubstring(str, subString) {
  return str.indexOf(subString) !== -1;
}

export function getReferrerDomain(referrer) {
  const split = referrer.split('/');
  if (split.length >= 3) {
    return split[2];
  }
  return '';
}

/**
 * This function detects which browser is running this script.
 * The order of the checks are important since many user agents
 * include key words used in later checks.
 */
export function getBrowserName() {
  const vendor = root.navigator.vendor || ''; // vendor is undefined for at least IE9
  if (root.opera || isSubstring(userAgent, ' OPR/')) {
    if (isSubstring(userAgent, 'Mini')) {
      return 'Opera Mini';
    }
    return 'Opera';
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return 'BlackBerry';
  } else if (isSubstring(userAgent, 'IEMobile') || isSubstring(userAgent, 'WPDesktop')) {
    return 'Internet Explorer Mobile';
  } else if (isSubstring(userAgent, 'SamsungBrowser/')) {
    // https://developer.samsung.com/internet/user-agent-string-format
    return 'Samsung Internet';
  } else if (isSubstring(userAgent, 'Edge') || isSubstring(userAgent, 'Edg/')) {
    return 'Microsoft Edge';
  } else if (isSubstring(userAgent, 'FBIOS')) {
    return 'Facebook Mobile';
  } else if (isSubstring(userAgent, 'Chrome')) {
    return 'Chrome';
  } else if (isSubstring(userAgent, 'CriOS')) {
    return 'Chrome iOS';
  } else if (isSubstring(userAgent, 'UCWEB') || isSubstring(userAgent, 'UCBrowser')) {
    return 'UC Browser';
  } else if (isSubstring(userAgent, 'FxiOS')) {
    return 'Firefox iOS';
  } else if (isSubstring(vendor, 'Apple')) {
    if (isSubstring(userAgent, 'Mobile')) {
      return 'Mobile Safari';
    }
    return 'Safari';
  } else if (isSubstring(userAgent, 'Android')) {
    return 'Android Mobile';
  } else if (isSubstring(userAgent, 'Konqueror')) {
    return 'Konqueror';
  } else if (isSubstring(userAgent, 'Firefox')) {
    return 'Firefox';
  } else if (isSubstring(userAgent, 'MSIE') || isSubstring(userAgent, 'Trident/')) {
    return 'Internet Explorer';
  } else if (isSubstring(userAgent, 'Gecko')) {
    return 'Mozilla';
  } else {
    return '';
  }
}

/**
 * This function detects which browser version is running this script,
 * parsing major and minor version (e.g., 42.1). User agent strings from:
 * http://www.useragentstring.com/pages/useragentstring.php
 */
export function getBrowserVersion() {
  const browser = getBrowserName();
  const versionRegexs = {
    'Internet Explorer Mobile': /rv:(\d+(\.\d+)?)/,
    'Microsoft Edge': /Edge?\/(\d+(\.\d+)?)/,
    Chrome: /Chrome\/(\d+(\.\d+)?)/,
    'Chrome iOS': /CriOS\/(\d+(\.\d+)?)/,
    'UC Browser': /(UCBrowser|UCWEB)\/(\d+(\.\d+)?)/,
    Safari: /Version\/(\d+(\.\d+)?)/,
    'Mobile Safari': /Version\/(\d+(\.\d+)?)/,
    Opera: /(Opera|OPR)\/(\d+(\.\d+)?)/,
    Firefox: /Firefox\/(\d+(\.\d+)?)/,
    'Firefox iOS': /FxiOS\/(\d+(\.\d+)?)/,
    Konqueror: /Konqueror:(\d+(\.\d+)?)/,
    BlackBerry: /BlackBerry (\d+(\.\d+)?)/,
    'Android Mobile': /android\s(\d+(\.\d+)?)/,
    'Samsung Internet': /SamsungBrowser\/(\d+(\.\d+)?)/,
    'Internet Explorer': /(rv:|MSIE )(\d+(\.\d+)?)/,
    Mozilla: /rv:(\d+(\.\d+)?)/,
  };
  const regex = versionRegexs[browser];
  if (regex === undefined) {
    return null;
  }
  const matches = userAgent.match(regex);
  if (!matches) {
    return null;
  }
  return parseFloat(matches[matches.length - 2]);
}

export function getOS() {
  const a = userAgent;
  if (/Windows/i.test(a)) {
    if (/Phone/.test(a) || /WPDesktop/.test(a)) {
      return 'Windows Phone';
    }
    return 'Windows';
  } else if (/(iPhone|iPad|iPod)/.test(a)) {
    return 'iOS';
  } else if (/Android/.test(a)) {
    return 'Android';
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(a)) {
    return 'BlackBerry';
  } else if (/Mac/i.test(a)) {
    return 'Mac OS X';
  } else if (/Linux/.test(a)) {
    return 'Linux';
  } else if (/CrOS/.test(a)) {
    return 'Chrome OS';
  } else {
    return '';
  }
}

export function getDevice() {
  const a = userAgent;
  if (/Windows Phone/i.test(a) || /WPDesktop/.test(a)) {
    return 'Windows Phone';
  } else if (/iPad/.test(a)) {
    return 'iPad';
  } else if (/iPod/.test(a)) {
    return 'iPod Touch';
  } else if (/iPhone/.test(a)) {
    return 'iPhone';
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(a)) {
    return 'BlackBerry';
  } else if (/Android/.test(a)) {
    return 'Android';
  } else {
    return '';
  }
}

export function getSearchEngine(referrer) {
  if (referrer.search('https?://(.*)google.([^/?]*)') === 0) {
    return 'google';
  } else if (referrer.search('https?://(.*)bing.com') === 0) {
    return 'bing';
  } else if (referrer.search('https?://(.*)yahoo.com') === 0) {
    return 'yahoo';
  } else if (referrer.search('https?://(.*)duckduckgo.com') === 0) {
    return 'duckduckgo';
  } else {
    return null;
  }
}
