import objectEquals from './objectEquals';
import pendingRemovalTimers from './pendingRemovalTimers';
import reduceCartItemProps from './reduceCartItemProps';

function getCartIdPendingRemoval({ slug, ...props }) {
  const ids = Object.keys(pendingRemovalTimers);
  return ids.find((id) => {
    const pendingItem = pendingRemovalTimers[id];
    return (
      pendingItem.slug === slug &&
      objectEquals(pendingItem.item, reduceCartItemProps(props))
    );
  });
}

export default getCartIdPendingRemoval;
