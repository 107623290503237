import groupBy from 'lodash/groupBy';

import { camelCaseKeys, stringCompare } from '../../../../common/config/utils';
import { isSmallDevice } from '../../../../common/config/utils';
import { getPaymentOptionKey } from '../../utils/FinalizePurchaseUtils';
import { isMobileDevice } from '../../utils/GlobalUtils';

export const gridItems = 6;

export function sanitizePaymentOptions(paymentOptions) {
  const { paymentOptions: options } = paymentOptions;
  const standardPaymentOptions = options ? options : [];

  let hasEMI = false;
  const reducedPaymentOptions = standardPaymentOptions.reduce((obj, paymentOption) => {
    let sanitizedOption = paymentOption;
    if (paymentOption.type === 'nb') {
      const { nbList } = paymentOption;
      sanitizedOption = {
        ...paymentOption,
        nbGridList: nbList.slice(0, gridItems),
        nbList: [...nbList].sort((bankObj1, bankObj2) =>
          stringCompare(bankObj1.name, bankObj2.name),
        ),
      };
    }

    if (paymentOption.type === 'emi') {
      hasEMI = true;
    }

    obj[getPaymentOptionKey(paymentOption)] = sanitizedOption;

    return obj;
  }, {});

  let additionalChanges = {};
  if (hasEMI) {
    const { emiList } = standardPaymentOptions.find((option) => option.type === 'emi');
    if (emiList && Object.keys(emiList).length) {
      const selectedEMICardProvider = Object.keys(emiList)[0];
      additionalChanges = {
        ...additionalChanges,
        selectedEMICardProvider,
        selectedEMITenure: Object.keys(emiList[selectedEMICardProvider].rates)[0],
      };
    }
  }

  return {
    ...paymentOptions,
    ...additionalChanges,
    paymentOptions: reducedPaymentOptions,
  };
}

export default sanitizePaymentOptions;

export function getSupportedPaymentInstruments(paymentOptions = {}) {
  // this method is being used when page lands directly to payments
  // as per new impl, this data will be sent in an array.
  // so simply camelCasing those options should work
  return camelCaseKeys(paymentOptions);
}

export function getPaymentGroups(paymentOptions = {}) {
  if (paymentOptions.length) {
    const paymentGroups = groupBy(paymentOptions, (paymentOption) => paymentOption.group);
    if (
      paymentOptions.find((paymentOption) => (paymentOption.type === 'dynamic_qr' || paymentOption.type === 'dynamic_qr_initiate')) &&
      !(isSmallDevice() || isMobileDevice())
      ) {
      return paymentGroups;
    }
  }
  return null;
}

export const resetPaymentOptions = {
  selectedPaymentMode: '',
  selectedPaymentModeName: '',
  selectedBankId: '',
  selectedWalletId: '',
  isBankPaymentRequestSubmitted: false,
  isWalletPaymentRequestSubmitted: false,
  isPayLaterRequestSubmitted: false,
  isEMIFormSubmitted: false,
  isUPIRequestSubmitting: false,
};
