import Rupee from '@instamojo/rupee';
import PropTypes from 'prop-types';
import React from 'react';

import CartConvenienceFee from '../../../../dashboard/cart/components/full/CartConvenienceFee';
import ConvenienceFeesAccordion from '../convenience-fee/ConvenienceFeesAccordion';
import PaymentBreadcrumsNavbar from './PaymentBreadcrumsNavbar';

const PaymentHeader = ({
  isRetryingCartPayment,
  totalAmountFormatted,
  settings,
  handleToggleOpenState,
  convenienceFeeVersion,
}) => {
  const {
    isEmbed,
    isIFrame,
    isDirectToPayments,
    isFinalizeFormOpen,
    showConvenienceFee,
    showConvenienceFeeForm,
  } = settings;
  return (
    <div>
      {isEmbed && !isIFrame && isFinalizeFormOpen && !showConvenienceFeeForm ? (
        <div className={showConvenienceFee ? 'push--sides push--top' : 'push'}>
          {convenienceFeeVersion != 'v2' || !isFinalizeFormOpen ? (
            <div>
              <span>Amount</span>
              <span className="right">
                <Rupee value={totalAmountFormatted} />
              </span>
            </div>
          ) : null}
          <ConvenienceFeesAccordion />
        </div>
      ) : null}
      {!isDirectToPayments ? (
        <div className="soft--sides">
          {isRetryingCartPayment ? <CartConvenienceFee /> : null}
          <PaymentBreadcrumsNavbar
            settings={settings}
            handleToggleOpenState={handleToggleOpenState}
          />
        </div>
      ) : null}
    </div>
  );
};

PaymentHeader.propTypes = {
  isRetryingCartPayment: PropTypes.bool,
  totalAmountFormatted: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  settings: PropTypes.object,
  handleToggleOpenState: PropTypes.func,
  convenienceFeeVersion: PropTypes.string,
};

export default PaymentHeader;
