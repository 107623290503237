/*globals STATIC_URL*/
import PropTypes from 'prop-types';
import React from 'react';
import ReactPhoneNumber from 'react-phone-number-input';

import { FormFieldWithLabel } from '../../../../common/components/FormComponents';

const Phone = ({
  phone,
  isInternationalNumberEnabled,
  readOnly = {},
  errors,
  onChange,
}) => {
  return (
    <FormFieldWithLabel label="Phone Number" errors={errors.phone}>
      {readOnly.phone ? (
        <p className="flush break-long-words">{phone}</p>
      ) : (
        <div>
          {isInternationalNumberEnabled ? (
            <ReactPhoneNumber
              value={(phone && phone.indexOf('+') == 0) || !phone ? phone : `+91${phone}`}
              maxLength="25"
              country={!phone ? 'IN' : ''}
              international={false}
              flagsPath={`${STATIC_URL}assets/images/flag-icons/`}
              onKeyDown={(e) => {
                // Prevent Taking + in the Input Field, As it Breaks the Library
                if (e.shiftKey) {
                  e.preventDefault();
                }
              }}
              onChange={(phoneValue) => onChange({ phone: phoneValue ? phoneValue : '' })}
            />
          ) : (
            <input
              type="tel"
              value={phone}
              onChange={(e) => onChange({ phone: e.target.value })}
            />
          )}
        </div>
      )}
    </FormFieldWithLabel>
  );
};

Phone.propTypes = {
  phone: PropTypes.string,
  isInternationalNumberEnabled: PropTypes.bool,
  readOnly: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default Phone;
