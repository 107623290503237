import '../../styles/InputCheckbox.scss';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/*
This component is the version 2 of the checkbox (as same the one we have in mojocommerce-dashboard).
As maintaining all the styles in a single component makes more cluttery,
introduced a new verison of file.
*/
class InputCheckboxV2 extends React.Component {
  render() {
    const inputProps = { ...this.props };
    delete inputProps.label;
    delete inputProps.className;
    delete inputProps.style;
    delete inputProps.checkboxClassName;
    delete inputProps.checkboxStyle;
    return (
      <label
        className={classnames('checkbox-v2', this.props.className)}
        style={this.props.style}
      >
        <input
          style={{ top: '-1px', position: 'relative', ...this.props.checkboxStyle }}
          type="checkbox"
          className={`push-half--right ${this.props.checkboxClassName}`}
          {...inputProps}
        />
        <span />
        {this.props.label}
        {this.props.errors ? <p className="text-red push-half--top">{this.props.errors}</p> : null}
      </label>
    );
  }
}

InputCheckboxV2.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  checkboxClassName: PropTypes.string,
  style: PropTypes.object,
  errors: PropTypes.array,
  checkboxStyle: PropTypes.object,
};

InputCheckboxV2.defaultProps = {
  checkboxClassName: '',
  checkboxStyle: {},
};

export default InputCheckboxV2;
