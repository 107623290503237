import PropTypes from 'prop-types';
import React from 'react';

import { FormFieldWithLabel } from '../../../../common/components/FormComponents';

const FullName = ({ errors, readOnly = {}, name, onChange }) => {
  return (
    <FormFieldWithLabel label="Name" errors={errors.name}>
      {readOnly.name ? (
        <p className="flush break-long-words">{name}</p>
      ) : (
        <input
          type="text"
          autoFocus
          value={name}
          onChange={(e) => onChange({ name: e.target.value })}
        />
      )}
    </FormFieldWithLabel>
  );
};

FullName.propTypes = {
  errors: PropTypes.object,
  readOnly: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

export default FullName;
