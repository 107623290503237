import { combineReducers } from 'redux';
import root from 'window-or-global';

import { pageState } from '../../../common/reducers/PageStateReducers';
import smartLinks from '../../../public/smart-links/reducers/SmartLinksReducers';
import finalizePurchase from './FinalizePurchaseReducers';
import initializePurchase from './InitializePurchaseReducers';
import offerDetails from './OfferDetailsReducers';
import refinePurchase from './RefinePurchaseReducers';
import settings from './SettingsReducers';

let rootReducer;
if (root.SHOW_SMART_LINK_WEBAPP) {
  rootReducer = combineReducers({
    smartLinks,
    settings,
    initializePurchase,
    refinePurchase,
    finalizePurchase,
    pageState,
    offerDetails,
  });
} else {
  rootReducer = combineReducers({
    settings,
    initializePurchase,
    refinePurchase,
    finalizePurchase,
    pageState,
    offerDetails,
  });
}

export default rootReducer;
