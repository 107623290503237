/* globals INITIAL_STATE */
import deepmerge from 'deepmerge';

import { objectWithout } from '../../../common/config/utils';
import * as CartActionTypes from '../../../dashboard/cart/actions/CartActions';
import * as ActionTypes from '../actions/InitializePurchaseActions';
import * as InstapayActionTypes from '../actions/InstapayActions';
import {
  getInitialStateTotalAmount,
  getVariantAmountMap,
  reducedState,
} from '../utils/GlobalUtils';
import { getCustomFields } from '../utils/GlobalUtils';
import {
  getVariantCategoryName,
  mapPayloadForClient,
} from '../utils/InitializePurchaseUtils';

function initializePurchase(state = getInitialState(INITIAL_STATE), action) {
  switch (action.type) {
    case ActionTypes.HANDLE_IP_CHANGE_INPUT: {
      const {
        payload: { changedData },
      } = action;

      let reducerChange = { ...changedData };

      /* Special case to consider Pay as you want condition */
      if ('isClaimFreeChecked' in changedData) {
        if (changedData.isClaimFreeChecked) {
          // toggled on
          reducerChange = { ...reducerChange, amount: 0, isAmountEditable: false };
        } else {
          reducerChange = { ...reducerChange, isAmountEditable: true };
        }
      }

      let mergePayload = {
        ...state,
        form: {
          ...state.form,
          ...reducerChange,
          errors: objectWithout(state.form.errors, Object.keys(reducerChange)[0]),
        },
      };

      /* Special case to consider Variant errors from server */
      if (mergePayload.form.errors.Variant) {
        mergePayload = {
          ...mergePayload,
          form: {
            ...mergePayload.form,
            errors: objectWithout(mergePayload.form.errors, 'Variant'),
          },
        };
      }

      return mergePayload;
    }

    case ActionTypes.SHOW_DISCOUNT_CODE_BOX: {
      return deepmerge(state, {
        form: {
          isDiscountEditable: true,
          isDiscountBeingEdited: true,
        },
      });
    }

    case ActionTypes.HANDLE_APPLY_DISCOUNT_SUCCESS: {
      const {
        discount: { amountOff },
      } = action.response;
      return reducedState(state, {
        discountAmount: Number(amountOff),
        isDiscountApplied: true,
        isDiscountEditable: false,
        errors: objectWithout(state.form.errors, 'discountCode'),
      });
    }

    case ActionTypes.HANDLE_APPLY_DISCOUNT_FAILURE: {
      const { errors } = action;
      return {
        ...state,
        form: {
          ...state.form,
          errors: {
            ...state.form.errors,
            ...errors,
          },
        },
      };
    }

    case ActionTypes.HANDLE_APPLY_LINK_DISCOUNT_SUCCESS: {
      return {
        ...state,
        subtotal: state.totalAmount,
        form: {
          ...state.form,
          isLinkDiscountApplied: true,
          linkDiscountAmountOff: Number(action.response?.data?.amountOff),
        },
      };
    }

    case ActionTypes.HANDLE_APPLY_LINK_DISCOUNT_FAILURE: {
      return {
        ...state,
        form: {
          ...state.form,
          errors: {
            ...state.form.errors,
            ...action.errors,
          },
        },
      };
    }

    case ActionTypes.HANDLE_REMOVE_LINK_DISCOUNT: {
      return {
        ...state,
        subtotal: '',
        form: {
          ...state.form,
          linkDiscountCode: '',
          linkDiscountAmountOff: '',
          isLinkDiscountApplied: false,
        },
      };
    }

    case ActionTypes.HANDLE_UPDATE_LINK_DISCOUNT_DETAILS: {
      return {
        ...state,
        subtotal: action.basePrice,
        form: {
          ...state.form,
          linkDiscountAmountOff: action.linkDiscountAmount,
          isLinkDiscountApplied: Boolean(action.linkDiscountAmount),
        },
      };
    }

    case ActionTypes.HANDLE_REMOVE_DISCOUNT_SUCCESS: {
      return reducedState(state, {
        discountAmount: 0,
        discountCode: '',
        isDiscountBeingEdited: false,
        isDiscountApplied: false,
      });
    }

    case ActionTypes.HANDLE_SUBMIT_INITIALIZE_PURCHASE_REQUEST: {
      return {
        ...state,
        isFormServerValidated: false,
      };
    }

    case ActionTypes.HANDLE_SUBMIT_INITIALIZE_PURCHASE_SUCCESS: {
      const { claimUri } = action.response.details;
      return deepmerge(state, {
        form: {
          claimUrl: claimUri,
        },
        isFormServerValidated: true,
      });
    }

    case ActionTypes.HANDLE_SUBMIT_INITIALIZE_PURCHASE_FAILURE: {
      const { errors } = action;
      return {
        ...state,
        form: {
          ...state.form,
          errors: {
            ...state.form.errors,
            ...errors,
          },
        },
        isFormServerValidated: false,
      };
    }

    case CartActionTypes.HANDLE_ADD_TO_CART_FAILURE: {
      const { errors } = action;

      // fix for PWYW errors from offer page
      const { totalAmount } = errors;
      if (totalAmount && totalAmount.length) {
        errors.amount = [...errors.totalAmount];
        delete errors.totalAmount;
      }

      return deepmerge(state, {
        form: {
          errors,
        },
      });
    }

    case ActionTypes.HANDLE_SET_TOTAL_AMOUNT: {
      const { totalAmount } = action.payload;

      return {
        ...state,
        totalAmount: totalAmount && totalAmount > 0 ? totalAmount : 0,
      };
    }

    case InstapayActionTypes.HANDLE_SUBMIT_INSTAPAY_SUCCESS: {
      return {
        ...state,
        totalAmount: action.payload.amount,
        form: {
          ...state.form,
          amount: action.payload.amount,
        },
        offer: {
          ...state.offer,
          title: action.payload.purpose,
        },
      };
    }

    default:
      return state;
  }
}

export default initializePurchase;

const getOfferInitialState = (state) => {
  const { show_additional_info_tab: hasAdditionalInfoTab, signature, affiliate } = state;

  const {
    discount_isapplicable: isDiscountApplicable,
    variant_categories: variantCategories,
    base_price: basePrice,
    min_price: minPrice,
    price_boost: priceBoost,
    max_quantity: maxQuantity,
    prefilled,
    slug,
  } = state.offer;

  const { discount_code: discountCode, discount_amount: discountAmount } = state.discount;

  const prefilledProps = mapPayloadForClient(prefilled);
  let initialPropsForInitialState = {};
  initialPropsForInitialState = Object.keys(prefilledProps).reduce((o, p) => {
    o[p] = prefilledProps[p].value;
    return o;
  }, {});

  const notCustomFieldProps = ['name', 'email', 'phone', 'amount'];
  const prefilledHiddenCustomFields = Object.keys(prefilledProps)
    .filter((prefilledProp) => notCustomFieldProps.indexOf(prefilledProp) === -1)
    .reduce((o, p) => {
      if (prefilledProps[p].hidden) {
        o[p] = prefilledProps[p].value;
      }
      return o;
    }, {});

  let readonlyProps = {};
  readonlyProps = Object.keys(prefilledProps).reduce((o, p) => {
    if (prefilledProps[p].readonly) {
      o[p] = prefilledProps[p].readonly;
    }
    return o;
  }, {});

  let isDiscountApplied = false;
  let isDiscountBeingEdited = false;
  let isDiscountEditable = true;

  if (discountCode && isDiscountApplicable) {
    isDiscountApplied = true;
    isDiscountBeingEdited = true;
    isDiscountEditable = false;
  }

  let variableFormFields = {};
  if (variantCategories && variantCategories.length) {
    // corresponding to a particular category, you have multiple variant options
    const variantFormFields = variantCategories.reduce(
      (variantFormFieldsObject, variantCategory) => {
        variantFormFieldsObject[getVariantCategoryName(variantCategory.id)] = '';
        return variantFormFieldsObject;
      },
      {},
    );

    variableFormFields = {
      ...variableFormFields,
      ...variantFormFields,
    };
  }

  return {
    isLinkPayment: false,
    totalAmount: getInitialStateTotalAmount(),
    offer: {
      basePrice,
      minPrice,
      variantCategories,
      priceBoost,
      hasAdditionalInfoTab,
      maxQuantity,
      variantAmountMap: getVariantAmountMap(),
      slug,
    },
    form: {
      name: '',
      email: '',
      phone: '',
      isQuantityApplicable: !!maxQuantity,
      quantity: maxQuantity ? 1 : null,
      isClaimFreeChecked: false,
      amount: '',
      isAmountEditable: true,
      discountCode: discountCode ? discountCode : '',
      discountAmount,
      isDiscountApplicable,
      isDiscountApplied,
      isDiscountBeingEdited,
      isDiscountEditable,
      priceBoostAmount: '',
      isDiscountRemovable: true, //make a provision to delete the capability of removing discount codes once applied
      claimUrl: null,
      errors: {},
      readOnly: readonlyProps,
      ...variableFormFields,
      ...initialPropsForInitialState,
    },
    isFormServerValidated: false,
    prefilledHiddenCustomFields,
    signature,
    affiliate,
  };
};

const getLinkInitialState = (state) => {
  const {
    link: {
      amount,
      title,
      line_items: lineItems,
      has_discount_code: isLinkDiscountApplicable,
    },
  } = state;

  return {
    isLinkPayment: true,
    totalAmount: amount,
    subtotal: '',
    offer: {
      basePrice: amount,
      minPrice: '',
      priceBoost: state.link.amount_editable,
      hasAdditionalInfoTab: false,
      //Currently setting hasSmartPricing to the state where line_items is present and the children length > 0
      hasSmartPricing:
        lineItems && lineItems.length && lineItems[0].children.length ? true : false,
      title,
    },
    form: {
      name: '',
      email: '',
      phone: '',
      isQuantityApplicable: false,
      quantity: null,
      isClaimFreeChecked: false,
      amount: '',
      isAmountEditable: true,
      priceBoostAmount: '',
      minimumPriceBoostAmount: state.link.minimum_amount || '',
      isPriceBoostApplied: false,
      errors: {},
      readOnly: {},
      smartPricingLineItems: [],
      linkDiscountCode: '',
      isLinkDiscountApplicable,
      isLinkDiscountApplied: false,
      linkDiscountAmountOff: '',
    },
    customFields: getCustomFields(),
    isFormServerValidated: false,
  };
};

const getInstapayInitialState = (state) => {
  const {
    prefilled: { amount, purpose } = {},
    buyer: { email = '', name = '', phone = '' } = {},
    seller: { username },
    is_rap: isRAP,
  } = state;

  let readOnly = {};
  if (email) {
    readOnly = { ...readOnly, email: true };
  }
  if (name) {
    readOnly = { ...readOnly, name: true };
  }
  if (phone) {
    readOnly = { ...readOnly, phone: true };
  }

  return {
    isInstapayPayment: true,
    isRAP,
    totalAmount: amount,
    shippingCharges: '',
    offer: {
      basePrice: amount,
      title: purpose,
      sellerUsername: username,
    },
    form: {
      name,
      email,
      phone,
      errors: {},
      readOnly,
    },
  };
};

const getInitialState = (state) => {
  return state.is_link_payment
    ? getLinkInitialState(state)
    : state.is_instapay_payment
      ? getInstapayInitialState(state)
      : state.offer
        ? getOfferInitialState(state)
        : {};
};
