import root, { location } from 'window-or-global';

import { MARK_USER_LOGGED_OUT } from '../actions/GlobalActions';
import {
  DISABLE_ACTION_ITEMS,
  DISABLE_PAGE_STATE_ASYNC,
  ENABLE_ACTION_ITEMS,
  ENABLE_PAGE_STATE_ASYNC,
  MARK_PAGE_FORBIDDEN,
  MARK_PAGE_FOUND,
  MARK_PAGE_NOT_FOUND,
  MARK_REQUEST_TIMEOUT,
} from '../actions/PageStateActions';
import { camelCaseKeys } from '../config/utils';
import { API_TIMEOUT } from '../config/variables';
import { isDashboardProduction } from '../utils/domainResolution';

const urlTimeoutId = {};

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
function callApi(endpoint) {
  return fetch(endpoint, {
    credentials: 'same-origin',
  })
    .then((response) => {
      if (urlTimeoutId[endpoint]) {
        clearTimeout(urlTimeoutId[endpoint]);
        delete urlTimeoutId[endpoint];
      }
      return response
        .json()
        .then((json) => ({ json, response }))
        .catch(() => {
          if (response.ok) {
            return Promise.resolve({ response });
          }
          return Promise.reject({
            status: response.status,
          });
        });
    })
    .then(({ json, response }) => {
      if (Array.isArray(json)) {
        json = {
          data: json,
        };
      } else if (typeof json !== 'object') {
        json = {};
      }
      json.isServerOK = !!response.ok;
      const camelizedJson = camelCaseKeys(json);
      if (!camelizedJson.isServerOK) {
        return Promise.reject({
          status: response.status,
          ...camelizedJson,
        });
      }
      return { ...camelizedJson };
    });
}

function callApiWithTimeout({ endpoint, timeout }) {
  return Promise.race([
    callApi(endpoint),
    new Promise((resolve, reject) => {
      const id = setTimeout(() => {
        reject({
          status: 500,
          message: 'Request timed out',
        });
      }, timeout);
      urlTimeoutId[endpoint] = id;
    }),
  ]);
}

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol('Call API');

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default (store) => (next) => (action) => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  let { endpoint } = callAPI;
  const { types, bailout } = callAPI;

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState());
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }

  if (!Array.isArray(types) || types.length < 3) {
    throw new Error('Expected an array of atleast three action types.');
  }

  if (!Array.isArray(types) || types.length > 4) {
    throw new Error('Expected an array of three or four action types.');
  }

  if (!types.every((type) => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  // Skips the request if the bailout is true
  // bailout can either be a boolean or a function(state, dispatch) returning boolean
  if (
    (typeof bailout === 'boolean' && bailout) ||
    (typeof bailout === 'function' &&
      bailout(store.getState(), { endpoint }, store.dispatch))
  ) {
    return Promise.resolve('Bailing out');
  }

  const [requestType, successType, failureType, serviceUnavailableType] = types;

  function actionWith(data) {
    const finalAction = { ...action, ...data };
    delete finalAction[CALL_API];
    return finalAction;
  }

  function disableActionables() {
    next(actionWith({ type: ENABLE_PAGE_STATE_ASYNC }));
    next(actionWith({ type: DISABLE_ACTION_ITEMS }));
  }

  function enableActionables() {
    next(actionWith({ type: ENABLE_ACTION_ITEMS }));
    next(actionWith({ type: DISABLE_PAGE_STATE_ASYNC }));
  }

  function successHandler(response) {
    const { blocking = true } = callAPI;
    if (blocking) {
      enableActionables();
    }
    next(actionWith({ type: MARK_PAGE_FOUND }));
    if (callAPI.successTypeActionProps) {
      next(
        actionWith({ response, ...callAPI.successTypeActionProps, type: successType }),
      );
    } else {
      next(actionWith({ response, type: successType }));
    }
    const { onSuccess } = callAPI;
    if (onSuccess) {
      if (typeof onSuccess !== 'function') {
        throw new Error('Success Callback should be a function');
      }
      onSuccess(response, store.getState(), store.dispatch);
    }
  }

  function handleCallApi() {
    const { blocking = true } = callAPI;
    if (blocking) {
      disableActionables();
    }
    if (endpoint.indexOf('/webapi/') >= 0) {
      return callApiWithTimeout({ endpoint, timeout: API_TIMEOUT }).then(
        successHandler,
        failureHandler,
      );
    }
    return callApi(endpoint).then(successHandler, failureHandler);
  }

  function failureHandler(response) {
    const { blocking = true, isPageBlocking = true } = callAPI;
    if (blocking) {
      enableActionables();
    }

    response.status = response.status || 0;

    switch (response.status) {
      case 401:
        if (root.MojoUser && root.MojoUser.id) {
          // 401 UnAuthorized or 403 forbidden redirects to be done only for logged in pages
          if (`${location.origin}`.includes('developers') || isDashboardProduction(window.location.host)) {
            window.location.href = `${
              location.origin
            }/sso/login/?next=${encodeURIComponent(window.location.pathname)}`;
          } else {
            window.location.href = `${
              location.origin
            }/accounts/login/?next=${encodeURIComponent(window.location.pathname)}`;
          }
          return false;
        }
        next(actionWith({ type: MARK_USER_LOGGED_OUT }));
        break;
      case 403:
        if (response.redirectLocation) {
          location.href = response.redirectLocation;
          break;
        }
        isPageBlocking && next(actionWith({ type: MARK_PAGE_FORBIDDEN }));
        break;
      case 404:
        isPageBlocking && !next(actionWith({ type: MARK_PAGE_NOT_FOUND }));
        break;
      case 500:
        isPageBlocking && next(actionWith({ type: MARK_REQUEST_TIMEOUT }));
        break;
      // Below cases are to handle 503 and 504 status only if service unavailable constant is defined.
      case 503:
        serviceUnavailableType && next(actionWith({ type: serviceUnavailableType }));
        break;
      case 504:
        serviceUnavailableType && next(actionWith({ type: serviceUnavailableType }));
        break;
      case 0:
    }

    next(
      actionWith({
        type: failureType,
        error: response.message || 'Something bad happened',
      }),
    );

    const { onFailure } = callAPI;

    if (onFailure) {
      if (typeof onFailure !== 'function') {
        throw new Error('Failure Callback should be a function');
      }
      onFailure(response, store.getState(), store.dispatch);
    }
  }

  let requestTypeActionData = { type: requestType };

  if (callAPI.requestTypeActionProps) {
    requestTypeActionData = {
      ...requestTypeActionData,
      ...callAPI.requestTypeActionProps,
    };
  }
  next(actionWith(requestTypeActionData));

  //validate the get params
  const { validator } = callAPI;
  let { toValidate } = callAPI;
  if (typeof validator === 'function' && typeof toValidate !== 'function') {
    throw new Error(
      'Expected toValidate to be a function since you supplied a validator method',
    );
  }

  if (!validator) {
    return handleCallApi();
  } else {
    if (typeof toValidate === 'function') {
      toValidate = toValidate(store.getState());
    }
    return validator.validate(toValidate).then((result) => {
      if (result.valid) {
        return handleCallApi();
      }

      let failureTypeActionData = {
        type: failureType,
        errors: result.errors,
      };

      if (callAPI.failureTypeActionProps) {
        failureTypeActionData = {
          ...failureTypeActionData,
          ...callAPI.failureTypeActionProps,
        };
      }
      return Promise.resolve(next(actionWith(failureTypeActionData)));
    });
  }
};
