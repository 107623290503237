import LGTM from 'lgtm';

export const unbuiltInitializeFormValidator = () =>
  LGTM.validator()
    .validates('name')
    .required('Enter your name')
    .validates('email')
    .required('Enter your email ID')
    .email('Enter a valid email ID', { strictCharacters: true })
    .validates('phone')
    .required('Enter your mobile number')
    .using((phone) => /^[0-9 ()+/-]{10,}$/.test(phone), 'Enter a valid phone number');
