import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { contentWrapperStyles } from '../../../../common/components/Modal';
import { checkFormErrors } from '../../utils/PaymentUtils';

const WrappedContent = ({
  initializePurchaseFormErrors,
  refinePurchaseFormErrors,
  finalizePurchaseFormErrors,
  settings: {
    isInitializeFormOpen,
    isRefineFormOpen,
    isFinalizeFormOpen,
    isEmbed,
    isIFrame,
    isPaymentResponse,
    isRetrying,
    responseType,
  },
  children,
}) => {
  const hasFormErrors =
    (isInitializeFormOpen && checkFormErrors(initializePurchaseFormErrors)) ||
    (isRefineFormOpen && checkFormErrors(refinePurchaseFormErrors)) ||
    (isFinalizeFormOpen && checkFormErrors(finalizePurchaseFormErrors));

  const embedPaymentFormStyles = { width: 'auto', minWidth: 320, maxWidth: 440 };

  return (
    <div
      className={classNames(
        'payments-container',
        hasFormErrors ? 'shake' : null,
        isEmbed && !isIFrame ? null : 'is--embed-iframe',
      )}
      style={
        isEmbed && !isIFrame
          ? embedPaymentFormStyles
          : isEmbed && isIFrame
          ? {}
          : isPaymentResponse
          ? contentWrapperStyles
          : {
              ...contentWrapperStyles,
              background:
                isRetrying || !(responseType === 'product' || responseType === 'cart')
                  ? 'none'
                  : '#F1F4F6',
            }
      }
    >
      {children}
    </div>
  );
};

WrappedContent.propTypes = {
  settings: PropTypes.object,
  initializePurchaseFormErrors: PropTypes.object,
  refinePurchaseFormErrors: PropTypes.object,
  finalizePurchaseFormErrors: PropTypes.object,
  children: PropTypes.node,
};

export default WrappedContent;
