export const DISABLE_ACTION_ITEMS = 'DISABLE_ACTION_ITEMS';
export const ENABLE_ACTION_ITEMS = 'ENABLE_ACTION_ITEMS';
export const ENABLE_PAGE_STATE_ASYNC = 'ENABLE_PAGE_STATE_ASYNC';
export const DISABLE_PAGE_STATE_ASYNC = 'DISABLE_PAGE_STATE_ASYNC';
export const MARK_PAGE_NOT_FOUND = 'MARK_PAGE_NOT_FOUND';
export const MARK_PAGE_FORBIDDEN = 'MARK_PAGE_FORBIDDEN';
export const MARK_PAGE_NOT_FORBIDDEN = 'MARK_PAGE_NOT_FORBIDDEN';
export const MARK_PAGE_FOUND = 'MARK_PAGE_FOUND';
export const SCROLL_TO_FIRST_ERROR = 'SCROLL_TO_FIRST_ERROR';
export const UNMARK_SCROLL_TO_FIRST_ERROR_FLAG = 'UNMARK_SCROLL_TO_FIRST_ERROR_FLAG';
export const MARK_APP_OFFLINE = 'MARK_APP_OFFLINE';
export const MARK_APP_ONLINE = 'MARK_APP_ONLINE';
export const MARK_CONTENT_OFFLINE = 'MARK_CONTENT_OFFLINE';
export const MARK_CONTENT_ONLINE = 'MARK_CONTENT_ONLINE';
export const MARK_REQUEST_TIMEOUT = 'MARK_REQUEST_TIMEOUT';
export const MARK_REQUEST_SUCCESSFUL = 'MARK_REQUEST_SUCCESSFUL';
export const TOGGLE_CONTENT_AREA_BACKGROUND = 'HIDE_AREA_CONTENT';
export const MARK_SERVICE_UNAVAILABLE = 'MARK_SERVICE_UNAVAILABLE';
export const TOGGLE_PAGE_HEADER_FIXED = 'TOGGLE_PAGE_HEADER_FIXED';

export function markPageNotFound(isPageNotFound) {
  return {
    type: isPageNotFound ? MARK_PAGE_NOT_FOUND : MARK_PAGE_FOUND,
  };
}

export function markPageForbidden(isPageForbidden) {
  return {
    type: isPageForbidden ? MARK_PAGE_FORBIDDEN : MARK_PAGE_NOT_FORBIDDEN,
  };
}

export function markPageServiceNotAvailable() {
  return {
    type: MARK_SERVICE_UNAVAILABLE,
  };
}

export function unmarkScrollToFirstErrorFlag() {
  return {
    type: UNMARK_SCROLL_TO_FIRST_ERROR_FLAG,
  };
}

export function markContentOffline(isOffline) {
  return {
    type: isOffline ? MARK_CONTENT_OFFLINE : MARK_CONTENT_ONLINE,
  };
}

export function markRequestTimeout(isRequestTimeout) {
  return {
    type: isRequestTimeout ? MARK_REQUEST_TIMEOUT : MARK_REQUEST_SUCCESSFUL,
  };
}

export function toggleContentAreaBackground(status) {
  return {
    type: TOGGLE_CONTENT_AREA_BACKGROUND,
    status,
  };
}

export function togglePageHeaderFixed(status) {
  return {
    type: TOGGLE_PAGE_HEADER_FIXED,
    status,
  };
}
