import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import root from 'window-or-global';

import Loading from '../../../../common/components/Loading';
import ia from '../../../../common/config/Analytics';
import { getFormattedDateWithTime } from '../../../../common/config/utils';
import {
  handleShowPanicFaq,
  retryFailedPayment,
} from '../../actions/PostPurchaseActions';

const FailureResponse = ({
  payment,
  retryFailedPayment: onRetryFailedPayment,
  handleShowPanicFaq: onShowPanicFaq,
  isRetryEnabled,
  hasFailureRedirection,
  showPanicFaq,
}) => (
  <div className="font-rc">
    <div className="center">
      {root.redirectUrl && root.redirectionDelay ? (
        <Loading className="pace-loading huge slow" />
      ) : null}
      <h3 className="center text-red soft-half--top font-rc">Payment Failed!</h3>
      {root.redirectUrl && root.redirectionDelay ? (
        <p className="text-bold"> Please wait while we redirect you... </p>
      ) : null}
    </div>
    <hr style={{ margin: '10px 0' }} />
    {showPanicFaq ? (
      <div className="text-small soft-half">
        <p className="text-bold">Money was debited from my account.</p>
        <p className="text-bold soft--top soft-half--bottom">Where is my money?</p>
        <p>
          Your money will be automatically refunded back to the same payment method you
          used to make this payment in 5-7 working days.
        </p>
        <p className="text-bold soft--top soft-half--bottom">
          Whom should I contact if I don’t get the refund?
        </p>
        <p>
          If you used Debit card, Credit cards, Net banking or UPI please contact your
          bank. If you used Wallets or any other payment service, please contact their
          customer care.
        </p>
        <p className="text-center push--top soft-double--sides">
          You can retry this payment using another payment method.
        </p>
      </div>
    ) : null}
    {isRetryEnabled ? (
      <div>
        <button
          className="btn-green btn-block"
          type="button"
          onClick={() => {
            onRetryFailedPayment();
            ia.sendEvent('Retried Payment', {
              buyer_email: payment.buyer.email,
              amount: payment.link.amount,
              purpose: payment.link.title,
              payment_id: payment.paymentId,
              seller_username: payment.seller.username,
              order_id: payment.orderId,
            });
          }}
        >
          Retry Payment
        </button>
        {hasFailureRedirection ? (
          <div className="text-center push--top push-half--bottom">
            <span>or</span>
            <a className="push-half--left caps" onClick={root.processCancel}>
              Cancel
            </a>
          </div>
        ) : null}
      </div>
    ) : null}
    <button
      className={`btn push--top btn-block ${showPanicFaq ? 'hide' : ''}`}
      type="button"
      style={{ textTransform: 'none', fontWeight: 'unset' }}
      onClick={() => {
        onShowPanicFaq();
        ia.sendEvent('Clicked on Money Debited Message', {
          buyer_email: payment.buyer.email,
          amount: payment.link.amount,
          purpose: payment.link.title,
          payment_id: payment.paymentId,
          seller_username: payment.seller.username,
          order_id: payment.orderId,
        });
      }}
    >
      Money was debited from my account
    </button>
    <hr style={{ margin: '10px 0' }} />
    <table
      className="table-compact table-vertical-center table-colorless text-medium soft-half--sides"
      style={{ width: '100%' }}
    >
      <tbody>
        <tr>
          <td className="text-bold">Payment ID</td>
          <td className="float--right">{payment.paymentId}</td>
        </tr>
        <tr>
          <td className="text-bold">Time</td>
          <td className="float--right">{getFormattedDateWithTime(new Date())}</td>
        </tr>
      </tbody>
    </table>
    <hr style={{ margin: '10px 0' }} />
    <p className="text-bold text-medium soft-half--sides">Why did it fail?</p>
    <ol className="soft--left push-half--left soft--bottom text-medium">
      <li>Incorrect CVV or Expiry date.</li>
      <li>Network problems with your payment method.</li>
    </ol>
  </div>
);

FailureResponse.propTypes = {
  payment: PropTypes.object,
  retryFailedPayment: PropTypes.func,
  handleShowPanicFaq: PropTypes.func,
  isRetryEnabled: PropTypes.bool,
  hasFailureRedirection: PropTypes.bool,
  showPanicFaq: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

const mapStateToProps = ({ settings, postPurchase }) => ({
  isRetryEnabled: settings.isRetryEnabled,
  hasFailureRedirection: settings.hasFailureRedirection,
  showPanicFaq: postPurchase.showPanicFaq,
});
export default connect(mapStateToProps, {
  retryFailedPayment,
  handleShowPanicFaq,
})(FailureResponse);
