import Rupee, { rupees } from '@instamojo/rupee';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import root from 'window-or-global';

import Loading from '../../../../common/components/Loading';
import {
  handleBankPaymentRequest,
  handlePayLaterPaymentRequest,
  handleResetQrConvenienceFeeDetails,
  handleResetUPIPaymentOption,
  handleSubmitUPI,
  handleSubmitUPIIntent,
  handleUpdateConvenienceFeeObject,
  handleWalletPaymentRequest,
  toggleUPIIntentWaitScreen,
} from '../../actions/FinalizePurchaseActions';

class ConvenienceFees extends React.Component {
  handleProcessPaymentClick() {
    const {
      selectedPaymentObject: selectedPaymentOption,
      order,
      orderToken,
      finalIntentUri,
      upiIntentAppCalled,
    } = this.props.finalizePurchase;

    // The selectedPaymentOption object will not be populated in the case of UPI Intent/QR.
    // We will not arrive at this component in case of QR.
    const type = selectedPaymentOption?.type || 'dynamic_qr';
    switch (type) {
      case 'nb': {
        this.props.handleBankPaymentRequest();
        return;
      }

      case 'wallet': {
        this.props.handleWalletPaymentRequest();
        return;
      }

      case 'paylater': {
        this.props.handlePayLaterPaymentRequest();
        return;
      }

      case 'dynamic_qr': {
        // This is called to close the convenience fee form so that user lands on the payment options
        // screen in case of force close or failures.
        this.props.handleResetQrConvenienceFeeDetails();
        this.props.toggleUPIIntentWaitScreen(true, upiIntentAppCalled);
        this.props.handleSubmitUPIIntent(order, orderToken);
        root.open(finalIntentUri);
        return;
      }

      case 'dynamic_qr_initiate': {
        // This is called to close the convenience fee form so that user lands on the payment options
        // screen in case of force close or failures.
        this.props.handleResetQrConvenienceFeeDetails();
        this.props.toggleUPIIntentWaitScreen(true, upiIntentAppCalled);
        this.props.handleSubmitUPIIntent(order, orderToken);
        root.open(finalIntentUri);
        return;
      }

      case 'upi': {
        this.props.handleSubmitUPI({
          paymentOption: this.props.finalizePurchase.selectedPaymentObject,
        });
        return;
      }
    }
  }

  render() {
    const {
      finalizePurchase: {
        selectedPaymentObject: {
          type,
          convenienceFeeObject: {
            isFetching,
            isFetched,
            convenienceFees,
            convenienceFeesTax,
            amount,
            totalAmount,
            showConvenienceFeeForm,
          } = {},
        } = {},
        dynamicQrDetails: {
          isFetching: isFetchingUpiIntent,
          isFetched: isFetchedUpiIntent,
          amount: upiIntentAmount,
          convenienceFees: upiIntentConvenienceFees,
          convenienceFeesTax: upiIntentConvenienceFeesTax,
          convenienceFeesVersion: upiIntentConvenienceFeesVersion,
          totalAmount: upiIntentTotalAmount,
          showConvenienceFeeForm: showUpiIntentConvenienceFeeForm,
        } = {},
        paymentOptions: {
          dynamic_qr_qr: {
            type: dynamic_qr_type,
            name: dynamic_qr_name,
            isUpiIntentEnabled,
          } = {},
        } = {},
        upiOptions,
      },
    } = this.props;
    // We are checking showConvenienceFeeForm to ensure other payment object isn't selected in case of web
    // (QR convenience fee details are fetched on display of QR)
    const isUpiIntent = !showConvenienceFeeForm && upiIntentConvenienceFeesVersion === 'v2';
    const showLoader = showUpiIntentConvenienceFeeForm ? (isFetchingUpiIntent || !isFetchedUpiIntent) : (isFetching || !isFetched);
    // If we land here with a UPI type and with UPI Intent enabled, we will have to handle the reset (back button) behaviour
    const resetUpiPaymentOption = type === 'upi' && isUpiIntentEnabled;
    // We want to show the payment options screen when UPI (collectpay) is used from UPI Intent menu (outside)
    // and the user clicks back
    // We want to show dynamic QR as a selected payment mode when UPI (collectpay) is used from UPI Intent menu (inside)
    // and the user clicks back
    const resetToPaymentMode = upiOptions?.upiIntentPosition === 'outside' ? {} : { type: dynamic_qr_type, name: dynamic_qr_name };

    const convenienceFeeData = isUpiIntent ?
    {
      amount: upiIntentAmount,
      convenienceFees: upiIntentConvenienceFees,
      convenienceFeesTax: upiIntentConvenienceFeesTax,
      totalAmount: upiIntentTotalAmount,
    } : {
      amount: amount,
      convenienceFees: convenienceFees,
      convenienceFeesTax: convenienceFeesTax,
      totalAmount: totalAmount,
    };

    // We are handling UPI Intent separate from the other instruments here. This is because convenience fee
    // details are fetched and stored in a different object for UPI Intent/QR.
    return (
      <div>
        {showLoader ? (
          <Loading className="big" style={{ margin: '150px auto' }} />
        ) : (
          <div className="soft--top">
            <div
              style={{
                borderBottom: '1px solid rgba(227, 236, 242, 0.5)',
              }}
            >
              <div>Price Details</div>
              <p className="soft--ends text-medium-grey micro">
                Convenience fee has been added to your final payment amount
              </p>
            </div>
            <div className="soft--ends">
              <p>
                <span>Amount</span>
                <span className="right">
                  <Rupee value={rupees(convenienceFeeData.amount)} />
                </span>
              </p>
              <p className="soft-half--top">
                <span>Convenience Fee</span>
                <span className="right">
                  <Rupee value={rupees(convenienceFeeData.convenienceFees)} />
                </span>
              </p>
              <p className="soft-half--top">
                <span>GST @ 18%</span>
                <span className="right">
                  <Rupee value={rupees(convenienceFeeData.convenienceFeesTax)} />
                </span>
              </p>
            </div>
            <div
              className="soft--ends"
              style={{
                borderTop: '1px solid rgba(227, 236, 242, 0.498039)',
              }}
            >
              <span>Total Amount</span>
              <span className="right">
                <Rupee value={rupees(convenienceFeeData.totalAmount)} />
              </span>
            </div>
            <div className="soft--bottom">
              <button
                className="btn-green btn-block"
                onClick={() => this.handleProcessPaymentClick()}
              >
                Pay <Rupee value={rupees(convenienceFeeData.totalAmount)} />
              </button>
            </div>
            <div className="soft--bottom">
              <button
                className="btn-link btn-block"
                onClick={() => {
                  if (isUpiIntent) {
                    // UPI Intent + conv. fee v2 = back button should show all payment options (outside)
                    // or UPI as selection payment option (inside).
                    this.props.handleResetQrConvenienceFeeDetails();
                  } else if (resetUpiPaymentOption) {
                    // UPI + conv. fee v2 = back button should show all payment options (outside)
                    // or UPI as selection payment option (inside).
                    this.props.handleResetUPIPaymentOption(resetToPaymentMode);
                  } else {
                    // default behaviour for other instruments.
                    this.props.handleUpdateConvenienceFeeObject({
                      showConvenienceFeeForm: false,
                    });
                  }
                }}
              >
                Back
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ConvenienceFees.propTypes = {
  finalizePurchase: PropTypes.object,
  handleBankPaymentRequest: PropTypes.func,
  handleWalletPaymentRequest: PropTypes.func,
  handlePayLaterPaymentRequest: PropTypes.func,
  handleUpdateConvenienceFeeObject: PropTypes.func,
  handleResetQrConvenienceFeeDetails: PropTypes.func,
  handleSubmitUPI: PropTypes.func,
  toggleUPIIntentWaitScreen: PropTypes.func,
  handleSubmitUPIIntent: PropTypes.func,
  handleResetUPIPaymentOption: PropTypes.func,
};

const mapStateToProps = ({ cart, settings, retry, finalizePurchase }) => {
  if (cart) {
    return {
      finalizePurchase: cart.finalizePurchase,
    };
  } else if (settings.isRetrying) {
    return {
      finalizePurchase: retry.finalizePurchase,
    };
  }

  return {
    finalizePurchase,
  };
};

export default connect(mapStateToProps, {
  handleBankPaymentRequest,
  handleWalletPaymentRequest,
  handlePayLaterPaymentRequest,
  handleUpdateConvenienceFeeObject,
  handleResetQrConvenienceFeeDetails,
  handleSubmitUPI,
  toggleUPIIntentWaitScreen,
  handleSubmitUPIIntent,
  handleResetUPIPaymentOption,
})(ConvenienceFees);
