/* globals STATIC_URL */
import { isAndroidDevice, isIOS_Device } from '@instamojo/agent-utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import root from 'window-or-global';

import {
  Form,
  FormFieldWithLabel as FormField,
  FormFieldWrapper as FormFieldsWrapper,
} from '../../../../../common/components/FormComponents';
import { Columns, Row } from '../../../../../common/components/Grid';
import Icon from '../../../../../common/components/Icon';
import Loading from '../../../../../common/components/Loading';
import MojoPopup from '../../../../../common/components/MojoPopup';
import ia from '../../../../../common/config/Analytics';
import {
  getUPIIntentStatusCheck,
  handleChangeUPIHandle,
  handleDynamicQrV2,
  handleFetchConvenienceFees,
  handleFetchConvenienceFeesForQR,
  handleSelectPaymentOption,
  handleShowUpiField,
  handleShowUpiLabelPopup,
  handleShowUpiPopup,
  handleSubmitUPI,
  handleSubmitUPIIntent,
  passIntentWithConvenienceFee,
  pollForUPIAcceptance,
  setUpiPsp,
  toggleUPIIntentWaitScreen,
  validateUPIHandle,
} from '../../../actions/FinalizePurchaseActions';
import {
  getUpiDisplayNameFromPsp,
  getUpiOptionFromPsp,
  UPIIntentList,
  UPIOptionToVerificationTextMap,
} from '../../../config/Upi';
import TnCText from '../TnCText';
import UPIIntentWaitScreen from '../UPIIntentWaitScreen';
import UpiLabelPopup from '../UpiLabelPopup';
import UpiPopup from '../UpiPopup';
import Autocomplete from './Autocomplete';
import ErrorMessageBlock from './ErrorMessageBlock';

class MobileUPIIntentV2 extends React.Component {
  componentDidMount() {
    root.document.activeElement.blur();
    this.props.handleDynamicQrV2(this.props.finalizePurchase.paymentOptions?.dynamic_qr_initiate_qr_init?.submissionUri, isIOS_Device ? 'ios' : isAndroidDevice ? 'android' : 'others');
  }
  upiPopupEvent = (ctaText) => {
    ia.sendEvent('Clicked Info Popup', {
      popup_cta: ctaText,
      popup_source: 'payment_form',
    });
  };

  renderUPIIntentList() {
    const {
      dynamicQrDetailsV2: { dynamicQrUri } = {},
    } = this.props.finalizePurchase;
    return UPIIntentList.map((UPIIntentItem, index) => {
      const { androidPrefix, postfix, iosPrefix, value } = UPIIntentItem;
      const finalPrefix = isAndroidDevice ? androidPrefix : iosPrefix;
      const finalUri = `${finalPrefix}${dynamicQrUri?.substring(6)}${postfix}`;
      if (value !== 'others') {
        return (
          <Columns className="small-6 grid__item end" key={index}>
            <div
              className={classNames('grid__item--unit', {
                'bank-selected': false,
              })}
              onClick={() => {
                ia.sendEvent('Selected UPI Bank', {
                  bank_name: UPIIntentItem.value,
                  seller_username: this.props.username,
                  order_id: this.props.finalizePurchase.order,
                  action: 'open_upi_app',
                });
                if (
                  this.props.showConvenienceFee &&
                  this.props.finalizePurchase.convenienceFeeVersion === 'v2'
                ) {
                  this.props.passIntentWithConvenienceFee(finalUri, UPIIntentItem.appName);
                  this.props.handleFetchConvenienceFeesForQR(true);
                } else {
                  this.props.handleSubmitUPIIntent(
                    this.props.finalizePurchase.order,
                    this.props.finalizePurchase.orderToken,
                  );
                  this.props.toggleUPIIntentWaitScreen(true, UPIIntentItem.appName);
                  root.open(finalUri);
                }
              }}
            >
              <div style={{ height: '40px', display: 'table', width: '100%' }}>
                <div
                  style={{
                    display: 'table-cell',
                    verticalAlign: 'middle',
                    textAlign: 'center',
                  }}
                >
                  <span style={{ verticalAlign: 'middle' }}>
                    {this.renderUPIIntentItem(UPIIntentItem, index)}
                  </span>
                </div>
              </div>
            </div>
          </Columns>
        );
      }
    });
  }

  renderOthersForAndroid() {
    const {
      dynamicQrDetailsV2: { dynamicQrUri } = {},
    } = this.props.finalizePurchase;
    const finalUri = `upi://${dynamicQrUri?.substring(6)}`;
    return (
      <div
        onClick={() => {
          ia.sendEvent('Selected UPI Bank', {
            bank_name: 'others',
            seller_username: this.props.username,
            order_id: this.props.finalizePurchase.order,
            action: 'open_upi_app',
          });
          if (
            this.props.showConvenienceFee &&
            this.props.finalizePurchase.convenienceFeeVersion === 'v2'
          ) {
            this.props.passIntentWithConvenienceFee(finalUri, 'your UPI');
            this.props.handleFetchConvenienceFeesForQR(true);
          } else {
            this.props.handleSubmitUPIIntent(
              this.props.finalizePurchase.order,
              this.props.finalizePurchase.orderToken,
            );
            this.props.toggleUPIIntentWaitScreen(true, 'your UPI');
            root.open(finalUri);
          }
        }}
      >
        <div className="btn btn-block">
          Pay by any UPI App
        </div>
      </div>
    );
  }

  renderPostfix = (postfix, value) => {
    const { upiHandleError } = this.props.finalizePurchase;
    return (
      <FormField errors={upiHandleError}>
        <select
          value={value || 'disabled-placeholder'}
          onChange={(e) => this.props.setUpiPsp(e.target.value)}
          style={{
            border: 'none',
            backgroundColor: '#FAFBFD',
            color: '#93A0AF',
            backgroundSize: '12px',
            fontSize: 12,
          }}
        >
          {/*
              Set these default values according to the design parameters,
              Keys are set to random constants to prevent mismatch
              The ----- is for seperation as per design and @upiHandle is the default upi handle
            */}
          <option value="disabled-placeholder" disabled key={-9999}>
            @UPI handle
          </option>
          <option value="others" key={-9998}>
            Others
          </option>
          <option value="disabled-break" disabled key={-9997}>
            ------------
          </option>
          {postfix.map((postfixValue, postfixIndex) => {
            const displayName = getUpiDisplayNameFromPsp(postfixValue);
            return (
              <option value={postfixValue} key={postfixIndex}>
                {postfixValue}&nbsp;&nbsp;&nbsp;{`(${displayName})`}
              </option>
            );
          })}
        </select>
      </FormField>
    );
  };

  renderUPIIntentItem = (UPIIntentItem, UPIIntentIndex) => {
    // Show either Image or Icon
    if (UPIIntentItem.imageUrl) {
      return (
        <div key={UPIIntentIndex}>
          <img
            src={UPIIntentItem.imageUrl}
            alt={UPIIntentItem.displayName}
            width="80px"
          />
          {UPIIntentItem.displayName ? <span>{UPIIntentItem.displayName}</span> : null}
        </div>
      );
    } else if (UPIIntentItem.iconClassName) {
      return (
        <div key={UPIIntentIndex}>
          <Icon className={UPIIntentItem.iconClassName} />
          {UPIIntentItem.displayName ? <span>{UPIIntentItem.displayName}</span> : null}
        </div>
      );
    } else {
      return (
        <div key={UPIIntentIndex}>
          <span>{UPIIntentItem.displayName}</span>
        </div>
      );
    }
  };

  render() {
    const {
      isUPIRequestSubmitted,
      isUPIRequestSubmitting,
      upiHandleError,
      selectedUPIOption = '',
      selectedUpiPSP,
      showUpiPopup,
      showUpiLabelPopup,
      showUPIList,
      convenienceFeeVersion,
      isUpiIntentPolling,
      upiIntentAppCalled,
      dynamicQrDetailsV2 = {},
      isUpiFieldShown,
      formHtmlText,
      isUpiPopupVisible = false,
    } = this.props?.finalizePurchase;
    const { upiIntentPositionOutside } = this.props;

    // Here we are hardcoding the name and type as we know the instrument.
    // NOTE: This has to be updated if there is a backend change.
    // This update is required for many other instances across the system.
    // The selectedPaymentOption can be empty when the UPI/UPI Intent option is displayed outside - along with other instruments.
    const UPIPaymentOption = { name: 'UPI', type: 'upi' };
    const isUPIEnabled = !!(this.props.finalizePurchase.paymentOptions?.upi_upi?.submissionUri);
    const isDynamicQrFetching = this.props.finalizePurchase?.isDynamicQrFetching;
    const isErrorInDynamicQr = this.props.finalizePurchase?.errors?.isDynamicQrV2;

    return (
      <div>
        {showUPIList ? (
          <>
            {isDynamicQrFetching ?
              <Loading /> : (
              <div>
                {this.props.finalizePurchase?.isDynamicQrFetched && !isErrorInDynamicQr ? (
                  <>
                    {isAndroidDevice && this.renderOthersForAndroid()}
                    <Row className="banks--grid soft--sides">
                      <p className="text-grey" style={{ fontSize: '14px' }}>
                        Select your UPI app
                      </p>
                      {this.renderUPIIntentList()}
                    </Row>
                  </>
                ) : !isUPIEnabled && (
                  <ErrorMessageBlock message={isErrorInDynamicQr} />
                  )}
              </div>
            )}
            {(upiIntentPositionOutside && !isUpiFieldShown && isUPIEnabled) && (
              <div className="soft--bottom" style={{ marginTop: '-8px' }}>
                <a onClick={() =>
                  this.props.handleShowUpiField(true)
                }
                >
                  Click here to enter your UPI ID
                </a>
              </div>
            )}
            {(!upiIntentPositionOutside || isUpiFieldShown) && isUPIEnabled && (
              <>
                {!isDynamicQrFetching && !isErrorInDynamicQr && <Row className="text-center push-half--bottom f12"> OR </Row>}
                <div>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.props.showConvenienceFee &&
                      this.props.finalizePurchase.convenienceFeeVersion === 'v2'
                        ? this.props
                            .validateUPIHandle()
                            .then(() => this.props.handleSelectPaymentOption(UPIPaymentOption))
                            .then(() => this.props.handleFetchConvenienceFees())
                            .catch(() => {
                              /**/
                            })
                        : this.props.handleSubmitUPI({
                          paymentOption: UPIPaymentOption,
                        });
                    }}
                  >
                    <FormFieldsWrapper>
                      <FormField label="Enter your UPI ID" errors={upiHandleError}>
                        <Autocomplete {...this.props} />
                      </FormField>
                      {!isUPIRequestSubmitted ? (
                        <FormField>
                          <input
                            className="btn-block btn-green"
                            disabled={isUPIRequestSubmitting}
                            style={{ marginBottom: 7 }}
                            type="submit"
                            value={
                              this.props.showConvenienceFee &&
                              this.props.finalizePurchase.convenienceFeeVersion === 'v2'
                                ? 'Confirm'
                                : 'Verify & Pay'
                            }
                          />
                          <div className="soft-half--ends">
                            <a
                              onClick={(e) => {
                                this.props.handleShowUpiPopup(true);
                                this.upiPopupEvent(e.target.innerHTML);
                              }}
                            >
                              How to pay using UPI?
                            </a>
                          </div>
                          {!upiIntentPositionOutside && <TnCText />}
                        </FormField>
                      ) : (
                        <FormField>
                          <input
                            className="btn-block btn-green disabled"
                            disabled
                            value="Waiting..."
                          />
                        </FormField>
                      )}
                      {isUPIRequestSubmitted ? (
                        <div className="center">
                          <img
                            src={`${STATIC_URL}assets/images/payment/upi.png`}
                            alt="upi"
                            className="push--bottom"
                          />
                          <p className="push--bottom">
                            Check your phone for a verification notification on{' '}
                            {UPIOptionToVerificationTextMap[selectedUPIOption] || ''}.
                          </p>
                        </div>
                      ) : null}
                    </FormFieldsWrapper>
                  </Form>
                </div>
              </>
            )}
            {!upiIntentPositionOutside && !isUPIEnabled && (<TnCText />)}
          </>
        ) : null}
        <div>
          {showUpiPopup ? (
            <UpiPopup
              showUpiPopup={showUpiPopup}
              closeUpiPopup={this.props.handleShowUpiPopup}
            />
          ) : null}
          {showUpiLabelPopup ? (
            <UpiLabelPopup
              selectedUPIOption={getUpiOptionFromPsp(selectedUpiPSP)}
              showUpiPopup={showUpiLabelPopup}
              closeUpiPopup={this.props.handleShowUpiLabelPopup}
            />
          ) : null}
          {isUpiIntentPolling ? (
            <UPIIntentWaitScreen
              dynamicQrDetails={dynamicQrDetailsV2}
              convenienceFeeVersion={convenienceFeeVersion}
              pollForUPIAcceptance={(statusCheckUrl) =>
                this.props.pollForUPIAcceptance(statusCheckUrl)
              }
              toggleUPIIntentWaitScreen={(status, app) =>
                this.props.toggleUPIIntentWaitScreen(status, app)
              }
              intentAppCalled={upiIntentAppCalled}
              getUPIIntentStatusCheck={(statusCheckUrl) =>
                this.props.getUPIIntentStatusCheck(statusCheckUrl)
              }
            />
          ) : null}
          <MojoPopup
            show={isUpiPopupVisible}
            showCloseModalIcon={false}
            contentContainerStyle={{ width: '360px' }}
          >
            <div className="soft-double text-center upipoup-content">
              <div dangerouslySetInnerHTML={{ __html: formHtmlText }} />
            </div>
          </MojoPopup>
        </div>
      </div>
    );
  }
}

MobileUPIIntentV2.propTypes = {
  finalizePurchase: PropTypes.object,
  username: PropTypes.string,
  showConvenienceFee: PropTypes.bool,
  passIntentWithConvenienceFee: PropTypes.func,
  setUpiPsp: PropTypes.func,
  handleShowUpiPopup: PropTypes.func,
  handleSubmitUPI: PropTypes.func,
  handleShowUpiLabelPopup: PropTypes.func,
  handleChangeUPIHandle: PropTypes.func,
  handleUPIOptions: PropTypes.func,
  handleFetchConvenienceFees: PropTypes.func,
  validateUPIHandle: PropTypes.func,
  pollForUPIAcceptance: PropTypes.func,
  toggleUPIIntentWaitScreen: PropTypes.func,
  getUPIIntentStatusCheck: PropTypes.func,
  handleSubmitUPIIntent: PropTypes.func,
  upiIntentPositionOutside: PropTypes.bool,
  handleShowUpiField: PropTypes.func,
  handleSelectPaymentOption: PropTypes.func,
  handleFetchConvenienceFeesForQR: PropTypes.func,
  handleDynamicQrV2: PropTypes.func,
};

export default connect(() => ({}), {
  setUpiPsp,
  handleShowUpiPopup,
  handleSubmitUPI,
  handleShowUpiLabelPopup,
  handleChangeUPIHandle,
  handleFetchConvenienceFees,
  handleFetchConvenienceFeesForQR,
  validateUPIHandle,
  passIntentWithConvenienceFee,
  pollForUPIAcceptance,
  toggleUPIIntentWaitScreen,
  getUPIIntentStatusCheck,
  handleSubmitUPIIntent,
  handleShowUpiField,
  handleSelectPaymentOption,
  handleDynamicQrV2,
})(MobileUPIIntentV2);
