import Rupee from '@instamojo/rupee';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import root from 'window-or-global';

import Icon from '../../../../common/components/Icon';
import ia from '../../../../common/config/Analytics';
import {
  beneficiaryInfo,
  fetchOptionfromId,
} from '../../actions/FinalizePurchaseActions';

class LoginNeft extends Component {
  redirectUrl() {
    const { order, username, selectedNeftBankUrl, selectedNeftBankId } = this.props;
    root.open(selectedNeftBankUrl);
    ia.sendEvent('Initiated NEFT Payment', {
      bank_name: fetchOptionfromId(selectedNeftBankId, 'name'),
      order_id: order,
      seller_username: username,
    });
  }

  render() {
    const {
      ifscCode,
      bankName,
      beneficiaryName,
      otherBankNeft,
      orderAmount,
      accountNumber,
    } = this.props;
    let accountNumberArr = [];
    if (accountNumber) {
      accountNumberArr = accountNumber.toString().match(/.{1,5}/g);
    }
    return (
      <div className="soft--bottom">
        <div
          className="payments-header soft-double--ends text-center"
          style={{ background: '#F4F6FA', fontWeight: 'bold' }}
        >
          <a
            className="left soft--left"
            onClick={() => this.props.beneficiaryInfo(false)}
          >
            <Icon className="arrows-1_minimal-left" style={{ fontSize: '12px' }} />
          </a>
          <span className="soft-double--right" style={{ color: '#2C475D' }}>
            How to pay using NEFT?
          </span>
        </div>
        <div className="payments-form payments-form-wrapper-neft">
          <ol className="soft-double--sides soft--top">
            <li>Login into Netbanking</li>
            <li>Find the Add Beneficiary tab</li>
            <li>
              <div>Add the Beneficiary Account number</div>
              <span className="credentials-font">
                {accountNumberArr.map((n, i) => (
                  <span key={i} style={{ paddingRight: '10px' }}>
                    {n}
                  </span>
                ))}
              </span>
            </li>
            <li>
              <div>Add the Beneficiary IFSC Code</div>
              <span className="credentials-font">{ifscCode}</span>
            </li>
            <li>
              <div>Add the Beneficiary Bank Name</div>
              <span style={{ color: '#192A38' }}>{bankName}</span>
            </li>
            <li>
              <div>Add the Beneficiary Name</div>
              <span style={{ color: '#192A38' }}>{beneficiaryName}</span>
            </li>
            <li>
              <div>Select account type as</div>
              <span style={{ color: '#192A38' }}>Current Account</span>
            </li>
            <li>
              <div>Transfer amount to added Beneficiary</div>
              <span style={{ color: '#192A38' }}>
                <Rupee value={orderAmount} />
              </span>
            </li>
            <li>All done, you will get a payment confirmation email.</li>
          </ol>
          <ul className="soft">
            <li style={{ color: '#617389' }}>
              <span style={{ color: '#192A38', paddingRight: '4px' }}>Note:</span>
              We have sent this instructions to your email as well
            </li>
          </ul>
          {!otherBankNeft ? (
            <div className="push">
              <a
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => this.redirectUrl()}
                className="btn btn-block btn-green"
              >
                Login to your bank
              </a>
            </div>
          ) : (
            <a
              className="block btn soft push push--bottom"
              style={{
                cursor: 'default',
                textAlign: 'left',
                fontSize: '14px',
                fontWeight: '600',
                textTransform: 'none',
                background: '#E6EFFA',
              }}
            >
              Please go to your Bank&rsquo;s website & pay using NEFT.
            </a>
          )}
          <div className="text-center soft--bottom">
            <a onClick={() => this.props.beneficiaryInfo(false)}>
              <Icon className="arrows-1_minimal-left" style={{ fontSize: '12px' }} />
              Back
            </a>
          </div>
        </div>
      </div>
    );
  }
}

LoginNeft.propTypes = {
  order: PropTypes.string,
  bankName: PropTypes.string,
  orderAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  beneficiaryName: PropTypes.string,
  ifscCode: PropTypes.string,
  accountNumber: PropTypes.string,
  selectedNeftBankUrl: PropTypes.string,
  otherBankNeft: PropTypes.bool,
  beneficiaryInfo: PropTypes.func,
  username: PropTypes.string,
  selectedNeftBankId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const mapStateToProps = (state) => {
  const storeState = state.cart ? state.cart : state;

  const {
    otherBankNeft,
    isRetrying,
    seller: { username },
  } = storeState.settings;

  const {
    order,
    orderAmount,
    selectedNeftBankUrl,
    selectedNeftBankId,
    neftDetails: { bankName, beneficiaryName, ifscCode, accountNumber },
  } = isRetrying ? storeState.retry.finalizePurchase : storeState.finalizePurchase;

  return {
    order,
    bankName,
    orderAmount,
    beneficiaryName,
    ifscCode,
    accountNumber,
    selectedNeftBankUrl,
    otherBankNeft,
    username,
    selectedNeftBankId,
  };
};

export default connect(mapStateToProps, {
  beneficiaryInfo,
})(LoginNeft);
