/* globals INITIAL_STATE */
import deepmerge from 'deepmerge';

import { camelCaseKeys, objectWithout } from '../../../common/config/utils';
import * as InitializePurchaseActionTypes from '../actions/InitializePurchaseActions';
import * as ActionTypes from '../actions/RefinePurchaseActions';
import { getInitialStateTotalAmount } from '../utils/GlobalUtils';
import { getCustomFields } from '../utils/GlobalUtils';
import { mapPayloadForClient } from '../utils/InitializePurchaseUtils';
import {
  customFieldsNormalizer,
  customFormFields,
  getLinkBillingAddressFields,
  getLinkShippingAddressFields,
  getShippingAddressFields,
} from './refine/customFields';

function refinePurchase(state = getInitialState(INITIAL_STATE), action) {
  switch (action.type) {
    case ActionTypes.HANDLE_CHANGE_RP_INPUT: {
      let {
        payload: { changedData },
      } = action;
      let errorKeysToRemove;
      if (!changedData.keyName) {
        errorKeysToRemove = Object.keys(changedData);
      }
      if (changedData.keyName) {
        const { keyName, field, value } = changedData;

        const { [keyName]: prevData, billingSameAsShipping } = state.form.customFields;

        errorKeysToRemove = [
          keyName.split('Address')[0] + field.charAt(0).toUpperCase() + field.slice(1),
        ];

        changedData[keyName] = prevData;
        changedData[keyName][field] = value;

        delete changedData['field'];
        delete changedData['keyName'];
        delete changedData['value'];
        if (keyName === 'shippingAddress' && billingSameAsShipping) {
          changedData['billingAddress'] = changedData[keyName];
        }
      } else if (changedData.billingSameAsShipping === false) {
        changedData['billingAddress'] = Object.keys(
          state.form.customFields['billingAddress'],
        ).reduce((prev, curr) => {
          prev[curr] = '';
          return prev;
        }, {});
      } else if (changedData.billingSameAsShipping === true) {
        changedData['billingAddress'] = state.form.customFields['shippingAddress'];

        const billingErrorKeysToRemove = Object.keys(
          state.form.customFields['billingAddress'],
        ).map((field) => {
          return 'billing' + field.charAt(0).toUpperCase() + field.slice(1);
        });
        errorKeysToRemove = [...errorKeysToRemove, ...billingErrorKeysToRemove];
      }

      if ('showGstField' in changedData) {
        changedData['companyName'] = '';
        changedData['gstin'] = '';
        errorKeysToRemove = [...errorKeysToRemove, 'companyName', 'gstin'];
      }

      if (changedData.isAdditionalField) {
        delete changedData.isAdditionalField;
        const additionalCustomFields = {
          ...state.form.customFields.additionalCustomFields,
          ...changedData,
        };
        const fieldKey = Object.keys(changedData)[0];
        const fieldName = state.link.customFields.additionalCustomFields[fieldKey].name;
        errorKeysToRemove = [...errorKeysToRemove, `additional_${fieldName}`];
        changedData = { additionalCustomFields };
      }

      const mergePayload = {
        ...state,
        form: {
          ...state.form,
          customFields: {
            ...state.form.customFields,
            ...changedData,
          },
          errors: objectWithout(state.form.errors, errorKeysToRemove),
        },
      };

      return mergePayload;
    }

    case ActionTypes.STORE_INITIAL_PAYLOAD: {
      const {
        payload: { initialPayload },
      } = action;
      return {
        ...state,
        initialPayload,
      };
    }

    case ActionTypes.HANDLE_SUBMIT_REFINE_PURCHASE_FAILURE: {
      const { errors } = action;
      return {
        ...state,
        form: {
          ...state.form,
          errors,
        },
      };
    }

    case ActionTypes.HANDLE_SUBMIT_REFINE_PURCHASE_SUCCESS: {
      const { claimUri } = action.response.details;
      return deepmerge(state, {
        form: {
          claimUrl: claimUri,
        },
        isFormServerValidated: true,
      });
    }

    case InitializePurchaseActionTypes.HANDLE_SET_TOTAL_AMOUNT: {
      const { totalAmount } = action.payload;
      return {
        ...state,
        totalAmount,
      };
    }

    case InitializePurchaseActionTypes.HANDLE_SUBMIT_INITIALIZE_PURCHASE_SUCCESS: {
      const {
        details: { shippingCharges, totalAmount },
      } = action.response;
      return {
        ...state,
        shippingCharges,
        totalAmount,
      };
    }

    default:
      return state;
  }
}

export default refinePurchase;

const getInitialState = (state) => {
  if (!(state.offer || getCustomFields().length || state.is_link_payment)) {
    return {};
  }
  if (state.offer) {
    const {
      shipping_address: hasShippingAddress,
      physical: { states } = {},
      prefilled,
    } = state.offer;

    let { custom_fields: rawCustomFields } = state.offer;

    const prefilledProps = mapPayloadForClient(prefilled);

    // TODO normalize this usage for both Initialize and Refine as it's NOT DRY.
    const notCustomFieldProps = ['name', 'email', 'phone', 'amount'];
    const prefilledCustomFields = Object.keys(prefilledProps)
      .filter((prefilledProp) => notCustomFieldProps.indexOf(prefilledProp) === -1)
      .reduce((o, p) => {
        if (!prefilledProps[p].hidden) {
          o[p] = prefilledProps[p];
        }
        return o;
      }, {});

    rawCustomFields = rawCustomFields || [];

    if (hasShippingAddress) {
      // Add Custom Fields for Shipping Address manually
      rawCustomFields = [...getShippingAddressFields(states), ...rawCustomFields];
    }

    const readOnlyProps = (fields = rawCustomFields) =>
      fields.reduce((props, field) => {
        if (prefilledCustomFields[field.name]) {
          props[field.name] = prefilledCustomFields[field.name].readonly;
        }
        return props;
      }, {});

    return {
      totalAmount: getInitialStateTotalAmount(),
      totalAmountWithShipping: 0,
      offer: {
        hasShippingAddress,
        customFields: customFieldsNormalizer(rawCustomFields),
      },
      form: {
        errors: {},
        claimUrl: null,
        customFields: customFormFields({
          fields: rawCustomFields,
          prefilledCustomFields,
        }),
        readOnly: readOnlyProps(),
      },
      isFormServerValidated: false,
      initialPayload: {},
    };
  }
  if (state.is_link_payment) {
    let rawCustomFields = getCustomFields();
    let normalizedCustomObj = {};
    let normalisedFormObj = {};
    let hasShippingAddress = false;
    let hasBillingAddress = false;
    if (rawCustomFields.length > 0) {
      const keys = customFieldsNormalizer(getCustomFields());
      hasShippingAddress = 'shipping_address' in keys;
      hasBillingAddress = 'billing_address' in keys;

      const rawCustomFieldsClone = rawCustomFields;
      const keysToDelete = ['shipping_address', 'billing_address'];
      rawCustomFieldsClone.forEach((field, index) => {
        // adding check for additonal custom fields with both flags i.e, isAdditionalField and is_additonal_field
        // as some field flags are getting converted to camelcase and some are not
        if (
          keysToDelete.indexOf(field.name) > -1 &&
          !field.isAdditionalField &&
          !field.is_additional_field
        ) {
          rawCustomFields.splice(index, 1);
        }
      });

      const states = state.states;
      rawCustomFields = rawCustomFields || [];

      if (hasShippingAddress) {
        // Add Custom Fields for Shipping Address manually
        rawCustomFields = [
          {
            name: 'shippingAddress',
            shouldUseAsObject: true,
            ...customFieldsNormalizer(getLinkShippingAddressFields(states)),
          },
          ...rawCustomFields,
        ];
      }
      if (hasBillingAddress) {
        // Add Custom Fields for Shipping Address manually
        rawCustomFields = [
          {
            name: 'billingAddress',
            shouldUseAsObject: true,
            ...customFieldsNormalizer(getLinkBillingAddressFields(states)),
          },
          ...rawCustomFields,
        ];
      }
      //Delete the default shipping and billing objects received from INITAIL_STATE.
      normalizedCustomObj = camelCaseKeys(customFieldsNormalizer(rawCustomFields));
      normalisedFormObj = camelCaseKeys(
        customFormFields({
          fields: rawCustomFields,
        }),
      );
    }

    return {
      //TODO fix the amount fetching below
      totalAmount: getInitialStateTotalAmount(),
      totalAmountWithShipping: 0,
      link: {
        hasShippingAddress: hasShippingAddress,
        customFields: normalizedCustomObj,
      },
      form: {
        errors: {},
        customFields: {
          ...normalisedFormObj,
          billingSameAsShipping: hasShippingAddress && hasBillingAddress ? true : false,
          showGstField: false,
        },
      },
      isFormServerValidated: false,
      initialPayload: {},
    };
  }
};
