import ProfileImage from '@instamojo/profile-image';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Columns, Row } from '../../../../common/components/Grid';

const LinksPostPurchaseMerchantHeader = ({
  sellerAvatar,
  fullName,
  smartlinksHeaderColor,
}) => {
  const backgroundStyle = smartlinksHeaderColor
    ? { backgroundColor: smartlinksHeaderColor }
    : { backgroundColor: '#617389' };
  return (
    <div className={classNames('payments-header', sellerAvatar ? 'has--avatar' : null)}>
      <div className="soft border-radius banner-background" style={backgroundStyle}>
        <Row className="collapse">
          <Columns className="small-2">
            <ProfileImage
              className="payments-header--avatar"
              height={40}
              width={40}
              alt={fullName}
              src={sellerAvatar}
            />
          </Columns>
          <Columns className="small-10">
            <p className="flush--bottom break-long-words soft-half--top white">
              {fullName}
            </p>
          </Columns>
        </Row>
      </div>
    </div>
  );
};

LinksPostPurchaseMerchantHeader.propTypes = {
  sellerAvatar: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  smartlinksHeaderColor: PropTypes.string,
};

export default LinksPostPurchaseMerchantHeader;
