import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Columns, Row } from '../../components/Grid';

class InputWithHint extends React.Component {
  render() {
    const {
      children,
      hint,
      hintContainerClassName,
      childrenContainerClassName,
    } = this.props;
    return (
      <Row className="medium-collapsed">
        <Columns className={childrenContainerClassName}>{children}</Columns>
        <Columns className={hintContainerClassName}>
          <div className={classnames({ 'text-hint': hint })}>{hint}</div>
        </Columns>
      </Row>
    );
  }
}

InputWithHint.defaultProps = {
  childrenContainerClassName: 'small-12 medium-6',
  hintContainerClassName: 'small-12 medium-6',
};

InputWithHint.propTypes = {
  children: PropTypes.node,
  hint: PropTypes.node,
  hintContainerClassName: PropTypes.string,
  childrenContainerClassName: PropTypes.string,
};

export default InputWithHint;
