import React from 'react';
import { Provider } from 'react-redux';

import Payments from '../../payments/payments/components/Payments';
import SmartLinks from '../../public/smart-links';
import store from '../payments/store';

const Root = () => (
  <Provider store={store}>
    <div>
      <SmartLinks />
      <Payments />
    </div>
  </Provider>
);

export default Root;
