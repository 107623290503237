import '../styles/MojoPopup2.scss';

import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';

import { MODAL_WIDTH } from '../config/CommonComponents';
import { isAndroidWebview } from '../config/utils';
import ModalClose2 from './modal2/ModalClose2';
import ModalContent2 from './modal2/ModalContent2';
import ModalHeader2 from './modal2/ModalHeader2';

const defaultOverlayStyle = {
  background: 'rgba(0, 0, 0, 0.75)',
  overflowY: 'auto',
  zIndex: '9999',
};

const defaultContentStyle = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  border: 'none',
  width: MODAL_WIDTH.SHORT,
  marginTop: '48px',
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'relative',
  padding: '0',
};

class MojoPopup2 extends React.Component {
  componentDidMount() {
    if (isAndroidWebview) {
      document.body.style.position = 'relative';
    }
  }
  componentWillUnmount() {
    if (isAndroidWebview) {
      document.body.style.position = null;
    }
  }
  render() {
    const {
      isModalOpen,
      onCloseModal,
      shouldCloseOnOverlayClick,
      shouldCloseOnEsc,
      shouldShowCloseIcon,
      modalWidth,
      children,
      className,
      overlayStyle,
      contentStyle,
      closeIconStyles = {},
    } = this.props;

    return (
      <Modal
        isOpen={isModalOpen}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        shouldCloseOnEsc={shouldCloseOnEsc}
        style={{
          overlay: {
            ...defaultOverlayStyle,
            ...overlayStyle,
          },
          content: {
            ...defaultContentStyle,
            width: modalWidth,
            ...contentStyle,
          },
        }}
        onRequestClose={() => onCloseModal()}
        ariaHideApp={false}
        className={className}
      >
        <div className="mojo-popup-2-wrapper">
          {shouldShowCloseIcon ? <ModalClose2 closeIconStyles={closeIconStyles} onRequestClose={onCloseModal} /> : null}
          {children}
        </div>
      </Modal>
    );
  }
}

MojoPopup2.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  shouldCloseOnOverlayClick: PropTypes.bool,
  shouldCloseOnEsc: PropTypes.bool,
  shouldShowCloseIcon: PropTypes.bool,
  modalWidth: PropTypes.oneOf(Object.values(MODAL_WIDTH)),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  contentStyle: PropTypes.object,
  overlayStyle: PropTypes.object,
  closeIconStyles: PropTypes.object,
};

MojoPopup2.defaultProps = {
  shouldCloseOnOverlayClick: false,
  shouldCloseOnEsc: false,
  shouldShowCloseIcon: true,
  modalWidth: MODAL_WIDTH.SHORT,
};

export { ModalContent2, ModalHeader2, MojoPopup2 };

// TODO: Find a way to remove `ariaHideApp` prop in Modal
