import { getUrlParameterByName } from '../../../common/config/utils';
import getUsernameFromStore from '../actions/helpers/getUsernameFromStore';

function getCartModifyAnalytics({ slug, response, tracker, store }) {
  /* globals MojoUser */
  return {
    offer_id: slug,
    cart_amount: response.cart ? response.cart.totalAmount : 0,
    buyer_id: MojoUser ? MojoUser.id : null,
    ga_client_id: tracker.get('clientId'),
    seller_username: getUsernameFromStore(store),
    parent_ref_store_flag: getUrlParameterByName('ref'),
    self_flag: MojoUser ? MojoUser.username === getUsernameFromStore(store) : false,
  };
}

export default getCartModifyAnalytics;
