import ProfileImage from '@instamojo/profile-image';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Columns, Row } from '../../../../common/components/Grid';

const ProductsPostPurchaseMerchantHeader = ({
  preview,
  sellerAvatar,
  title,
  fullName,
}) => (
  <div
    className={classNames(
      'payments-header is--product-payment',
      preview ? 'has--preview' : null,
      sellerAvatar ? 'has--avatar' : null,
    )}
    style={
      preview
        ? {
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(${preview})`,
          }
        : null
    }
  >
    {sellerAvatar ? (
      <div className="payments-header--avatar-container sticky">
        <ProfileImage
          alt=""
          className="payments-header--avatar"
          height={60}
          width={60}
          src={sellerAvatar}
        />
      </div>
    ) : null}

    <div className="offer--merchant">
      <Row>
        <Columns className="small-12 center break-long-words">{fullName}</Columns>
      </Row>
    </div>

    <div className="offer--specs">
      <div className="soft--sides" style={{ wordWrap: 'break-word', lineHeight: '1.2' }}>
        {title}
      </div>
    </div>
  </div>
);

ProductsPostPurchaseMerchantHeader.propTypes = {
  preview: PropTypes.string,
  sellerAvatar: PropTypes.string,
  title: PropTypes.string,
  fullName: PropTypes.string,
};

export default ProductsPostPurchaseMerchantHeader;
