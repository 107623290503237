import PropTypes from 'prop-types';
import React from 'react';

import { isAndroidWebview, isIOS_Device } from '../../config/utils';
import ModalClose from './ModalClose';

const ModalContentCore = (props) => {
  return (
    <div
      className="react-overlays-modal__content"
      style={{
        ...defaultStyle,
        ...props.style,
      }}
    >
      {props.children}
      {props.isClosable ? <ModalClose {...props.modalClose} /> : null}
    </div>
  );
};

ModalContentCore.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
  isClosable: PropTypes.bool,
  modalClose: PropTypes.shape({
    className: PropTypes.string,
    style: PropTypes.object,
    onRequestClose: PropTypes.func.isRequired,
  }),
};

const ModalContent = (props) => {
  if (!props.handleFullScreenScroll) {
    return <ModalContentCore {...props} />;
  }
  return (
    <div style={contentWrapperStyles} role="document" tabIndex="-1">
      <ModalContentCore {...props} />
    </div>
  );
};

export default ModalContent;

ModalContent.defaultProps = {
  style: {},
  handleFullScreenScroll: true,
  isClosable: true,
};

ModalContent.propTypes = {
  style: PropTypes.object,
  handleFullScreenScroll: PropTypes.bool,
  isClosable: PropTypes.bool,
  modalClose: PropTypes.shape({
    className: PropTypes.string,
    style: PropTypes.object,
    onRequestClose: PropTypes.func.isRequired,
  }),
};

const defaultStyle = {
  width: '320px',
  marginTop: '48px',
  marginLeft: 'auto',
  marginRight: 'auto',
};

export const contentWrapperStyles = {
  width: '100%',
  height: '100%',
  border: '0 none transparent',
  transition: 'all 0.2s',
  overflowY: 'auto',
  overflowX: 'hidden',
  position: (isIOS_Device || isAndroidWebview) ? 'relative' : 'fixed', // needed to fix the focus juggle in IOS
  display: 'block',
  margin: '0px',
  padding: '0px',
  background: 'none',
  outline: 'none',
};
