import React from 'react';
import ReactDOM from 'react-dom';

const render = (Component, mountOnNodeId) => {
  ReactDOM.render(<Component />, document.getElementById(mountOnNodeId));
};

export const hydrate = (Component, mountOnNodeId) => {
  ReactDOM.hydrate(<Component />, document.getElementById(mountOnNodeId));
};

export default render;
