import PropTypes from 'prop-types';
import React from 'react';

import { FormFieldWithLabel } from '../../../../common/components/FormComponents';

const Email = ({ errors, readOnly = {}, email, onChange }) => {
  return (
    <FormFieldWithLabel label="Email" errors={errors.email}>
      {readOnly.email ? (
        <p className="flush break-long-words">{email}</p>
      ) : (
        <input
          type="email"
          value={email}
          onChange={(e) => onChange({ email: e.target.value })}
        />
      )}
    </FormFieldWithLabel>
  );
};

Email.propTypes = {
  errors: PropTypes.object,
  readOnly: PropTypes.object,
  email: PropTypes.string,
  onChange: PropTypes.func,
};

export default Email;
