import Rupee from '@instamojo/rupee';
import { rupees } from '@instamojo/rupee';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import ConvenienceFeeCard from './ConvenienceFeeCard';

class ConvenienceFeesAccordion extends React.Component {
  // For version v1 convenience all the payment options have the same fees and tax. So
  // we will fetch the fees and taxes from first paymentOption
  // If we don't get any payment Option we will not render anything
  getVersionBasedComponent() {
    const {
      convenienceFeeVersion,
      convenienceFeeDetails,
      selectedPaymentMode,
    } = this.props.finalizePurchase;
    // We have yet another special case for bank_transfer. We never show the banner for
    // neft irrespective of version.
    if (selectedPaymentMode == 'bank_transfer') {
      return null;
    }
    if (convenienceFeeVersion == 'v1' && convenienceFeeDetails !== null) {
      return (
        <div className="no-text-selection">
          <ConvenienceFeeCard
            convenienceFeesAndTax={convenienceFeeDetails.convenienceFeesAndTax}
            convenienceFees={convenienceFeeDetails.convenienceFees}
            convenienceFeesTax={convenienceFeeDetails.convenienceFeesTax}
          />
          <div
            style={{
              paddingTop: '15px',
              borderTop: '1px solid rgba(227, 236, 242, 0.5)',
            }}
          >
            <span className="strong">Total </span>
            <span className="strong right">
              <Rupee value={rupees(convenienceFeeDetails.totalAmount)} />
            </span>
          </div>
        </div>
      );
    } else if (convenienceFeeVersion == 'v2') {
      return (
        <div
          className="soft--top success-message-block"
          style={{ color: '#465E71', lineHeight: 1.3 }}
        >
          Your final amount is inclusive of nominal convenience fees.
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const { showConvenienceFee, isFinalizeFormOpen, isCashOnDeliveryOrder } = this.props;

    return showConvenienceFee && isFinalizeFormOpen ? (
      isCashOnDeliveryOrder ? (
        <p className="push-half--top soft--bottom text-light-grey micro">
          Convenience fee has been added to your final payment amount
        </p>
      ) : (
        <div className="soft-half--bottom">{this.getVersionBasedComponent()}</div>
      )
    ) : null;
  }
}

ConvenienceFeesAccordion.propTypes = {
  showConvenienceFee: PropTypes.bool,
  isFinalizeFormOpen: PropTypes.bool,
  isCashOnDeliveryOrder: PropTypes.bool,
  finalizePurchase: PropTypes.object,
};

const mapStateToProps = ({ cart, settings, finalizePurchase = {}, retry }) => {
  let selectedPaymentMode = finalizePurchase.selectedPaymentMode;
  // if payment is being retried then, the settings will be in retry setttings
  if (settings?.isRetrying) {
    return {
      finalizePurchase: retry.finalizePurchase,
      showConvenienceFee: retry.settings.showConvenienceFee,
      isFinalizeFormOpen: retry.settings.isFinalizeFormOpen,
      isCashOnDeliveryOrder: selectedPaymentMode === 'cash_on_delivery',
    };
  }

  if (cart) {
    selectedPaymentMode = cart.finalizePurchase.selectedPaymentMode;
    return {
      finalizePurchase: cart.finalizePurchase,
      showConvenienceFee: cart.settings.showConvenienceFee,
      isFinalizeFormOpen: cart.openTab === 'PAYMENT',
      isCashOnDeliveryOrder: selectedPaymentMode === 'cash_on_delivery',
    };
  }

  return {
    finalizePurchase: finalizePurchase,
    showConvenienceFee: settings.showConvenienceFee,
    isFinalizeFormOpen: settings.isFinalizeFormOpen,
    isCashOnDeliveryOrder: selectedPaymentMode === 'cash_on_delivery',
  };
};

export default connect(mapStateToProps)(ConvenienceFeesAccordion);
