/* globals INITIAL_STATE */
import root from 'window-or-global';

import { camelCaseKeys } from '../../../common/config/utils';
import { getIsMobileDevice } from '../../../common/utils/ModernizrUtils';
import * as SmartLinkActionTypes from '../../../public/smart-links/actions/SmartLinksActions';
import * as FinalizePurchaseActionTypes from '../actions/FinalizePurchaseActions';
import * as InitializePurchaseActionTypes from '../actions/InitializePurchaseActions';
import * as InstapayActionTypes from '../actions/InstapayActions';
import * as PostPurchaseActionTypes from '../actions/PostPurchaseActions';
import * as RefinePurchaseActionTypes from '../actions/RefinePurchaseActions';
import * as ActionTypes from '../actions/SettingsActions';
import { TABS } from '../constants/SettingsConstants';
import { getAccordianHeading, getInitialStateTotalAmount } from '../utils/GlobalUtils';
import { getCustomFields } from '../utils/GlobalUtils';
import { sanitizePaymentOptions } from './finalize/paymentOptions';

const policies = {
  'terms-and-conditions': 'Terms and Conditions',
  'privacy': 'Privacy Policy',
  'cancel-and-refund': 'Cancellation and Refund Policy',
  'shipping-and-delivery': 'Shipping and Delivery Policy',
};

function settings(state = getInitialState(INITIAL_STATE), action) {
  switch (action.type) {
    case ActionTypes.HANDLE_TOGGLE_OPEN_STATE: {
      const { tab, isOpen } = action.payload;
      if (!isOpen) {
        // we don't support closing the tab right now.
        // since atleast one form has to be open at all times.
        return state;
      }

      const orderOfTabs = [
        'isInitializeFormOpen',
        'isRefineFormOpen',
        'isFinalizeFormOpen',
      ];

      let updateWith = {};
      const allTabs = Object.keys(TABS).map((tabKey) => TABS[tabKey]);
      // delete this tab from the tabs that need to be nuked
      allTabs.splice(allTabs.indexOf(tab), 1);

      const otherTabsUpdate = allTabs.reduce((markFalse, tabKey) => {
        markFalse[`is${tabKey}FormOpen`] = false;
        return markFalse;
      }, {});

      const handledTab = `is${tab}FormOpen`;

      // if `tab` is getting open, close all other tabs
      updateWith = {
        [handledTab]: true,
        ...otherTabsUpdate,
      };

      const currentlyOpenTabIndex = orderOfTabs.indexOf(
        orderOfTabs.find((tabKey) => state[tabKey]),
      );
      updateWith = {
        ...updateWith,
        isBackwardMotion: currentlyOpenTabIndex > orderOfTabs.indexOf(handledTab),
      };

      return {
        ...state,
        ...updateWith,
      };
    }

    case ActionTypes.HANDLE_TOGGLE_PAYMENT_POPUP: {
      const toggleState = !state.isPaymentPopupOpen;
      root.isPaymentButtonClicked = toggleState;
      return {
        ...state,
        isPaymentPopupOpen: toggleState,
      };
    }

    case SmartLinkActionTypes.HANDLE_TOGGLE_PAYMENT_LINK_POPUP: {
      return {
        ...state,
        isPaymentPopupOpen: !action.isSmartLinkPopupOpen,
      };
    }

    case ActionTypes.HANDLE_TOGGLE_INITIALIZE_FORM_ANIMATED: {
      return {
        ...state,
        isInitializeFormAnimated: !state.isInitializeFormAnimated,
      };
    }

    case ActionTypes.FETCH_REPORT_ABUSE_INFO_SUCCESS: {
      const { showReportLink = false } = action.response;
      return {
        ...state,
        showReportLink,
      };
    }

    case ActionTypes.FETCH_TOS_POLICIES_SUCCESS: {
      const policiesProcess = { ...action.response };
      const tosPolicies = {};
      Object.entries(policies).map(([policyName, policy], index) => {
        if (policiesProcess[policyName] !== undefined) {
          tosPolicies[index] = {
            name: policy,
            status: !(policiesProcess[policyName]?.hidden ?? false),
            content: policiesProcess[policyName]?.content ?? '',
            type: policyName,
            additionalContent: policiesProcess[policyName]?.additionalContent ?? '',
          };
        }
      });
      return {
        ...state,
        isFetched: true,
        tosPolicies,
      };
    }

    case ActionTypes.FETCH_TOS_POLICIES_FAILURE: {
      return {
        ...state,
        isFetched: false,
        tosPolicies: {},
      };
    }

    case ActionTypes.SHOW_DEFERRED_MERCHANT_HEADER: {
      return {
        ...state,
        showDeferred: true,
      };
    }

    case ActionTypes.HANDLE_AWAITING_PAYMENT_COMPLETION: {
      return {
        ...state,
        isAwaitingPaymentCompletion: action.waitingStatus,
      };
    }

    case InitializePurchaseActionTypes.HANDLE_SET_TOTAL_AMOUNT: {
      const { totalAmount } = action.payload;

      const { isShippingChargesEnabled, hasInitialShippingCharges } = state;

      const mergeWith = {
        ...state,
        totalAmount,
      };

      //Hide the finalize purchase box ONLY when shipping charges are disabled and total amount is 0.
      //Total amount DOES NOT include shippingCharges.
      if (!totalAmount && !isShippingChargesEnabled && !hasInitialShippingCharges) {
        return {
          ...mergeWith,
          isShowFinalizePurchaseBox: false,
        };
      }

      return {
        ...mergeWith,
        isShowFinalizePurchaseBox: true,
      };
    }

    case InitializePurchaseActionTypes.HANDLE_SET_ACCORDIAN_HEADING: {
      const { heading } = action.payload;
      return {
        ...state,
        initializeHeading: heading,
      };
    }

    case InitializePurchaseActionTypes.HANDLE_SUBMIT_INITIALIZE_PURCHASE_SUCCESS: {
      const fieldsWithNoPayment = ['claimUri'];

      const { details } = action.response;

      // if no payment is required, then stay in the same tab
      if (fieldsWithNoPayment.find((field) => details[field])) {
        return state;
      }

      // refactor incoming options to suit new ways
      //
      const sanitizedPaymentOptions = sanitizePaymentOptions(details);

      // if paymentOptions are sent as part of this action, move to last step
      if (
        sanitizedPaymentOptions.paymentOptions &&
        Object.keys(sanitizedPaymentOptions.paymentOptions).length
      ) {
        return {
          ...state,
          isInitializeFormOpen: false,
          isInitializeFormOpenable: true,
          isFinalizeFormOpenable: true,
          isFinalizeFormOpen: true,
          isBackwardMotion: false,
        };
      }

      // regular flow. move to custom fields.
      return {
        ...state,
        isInitializeFormOpen: false,
        isInitializeFormOpenable: true,
        isRefineFormOpen: true,
        isRefineFormOpenable: true,
        isBackwardMotion: false,
      };
    }

    case RefinePurchaseActionTypes.HANDLE_SUBMIT_REFINE_PURCHASE_SUCCESS: {
      const {
        details: { claimUri },
      } = action.response;

      if (claimUri) {
        return state;
      }
      return {
        ...state,
        isInitializeFormOpen: false,
        isRefineFormOpen: false,
        isFinalizeFormOpen: true,
        isFinalizeFormOpenable: true,
        isBackwardMotion: false,
      };
    }

    /* Initialize Purchase Details should be server validated before moving to the next steps */
    case InitializePurchaseActionTypes.HANDLE_IP_CHANGE_INPUT: {
      return {
        ...state,
        isRefineFormOpenable: false,
        isFinalizeFormOpenable: false,
      };
    }

    /* Refine Purchase Details should be server validated before moving to the next steps */
    case RefinePurchaseActionTypes.HANDLE_CHANGE_RP_INPUT: {
      return {
        ...state,
        isFinalizeFormOpenable: false,
      };
    }

    case InstapayActionTypes.HANDLE_SUBMIT_INSTAPAY_SUCCESS: {
      return {
        ...state,
        isInstapayInitiated: true,
        initializeHeading: getAccordianHeading(
          'InitializePurchase',
          action.payload.amount,
        ),
      };
    }

    case InstapayActionTypes.HANDLE_CHANGE_INSTAPAY_FORM: {
      return {
        ...state,
        isInstapayInitiated: false,
        isInitializeFormOpen: true,
        isFinalizeFormOpen: false,
      };
    }

    case PostPurchaseActionTypes.RETRY_FAILED_PAYMENT: {
      const { responseType } = state;
      const commonStateSettings = {
        isRetrying: true,
        isPaymentResponse: false,
        isPaymentPopupOpen: true,
        isInitializeFormOpen: false,
        isInitializeFormOpenable: false,
        isShowInitializePurchaseBox: false,
        isFinalizeFormOpen: true,
        showDeferred: true,
        isOfferAvailable: true,
      };

      if (responseType == 'product' || responseType == 'cart') {
        return {
          ...state,
          ...getOfferInitialState(INITIAL_STATE),
          ...commonStateSettings,
          isShowFinalizePurchaseBox: true,
          isFinalizeFormOpenable: true,
          isShowRefinePurchaseBox: false,
        };
      } else if (responseType == 'instapay') {
        return {
          ...state,
          ...getInstapayInitialState(INITIAL_STATE),
          ...commonStateSettings,
          isInstapayInitiated: true,
        };
      } else if (responseType == 'link') {
        return {
          ...state,
          ...getLinkInitialState(INITIAL_STATE),
          ...commonStateSettings,
        };
      }
      break;
    }

    case FinalizePurchaseActionTypes.HANDLE_SHOW_ADD_BENEFICIARY_INFO: {
      return {
        ...state,
        showNeftLogin: action.payload.flag,
        otherBankNeft: action.payload.otherBankNeft,
      };
    }

    case FinalizePurchaseActionTypes.HANDLE_FETCH_CONVENIENCE_FEES_REQUEST: {
      return {
        ...state,
        showConvenienceFeeForm: true,
      };
    }

    case FinalizePurchaseActionTypes.HANDLE_SUBMIT_UPI_FAILURE: {
      return {
        ...state,
        showConvenienceFeeForm: false,
      };
    }

    case FinalizePurchaseActionTypes.HANDLE_RESET_QR_CONVENIENCE_FEE_DETAILS: {
      return {
        ...state,
        showConvenienceFeeForm: false,
      };
    }

    case FinalizePurchaseActionTypes.HANDLE_RESET_UPI_PAYMENT_OPTION: {
      return {
        ...state,
        showConvenienceFeeForm: false,
      };
    }

    case FinalizePurchaseActionTypes.HANDLE_FETCH_QR_CONVENIENCE_FEES_REQUEST: {
      return {
        ...state,
        showConvenienceFeeForm: action.showConvenienceFeeForm,
      };
    }

    case FinalizePurchaseActionTypes.HANDLE_UPDATE_CONVENIENCE_FEE_OBJECT: {
      if (
        action.convenienceFeeData &&
        Object.prototype.hasOwnProperty.call(
          action.convenienceFeeData,
          'showConvenienceFeeForm',
        )
      ) {
        return {
          ...state,
          showConvenienceFeeForm: action.convenienceFeeData.showConvenienceFeeForm,
        };
      }
      return state;
    }

    default:
      return state;
  }
}

export default settings;

const getOfferInitialState = (state) => {
  const { should_retry: shouldRetry } = state;
  const {
    show_additional_info_tab: isShowRefinePurchaseBox,
    show_payment_tab: isShowFinalizePurchaseBox,
    is_direct_to_payments: isDirectToPayments,
    is_embed: isEmbed,
    im_branding: imBranding,
    is_anchor_text: isAnchorText,
    is_iframe: isIFrame,
    show_convenience_fee: showConvenienceFee,
    is_international_enabled: isInternationalNumberEnabled,
    is_shipping_charges_enabled: isShippingChargesEnabled,
    offer: {
      is_available: isOfferAvailable,
      avatar: sellerAvatar,
      seller,
      initial_shipping_charges: initialShippingCharges,
    },
  } = shouldRetry ? state.retry_data : state;

  const initialTotalAmount = getInitialStateTotalAmount();
  const initialState = {
    isLinkPayment: false,
    isOfferAvailable,
    showNeftLogin: false,
    isPaymentPopupOpen: false,
    totalAmount: initialTotalAmount,
    isInitializeFormOpen: true,
    isInitializeFormOpenable: true,
    isInitializeFormAnimated: getIsMobileDevice() || (isEmbed && isIFrame),
    initializeHeading: getAccordianHeading('InitializePurchase', initialTotalAmount),
    isRefineFormOpen: false,
    isRefineFormOpenable: false,
    isRefineFormAnimated: true,
    refineHeading: getAccordianHeading('RefinePurchase', initialTotalAmount),
    isFinalizeFormOpen: false,
    isFinalizeFormOpenable: false,
    isFinalizeFormAnimated: true,
    finalizeHeading: getAccordianHeading('FinalizePurchase', initialTotalAmount),
    isShowInitializePurchaseBox: true,
    isShowRefinePurchaseBox,
    isShowFinalizePurchaseBox: !!(
      (initialTotalAmount && isShowFinalizePurchaseBox) ||
      (isShippingChargesEnabled && initialShippingCharges)
    ),
    hasInitialShippingCharges: !!initialShippingCharges,
    isShippingChargesEnabled,
    isEmbed,
    imBranding,
    isAnchorText,
    showConvenienceFee,
    isInternationalNumberEnabled,
    isIFrame,
    isMobileDevice: getIsMobileDevice(),
    seller: camelCaseKeys(seller),
    sellerAvatar,
    isOfferDetailsFormOpen: false,
    isBackwardMotion: false,
    showDeferred: false,
  };

  if (isDirectToPayments && initialTotalAmount) {
    return {
      ...initialState,
      isInitializeFormOpen: false,
      isInitializeFormOpenable: false,
      isShowFinalizePurchaseBox: true,
      isShowInitializePurchaseBox: false,
      isFinalizeFormOpen: true,
      isFinalizeFormOpenable: true,
      isShowRefinePurchaseBox: false,
    };
  }

  return initialState;
};

const getLinkInitialState = (state) => {
  const { should_retry: shouldRetry } = state;
  const {
    is_embed: isEmbed,
    is_iframe: isIFrame,
    is_owner: isOwner,
    im_branding: imBranding,
    show_convenience_fee: showConvenienceFee,
    is_international_enabled: isInternationalNumberEnabled,
    link: {
      amount: initialTotalAmount,
      title: title,
      colour: colour,
      cover_image: coverImage,
      description: description,
      line_items: lineItems,
    },
    seller: { avatar: sellerAvatar, username, name },
  } = shouldRetry ? state.retry_data : state;

  return {
    isLinkPayment: true,
    isMerchantViewingOwnLink: isOwner,
    isOfferAvailable: true,
    isPaymentPopupOpen: !root.SHOW_SMART_LINK_WEBAPP,
    isLinkPaymentAppAvailable: root.SHOW_SMART_LINK_WEBAPP,
    totalAmount: initialTotalAmount,
    isInitializeFormOpen: true,
    isInitializeFormOpenable: true,
    isInitializeFormAnimated: getIsMobileDevice() || (isEmbed && isIFrame),
    initializeHeading: getAccordianHeading('InitializePurchase', initialTotalAmount),
    isFinalizeFormOpen: false,
    isFinalizeFormOpenable: false,
    isFinalizeFormAnimated: true,
    refineHeading: getAccordianHeading('RefinePurchase', initialTotalAmount),
    finalizeHeading: getAccordianHeading('FinalizePurchase', initialTotalAmount),
    isShowInitializePurchaseBox: true,
    isShowRefinePurchaseBox: getCustomFields().length > 0, //Yes depending on if custom fields are present.
    isShowFinalizePurchaseBox: true,
    isEmbed,
    isIFrame,
    imBranding,
    showConvenienceFee,
    isInternationalNumberEnabled,
    isMobileDevice: getIsMobileDevice(),
    sellerAvatar,
    seller: {
      username,
      name,
    },
    smartlinksHeader: {
      colour,
      coverImage,
      description,
    },
    isBackwardMotion: false,
    showDeferred: false,
    title,
    hasSmartPricing:
      lineItems && lineItems.length && lineItems[0].children.length ? true : false,
  };
};

const getInstapayInitialState = (state) => {
  const { should_retry: shouldRetry } = state;
  const {
    is_embed: isEmbed,
    is_iframe: isIFrame,
    im_branding: imBranding,
    is_direct_to_payments: isDirectToPayments,
    is_rap: isRAP,
    show_convenience_fee: showConvenienceFee,
    is_international_enabled: isInternationalNumberEnabled,
    seller: { username, avatar, name },
    prefilled: { amount } = {},
  } = shouldRetry ? state.retry_data : state;

  const initialState = {
    isInstapayPayment: true,
    isRAP,
    isEmbed,
    isIFrame,
    imBranding,
    showConvenienceFee,
    isInternationalNumberEnabled,
    isOfferAvailable: true,
    isPaymentPopupOpen: true,
    isInitializeFormOpen: true,
    isInitializeFormOpenable: true,
    isFinalizeFormOpenable: false,
    isShowInitializePurchaseBox: true,
    isShowFinalizePurchaseBox: true,
    initializeHeading: getAccordianHeading('InitializePurchase', amount),
    finalizeHeading: getAccordianHeading('FinalizePurchase', amount),
    sellerAvatar: avatar,
    seller: {
      username,
      name,
    },
  };

  if (isDirectToPayments) {
    return {
      ...initialState,
      isInitializeFormOpen: false,
      isInitializeFormOpenable: false,
      isShowInitializePurchaseBox: false,
      isFinalizeFormOpen: true,
      isDirectToPayments,
    };
  }

  return initialState;
};

const getResponseInitialState = (state) => {
  return {
    isPaymentResponse: true,
    responseType: state.response_type,
    isCartOrder: state.payment.is_cart_order,
    isPaymentPopupOpen: true,
    isRetryEnabled: state.should_retry,
    hasFailureRedirection: state.has_failure_redirection,
    isRetrying: false,
    shouldShowPaymentOptionsDirectlyOnRetry: state.direct_to_payments,
    smartlinksHeaderColor: state?.payment?.link?.colour,
  };
};

const getInitialState = (state) =>
  state.is_payment_response
    ? getResponseInitialState(state)
    : state.is_link_payment
    ? getLinkInitialState(state)
    : state.is_instapay_payment
    ? getInstapayInitialState(state)
    : state.offer
    ? getOfferInitialState(state)
    : {};
