/* globals INITIAL_STATE */
import offersRootReducer from '../../../bundles/payments/rootReducer';
import instapayRootReducer from './instapay';
import linksRootReducer from './links';

const retry = () => {
  const { response_type: responseType } = INITIAL_STATE;

  if (responseType == 'link') {
    return linksRootReducer;
  } else if (responseType == 'instapay') {
    return instapayRootReducer;
  } else {
    return offersRootReducer;
  }
};

export default retry();
