import Rupee from '@instamojo/rupee';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Button from '../../../common/components/form/Button';
import { InputCheckbox } from '../../../common/components/FormComponents';
import { Columns, Row } from '../../../common/components/Grid';
import Loading from '../../../common/components/Loading';
import MojoPopup from '../../../common/components/MojoPopup';
import { handleChangeInput } from '../../../payments/payments/actions/InitializePurchaseActions';
import LinksMerchantHeader from '../../../payments/payments/components/links/LinksMerchantHeader';
import { TOTAL_AMOUNT_CAN_CHANGE } from '../../../payments/payments/constants/GlobalConstants';
import {
  handleChangeItemSelection,
  handleTogglePaymentLinkPopup,
} from '../actions/SmartLinksActions';

class SmartLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCoverImageDownloaded: false,
    };
  }

  componentDidMount() {
    if (!this.props.smartLinks) {
      return null;
    }
    const {
      link: {
        amount,
        smartPricing: { showLineItems, lineItems, minimumAmount },
      },
    } = this.props.smartLinks;

    // let mandatoryLineItems = [];
    if (showLineItems) {
      const displayAmount = lineItems && lineItems.length ? minimumAmount : amount;
      //Set the total amount to the calculated total amount (Depending on mandatory/optional) in smart pricing checkout;
      this.props.handleChangeInput({ amount: displayAmount }, TOTAL_AMOUNT_CAN_CHANGE);
    }
  }

  render() {
    if (!this.props.smartLinks) {
      return null;
    }
    const {
      link: {
        colour,
        coverImage,
        description,
        title,
        amount,
        smartPricing: { minimumAmount, lineItems },
      },
      seller: { avatar, name, username },
      isPaymentLinkPopupOpen,
    } = this.props.smartLinks;

    //Inititially only one container is present. Will have to change this when multiple are there.
    const { isMerchantViewingOwnLink } = this.props.settings;

    const backdropStyle = {
      background: 'rgba(241, 244, 242, 0.94)',
    };

    //Change the item selection in reducer
    //Get all the items with the selectedStatus = true and update;
    const selectedLineItems = lineItems.reduce((filtered, item) => {
      if (item.selected) {
        const newOptionalArray = {
          id: item.id,
          description: item.description,
          price: item.price,
          selected: item.selected,
        };
        filtered.push(newOptionalArray);
      }
      return filtered;
    }, []);

    const paymentPageHeaderProps = {
      sellerAvatar: avatar,
      seller: {
        name,
        username,
      },
      title: title,
      smartlinksHeader: {
        colour,
        description,
      },
      showDeferred: true, //TODO This needs to be set either through reducer or on load of app.
    };
    let descriptionText = description;
    if (descriptionText) {
      descriptionText = descriptionText.split('\n').map((item, key) => {
        return (
          <div style={{ lineHeight: '20px' }} key={key}>
            {item}
          </div>
        );
      });
    }
    const breakLinkStyle = { borderColor: 'rgba(78,78,145,0.1)' };
    const displayAmount = lineItems && lineItems.length ? minimumAmount : amount;
    return (
      <MojoPopup
        show={isPaymentLinkPopupOpen}
        showCloseModalIcon={false}
        contentContainerStyle={{
          width: '320px',
          marginTop: `${isMerchantViewingOwnLink ? '76px' : '64px'}`,
          marginBottom: '64px',
        }}
        backdropStyle={{ ...backdropStyle }}
        contentStyle={{ padding: '0px 0px' }}
        onClose={() => {
          /* Todo something */
        }}
      >
        <LinksMerchantHeader {...paymentPageHeaderProps} />
        <div className="soft background-white">
          {coverImage ? (
            <div
              className="text-center"
              style={
                this.state.isCoverImageDownloaded
                  ? { backgroundColor: '#f3f5f8' }
                  : { backgroundColor: '' }
              }
            >
              {!this.state.isCoverImageDownloaded ? <Loading /> : null}
              <img
                alt="Cover Image"
                src={coverImage}
                style={
                  this.state.isCoverImageDownloaded
                    ? { maxHeight: '194px' }
                    : { display: 'none' }
                }
                onLoad={() => {
                  this.setState({ isCoverImageDownloaded: true });
                }}
              />
            </div>
          ) : null}
          <p className="soft--ends">{title}</p>
          <div
            className={classNames('text-grey soft--bottom break-long-words', {
              hidden: !description,
            })}
          >
            {' '}
            {descriptionText}{' '}
          </div>
          <div>
            {lineItems.length > 0 ? (
              <div>
                <p className="text-light-grey strong"> Amount Details </p>
                <hr className="flush" style={breakLinkStyle} />
                {lineItems.map((item, i) => {
                  if (item.type == 'Mandatory') {
                    return (
                      <>
                        <Row className="soft--ends" key={i}>
                          <Columns className="small-7 hard--left">
                            {item.description}
                          </Columns>
                          <Columns className="small-5 right hard">
                            <Rupee value={item.price} />
                          </Columns>
                        </Row>
                        <hr className="flush" style={breakLinkStyle} />
                      </>
                    );
                  } else if (item.type == 'Checkbox') {
                    return (
                      <>
                        <Row className="soft--ends" key={i}>
                          <Columns className="small-7 hard--left">
                            <InputCheckbox
                              label={item.description}
                              className="flush--bottom"
                              checked={item.selected}
                              checkboxClassName="flush--bottom"
                              onChange={(e) => {
                                this.props.handleChangeItemSelection(
                                  item.id,
                                  e.target.checked,
                                  item.price,
                                );
                              }}
                              style={{ fontSize: 16 }}
                            />
                          </Columns>
                          <Columns className="small-5 right hard">
                            <Rupee value={item.price} />
                          </Columns>
                        </Row>
                        <hr className="flush" style={breakLinkStyle} />
                      </>
                    );
                  }
                })}
              </div>
            ) : null}
          </div>
          <Row className="soft--ends text-green strong">
            <Columns className="small-6 hard--left ">Total Amount</Columns>
            <Columns className="small-6 right hard">
              <Rupee value={displayAmount} />
            </Columns>
          </Row>

          <Button
            onClick={() => {
              this.props.handleChangeInput(
                { amount: displayAmount },
                TOTAL_AMOUNT_CAN_CHANGE,
              );
              this.props.handleChangeInput({ smartPricingLineItems: selectedLineItems });
              this.props.handleTogglePaymentLinkPopup(false);
            }}
            className="btn btn-block btn-green"
            disabled={!displayAmount}
          >
            {' '}
            Pay Now
          </Button>
        </div>
      </MojoPopup>
    );
  }
}

SmartLinks.propTypes = {
  smartLinks: PropTypes.object,
  handleTogglePaymentLinkPopup: PropTypes.func.isRequired,
  settings: PropTypes.object,
  handleChangeInput: PropTypes.func,
  handleChangeItemSelection: PropTypes.func,
};

const mapStateToProps = ({ smartLinks, settings, initializePurchase }) => ({
  smartLinks,
  settings,
  initializePurchase,
});

export default connect(mapStateToProps, {
  handleTogglePaymentLinkPopup,
  handleChangeInput,
  handleChangeItemSelection,
})(SmartLinks);
