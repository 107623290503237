import classnames from 'classnames';
import Proptypes from 'prop-types';
import React from 'react';

class BreadcrumbNav extends React.Component {
  render() {
    return (
      <ul
        className={classnames('nav nav--breadcrumb', this.props.className)}
        style={{
          padding: '10px 0',
          borderBottom: '1px solid rgba(227, 236, 242, 0.5)',
          marginBottom: 16,
          ...this.props.style,
        }}
      >
        {this.props.children}
      </ul>
    );
  }
}

BreadcrumbNav.defaultProps = {
  className: '',
};

BreadcrumbNav.propTypes = {
  className: Proptypes.string,
  style: Proptypes.object,
  children: Proptypes.node,
};

export default BreadcrumbNav;
