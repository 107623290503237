/* globals mixpanel, ga, Sentry */
import root from 'window-or-global';

import { isHostProduction } from '../utils/domainResolution';
import { FootprintsEvents } from './analytics/footprintsEvent';
import { getCookie, getUrlParameterByName } from './utils';
import {
  getBrowserName,
  getBrowserVersion,
  getDevice,
  getOS,
  getReferrerDomain,
  getSearchEngine,
} from './utils/mixpanelUtils';

let isProduction;
if (!root.location) {
  isProduction = false;
} else {
  isProduction = isHostProduction(root.location.host);
}

if (!root.MojoUser) {
  root.MojoUser = {};
}

function eventTracker(category, action, label, value) {
  label = label || false;
  value = value || false;

  if (label && value) {
    ga('send', 'event', category, action, label, value);
  } else if (label) {
    ga('send', 'event', category, action, label);
  } else {
    ga('send', 'event', category, action);
  }
}

let queuedEvents = [];

const MixpanelAPI = {
  setUserProps: function (user, userProps = {}) {
    if (!user.delay_mixpanel_identify && user.id) {
      mixpanel.identify(user.id);
    }

    const userAgentBotTest = navigator.userAgent;
    mixpanel.register({ user_agent: userAgentBotTest });
    if (/bot/i.test(userAgentBotTest)) {
      mixpanel.register({ $ignore: 'true' });
    }

    if (user && user.username) {
      mixpanel.people.set({
        id: user.id,
        $email: user.email,
        $username: user.username,
        $phone: user.phone,
        user_level: user.user_level,
        ...userProps,
      });
    }
  },

  sendEvent: function (eventName, eventData, callback) {
    if (FootprintsEvents.indexOf(eventName) !== -1 && root.FootprintsApiUrl) {
      FootprintsAPI.sendEvent(eventName, eventData, callback);
    } else {
      mixpanel.track(eventName, eventData, callback);
    }
  },

  setNewUsername: function (newUsername) {
    root.MojoUser.username = newUsername;
    mixpanel.register({ username: newUsername });
    mixpanel.people.set({ $username: newUsername });
  },

  setNewConvenienceFeeStatus: function (updatedStatus) {
    root.MojoUser.convenience_fee_status = updatedStatus;
    mixpanel.register({ convenience_fee_status: updatedStatus });
    mixpanel.people.set({ $convenience_fee_status: updatedStatus });
  },

  queueEvent: function (eventName, eventData) {
    queuedEvents.push({ eventName, eventData });
  },

  clearQueue: function () {
    queuedEvents.forEach((event) => {
      const { eventName, eventData } = event;
      MixpanelAPI.sendEvent(eventName, eventData);
    });

    queuedEvents = [];
  },
};

const FootprintsAPI = {
  /**
   * Generates browser related data to be sent to analytics service's
   * http endpoint (yet to be implemented). This data replicates
   * mixpanel's special data which is genrated by mixpanel-js library
   * internally and sent to mixpanel when we call mixpanel.track(). In
   * order to send the same data to analytics service, we need to
   * generate it manually.
   */
  getBrowserData: function () {
    const referrer = document.referrer;
    const extraData = {
      $browser: getBrowserName(),
      $browser_version: getBrowserVersion(),
      $current_url: root.location.href,
      $device: getDevice(),
      $os: getOS(),
      $referrer: referrer,
      $referrer_domain: getReferrerDomain(referrer),
      $search_engine: getSearchEngine(referrer),
      $screen_height: root.screen.height,
      $screen_width: root.screen.width,
    };

    const utmParamteres = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_content',
      'utm_term',
    ];
    utmParamteres.forEach((utmParam) => {
      // First try to fetch the utm value from url query parameter, if
      // not available in url query param, fallback to using mixpanel.get_property.
      // Also perform a check if mixpanel.get_property is avaible as
      // mixpanel.get_property is `undefined` when ad blocker is active
      let utmValue = getUrlParameterByName(utmParam);
      if (utmValue === '' && mixpanel.get_property) {
        utmValue = mixpanel.get_property(utmParam);
      }
      if (utmValue) {
        extraData[utmParam] = utmValue;
      }
    });
    return extraData;
  },

  getDistinctId: function () {
    if (root.MIXPANEL_DISTINCT_ID) {
      return root.MIXPANEL_DISTINCT_ID;
    } else {
      const mpCookie = getCookie(`mp_${root.MIXPANEL_TOKEN}_mixpanel`);
      return mpCookie ? JSON.parse(mpCookie).distinct_id || null : null;
    }
  },

  sendEvent: function (eventName, eventData, callback) {
    const distinct_id = FootprintsAPI.getDistinctId();

    const payload = {
      event_name: eventName,
      event_source: 'frontend-webapp',
      event_time: Math.floor(Date.now() / 1000),
      event_data: Object.assign(eventData, FootprintsAPI.getBrowserData()),
      distinct_id: distinct_id,
      user_account_id: root.MojoUser.id,
    };

    fetch(root.FootprintsApiUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
      })
      .catch((error) => {
        Sentry?.withScope((scope) => {
          scope.setTag('api', 'footprints');
          scope.setLevel('fatal');
          scope.setExtra('error', error);
          scope.setExtra('payload', payload);
          scope.setExtra('footprints_url', root.FootprintsApiUrl);
          Sentry.captureException(new Error('Failed Footprints Event'));
        });
      });

    if (callback instanceof Function) {
      callback();
    }
  },
};

function Analytics(provider) {
  provider = provider || 'mixpanel'; //flexibility to change provider later

  const providerApiMap = {
    mixpanel: MixpanelAPI,
  };

  const tracker = providerApiMap[provider];

  typeof mixpanel !== 'undefined' ? tracker.setUserProps(root.MojoUser) : null;
  return {
    sendEvent: function (eventName, eventData = {}, ga = false, callback) {
      if (typeof eventName !== 'string') {
        throw Error('Expected first argument to be a string');
      }

      if (ga && isProduction) {
        eventTracker(
          eventData.category || eventName,
          eventData.action || '',
          eventData.label || false,
          eventData.value || false,
        );
      }

      if ('onLine' in root && !root.onLine) {
        // assign the time at which event occurred.
        tracker.queueEvent(eventName, {
          ...eventData,
          offline: true,
          time: Math.floor(Date.now() / 1000),
        });
      }
      /* send the actual event */
      tracker.sendEvent(eventName, eventData, callback);
    },

    clearQueuedEvents: () => {
      tracker.clearQueue();
    },
    setUserProps: (userProps) => {
      tracker.setUserProps(root.MojoUser, userProps);
    },
    setNewUsername: (newUsername) => {
      tracker.setNewUsername(newUsername);
    },
    setNewConvenienceFeeStatus: (updatedStatus) => {
      tracker.setNewConvenienceFeeStatus(updatedStatus);
    },
  };
}

const analytics = new Analytics();

export default analytics;
