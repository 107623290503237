import root from 'window-or-global';

import { resolveCorrectOriginForDashboardDomain } from '../../../common/utils/domainResolution';


export const getStoreLink = (username, ref) => {
  if (ref) {
    return `${resolveCorrectOriginForDashboardDomain(root.location.origin)}/${username}/?ref=${ref}`;
  }
  return `${resolveCorrectOriginForDashboardDomain(root.location.origin)}/${username}/`;
};
