import PropTypes from 'prop-types';
import React from 'react';

import { FormFieldWithLabel } from '../../../../common/components/FormComponents';
import { range } from '../../utils/InitializePurchaseUtils';

const Quantity = ({
  isQuantityApplicable,
  quantity,
  errors,
  maxQuantity,
  onChangeInput,
}) => {
  if (!isQuantityApplicable) {
    return null;
  }

  return (
    <FormFieldWithLabel label="Quantity" errors={errors.quantity}>
      <select
        value={quantity}
        onChange={(e) =>
          onChangeInput({
            quantity: e.target.value,
          })
        }
      >
        {range(maxQuantity).map((qty) => (
          <option key={qty} value={qty}>
            {qty}
          </option>
        ))}
      </select>
    </FormFieldWithLabel>
  );
};

Quantity.propTypes = {
  isQuantityApplicable: PropTypes.bool,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.object,
  maxQuantity: PropTypes.number,
  onChangeInput: PropTypes.func,
};

export default Quantity;
