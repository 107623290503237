/* globals STATIC_URL */
import PropTypes from 'prop-types';
import React from 'react';

import { Columns, Row } from '../../../../../common/components/Grid';

const ErrorMessageBlock = ({ message }) => {
  return (
    <div className="error-message-block push--bottom">
      <Row>
        <Columns className="small-2 text--right hard--left">
          <img
            src={`${STATIC_URL}assets/images/online-store/info.svg`}
            width="14"
            alt="info"
            style={{ marginTop: '-2px' }}
          />
        </Columns>
        <Columns className="small-10 hard--left">
          <span style={{ color: '#6E6E85', fontSize: '13px' }}>
            <span>
              {' '}
              {message}
            </span>
          </span>
        </Columns>
      </Row>
    </div>
  );
};

ErrorMessageBlock.propTypes = {
    message: PropTypes.string,
  };

export default ErrorMessageBlock;
