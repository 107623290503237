import PropTypes from 'prop-types';
import React from 'react';

class InputRadioButton extends React.Component {
  render() {
    const { label, labelClassName, radioClassName, ...others } = this.props;
    return (
      <label className={this.props.className}>
        <input
          type="radio"
          {...others}
          className={`push-half--right ${radioClassName}`}
        />
        <span className={labelClassName}>{label}</span>
      </label>
    );
  }
}

InputRadioButton.propTypes = {
  label: PropTypes.any.isRequired,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  radioClassName: PropTypes.string,
};

InputRadioButton.defaultProps = {
  className: '',
  labelClassName: '',
};

export default InputRadioButton;
