import PropTypes from 'prop-types';
import React from 'react';

import CartHeader from '../../../dashboard/cart/components/CartHeader';
import { Modes } from '../../../dashboard/cart/config';
import LinksPostPurchaseMerchantHeader from './links/LinksPostPurchaseMerchantHeader';
import BrandFooter from './payments/BrandFooter';
import PostPurchaseResponse from './post-purchase/PostPurchaseResponse';
import ProductsPostPurchaseMerchantHeader from './products/ProductsPostPurchaseMerchantHeader';

const PostPurchase = ({
  responseType,
  isCartOrder,
  shouldShowPaymentOptionsDirectlyOnRetry,
  postPurchase,
  settings,
}) => {
  document.body.style.background = 'none';
  return (
    <div>
      {responseType == 'link' || responseType == 'instapay' ? (
        <LinksPostPurchaseMerchantHeader
          sellerAvatar={postPurchase.payment.seller.avatar}
          fullName={postPurchase.payment.seller.fullname}
          smartlinksHeaderColor={settings?.smartlinksHeaderColor}
        />
      ) : isCartOrder ? (
        <div style={{ background: '#fff', borderRadius: '4px 4px 0 0' }}>
          <CartHeader
            purchased={postPurchase.status === 'success'}
            mode={Modes.FULL}
            totalItems={postPurchase.payment.subOrders.length}
          />
        </div>
      ) : (
        <ProductsPostPurchaseMerchantHeader
          sellerAvatar={postPurchase.payment.seller.avatar}
          fullName={postPurchase.payment.seller.fullname}
          preview={postPurchase.payment.offer.preview}
          title={postPurchase.payment.offer.title}
        />
      )}
      <PostPurchaseResponse
        isCartOrder={isCartOrder}
        shouldShowPaymentOptionsDirectlyOnRetry={shouldShowPaymentOptionsDirectlyOnRetry}
        responseType={responseType}
        {...postPurchase}
      />

      <BrandFooter settings={settings} type="light" />
    </div>
  );
};

PostPurchase.propTypes = {
  responseType: PropTypes.string,
  isCartOrder: PropTypes.bool,
  shouldShowPaymentOptionsDirectlyOnRetry: PropTypes.bool,
  postPurchase: PropTypes.object,
  settings: PropTypes.object,
};

export default PostPurchase;
