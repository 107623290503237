import ErrorsHigherOrderComponent from '@instamojo/errors-hoc';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import FormField from './FormField';

export default class FormFieldWithLabel extends ErrorsHigherOrderComponent {
  render() {
    const {
      isCard,
      className,
      labelClassName,
      errors,
      label,
      children,
      rightLabel,
    } = this.props;
    const childrenClassName = classnames({
      'card soft-card': isCard,
      'can-has-errors': 'errors' in this.props,
      'has-errors':
        (Array.isArray(errors) && errors.filter((error) => error).length) ||
        (typeof errors === 'string' && errors.length),
    });
    const formFieldClassName = isCard ? `${className}` : className;
    return (
      <FormField className={formFieldClassName}>
        {label ? (
          <div>
            <label
              className={labelClassName}
              style={rightLabel ? { display: 'inline-block' } : {}}
            >
              {label}
            </label>
            {rightLabel ? rightLabel : null}
          </div>
        ) : null}
        <div className={childrenClassName}>
          {children}
          {errors ? this.getErrorsBlock(errors) : null}
        </div>
      </FormField>
    );
  }
}

FormFieldWithLabel.propTypes = {
  label: PropTypes.any,
};

FormFieldWithLabel.defaultProps = {
  className: '',
  isCard: false,
};
