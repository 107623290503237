/* global mixpanel */
import * as PERMISSIONS from '../../rbac/utils/RBACPermissions';
import { isApiRequestBlocked } from '.././middlewares/middlewareUtils';

export const MARK_USER_LOGGED_OUT = 'MARK_USER_LOGGED_OUT';

export const DISABLE_ACTION_ITEMS = 'DISABLE_ACTION_ITEMS';
export const ENABLE_ACTION_ITEMS = 'ENABLE_ACTION_ITEMS';
export const ENABLE_PAGE_STATE_ASYNC = 'ENABLE_PAGE_STATE_ASYNC';
export const DISABLE_PAGE_STATE_ASYNC = 'DISABLE_PAGE_STATE_ASYNC';

export const TOGGLE_MODAL = 'TOGGLE_MODAL';

export const REQUEST_TOUR_DATA = 'REQUEST_TOUR_DATA';
export const REQUEST_TOUR_DATA_SUCCESS = 'REQUEST_TOUR_DATA_SUCCESS';
export const REQUEST_TOUR_DATA_FAILURE = 'REQUEST_TOUR_DATA_FAILURE';

export const SET_TOUR_DATA = 'SET_TOUR_DATA';
export const SET_TOUR_DATA_SUCCESS = 'SET_TOUR_DATA_SUCCESS';
export const SET_TOUR_DATA_FAILURE = 'SET_TOUR_DATA_FAILURE';

export const NOTIFICATION_BEFORE_DISMISS_REQUEST = 'NOTIFICATION_BEFORE_DISMISS_REQUEST';
export const NOTIFICATION_BEFORE_DISMISS_REQUEST_SUCCESS =
  'NOTIFICATION_BEFORE_DISMISS_REQUEST_SUCCESS';
export const NOTIFICATION_BEFORE_DISMISS_REQUEST_FAILURE =
  'NOTIFICATION_BEFORE_DISMISS_REQUEST_FAILURE';

export const HANDLE_GENERATE_NOTIFICATION = 'HANDLE_GENERATE_NOTIFICATION';
export const HANDLE_REMOVE_NOTIFICATION = 'HANDLE_REMOVE_NOTIFICATION';
export const HANDLE_REMOVE_ALL_NOTIFICATIONS = 'HANDLE_REMOVE_ALL_NOTIFICATIONS';

import md5 from 'md5';
import root from 'window-or-global';

import { MARK_APP_OFFLINE, MARK_APP_ONLINE } from '../actions/PageStateActions';
import { CALL_API } from '../middlewares/api';
import { POST_API } from '../middlewares/form';
import { sendMessage } from '../utils/serviceWorkers';

export function requestLogout(forcedRedirectToRoot = false) {
  const logoutUrl =
    forcedRedirectToRoot || root.MojoUser.user_level == 'Buyer' ? '/' : '/welcome-back/';
  return {
    [POST_API]: {
      types: ['LOGOUT_REQUEST', 'LOGOUT_REQUEST_SUCCESS', 'LOGOUT_REQUEST_FAILURE'],
      endpoint: '/webapi/logout/',
      payload: {},
      onSuccess: function () {
        if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
          sendMessage({
            command: 'flush',
          }).then(() => {
            navigator.serviceWorker.getRegistration().then((r) => {
              r.unregister();
              window.location.href = logoutUrl;
            });
          });
        } else {
          window.location.href = logoutUrl;
        }
        mixpanel && mixpanel.reset();
      },
    },
  };
}

export function requestSSOLogout() {
  const logoutUrl = '/sso/logout/';
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      sendMessage({
        command: 'flush',
      }).then(() => {
        navigator.serviceWorker.getRegistration().then((r) => {
          r.unregister();
          window.location.href = logoutUrl;
        });
      });
    } else {
      window.location.href = logoutUrl;
    };
    mixpanel && mixpanel.reset();
}

function handleGenerateNotification(notification) {
  return {
    type: HANDLE_GENERATE_NOTIFICATION,
    notification,
  };
}

export function handleNotificationBeforeDismissHook(beforeDismiss) {
  return {
    [POST_API]: {
      types: [
        NOTIFICATION_BEFORE_DISMISS_REQUEST,
        NOTIFICATION_BEFORE_DISMISS_REQUEST_SUCCESS,
        NOTIFICATION_BEFORE_DISMISS_REQUEST_FAILURE,
      ],
      endpoint: beforeDismiss.url,
      payload: () => {
        const key = beforeDismiss.key;
        const value = beforeDismiss.value;
        return {
          [key]: value,
        };
      },
    },
  };
}

export function handleRemoveNotification(notification) {
  return {
    type: HANDLE_REMOVE_NOTIFICATION,
    notification,
  };
}

export const handleRemoveAllNotifications = () => ({
  type: HANDLE_REMOVE_ALL_NOTIFICATIONS,
});

export function showNotification(notification) {
  return (dispatch) => {
    // TODO find a better way to remove notification in future
    dispatch(handleRemoveNotification());
    if (!notification.id) {
      notification = { ...notification, id: md5(notification.message) };
    }

    dispatch(handleGenerateNotification(notification));

    if (notification.autoDismiss) {
      setTimeout(() => {
        dispatch(handleRemoveNotification(notification));
      }, 5000);
    }
  };
}

// ------------------ Global Actions earlier made for private bundle only------------------

export function markAppOffline({ isOffline, notification }) {
  return (dispatch) => {
    if (isOffline) {
      dispatch({ type: MARK_APP_OFFLINE });
      dispatch(showNotification(notification));
    } else {
      dispatch({ type: MARK_APP_ONLINE });
      dispatch(handleRemoveNotification(notification));
    }
  };
}

export function toggleModal(isModalShown) {
  return {
    type: TOGGLE_MODAL,
    isModalShown,
  };
}

export function cacheInServiceWorker({ pathname }) {
  return {
    [CALL_API]: {
      endpoint:
        pathname.lastIndexOf('/') === pathname.length - 1 ? pathname : pathname + '/',
      types: [
        'SERVICE_WORKER_CACHE_REQUEST',
        'SERVICE_WORKER_CACHE_SUCCESS',
        'SERVICE_WORKER_CACHE_FAILURE',
      ],
    },
  };
}

export function requestTourData(tourName) {
  return {
    [CALL_API]: {
      endpoint: `/webapi/tours/${tourName ? `?key=${tourName}` : ''}`,
      types: [
        'REQUEST_TOUR_DATA',
        'REQUEST_TOUR_DATA_SUCCESS',
        'REQUEST_TOUR_DATA_FAILURE',
      ],
    },
  };
}

export function setTourData(tourName, step) {
  return {
    [POST_API]: {
      endpoint: '/webapi/tours/',
      types: ['SET_TOUR_DATA', 'SET_TOUR_DATA_SUCCESS', 'SET_TOUR_DATA_FAILURE'],
      payload: {
        key: tourName,
        value: step,
      },
      successTypeActionProps: {
        step,
        tourName,
      },
    },
  };
}

export const HANDLE_FETCH_USER_DETAILS_REQUEST = 'HANDLE_FETCH_USER_DETAILS_REQUEST';
export const HANDLE_FETCH_USER_DETAILS_SUCCESS = 'HANDLE_FETCH_USER_DETAILS_SUCCESS';
export const HANDLE_FETCH_USER_DETAILS_FAILURE = 'HANDLE_FETCH_USER_DETAILS_FAILURE';
export const handleFetchUserDetails = (details) => ({
  [CALL_API]: {
    endpoint: `/webapi/user_details/?details=${details.toString()}`,
    types: [
      HANDLE_FETCH_USER_DETAILS_REQUEST,
      HANDLE_FETCH_USER_DETAILS_SUCCESS,
      HANDLE_FETCH_USER_DETAILS_FAILURE,
    ],
    bailout: isApiRequestBlocked(PERMISSIONS.PERSONA.USER_DETAIL_VIEW),
  },
});


export const HANDLE_FETCH_MERCHANT_COUNT_REQUEST = 'HANDLE_FETCH_MERCHANT_COUNT_REQUEST';
export const HANDLE_FETCH_MERCHANT_COUNT_SUCCESS = 'HANDLE_FETCH_MERCHANT_COUNT_SUCCESS';
export const HANDLE_FETCH_MERCHANT_COUNT_FAILURE = 'HANDLE_FETCH_MERCHANT_COUNT_FAILURE';
export const handleFetchMerchantCount = () => ({
  [CALL_API]: {
    endpoint: '/webapi/config/?key=merchant_count',
    types: [
      HANDLE_FETCH_MERCHANT_COUNT_REQUEST,
      HANDLE_FETCH_MERCHANT_COUNT_SUCCESS,
      HANDLE_FETCH_MERCHANT_COUNT_FAILURE,
    ],
  },
});
