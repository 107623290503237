const getStateFieldObject = (shippingStates) => {
  const stateProps = {
    isShippingField: true,
    name: 'shipping_state',
    label: 'State',
    required: true,
    validationMessage: 'Enter the Shipping State',
  };

  if (shippingStates) {
    stateProps.type = 'choice';
    stateProps.choices = shippingStates;
  } else {
    stateProps.type = 'char';
  }

  return stateProps;
};

export const getShippingAddressFields = (shippingStates) => {
  return [
    {
      isShippingField: true,
      name: 'shipping_address',
      label: 'Shipping Address',
      type: 'text',
      required: true,
      placeholder: 'House number, building name, street name etc.',
      validationMessage: 'Enter the shipping address',
      hasCustomValidation: true,
      customValidation: (value) => /^[a-z0-9A-Z,-:\r\n \\&#[/()\];]*$/g.test(value),
      customValidationMessage:
        'Enter an address without special characters except , - : \\ / & # [ ( ) ] ;',
    },
    {
      ...getStateFieldObject(shippingStates),
    },
    {
      isShippingField: true,
      name: 'shipping_city',
      label: 'City',
      type: 'char',
      required: true,
      validationMessage: 'Enter the shipping city',
      grid: true,
      hasCustomValidation: true,
      customValidation: (value) => /^[a-z0-9A-Z,-:\r\n \\&#[/()\];]*$/g.test(value),
      customValidationMessage:
        'Enter a city without special characters except , - : \\ / & # [ ( ) ] ;',
    },
    {
      isShippingField: true,
      name: 'shipping_zip',
      label: 'Postal Code',
      type: 'char',
      required: true,
      validationMessage: 'Enter the shipping postal code',
      hasCustomValidation: true,
      customValidation: (value) =>
        /^[\d\s]{6,10}$/g.test(value) && value.replace(/\s/g, '').length >= 6,
      customValidationMessage: 'Enter a valid postal code',
      grid: true,
    },
    {
      isShippingField: true,
      name: 'shipping_country',
      label: 'Country',
      type: 'choice',
      choices: [['India', 'India']],
      required: true,
    },
  ];
};

const getLinkStateFieldObject = (shippingStates) => {
  const stateProps = {
    isShippingField: true,
    name: 'state',
    label: 'State',
    required: true,
    validationMessage: 'Enter the Shipping State',
  };

  if (shippingStates) {
    stateProps.type = 'choice';
    stateProps.choices = shippingStates;
  } else {
    stateProps.type = 'char';
  }

  return stateProps;
};

export const getLinkShippingAddressFields = (shippingStates) => {
  return [
    {
      isShippingField: true,
      name: 'address',
      label: 'Shipping Address',
      type: 'text',
      required: true,
      placeholder: 'House number, building name, street name etc.',
      validationMessage: 'Enter the Shipping Address',
      hasCustomValidation: true,
      customValidation: (value) => /^[a-z0-9A-Z,-:\r\n \\&#[/()\];]*$/g.test(value),
      customValidationMessage:
        'Enter an address without special characters except , - : \\ / & # [ ( ) ] ;',
    },
    {
      ...getLinkStateFieldObject(shippingStates),
    },
    {
      isShippingField: true,
      name: 'city',
      label: 'City',
      type: 'char',
      required: true,
      validationMessage: 'Enter the Shipping City',
      grid: true,
      hasCustomValidation: true,
      customValidation: (value) => /^[a-z0-9A-Z,-:\r\n \\&#[/()\];]*$/g.test(value),
      customValidationMessage:
        'Enter a city without special characters except , - : \\ / & # [ ( ) ] ;',
    },
    {
      isShippingField: true,
      name: 'zip',
      label: 'Pincode',
      type: 'char',
      required: true,
      validationMessage: 'Enter the Shipping Pincode',
      hasCustomValidation: true,
      customValidation: (value) =>
        /^[\d\s]{6,10}$/g.test(value) && value.replace(/\s/g, '').length >= 6,
      customValidationMessage: 'Enter a valid Pincode',
      grid: true,
    },
    // {
    //   isShippingField: true,
    //   name: 'country',
    //   label: 'Country',
    //   type: 'choice',
    //   choices: [['India', 'India']],
    //   required: true,
    // }
  ];
};

const getLinkBillingStateFieldObject = (shippingStates) => {
  const stateProps = {
    isBillingField: true,
    name: 'state',
    label: 'State',
    required: true,
    validationMessage: 'Enter the Billing State',
  };

  if (shippingStates) {
    stateProps.type = 'choice';
    stateProps.choices = shippingStates;
  } else {
    stateProps.type = 'char';
  }

  return stateProps;
};

export const getLinkBillingAddressFields = (shippingStates) => {
  return [
    {
      isBillingField: true,
      name: 'address',
      label: 'Billing Address',
      type: 'text',
      required: true,
      placeholder: 'House number, building name, street name etc.',
      validationMessage: 'Enter the Billing Address',
      hasCustomValidation: true,
      customValidation: (value) => /^[a-z0-9A-Z,-:\r\n \\&#[/()\];]*$/g.test(value),
      customValidationMessage:
        'Enter an address without special characters except , - : \\ / & # [ ( ) ] ;',
    },
    {
      ...getLinkBillingStateFieldObject(shippingStates),
    },
    {
      isBillingField: true,
      name: 'city',
      label: 'City',
      type: 'char',
      required: true,
      validationMessage: 'Enter the Billing City',
      hasCustomValidation: true,
      customValidation: (value) => /^[a-z0-9A-Z,-:\r\n \\&#[/()\];]*$/g.test(value),
      customValidationMessage:
        'Enter a city without special characters except , - : \\ / & # [ ( ) ] ;',
    },
    {
      isBillingField: true,
      name: 'zip',
      label: 'Pincode',
      type: 'char',
      required: true,
      validationMessage: 'Enter the Billing Pincode',
      hasCustomValidation: true,
      customValidation: (value) =>
        /^[\d\s]{6,10}$/g.test(value) && value.replace(/\s/g, '').length >= 6,
      customValidationMessage: 'Enter a valid Pincode',
    },
    // {
    //   isBillingField: true,
    //   name: 'country',
    //   label: 'Country',
    //   type: 'choice',
    //   choices: [['India', 'India']],
    //   required: true,
    // }
  ];
};

export const customFieldsNormalizer = (fields = []) =>
  fields.reduce((normalizedFields, field) => {
    if (field.isAdditionalField || field.is_additional_field) {
      const additionalCustomFields = normalizedFields['additional_custom_fields'] || {};
      additionalCustomFields[field.name] = field;
      normalizedFields['additional_custom_fields'] = additionalCustomFields;
    } else {
      normalizedFields[field.name] = field;
    }
    return normalizedFields;
  }, {});

export const customFormFields = ({ fields = [], prefilledCustomFields = {} }) =>
  fields.reduce((formFields, field) => {
    if (prefilledCustomFields[field.name]) {
      formFields[field.name] = prefilledCustomFields[field.name].value;
    } else if (field.isAdditionalField || field.is_additional_field) {
      const additionalCustomFields = formFields['additional_custom_fields'] || {};
      additionalCustomFields[field.name] = '';
      formFields['additional_custom_fields'] = additionalCustomFields;
    } else if (field.name === 'shipping_country') {
      /*
       * Special case
       * ------------
       * default shipping_country to India
       */
      formFields[field.name] = 'India';
    } else if (field.shouldUseAsObject) {
      formFields[field.name] = Object.keys(field).reduce((prev, curr) => {
        prev[curr] = '';
        return prev;
      }, {});
    } else {
      formFields[field.name] = '';
    }
    return formFields;
  }, {});
