import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../../components/Icon';

class BreadcrumbItem extends React.Component {
  render() {
    const { heading, isActive, isActivable, isCompleted, style } = this.props;
    return (
      <li style={style}>
        <a
          onClick={() => (isActivable ? this.props.onToggleActiveState(!isActive) : null)}
          style={{
            color: isCompleted
              ? '#434A54'
              : isActive && isActivable
              ? '#434A54'
              : '#93A0AF',
          }}
        >
          <span className="arrow">
            <Icon className="arrows-1_minimal-right" />
          </span>
          <span>{heading}</span>
        </a>
      </li>
    );
  }
}

BreadcrumbItem.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isActivable: PropTypes.bool,
  isCompleted: PropTypes.bool,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onToggleActiveState: PropTypes.func,
  style: PropTypes.object,
};

BreadcrumbItem.defaultProps = {
  isActive: false,
  isActivable: false,
  isCompleted: false,
  style: {},
};

export default BreadcrumbItem;
