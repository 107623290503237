/* globals INITIAL_STATE */
import * as ActionTypes from '../actions/SmartLinksActions';

const smartLinks = (state = getInitialState(INITIAL_STATE), action) => {
  switch (action.type) {
    case ActionTypes.HANDLE_TOGGLE_PAYMENT_LINK_POPUP: {
      return {
        ...state,
        isPaymentLinkPopupOpen: action.isSmartLinkPopupOpen,
      };
    }

    case ActionTypes.HANDLE_CHANGE_ITEM_SELECTION: {
      const updatedLineItems = state.link.smartPricing.lineItems;
      const objIndex = updatedLineItems.map((item) => item.id).indexOf(action.payload.id);
      updatedLineItems[objIndex].selected = action.payload.status;
      let updatedAmount = parseFloat(state.link.smartPricing.minimumAmount);
      if (action.payload.status) {
        updatedAmount += parseFloat(action.payload.amount);
      } else {
        updatedAmount -= parseFloat(action.payload.amount);
      }

      return {
        ...state,
        link: {
          ...state.link,
          smartPricing: {
            ...state.link.smartPricing,
            lineItems: updatedLineItems,
            minimumAmount: updatedAmount,
          },
        },
      };
    }

    default: {
      return state;
    }
  }
};

const getInitialState = ({ link, seller }) => {
  let minimumAmount = 0;
  let lineItems = [];
  const {
    line_items: rawLineItems = [],
    cover_image: coverImage,
    colour,
    description,
    title,
    amount,
  } = link;

  if (rawLineItems) {
    //Current implementation has only one container hence accessing the first child
    const buyerContainerLineItems = rawLineItems[0].children || [];

    //Get the minimum amount for the buyer
    minimumAmount = buyerContainerLineItems.reduce((acc, item) => {
      return item.choice_type == 'Mandatory' ? acc + item.price : acc;
    }, 0);

    //Get the line items for smart link
    lineItems = buyerContainerLineItems.map((item) => {
      const newReducedArray = {
        id: item.id,
        description: item.description,
        price: item.price,
        selected: item.choice_type !== 'Checkbox',
        type: item.choice_type,
      };
      return newReducedArray;
    });
  }

  return {
    link: {
      coverImage,
      colour,
      description,
      title,
      amount,
      smartPricing: {
        lineItems,
        minimumAmount,
        showLineItems: link.line_items && link.line_items.length > 0,
      },
    },
    seller,
    isPaymentLinkPopupOpen: true,
  };
};

export default smartLinks;
