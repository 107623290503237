import '../../styles/FormFieldTextArea.scss';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { FormFieldWithLabel, FormFieldWrapper } from '../FormComponents';

export default class FormFieldTextArea extends React.Component {
  getLabelWithRemainingCharacters = (maxCharacters, value) => {
    if (!maxCharacters) return null;

    const textLength = value ? value.length : 0;
    const textHasWarning =
      textLength > maxCharacters - 10 && textLength < maxCharacters + 1;
    const textHasError = textLength > maxCharacters;
    return (
      <p
        className={classnames('char-remaining', {
          'text-red': textHasError,
          'text-orange': textHasWarning,
        })}
      >
        {`${maxCharacters - textLength} characters left`}
      </p>
    );
  };

  getHelperText = (helperText) => {
    return (
      <div className="helper-text">
        {helperText === null ? null : <span>{helperText}</span>}
      </div>
    );
  };

  render() {
    const {
      rows,
      placeholder,
      label,
      helperText,
      value,
      readOnly,
      resizable,
      autoComplete,
      onChange,
      errors,
      maxCharacters,
      className,
      textAreaClassName,
    } = this.props;

    const textAreaStyle = !resizable ? { resize: 'none' } : {};

    const textLength = value ? value.length : 0;
    const textExceedsLength = maxCharacters ? textLength > maxCharacters : false;

    let textAreaHTML;
    if (readOnly) {
      textAreaHTML = (
        <textarea
          readOnly
          rows={rows}
          value={value}
          placeholder={placeholder}
          style={textAreaStyle}
          className={`${textAreaClassName} ${textExceedsLength ? 'text-red' : ''} ${
            maxCharacters ? 'max-char' : ''
          }`}
          autoComplete={autoComplete}
        />
      );
    } else {
      textAreaHTML = (
        <textarea
          rows={rows}
          value={value}
          placeholder={placeholder}
          style={textAreaStyle}
          className={`${textAreaClassName} ${textExceedsLength ? 'text-red' : ''} ${
            maxCharacters ? 'max-char' : ''
          }`}
          autoComplete={autoComplete}
          onChange={onChange}
        />
      );
    }

    return (
      <div className="form-field-text-area-wrapper">
        <FormFieldWrapper>
          <FormFieldWithLabel
            className={className}
            label={label}
            labelClassName="push-half--bottom"
            errors={errors}
          >
            {this.getHelperText(helperText)}
            {textAreaHTML}
            {this.getLabelWithRemainingCharacters(maxCharacters, value)}
          </FormFieldWithLabel>
        </FormFieldWrapper>
      </div>
    );
  }
}

FormFieldTextArea.propTypes = {
  rows: PropTypes.number.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  resizable: PropTypes.bool,
  autoComplete: PropTypes.oneOf([
    'street-address',
    'address-level1',
    'address-level2',
    'postal-code',
    'country-name',
    'tel-national',
  ]),
  onChange: PropTypes.func,
  maxCharacters: PropTypes.number,
  className: PropTypes.string,
  textAreaClassName: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
};

FormFieldTextArea.defaultProps = {
  helperText: null,
  readonly: false,
  resizable: false,
};
