import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class Icon extends Component {

  handleclick() {
    const { onClick } = this.props;
    if (typeof onClick === 'function') {
      onClick();
    }
  }

  render() {
    const { className, isCustomSvg } = this.props;
    let iconClassNames = className;
    if (isCustomSvg) {
      //Only when custom svg is used
      iconClassNames = `nucleo-custom-svg-icon ${className}`;
    } else if (className.indexOf('icon icon-') === -1) {
      //For all other icons without the icon class use the below
      iconClassNames = `nc-icon-outline ${className}`;
    }
    return (
      <i
        className={iconClassNames}
        onClick={() => this.handleclick()}
        style={{ marginRight: 6, ...this.props.style }}
      />
    );
  }
}

Icon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  isCustomSvg: PropTypes.bool,
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  style: {},
};
