import { combineReducers } from 'redux';

import { pageState } from '../../../common/reducers/PageStateReducers';
import finalizePurchase from './FinalizePurchaseReducers';
import initializePurchase from './InitializePurchaseReducers';
import instapayForm from './InstapayFormReducers';
import offerDetails from './OfferDetailsReducers';
import settings from './SettingsReducers';

const rootReducer = combineReducers({
  settings,
  initializePurchase,
  finalizePurchase,
  pageState,
  offerDetails,
  instapayForm,
});

export default rootReducer;
