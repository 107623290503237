import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import AccordionCard from '../../../../common/components/card/AccordionCard';

class PaymentCard extends React.Component {
  // Customized Card for Payment options
  render() {
    const { isDetailViewOpen } = this.props;
    const headerStyle = classNames('clearfix openable--actionbar hard--sides', {
      'payment-card-btn': !isDetailViewOpen,
      'dotted-border': isDetailViewOpen,
    });
    const arrowsStyle = classNames('nc-icon-outline sm push--right', {
      'arrows-1_minimal-down': !isDetailViewOpen,
      'arrows-1_minimal-up': isDetailViewOpen,
    });
    return (
      <div className={this.props.className}>
        <AccordionCard
          isDetailViewOpen={this.props.isDetailViewOpen}
          cardHeading={
            !this.props.isDetailViewOpen ? (
              <div className="columns small-12">
                <span>{this.props.cardHeading}</span>
                <span className="right">
                  <i className={arrowsStyle} />
                </span>
              </div>
            ) : null
          }
          onClick={(...args) => this.props.onClick(...args)}
          className={headerStyle}
        >
          {this.props.children}
        </AccordionCard>
      </div>
    );
  }
}

PaymentCard.propTypes = {
  isDetailViewOpen: PropTypes.bool,
  cardHeading: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default PaymentCard;
