import { combineReducers } from 'redux';

import settings from '../reducers/SettingsReducers';
import postPurchase from './PostPurchaseReducers';
import retry from './retry';

export default combineReducers({
  settings,
  postPurchase,
  retry,
});
