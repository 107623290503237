export const HANDLE_CHANGE_INSTAPAY_INPUT = 'HANDLE_CHANGE_INSTAPAY_INPUT';
export const handleChangeInstapayInput = (data) => ({
  type: HANDLE_CHANGE_INSTAPAY_INPUT,
  payload: {
    ...data,
  },
});

import LGTM from 'lgtm';

import { decimalPlaces } from '../../../common/config/utils';
const createInstapayValidator = (maxAmount) =>
  LGTM.validator()
    .validates('purpose')
    .using((purpose) => purpose.length <= 30, 'Enter less than 30 characters')
    .validates('amount')
    .required('Please Enter an Amount')
    .using((amount) => Number(amount) >= 9, 'Min amount should be Rs. 9')
    .using((amount) => Number(amount) <= maxAmount, `Max amount can be Rs. ${maxAmount}`)
    .using(
      (amount) => decimalPlaces(Number(amount)) <= 2,
      'Enter atmost 2 decimal places',
    )
    .build();

export const HANDLE_SUBMIT_INSTAPAY_SUCCESS = 'HANDLE_SUBMIT_INSTAPAY_SUCCESS';
export const HANDLE_SUBMIT_INSTAPAY_FAILURE = 'HANDLE_SUBMIT_INSTAPAY_FAILURE';
export const handleSubmitInstapay = () => (dispatch, getState) => {
  const {
    instapayForm: { purpose, amount, maxAmount },
  } = getState();
  return createInstapayValidator(maxAmount)
    .validate({ purpose, amount })
    .then((result) => {
      if (!result.valid) {
        return dispatch({
          type: HANDLE_SUBMIT_INSTAPAY_FAILURE,
          errors: result.errors,
        });
      }

      // NOTE if buyer data is available & prefilled
      // and need to move direct to payments
      // feature available on `feature/instapay-checkout-d2p`

      return dispatch({
        type: HANDLE_SUBMIT_INSTAPAY_SUCCESS,
        payload: {
          amount,
          purpose,
        },
      });
    });
};

export const HANDLE_CHANGE_INSTAPAY_FORM = 'HANDLE_CHANGE_INSTAPAY_FORM';
export const handleChangeInstapayForm = () => ({
  type: HANDLE_CHANGE_INSTAPAY_FORM,
});
