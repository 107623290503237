import PropTypes from 'prop-types';
import React from 'react';

import Modal, { ModalContent } from '../components/Modal';

const MojoPopup = ({
  show,
  showCloseModalIcon,
  contentContainerStyle,
  transparentContentBackground = false,
  children,
  onShow,
  onClose,
  backdropStyle = {},
  contentStyle = {},
  isUpiIntentIOSWaitModal = false,
}) => (
  <Modal
    show={show}
    backdropStyle={{
      background: 'rgba(0, 0, 0, 0.75)',
      ...backdropStyle,
    }}
    onShow={onShow}
    isUpiIntentIOSWaitModal={isUpiIntentIOSWaitModal}
  >
    <ModalContent
      style={{ position: 'relative', ...contentContainerStyle }}
      modalClose={{
        style: {
          color: '#E83C58',
          right: '4px',
          top: '16px',
        },
        onRequestClose: () => onClose(),
      }}
      isClosable={showCloseModalIcon}
    >
      <div
        className={transparentContentBackground ? '' : 'background-white'}
        style={{ borderRadius: '4px', padding: '16px 0px', ...contentStyle }}
      >
        {children}
      </div>
    </ModalContent>
  </Modal>
);

MojoPopup.propTypes = {
  show: PropTypes.bool,
  showCloseModalIcon: PropTypes.bool,
  contentContainerStyle: PropTypes.object,
  children: PropTypes.node,
  onShow: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  transparentContentBackground: PropTypes.bool,
  backdropStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  isUpiIntentIOSWaitModal: PropTypes.bool,
};

export default MojoPopup;

// TODO
// make 'onClose' property more understandable
// Perhaps change the name to 'onCloseIconClick'
