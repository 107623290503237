/* globals STATIC_URL */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import FormFieldInput2 from '../../../../../common/components/form/FormFieldInput2';
import {
  Form,
  FormFieldDropdownImages,
  FormFieldWithLabel as FormField,
  FormFieldWrapper as FormFieldsWrapper,
} from '../../../../../common/components/FormComponents';
import { Columns, Row } from '../../../../../common/components/Grid';
import Icon from '../../../../../common/components/Icon';
import MojoPopup from '../../../../../common/components/MojoPopup';
import ia from '../../../../../common/config/Analytics';
import {
  handleChangeUPIHandle,
  handleFetchConvenienceFees,
  handleShowUpiLabelPopup,
  handleShowUpiPopup,
  handleSubmitUPI,
  handleUPIOptions,
  setUpiPsp,
  validateUPIHandle,
} from '../../../actions/FinalizePurchaseActions';
import { UPIList, UPIOptionToVerificationTextMap } from '../../../config/Upi';
import TnCText from '../TnCText';
import UpiLabelPopup from '../UpiLabelPopup';
import UpiPopup from '../UpiPopup';
import Autocomplete from './Autocomplete';

class UPI extends React.Component {
  handleUPIOptions = (option, psp) => {
    this.props.handleUPIOptions(option);
    ia.sendEvent('Selected UPI Bank', {
      bank_name: option,
      seller_username: this.props.username,
      order_id: this.props.finalizePurchase.order,
      action: 'update_vpa_string',
    });
    this.props.setUpiPsp(psp);
  };

  upiPopupEvent = (ctaText) => {
    ia.sendEvent('Clicked Info Popup', {
      popup_cta: ctaText,
      popup_source: 'payment_form',
    });
  };

  renderUPIList() {
    return UPIList.map((ele, i) => {
      if (ele.value != 'others') {
        return (
          <Columns className="small-6 grid__item end" key={i}>
            <div
              className={classNames('grid__item--unit', {
                'bank-selected': false,
              })}
              onClick={() => this.handleUPIOptions(ele.value)}
            >
              <div style={{ height: '40px', display: 'table', width: '100%' }}>
                <div
                  style={{
                    display: 'table-cell',
                    verticalAlign: 'middle',
                    textAlign: 'center',
                  }}
                >
                  <span style={{ verticalAlign: 'middle' }}>
                    {this.renderUPIListItem(ele, i)}
                  </span>
                </div>
              </div>
            </div>
          </Columns>
        );
      } else {
        return (
          <Columns className="small-12 hard" key={i}>
            <a className="btn block" onClick={() => this.handleUPIOptions(ele.value)}>
              OTHER UPI APPS
            </a>
          </Columns>
        );
      }
    });
  }

  renderPostfix = (postfix, value) => {
    const {
      upiHandleError,
      isUPIRequestSubmitted,
      isUPIRequestSubmitting,
      selectedUpiPSP,
    } = this.props.finalizePurchase;

    // This return the selected UPI psp instead of postfix if the request process is started.
    if (isUPIRequestSubmitted || isUPIRequestSubmitting) {
      return selectedUpiPSP;
    }

    if (Array.isArray(postfix) && postfix.length > 1) {
      return (
        <FormField errors={upiHandleError}>
          <select
            value={value}
            onChange={(e) => this.props.setUpiPsp(e.target.value)}
            style={{
              border: 'none',
              backgroundColor: '#FAFBFD',
              color: '#93A0AF',
              backgroundSize: '12px',
            }}
          >
            {postfix.map((e, i) => (
              <option value={e} key={i}>
                {e}
              </option>
            ))}
          </select>
        </FormField>
      );
    } else if (Array.isArray(postfix) && postfix.length == 1) {
      return postfix[0];
    } else {
      return postfix;
    }
  };

  renderUPIListItem = (e, i) => {
    // Show either Image or Icon
    if (e.imageUrl) {
      return (
        <div key={i}>
          <img src={e.imageUrl} alt={e.displayName} width="80px" />
          {e.displayName ? <span>{e.displayName}</span> : null}
        </div>
      );
    } else if (e.iconClassName) {
      return (
        <div key={i}>
          <Icon className={e.iconClassName} />
          {e.displayName ? <span>{e.displayName}</span> : null}
        </div>
      );
    } else {
      return (
        <div key={i}>
          <span>{e.displayName}</span>
        </div>
      );
    }
  };

  render() {
    const {
      upiHandle,
      isUPIRequestSubmitted,
      isUPIRequestSubmitting,
      upiHandleError,
      selectedUPIOption = '',
      upiPsp,
      selectedUpiPSP,
      showUpiPopup,
      showUpiLabelPopup,
      showUPIList,
      selectedPaymentObject: selectedPaymentOption,
      formHtmlText,
      isUpiPopupVisible = false,
    } = this.props.finalizePurchase;
    return (
      <div>
        {showUPIList ? (
          <div>
            <Row className="banks--grid soft--sides">
              <p className="text-grey" style={{ fontSize: '14px' }}>
                Select your UPI app
              </p>
              {this.renderUPIList()}
              <Columns className="hard--sides push--top soft--ends">
                <a
                  onClick={(e) => {
                    this.props.handleShowUpiPopup(true);
                    this.upiPopupEvent(e.target.innerHTML);
                  }}
                >
                  How to pay using UPI?
                </a>
              </Columns>
            </Row>
          </div>
        ) : (
          <div>
            <div>
              <FormFieldsWrapper>
                <FormField label="Select your UPI app">
                  <FormFieldDropdownImages
                    isDropdownDisabled={isUPIRequestSubmitted || isUPIRequestSubmitting}
                    selectedValue={selectedUPIOption}
                    listOfOptions={UPIList}
                    onSelect={this.handleUPIOptions}
                  />
                </FormField>
              </FormFieldsWrapper>
            </div>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                this.props.showConvenienceFee &&
                this.props.finalizePurchase.convenienceFeeVersion == 'v2'
                  ? this.props
                      .validateUPIHandle()
                      .then(() => this.props.handleFetchConvenienceFees())
                      .catch(() => {
                        /**/
                      })
                  : this.props.handleSubmitUPI({
                      paymentOption: selectedPaymentOption,
                    });
              }}
            >
              <FormFieldsWrapper>
                {selectedUpiPSP.length === 0 ? (
                  <FormField label="Enter your UPI ID" errors={upiHandleError}>
                    <Autocomplete {...this.props} />
                  </FormField>
                ) : (
                  /*
                      Due to inconsistent behavior of disabled attribute,
                      turned the pointer events to none & changed background color
                      */
                  <FormFieldInput2
                    style={
                      isUPIRequestSubmitting || isUPIRequestSubmitted
                        ? { pointerEvents: 'none', backgroundColor: '#eee' }
                        : {}
                    }
                    postfix={this.renderPostfix(upiPsp, selectedUpiPSP)}
                    type="text"
                    disabled={isUPIRequestSubmitting || isUPIRequestSubmitted}
                    value={upiHandle.split('@')[0]}
                    autoFocus={true}
                    label="Enter your UPI ID"
                    rightLabel={
                      selectedUPIOption != 'others' ? (
                        <a
                          className="right"
                          onClick={(e) => {
                            this.props.handleShowUpiLabelPopup(true);
                            this.upiPopupEvent(e.target.innerHTML);
                          }}
                          style={{ fontSize: '14px' }}
                        >
                          How to find UPI ID?
                        </a>
                      ) : null
                    }
                    errors={upiHandleError}
                    onChange={(e) => this.props.handleChangeUPIHandle(e.target.value)}
                  />
                )}

                {!isUPIRequestSubmitted ? (
                  <FormField>
                    <input
                      className="btn-block btn-green"
                      disabled={isUPIRequestSubmitting}
                      style={{ marginBottom: 7 }}
                      type="submit"
                      value={
                        this.props.showConvenienceFee &&
                        this.props.finalizePurchase.convenienceFeeVersion == 'v2'
                          ? 'Confirm'
                          : 'Verify & Pay'
                      }
                    />
                    <div className="soft-half--ends">
                      <a
                        onClick={(e) => {
                          this.props.handleShowUpiPopup(true);
                          this.upiPopupEvent(e.target.innerHTML);
                        }}
                      >
                        How to pay using UPI?
                      </a>
                    </div>
                    <TnCText />
                  </FormField>
                ) : (
                  <FormField>
                    <input
                      className="btn-block btn-green disabled"
                      disabled
                      value="Waiting..."
                    />
                  </FormField>
                )}
                {isUPIRequestSubmitted ? (
                  <div className="center">
                    <img
                      src={`${STATIC_URL}assets/images/payment/upi.png`}
                      alt="upi"
                      className="push--bottom"
                    />
                    <p className="push--bottom">
                      Check your phone for a verification notification on{' '}
                      {UPIOptionToVerificationTextMap[selectedUPIOption] || ''}.
                    </p>
                  </div>
                ) : null}
              </FormFieldsWrapper>
            </Form>
          </div>
        )}
        <div>
          {showUpiPopup ? (
            <UpiPopup
              showUpiPopup={showUpiPopup}
              closeUpiPopup={this.props.handleShowUpiPopup}
            />
          ) : null}
          {showUpiLabelPopup ? (
            <UpiLabelPopup
              selectedUPIOption={selectedUPIOption}
              showUpiPopup={showUpiLabelPopup}
              closeUpiPopup={this.props.handleShowUpiLabelPopup}
            />
          ) : null}
        </div>
        <MojoPopup
          show={isUpiPopupVisible}
          showCloseModalIcon={false}
          contentContainerStyle={{ width: '360px' }}
        >
          <div className="soft-double text-center upipoup-content">
            <div dangerouslySetInnerHTML={{ __html: formHtmlText }} />
          </div>
        </MojoPopup>
      </div>
    );
  }
}

UPI.propTypes = {
  finalizePurchase: PropTypes.object,
  username: PropTypes.string,
  showConvenienceFee: PropTypes.bool,

  setUpiPsp: PropTypes.func,
  handleShowUpiPopup: PropTypes.func,
  handleSubmitUPI: PropTypes.func,
  handleShowUpiLabelPopup: PropTypes.func,
  handleChangeUPIHandle: PropTypes.func,
  handleUPIOptions: PropTypes.func,
  handleFetchConvenienceFees: PropTypes.func,
  validateUPIHandle: PropTypes.func,
};

export default connect(() => ({}), {
  setUpiPsp,
  handleShowUpiPopup,
  handleSubmitUPI,
  handleShowUpiLabelPopup,
  handleChangeUPIHandle,
  handleUPIOptions,
  handleFetchConvenienceFees,
  validateUPIHandle,
})(UPI);
