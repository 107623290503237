import Rupee from '@instamojo/rupee';
import PropTypes from 'prop-types';
import React from 'react';

const PayableAmount = ({
  showConvenienceFee,
  totalAmountFormatted,
  showAmountBreakdown,
  subtotal,
  amountOff,
}) => {
  return (
    <li className="hard">
      <div style={{ borderTop: '1px solid #F0F4F8', borderBottom: '1px solid #F0F4F8' }}>
        <div className="soft-half--ends">
          <div>
            {showAmountBreakdown ? (
              <>
                <div
                  style={{ borderBottom: '1px solid #F0F4F8' }}
                  className="soft-half--bottom f14"
                >
                  <span className="text-grey">Subtotal</span>
                  <span className="right">
                    <Rupee value={subtotal} />
                  </span>
                </div>
                <div
                  style={{ borderBottom: '1px solid #F0F4F8' }}
                  className="soft-half--ends f14"
                >
                  <span className="text-green">Discount code applied</span>
                  <span className="right text-green">
                    <Rupee value={-amountOff} insertSpaceAfterMinus={true} />
                  </span>
                </div>
                <div className="soft-half--top">
                  <span>Total Amount</span>
                  <span className="right">
                    <Rupee value={totalAmountFormatted} />
                  </span>
                </div>
              </>
            ) : !showConvenienceFee ? (
              <div className="center">
                <span>You Pay </span>
                <Rupee value={totalAmountFormatted} />
              </div>
            ) : (
              <div>
                <span>Amount</span>
                <span className="right">
                  <Rupee value={totalAmountFormatted} />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

PayableAmount.propTypes = {
  showConvenienceFee: PropTypes.bool,
  totalAmountFormatted: PropTypes.string,
  showAmountBreakdown: PropTypes.bool,
  subtotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amountOff: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PayableAmount;
