import { rupees } from '@instamojo/rupee';

import { getIsMobileDevice } from '../../../../common/utils/ModernizrUtils';
import { CASH_ON_DELIVERY_ORDER_SUMMARY_STEP } from '../FinalizePurchaseReducers';
import sanitizePaymentOptions, {
  getPaymentGroups,
  getSupportedPaymentInstruments,
} from './paymentOptions';

const getCartInitialState = (state, baseInitialState = {}) => {
  const { should_retry: shouldRetry, retried_from_email: retriedFromEmail } = state;

  const paymentInstruments = getSupportedPaymentInstruments(
    (state.payment && state.payment.payment_forms) || {},
  );

  const amount = shouldRetry ? state.payment.amount || state.payment?.payment_forms?.total_amount : 0;
  return {
    ...baseInitialState,
    totalAmountFormatted: rupees(amount),
    retriedFromEmail,
    isMobileDevice: getIsMobileDevice(),
    ...sanitizePaymentOptions(paymentInstruments),
    paymentGroups: getPaymentGroups(paymentInstruments.paymentOptions),
    cashOnDeliveryDetails: {
      codStep: CASH_ON_DELIVERY_ORDER_SUMMARY_STEP,
      isOTPLimitExceeded: false,
      isOTPSent: false,
      isOTPResent: false,
      isPhoneNumberEditing: false,
      otp: [],
      isCODSubmitting: false,
    },
  };
};

export default getCartInitialState;
