import Rupee from '@instamojo/rupee';
import PropTypes from 'prop-types';
import React from 'react';

import {
  FormFieldWithLabel,
  FormFieldWrapper,
  InputCheckbox,
  InputNumber,
} from '../../../../common/components/FormComponents';
import { Columns, Row } from '../../../../common/components/Grid';

const PayWhatYouWant = ({ priceBoost, basePrice, form, onChangeInput }) => {
  if (!priceBoost) {
    return null;
  }
  /**
   * if basePrice === 0
   *  then show an option to "claim for free"
   */
  const {
    amount,
    errors,
    isClaimFreeChecked,
    isAmountEditable,
    readOnly,
    minimumPriceBoostAmount,
  } = form;

  return (
    <FormFieldWithLabel className="hard--bottom">
      <FormFieldWrapper>
        <FormFieldWithLabel
          label={
            readOnly.amount
              ? 'Amount'
              : `Enter Amount (min Rs.${minimumPriceBoostAmount || basePrice})`
          }
          errors={errors.amount}
        >
          {readOnly.amount ? (
            <p className="flush">
              <Rupee value={amount} />
            </p>
          ) : (
            <Row className="collapse prefix-radius">
              <Columns className="small-1">
                <span className="prefix">
                  <Rupee />
                </span>
              </Columns>
              <Columns className="small-11">
                <InputNumber
                  value={amount}
                  min={minimumPriceBoostAmount || basePrice}
                  onFocus={(e) => e.target.select()}
                  onClick={(e) => e.target.select()}
                  onChange={(e) =>
                    onChangeInput({
                      amount: e.target.value,
                    })
                  }
                  disabled={!isAmountEditable}
                  data-disabled={true}
                />
              </Columns>
            </Row>
          )}
        </FormFieldWithLabel>

        {basePrice === 0 && !readOnly.amount ? (
          <FormFieldWithLabel>
            <InputCheckbox
              label="Claim this for Free"
              checked={isClaimFreeChecked}
              value={isClaimFreeChecked}
              disabled={amount > 0}
              data-disabled={true}
              onChange={(e) =>
                onChangeInput({
                  isClaimFreeChecked: e.target.checked,
                })
              }
            />
          </FormFieldWithLabel>
        ) : null}
      </FormFieldWrapper>
    </FormFieldWithLabel>
  );
};

PayWhatYouWant.propTypes = {
  priceBoost: PropTypes.bool,
  basePrice: PropTypes.number,
  form: PropTypes.object,
  onUpdate: PropTypes.func,
  onChangeInput: PropTypes.func,
};

export default PayWhatYouWant;
