import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
  Form,
  FormFieldWithLabel as FormField,
  FormFieldWrapper as FormFieldsWrapper,
} from '../../../common/components/FormComponents';
import StatefulSubmitButton from '../../../common/components/StatefulSubmitButton';
import { getCookie } from '../../../common/config/utils';
import {
  handleChangeInput,
  handleSubmitRefinePurchase,
} from '../actions/RefinePurchaseActions';
import scrollController from '../decorators/ScrollController';
import AmountBreakup from './refine/AmountBreakup';
import CustomFields from './refine/customFields';
import CustomFieldsForLink from './refine/customFieldsForLink';

class RefinePurchase extends PureComponent {
  componentDidUpdate() {
    if (this.props.offer) {
      const {
        form: { claimUrl },
        isFormServerValidated,
      } = this.props;
      const serverForm = this.serverForm;
      if (isFormServerValidated && claimUrl && serverForm) {
        serverForm.submit();
      }
    }
  }

  render() {
    const {
      form,
      offer,
      link,
      form: { claimUrl },
      isFormServerValidated,
      totalAmount,
      shippingCharges,
    } = this.props;

    const isPaid = Number(totalAmount) > 0;
    const isSubmitButtonDisabled = !isPaid && isFormServerValidated && claimUrl;

    return (
      <div>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            this.props.handleSubmitRefinePurchase();
          }}
        >
          {offer ? (
            <CustomFields
              form={form}
              offer={offer}
              includeShipping={true}
              onChangeInput={this.props.handleChangeInput}
            />
          ) : null}

          {link ? (
            <CustomFieldsForLink
              form={form}
              link={link}
              onChangeInput={this.props.handleChangeInput}
            />
          ) : null}
          {offer && shippingCharges ? (
            <AmountBreakup
              shippingCharges={shippingCharges}
              shouldShowShippingCharges={shippingCharges > 0}
              totalAmount={totalAmount}
            />
          ) : null}
          {link || offer ? (
            <FormFieldsWrapper>
              <FormField>
                <StatefulSubmitButton
                  disabled={isSubmitButtonDisabled}
                  data-disabled={isSubmitButtonDisabled}
                  value={link || isPaid ? 'Next' : 'Claim for Free'}
                />
              </FormField>
            </FormFieldsWrapper>
          ) : null}
        </Form>

        {claimUrl && isFormServerValidated ? (
          <form ref={(ref) => (this.serverForm = ref)} action={claimUrl} method="POST">
            <input
              type="hidden"
              name="csrfmiddlewaretoken"
              value={getCookie('csrftoken')}
            />
          </form>
        ) : null}
      </div>
    );
  }
}

RefinePurchase.propTypes = {
  isOpenable: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleChangeInput: PropTypes.func,
  handleSubmitRefinePurchase: PropTypes.func,
  form: PropTypes.object,
  offer: PropTypes.object,
  link: PropTypes.object,
  isFormServerValidated: PropTypes.bool,
  totalAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shippingCharges: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

RefinePurchase.defaultProps = {
  isOpenable: true,
  isOpen: false,
  isAnimated: true,
};

function mapStateToProps(state) {
  const {
    refinePurchase: {
      form,
      offer,
      link,
      isFormServerValidated,
      totalAmount,
      shippingCharges,
    },
    settings: { isMobileDevice },
  } = state;
  return {
    form,
    offer,
    link,
    isFormServerValidated,
    totalAmount,
    shippingCharges,
    isMobileDevice,
  };
}

export default connect(mapStateToProps, {
  handleChangeInput,
  handleSubmitRefinePurchase,
})(scrollController(RefinePurchase));
