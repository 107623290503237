export const HANDLE_TOGGLE_PAYMENT_LINK_POPUP = 'HANDLE_TOGGLE_PAYMENT_LINK_POPUP';
export const handleTogglePaymentLinkPopup = (isSmartLinkPopupOpen) => ({
  type: HANDLE_TOGGLE_PAYMENT_LINK_POPUP,
  isSmartLinkPopupOpen,
});

export const HANDLE_CHANGE_ITEM_SELECTION = 'HANDLE_CHANGE_ITEM_SELECTION';
export const handleChangeItemSelection = (id, status, amount) => ({
  type: HANDLE_CHANGE_ITEM_SELECTION,
  payload: {
    id,
    status,
    amount,
  },
});
