/*globals STATIC_URL*/
import PropTypes from 'prop-types';
import React from 'react';

import MojoPopup from '../../../../common/components/MojoPopup';
import { isSmallDevice } from '../../../../common/config/utils';

const UpiPopup = ({ closeUpiPopup, showUpiPopup }) => (
  <div>
    <MojoPopup
      show={showUpiPopup}
      onClose={() => closeUpiPopup(false)}
      showCloseModalIcon={true}
      contentContainerStyle={isSmallDevice() ? { width: '320px' } : { width: '440px' }}
    >
      <div className="soft-double hard--bottom  upi-popup">
        <p style={{ color: '#2C475D' }}>Follow these steps to pay amount using UPI</p>
        <ol className="push text-grey" style={{ fontSize: '14px' }}>
          <li>Select your UPI app option</li>
          <li>
            Enter your VPA or UPI ID.
            <img
              src={`${STATIC_URL}assets/images/payment/vpa.png`}
              alt="vpa"
              className="soft--top push--bottom"
            />
          </li>
          <li>
            Click on <b>{'"Verify"'}.</b>
          </li>
          <li>
            You will receive a notification from the chosen UPI app.
            <img
              src={`${STATIC_URL}assets/images/payment/notification.png`}
              alt="notification"
              className="soft--top push--bottom"
            />
          </li>
          <li>
            This will be like “imojo@icici/instamojo is requesting you to pay Rs. XX”.
          </li>
          <li>Tap on the notification to open the UPI app.</li>
          <li>
            Didn’t get the notification? Please open the UPI app and check notification
            icon.
          </li>
          <li>Authorize payment by entering your secured MPIN in the UPI app.</li>
          <li>That’s it, your payment is done.</li>
        </ol>
        <div className="text-grey" style={{ fontSize: '14px', lineHeight: '1.6' }}>
          <b>Note:</b> The UPI app should be installed on your device for paying by UPI
          option.
        </div>
      </div>
    </MojoPopup>
  </div>
);

UpiPopup.propTypes = {
  closeUpiPopup: PropTypes.func,
  showUpiPopup: PropTypes.bool,
};

export default UpiPopup;
