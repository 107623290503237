import PropTypes from 'prop-types';
import React from 'react';

class InputNumber extends React.Component {
  render() {
    const inputProps = { ...this.props };
    delete inputProps.onlydigits;
    return (
      <input
        type="number"
        step="any"
        pattern={
          this.props.onlydigits && navigator.userAgent.match(/iPhone|iPad|iPod/i)
            ? '[0-9]*'
            : ''
        }
        onWheel={(e) => {
          e.target.blur();
        }}
        onKeyDown={(e) => {
          if (e.keyCode == '38' || e.keyCode == '40') return e.preventDefault();
        }}
        {...inputProps}
      />
    );
  }
}

InputNumber.propTypes = {
  onlydigits: PropTypes.bool,
};

export default InputNumber;
