import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { FormFieldWithLabel, FormFieldWrapper } from '../FormComponents';
import { Row } from '../Grid';
import SubmitButton from '../SubmitButton';
import OtpInput from './OtpInput';
import PhoneNumber from './PhoneNumber';

const VerifyOtp = ({
  phoneNumber,
  otp,
  validateSubmitOTPCodeForm,
  errors,
  isOTPLimitExceeded,
  isOTPResent,
  handleOtpChange,
  isPhoneNumberEditable,
  handleTriggerResendOTP,
  handlePhoneInputEditMode,
  verifyOtpTextData: { title, subTitle, submissionText },
  verifyOtpFormRef,
}) => (
  <>
    <h6 className="no-margin">{title}</h6>
    <p className="text-grey f14">{subTitle}</p>

    <PhoneNumber
      phoneNumber={phoneNumber}
      isPhoneNumberEditable={isPhoneNumberEditable}
      handlePhoneInputEditMode={() => handlePhoneInputEditMode()}
    />
    <Row>
      <div className="columns medium-12 small-centered">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validateSubmitOTPCodeForm();
          }}
          ref={(ref) => verifyOtpFormRef(ref)}
        >
          <FormFieldWrapper>
            <p className="text-center soft--bottom flush">Enter OTP</p>
            <FormFieldWithLabel className="otp-error flush" errors={errors.otp}>
              <OtpInput
                numInputs={4}
                onChange={(otpValue) => handleOtpChange(otpValue)}
                value={otp}
                shouldautofocus={true}
                isInputNum={true}
                separator={<span>&nbsp;&nbsp;</span>}
              />
            </FormFieldWithLabel>
            {isOTPLimitExceeded ? (
              <div
                className="error-message-block soft f14 push--bottom"
                style={{ border: '1px solid #DF542F' }}
              >
                Maximum OTP requests limit reached. Please try again in 60 minutes.
              </div>
            ) : (
              <div className="text-center push--bottom">
                {isOTPResent ? (
                  <p className="text-green hard">OTP sent successfully</p>
                ) : (
                  <p className="f14">
                    Didn&#39;t receive an OTP ?{' '}
                    <a
                      style={{ textDecoration: 'underline' }}
                      onClick={() => handleTriggerResendOTP()}
                    >
                      Resend Now
                    </a>
                  </p>
                )}
              </div>
            )}
            <SubmitButton
              value={submissionText}
              className={classNames('btn btn-green btn-block push--bottom', {
                disabled: isOTPLimitExceeded,
              })}
            />
          </FormFieldWrapper>
        </form>
      </div>
    </Row>
  </>
);

VerifyOtp.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  otp: PropTypes.array.isRequired,
  validateSubmitOTPCodeForm: PropTypes.func.isRequired,
  errors: PropTypes.object,
  isOTPLimitExceeded: PropTypes.bool,
  isOTPResent: PropTypes.bool,
  handleOtpChange: PropTypes.func.isRequired,
  isPhoneNumberEditable: PropTypes.bool,
  handleTriggerResendOTP: PropTypes.func,
  handlePhoneInputEditMode: PropTypes.func,
  verifyOtpFormRef: PropTypes.func.isRequired,
  verifyOtpTextData: PropTypes.object,
};

export default VerifyOtp;
