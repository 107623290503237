import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../../../common/components/Icon';
import { Modes } from '../config';

const CartHeader = ({ purchased, mode, totalItems }) => {
  return (
    <div style={{ borderBottom: '1px solid #DEE5EB' }}>
      <div
        style={{
          display: 'flex',
          flexWrap: 'no-wrap',
          flexDirection: 'row',
          alignItems: 'center',
          height: '42px',
        }}
      >
        <div
          className="center"
          style={{ borderRight: '1px solid #DEE5EB', width: '48px' }}
        >
          <Icon
            className={classnames(
              'shopping_cart-simple flush',
              mode === Modes.FULL ? 'lg' : null,
            )}
          />
        </div>
        <div style={{ paddingLeft: '14px' }}>
          <h6 className="text-uppercase flush">
            {purchased
              ? `${totalItems} product${totalItems > 1 ? 's' : ''} purchased`
              : totalItems
              ? `${totalItems} product${totalItems > 1 ? 's' : ''} in cart`
              : 'Cart'}
          </h6>
        </div>
      </div>
    </div>
  );
};

CartHeader.defaultProps = {
  purchased: false,
};

CartHeader.propTypes = {
  purchased: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf([Modes.FULL, Modes.SUMMARY]).isRequired,
  totalItems: PropTypes.number.isRequired,
};

export default CartHeader;
