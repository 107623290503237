import { postApi } from '../../../../common/middlewares/form';
import getRemoveItemFromCartEndpoint from './getRemoveItemFromCartEndpoint';
import pendingRemovalTimers from './pendingRemovalTimers';

function cleanupItemsPendingRemoval(store) {
  return new Promise((resolve, reject) => {
    const pendingRemovalIds = Object.keys(pendingRemovalTimers);
    if (!pendingRemovalIds.length) {
      return resolve();
    }

    // clear timeouts
    pendingRemovalIds.forEach((id) => clearTimeout(pendingRemovalTimers[id].timer));

    postApi({
      endpoint: getRemoveItemFromCartEndpoint(store),
      payload: {
        id: pendingRemovalIds.join(','),
      },
    })
      .then(() => {
        // delete all cache
        pendingRemovalIds.forEach((id) => delete pendingRemovalTimers[id]);
        return resolve();
      })
      .catch(() => reject());
  });
}

export default cleanupItemsPendingRemoval;
