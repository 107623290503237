import { rupees } from '@instamojo/rupee';

import { getIsMobileDevice } from '../../../../common/utils/ModernizrUtils';
import { getInitialStateTotalAmount, getVariantAmountMap } from '../../utils/GlobalUtils';
import { CASH_ON_DELIVERY_ORDER_SUMMARY_STEP } from '../FinalizePurchaseReducers';
import sanitizePaymentOptions, {
  getPaymentGroups,
  getSupportedPaymentInstruments,
} from './paymentOptions';

const getOfferInitialState = (state, baseInitialState) => {
  const { should_retry: shouldRetry, retried_from_email: retriedFromEmail } = state;
  const updatedState = shouldRetry ? state.retry_data : state;
  const {
    base_price: basePrice,
    prefilled: { total_amount: { value: totalAmount } = {} } = {},
  } = updatedState.offer;

  const { discount_amount: discountAmount } = updatedState.discount;

  const { is_direct_to_payments: isDirectToPayments } = updatedState;

  // ALl Initial Form props that can affect the totalAmount
  const initialFormFields = {
    discountAmount,
  };

  const initialAmount = isDirectToPayments
    ? totalAmount
      ? totalAmount
      : getInitialStateTotalAmount()
    : getInitialStateTotalAmount();

  const initialState = {
    ...baseInitialState,
    isLinkPayment: false,
    totalAmount: initialAmount,
    totalAmountFormatted: rupees(initialAmount),
    retriedFromEmail,
    offer: {
      basePrice,
      variantAmountMap: getVariantAmountMap(),
    },
    form: initialFormFields,
    isMobileDevice: getIsMobileDevice(),
    cashOnDeliveryDetails: {
      codStep: CASH_ON_DELIVERY_ORDER_SUMMARY_STEP,
      isOTPLimitExceeded: false,
      isOTPSent: false,
      isOTPResent: false,
      isPhoneNumberEditing: false,
      otp: [],
      isCODSubmitting: false,
    },
  };

  if (isDirectToPayments) {
    const supportedPaymentInstruments = getSupportedPaymentInstruments(
      updatedState.offer.payment_forms,
    );

    return {
      ...initialState,
      selectedBankId: '',
      ...sanitizePaymentOptions(supportedPaymentInstruments),
      paymentGroups: getPaymentGroups(supportedPaymentInstruments.paymentOptions),
    };
  }

  if (shouldRetry) {
    const supportedPaymentInstruments = getSupportedPaymentInstruments(
      state.payment.payment_forms,
    );

    let retriedOfferState = {
      ...initialState,
      selectedBankId: '',
      ...sanitizePaymentOptions(supportedPaymentInstruments),
      paymentGroups: getPaymentGroups(supportedPaymentInstruments.paymentOptions),
    };

    retriedOfferState = {
      ...retriedOfferState,
      totalAmountFormatted: rupees(retriedOfferState.totalAmount),
    };

    return retriedOfferState;
  }

  return initialState;
};

export default getOfferInitialState;
