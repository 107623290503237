import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

class SubmitButton extends React.Component {
  render() {
    const clonedProps = { ...this.props };
    const { value, className, type } = clonedProps;
    const { disabled: disabledConditionFromParent = false } = clonedProps;
    const isDisabled = clonedProps.isAppOffline || disabledConditionFromParent;
    ['value', 'className', 'type', 'disabled', 'isAppOffline'].map(
      (prop) => delete clonedProps[prop],
    );
    if (this.props.children) {
      return (
        <button type={type} className={className} disabled={isDisabled} {...clonedProps}>
          {this.props.children}
        </button>
      );
    }

    return (
      <input
        type={type}
        value={value}
        className={className}
        disabled={isDisabled}
        {...clonedProps}
      />
    );
  }
}

SubmitButton.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
};

SubmitButton.defaultProps = {
  value: 'Submit',
  className: 'btn-blue btn-block',
  type: 'submit',
};

function mapStateToProps(state) {
  const {
    pageState: { isAppOffline },
  } = state;
  return {
    isAppOffline,
  };
}

export default connect(mapStateToProps, {})(SubmitButton);
