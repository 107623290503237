import PropTypes from 'prop-types';
import React from 'react';

import BreadcrumbAnimation from '../../../../common/components/BreadcrumbAnimation';
import { Columns, Row } from '../../../../common/components/Grid';
import FinalizePurchase from '../FinalizePurchase';
import InitializePurchase from '../InitializePurchase';
import RefinePurchase from '../RefinePurchase';

const PaymentContent = ({
  showInitializePurchase,
  showRefinePurchase,
  showFinalizePurchase,
  isBackwardMotion,
}) => {
  return (
    <Row className="flush--sides">
      <Columns className="small-12">
        <BreadcrumbAnimation isBackwardMotion={isBackwardMotion}>
          {showInitializePurchase ? (
            <InitializePurchase />
          ) : showRefinePurchase ? (
            <RefinePurchase />
          ) : showFinalizePurchase ? (
            <FinalizePurchase />
          ) : null}
        </BreadcrumbAnimation>
      </Columns>
    </Row>
  );
};

PaymentContent.propTypes = {
  showInitializePurchase: PropTypes.bool,
  showRefinePurchase: PropTypes.bool,
  showFinalizePurchase: PropTypes.bool,
  isBackwardMotion: PropTypes.bool,
};

export default PaymentContent;
