import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  handleFetchConvenienceFees,
  handlePayLaterPaymentRequest,
  handleSelectPayLaterOption,
  validatePayLaterPaymentRequest,
} from '../../../actions/FinalizePurchaseActions';
import GridPaymentLayout from './GridPaymentLayout';

class PayLater extends React.Component {
  render() {
    const {
      errors,
      selectedPayLaterId,
      selectedPaymentObject,
    } = this.props.finalizePurchase;
    const { isPayLaterRequestSubmitted } = this.props.finalizePurchase;

    const { paylaterList } = selectedPaymentObject;
    return (
      <GridPaymentLayout
        errorMessage={errors.selectedPayLaterOption}
        selectedPaymentOptionId={selectedPayLaterId}
        optionsList={paylaterList}
        paymentOptionKey={'paylaterId'}
        submitKey={'wallet'}
        handlePaymentRequest={() => this.props.handlePayLaterPaymentRequest()}
        handleSelectPaymentOption={(selectedPayLaterOptionData) =>
          this.props.handleSelectPayLaterOption(selectedPayLaterOptionData)
        }
        isPaymentRequestSubmitted={isPayLaterRequestSubmitted}
        finalizePurchase={this.props.finalizePurchase}
        showConvenienceFee={this.props.showConvenienceFee}
        handleFetchConvenienceFees={() => this.props.handleFetchConvenienceFees()}
        validateForm={(reportFailureOnly) =>
          this.props.validatePayLaterPaymentRequest(reportFailureOnly)
        }
      />
    );
  }
}

PayLater.propTypes = {
  finalizePurchase: PropTypes.object,
  handleSelectPayLaterOption: PropTypes.func,
  handleFetchConvenienceFees: PropTypes.func,
  validatePayLaterPaymentRequest: PropTypes.func,
  handlePayLaterPaymentRequest: PropTypes.func,
  showConvenienceFee: PropTypes.bool,
};

export default connect(() => ({}), {
  handlePayLaterPaymentRequest,
  handleSelectPayLaterOption,
  handleFetchConvenienceFees,
  validatePayLaterPaymentRequest,
})(PayLater);
