import { rupees } from '@instamojo/rupee';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import {
  Form,
  FormFieldWithLabel as FormField,
  FormFieldWrapper as FormFieldsWrapper,
} from '../../../../../common/components/FormComponents';
import { Columns, Row } from '../../../../../common/components/Grid';
import { getCookie } from '../../../../../common/config/utils';
import {
  isNewWindowFormSubmissionSupportedByDevice as shouldSubmitInNewWindow,
  openNewWindowAndGetTarget,
} from '../../../../../common/config/utils';
import {
  isSubmitTargetForcedToSelf,
  redirectToPostPurchasePageAfterPaymentAttempt,
} from '../../../utils/FinalizePurchaseUtils';
import TnCText from '../TnCText';

export default class GridPaymentLayout extends React.Component {
  constructor(props) {
    super(props);
    this.gridPaymentFormSubmit = React.createRef();
    this.gridPaymentSubmitButton = React.createRef();
  }

  componentDidMount() {
    const {
      finalizePurchase: { selectedPaymentObject: paymentOption },
      optionsList,
      paymentOptionKey,
    } = this.props;

    //This is to preselect the option type if there is only one option.
    if (optionsList.length === 1) {
      this.props.handleSelectPaymentOption({
        [paymentOptionKey]: optionsList[0].id,
        paymentOption,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      isPaymentRequestSubmitted: prevIsPaymentRequestSubmitted,
      finalizePurchase: { order, orderToken },
    } = this.props;

    const { isPaymentRequestSubmitted } = nextProps;

    if (
      !prevIsPaymentRequestSubmitted &&
      isPaymentRequestSubmitted &&
      this.gridPaymentFormSubmit.current
    ) {
      if (shouldSubmitInNewWindow && !isSubmitTargetForcedToSelf()) {
        const { windowObject, windowName } = openNewWindowAndGetTarget();
        this.gridPaymentFormSubmit.current.target = windowName;
        this.gridPaymentSubmitButton.current.disabled = true;
        redirectToPostPurchasePageAfterPaymentAttempt(windowObject, {
          order,
          orderToken,
        });
      }
      if (isSubmitTargetForcedToSelf()) {
        this.gridPaymentFormSubmit.current.target = '_self';
      }
      this.gridPaymentFormSubmit.current.submit();
    }
  }

  renderOptionsGrid({
    option,
    paymentOption,
    selectedPaymentOptionId,
    paymentOptionKey,
  }) {
    return (
      <Columns
        key={option.id}
        className="small-6 grid__item end"
        style={{ height: '74px' }}
      >
        <div
          onClick={() =>
            this.props.handleSelectPaymentOption({
              [paymentOptionKey]: option.id,
              paymentOption,
            })
          }
          className={classNames('grid__item--unit soft-half--sides', {
            'bank-selected': option.id === selectedPaymentOptionId,
          })}
        >
          <img
            src={option.image}
            alt={option.name}
            title={option.name}
            style={{ display: 'block', width: '100%' }}
          />
        </div>
      </Columns>
    );
  }

  render() {
    const {
      errorMessage,
      selectedPaymentOptionId,
      finalizePurchase: {
        selectedPaymentObject,
        selectedPaymentObject: { submissionUri, totalAmount },
        convenienceFeeVersion,
      },
      optionsList,
      paymentOptionKey,
      submitKey,
    } = this.props;
    return (
      <div>
        <form
          ref={this.gridPaymentFormSubmit}
          action={submissionUri}
          method="POST"
          target="_parent"
        >
          <input
            type="hidden"
            name="csrfmiddlewaretoken"
            value={getCookie('csrftoken')}
          />
          <input type="hidden" name={submitKey} value={selectedPaymentOptionId} />
        </form>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            this.props.handlePaymentRequest();
          }}
        >
          <FormFieldsWrapper>
            <FormField errors={errorMessage} className="hard--bottom">
              <Row className="banks--grid soft--sides">
                {optionsList.map((option) =>
                  this.renderOptionsGrid({
                    option,
                    paymentOptionKey,
                    paymentOption: selectedPaymentObject,
                    selectedPaymentOptionId,
                  }),
                )}
              </Row>
            </FormField>

            <FormField>
              {this.props.showConvenienceFee && convenienceFeeVersion == 'v2' ? (
                <input
                  className="btn-block btn-green"
                  style={{ marginBottom: 7 }}
                  type="button"
                  value="Confirm"
                  onClick={() => {
                    this.props
                      .validateForm(true)
                      .then(() => {
                        this.props.handleFetchConvenienceFees();
                      })
                      .catch(() => {
                        /**/
                      });
                  }}
                />
              ) : null}
              <input
                ref={this.gridPaymentSubmitButton}
                className="btn-block btn-green"
                style={{
                  marginBottom: 7,
                  display:
                    this.props.showConvenienceFee && convenienceFeeVersion == 'v2'
                      ? 'none'
                      : 'block',
                }}
                type="submit"
                value={`Checkout Rs. ${rupees(totalAmount)}`}
              />
              <TnCText />
            </FormField>
          </FormFieldsWrapper>
        </Form>
      </div>
    );
  }
}

GridPaymentLayout.propTypes = {
  finalizePurchase: PropTypes.object,
  errorMessage: PropTypes.string,
  submitKey: PropTypes.string,
  selectedPaymentOptionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  optionsList: PropTypes.array,
  paymentOptionKey: PropTypes.string,
  isPaymentRequestSubmitted: PropTypes.bool,
  handlePaymentRequest: PropTypes.func,
  handleSelectPaymentOption: PropTypes.func,
  showConvenienceFee: PropTypes.bool,
  handleFetchConvenienceFees: PropTypes.func,
  validateForm: PropTypes.func,
};
