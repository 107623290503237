import PropTypes from 'prop-types';
import React from 'react';

import { InputNumber } from '../../components/FormComponents';
import { Columns, Row } from '../../components/Grid';

class PostfixedInput extends React.Component {
  render() {
    const { postfix, postFixStyle, ...others } = this.props;
    return (
      <Row className="collapse postfix-radius">
        <Columns className="small-10">
          {others.type === 'number' ? <InputNumber {...others} /> : <input {...others} />}
        </Columns>
        <Columns className="small-2">
          <span className="postfix" style={postFixStyle}>
            {postfix}
          </span>
        </Columns>
      </Row>
    );
  }
}

PostfixedInput.propTypes = {
  postfix: PropTypes.any.isRequired,
  postFixStyle: PropTypes.object,
};

export default PostfixedInput;
