import { rupees } from '@instamojo/rupee';

import { getIsMobileDevice } from '../../../../common/utils/ModernizrUtils';
import sanitizePaymentOptions, {
  getPaymentGroups,
  getSupportedPaymentInstruments,
} from './paymentOptions';

const getInstapayInitialState = (state, baseInitialState = {}) => {
  const { should_retry: shouldRetry, retried_from_email: retriedFromEmail } = state;
  const updatedState = shouldRetry ? state.retry_data : state;
  const { is_rap: isRAP, prefilled = {} } = updatedState;
  const amount = shouldRetry ?
    state.payment.amount || state.payment?.payment_forms?.total_amount :
    prefilled.amount;

  const paymentInstruments = getSupportedPaymentInstruments(
    updatedState.payment_forms || (state.payment && state.payment.payment_forms) || {},
  );

  return {
    ...baseInitialState,
    isInstapayPayment: true,
    isRAP,
    totalAmount: amount,
    retriedFromEmail,
    totalAmountFormatted: rupees(amount),
    selectedBankId: '',
    isMobileDevice: getIsMobileDevice(),
    ...sanitizePaymentOptions(paymentInstruments),
    paymentGroups: getPaymentGroups(paymentInstruments.paymentOptions),
  };
};

export default getInstapayInitialState;
