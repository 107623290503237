import { rupees } from '@instamojo/rupee';

import { getIsMobileDevice } from '../../../../common/utils/ModernizrUtils';
import sanitizePaymentOptions, {
  getPaymentGroups,
  getSupportedPaymentInstruments,
} from './paymentOptions';

const getLinkInitialState = (state, baseInitialState = {}) => {
  const { should_retry: shouldRetry, retried_from_email: retriedFromEmail } = state;
  const updatedState = shouldRetry ? state.retry_data : state;

  const { link } = updatedState;

  const initialAmount = shouldRetry ? (state.payment.amount || state.payment?.payment_forms?.total_amount) : link.amount;

  const paymentInstruments = getSupportedPaymentInstruments(
    (state.payment && state.payment.payment_forms) || {},
  );
  return {
    ...baseInitialState,
    isLinkPayment: true,
    totalAmount: initialAmount,
    totalAmountFormatted: rupees(initialAmount),
    retriedFromEmail,
    offer: {
      basePrice: initialAmount,
    },
    isMobileDevice: getIsMobileDevice(),
    ...sanitizePaymentOptions(paymentInstruments),
    paymentGroups: getPaymentGroups(paymentInstruments.paymentOptions),
  };
};

export default getLinkInitialState;
