/* Why do we need this?
**
** In production, some endpoints like Instapay, Store links etc should only
** be served via the www service (www.instamojo.com) not via dashboard service
** (app.instamojo.com).
**
** Such endpoints (being currently very fewer in numbers) need to be explicitly
** handled with these functions.
**
*/
import { getCookie } from '../config/utils';


export function resolveCorrectOriginForDashboardDomain(current_origin) {
    return (current_origin === 'https://app.instamojo.com') ? 'https://www.instamojo.com' : current_origin;
}

export function resolveCorrectHostForDashboardDomain(current_host) {
    return (current_host === 'app.instamojo.com') ? 'www.instamojo.com' : current_host;
}

export function isHostProduction(host) {
    return ['app.instamojo.com', 'www.instamojo.com'].includes(host);
}

export function isDashboardProduction(host) {
    return host === 'app.instamojo.com';
}

export const getOriginIfWhitelistedForDashboard = () => {
    const cookieValue = getCookie('im_dashboard_required');
    return (isHostProduction(window.location.host) && cookieValue) ? 'https://app.instamojo.com' : window.location.origin;
  };
