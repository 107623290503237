import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Icon from '../Icon';

// Common component for dropdown if to be shown with images or icon
class FormFieldDropdownImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContainer: false,
    };
  }

  toggleContainer() {
    if (this.props.isDropdownDisabled) {
      this.setState({ showContainer: false });
    } else {
      this.setState({ showContainer: !this.state.showContainer });
    }
  }

  renderListItem(element, index, isContainer) {
    const renderValueWithIcon = (
      <span>
        {element.displayName ? <span>{element.displayName}</span> : null}
        {element.value === this.props.selectedValue && isContainer ? (
          <i
            className="nc-icon-outline ui-1_check-circle-08 md push-half--right green right"
            style={{ marginTop: '4px' }}
          />
        ) : null}
      </span>
    );
    // Show either Image or Icon
    if (element.imageUrl) {
      return (
        <div
          key={index}
          className={isContainer ? null : 'list--items-width inline-block'}
        >
          <img
            src={element.imageUrl}
            alt={element.displayName}
            style={{ width: '68px', marginTop: '-8px' }}
          />
          {renderValueWithIcon}
        </div>
      );
    } else if (element.iconClassName) {
      return (
        <div
          key={index}
          className={isContainer ? null : 'list--items-width inline-block'}
        >
          <Icon className={element.iconClassName} />
          {renderValueWithIcon}
        </div>
      );
    } else {
      return (
        <div
          key={index}
          className={isContainer ? null : 'list--items-width inline-block'}
        >
          {renderValueWithIcon}
        </div>
      );
    }
  }

  renderInputBox() {
    return this.props.listOfOptions.map((element, index) => {
      if (element.value === this.props.selectedValue) {
        return this.renderListItem(element, index);
      }
    });
  }

  renderList() {
    return this.props.listOfOptions.map((element, index) => (
      <li
        key={index}
        className="list--hover soft-half pointer"
        onClick={() => {
          this.props.onSelect(element.value);
          this.setState({ showContainer: false });
        }}
      >
        {this.renderListItem(element, index, true)}
      </li>
    ));
  }

  render() {
    return (
      <div className="form-field-dropdown-images-wrapper">
        <div
          className="form-field-dropdown-images-select"
          onClick={() => this.toggleContainer()}
        >
          {this.renderInputBox()}
          {this.state.showContainer ? (
            <span>
              <Icon className="arrows-3_small-up" style={{ fontSize: '20px' }} />
            </span>
          ) : (
            <span>
              <Icon className="arrows-3_small-down" style={{ fontSize: '20px' }} />
            </span>
          )}
        </div>

        <div className="form-field-dropdown-images-list">
          {this.state.showContainer ? (
            <div className="list-container">
              <ul>{this.renderList()}</ul>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

FormFieldDropdownImages.propTypes = {
  listOfOptions: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      imageUrl: PropTypes.string,
      iconClassName: PropTypes.string,
      value: PropTypes.string.isRequired,
    }),
  ),
  selectedValue: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  isDropdownDisabled: PropTypes.bool,
};

export default FormFieldDropdownImages;
