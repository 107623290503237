function reduceCartItemProps(item) {
  const { variants, customFields, amount } = item;
  const variantsObject = variants.reduce((o, v) => {
    o[v.categoryName] = v.title;
    return o;
  }, {});

  const customFieldsObject = customFields.reduce((o, c) => {
    o[c.name] = c.value;
    return o;
  }, {});

  return {
    ...variantsObject,
    ...customFieldsObject,
    amount,
  };
}

export default reduceCartItemProps;
