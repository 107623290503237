import PropTypes from 'prop-types';
import React from 'react';

import EditOtp from './EditOtp';
import VerifyOtp from './VerifyOtp';

const OtpVerification = ({
  isPhoneNumberEditing,
  phoneNumber,
  handleValidatePhoneNumberAndSendOTP,
  handlePhoneChange,
  otp,
  validateSubmitOTPCodeForm,
  errors,
  isOTPLimitExceeded,
  isOTPResent,
  handleOtpChange,
  isPhoneNumberEditable,
  handleTriggerResendOTP,
  handlePhoneInputEditMode,
  verifyOtpTextData,
  verifyOtpFormRef,
}) => (
  <>
    {isPhoneNumberEditing ? (
      <EditOtp
        errors={errors}
        phoneNumber={phoneNumber}
        isOTPLimitExceeded={isOTPLimitExceeded}
        handlePhoneChange={(phone) => handlePhoneChange(phone)}
        handleValidatePhoneNumberAndSendOTP={(updatePhoneNumber) =>
          handleValidatePhoneNumberAndSendOTP(updatePhoneNumber)
        }
      />
    ) : (
      <VerifyOtp
        verifyOtpTextData={verifyOtpTextData}
        phoneNumber={phoneNumber}
        otp={otp}
        errors={errors}
        isOTPLimitExceeded={isOTPLimitExceeded}
        isOTPResent={isOTPResent}
        isPhoneNumberEditable={isPhoneNumberEditable}
        verifyOtpFormRef={(ref) => verifyOtpFormRef(ref)}
        handleOtpChange={(otpValue) => handleOtpChange(otpValue)}
        handleTriggerResendOTP={() => handleTriggerResendOTP()}
        handlePhoneInputEditMode={() => handlePhoneInputEditMode()}
        validateSubmitOTPCodeForm={() => validateSubmitOTPCodeForm()}
      />
    )}
  </>
);

OtpVerification.propTypes = {
  isPhoneNumberEditing: PropTypes.bool,
  phoneNumber: PropTypes.string.isRequired,
  handleValidatePhoneNumberAndSendOTP: PropTypes.func.isRequired,
  handlePhoneChange: PropTypes.func.isRequired,
  otp: PropTypes.array.isRequired,
  validateSubmitOTPCodeForm: PropTypes.func.isRequired,
  errors: PropTypes.object,
  isOTPLimitExceeded: PropTypes.bool,
  isOTPResent: PropTypes.bool,
  handleOtpChange: PropTypes.func.isRequired,
  isPhoneNumberEditable: PropTypes.bool,
  handleTriggerResendOTP: PropTypes.func,
  handlePhoneInputEditMode: PropTypes.func,
  verifyOtpTextData: PropTypes.object,
  verifyOtpFormRef: PropTypes.func.isRequired,
};

export default OtpVerification;
