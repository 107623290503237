import '../../styles/Button.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

class Button extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.displayName !== nextProps.displayName ||
      this.props.onClick !== nextProps.onClick ||
      this.props.disabled !== nextProps.disabled ||
      this.props.className !== nextProps.className ||
      this.props.preTextIcon !== nextProps.preTextIcon ||
      this.props.inTraffic !== nextProps.inTraffic ||
      this.props.isAppOffline !== nextProps.isAppOffline
    );
  }

  render() {
    const {
      displayName,
      onClick,
      disabled,
      type,
      className,
      preTextIcon,
      isAppOffline,
      children,
    } = this.props;

    let preTextIconHTML;
    if (preTextIcon !== undefined) {
      preTextIconHTML = preTextIcon;
    }

    const isDisabled = disabled || isAppOffline;

    return (
      <button
        className={`btn btn-block form-button ${className}  ${
          isDisabled ? ' disabled' : ''
        }`}
        onClick={onClick}
        disabled={isDisabled}
        type={type}
        style={{ height: '40px' }}
      >
        {preTextIconHTML}
        {displayName ? displayName : children}
      </button>
    );
  }
}

Button.propTypes = {
  displayName: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']).isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  preTextIcon: PropTypes.any,
  inTraffic: PropTypes.bool,
  isAppOffline: PropTypes.bool,
  children: PropTypes.node,
};

Button.defaultProps = {
  disabled: false,
  type: 'button',
};

function mapStateToProps(state) {
  const {
    pageState: { inTraffic, isAppOffline },
  } = state;
  return {
    inTraffic,
    isAppOffline,
  };
}

export default connect(mapStateToProps, {})(Button);
