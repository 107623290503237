import React from 'react';

import ia from '../../../../common/config/Analytics';

const PostPurchaseSignupLink = () => (
  <a
    href="/payment-gateway?utm_source=payment_signup_nudge&utm_medium=payment_success_page"
    target="_blank"
    className="soft-half--sides"
    onClick={() => {
      ia.sendEvent('Viewed Page', {
        page_name: 'Payment Gateway page',
        source: 'payment_signup_nudge',
      });
    }}
  >
    Learn More
  </a>
);

export default PostPurchaseSignupLink;
