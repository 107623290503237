import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import {
  FormFieldLabel,
  FormFieldWithLabel,
} from '../../../../common/components/FormComponents';
import { Columns, Row } from '../../../../common/components/Grid';

const LinkDiscountCode = ({
  discountCode,
  isDiscountApplied,
  errors,
  shouldShowDiscountCode,
  onChangeInput,
  handleApplyDiscount,
  handleRemoveDiscount,
}) => {
  const postfixClassNames = 'postfix flush';
  const discountFieldLabel = isDiscountApplied
    ? 'Discount Applied'
    : 'Discount Code (optional)';
  return shouldShowDiscountCode ? (
    <FormFieldWithLabel errors={errors.linkDiscountCode}>
      <FormFieldLabel
        className={classNames('transition-color', { 'text-green': isDiscountApplied })}
      >
        {discountFieldLabel}
      </FormFieldLabel>
      <Row className="collapse postfix-radius">
        <Columns className="small-9">
          <input
            type="text"
            value={discountCode}
            onChange={(e) => onChangeInput({ linkDiscountCode: e.target.value })}
            disabled={isDiscountApplied}
          />
        </Columns>
        <Columns className="small-3">
          {!isDiscountApplied ? (
            <a
              className={classNames(postfixClassNames, 'text-blue')}
              onClick={() => handleApplyDiscount()}
            >
              Apply
            </a>
          ) : (
            <a
              className={classNames(postfixClassNames, 'text-red')}
              onClick={() => handleRemoveDiscount()}
            >
              Remove
            </a>
          )}
        </Columns>
      </Row>
    </FormFieldWithLabel>
  ) : null;
};

LinkDiscountCode.propTypes = {
  shouldShowDiscountCode: PropTypes.bool,
  discountCode: PropTypes.string,
  isDiscountApplied: PropTypes.bool,
  errors: PropTypes.object,
  onChangeInput: PropTypes.func,
  handleApplyDiscount: PropTypes.func,
  handleRemoveDiscount: PropTypes.func,
};

export default LinkDiscountCode;
