import PropTypes from 'prop-types';
import React from 'react';

const ModalHeader2 = ({ title, subTitle, textAlign, className, headerStyle }) => (
  <div
    className={`modal-header-2-wrapper soft ${className} text-black text-${textAlign}`}
  >
    <h3 style={headerStyle} className="">
      {title}
    </h3>
    {subTitle !== '' ? (
      <p style={{ color: '#617389', lineHeight: '18px', margin: 'auto' }}>{subTitle}</p>
    ) : null}
  </div>
);

ModalHeader2.propTypes = {
  headerStyle: PropTypes.object,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  textAlign: PropTypes.oneOf(['left', 'center']),
  className: PropTypes.string,
};

ModalHeader2.defaultProps = {
  textAlign: 'center',
  subTitle: '',
  className: '',
};

export default ModalHeader2;
