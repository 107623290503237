/* globals INITIAL_STATE */

import * as InstapayActionTypes from '../actions/InstapayActions';
function offerDetails(state = getInitialState(INITIAL_STATE), action) {
  switch (action.type) {
    case InstapayActionTypes.HANDLE_SUBMIT_INSTAPAY_SUCCESS: {
      if (!state.isInstapayPayment) {
        return state;
      }
      return {
        ...state,
        title: action.payload.purpose || 'Instapay',
      };
    }

    default:
      return state;
  }
}

export default offerDetails;

const getOfferInitialState = ({ offer }) => ({
  ...offer,
});

function getLinkInitialState(state) {
  const {
    link: { title },
  } = state;

  return {
    title,
  };
}

function getInstapayState(state) {
  const { prefilled: { purpose } = {} } = state;

  return {
    isInstapayPayment: true,
    title: purpose,
  };
}

export const getInitialState = (state) => {
  const { should_retry: shouldRetry, response_type: responseType } = state;
  const updatedState = shouldRetry ? state.retry_data : state;
  return state.is_link_payment || (responseType == 'link' && shouldRetry)
    ? getLinkInitialState(updatedState)
    : state.is_instapay_payment || (responseType == 'instapay' && shouldRetry)
    ? getInstapayState(updatedState)
    : state.offer || (responseType == 'product' && shouldRetry)
    ? getOfferInitialState(updatedState)
    : {};
};
