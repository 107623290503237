import Rupee from '@instamojo/rupee';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import ConvenienceFeesAccordion from '../../../../payments/payments/components/convenience-fee/ConvenienceFeesAccordion';

const CartConvenienceFee = ({
  orderAmount,
  paymentAmount,
  showConvenienceFee,
  showConvenienceFeeForm,
  selectedPaymentMode,
  isRetrying,
  convenienceFeeVersion,
}) =>
  showConvenienceFee && !showConvenienceFeeForm ? (
    <div
      className={classnames(
        'text-medium',
        !isRetrying ? 'push--bottom' : 'push-half--top',
      )}
      style={
        isRetrying
          ? { paddingBottom: '6px' }
          : { borderBottom: '1px solid  rgba(227, 236, 242, 0.5)', paddingBottom: '6px' }
      }
    >
      {convenienceFeeVersion != 'v2' ? (
        <div>
          <span className="soft--bottom">Amount</span>
          {selectedPaymentMode === 'cash_on_delivery' ? (
            <span className="right">
              <Rupee value={paymentAmount} />
            </span>
          ) : (
            <span className="right">
              <Rupee value={orderAmount} />
            </span>
          )}
        </div>
      ) : null}
      <ConvenienceFeesAccordion />
    </div>
  ) : null;

CartConvenienceFee.propTypes = {
  orderAmount: PropTypes.string,
  paymentAmount: PropTypes.string,
  showConvenienceFee: PropTypes.bool,
  showConvenienceFeeForm: PropTypes.bool,
  selectedPaymentMode: PropTypes.string,
  isRetrying: PropTypes.bool,
  convenienceFeeVersion: PropTypes.string,
};

const mapStateToProps = ({ cart, settings, retry }) => {
  if (settings && settings.isRetrying) {
    const {
      selectedPaymentObject: { totalAmount: paymentAmount } = {},
    } = retry.finalizePurchase;
    return {
      showConvenienceFee: settings.showConvenienceFee,
      showConvenienceFeeForm: settings.showConvenienceFeeForm,
      selectedPaymentMode: retry.finalizePurchase.selectedPaymentMode,
      isRetrying: true,
      orderAmount: retry.finalizePurchase.orderAmount,
      convenienceFeeVersion: retry.finalizePurchase.convenienceFeeVersion,
      paymentAmount,
    };
  }
  const {
    selectedPaymentObject: { totalAmount: paymentAmount } = {},
  } = cart.finalizePurchase;
  return {
    showConvenienceFee: cart.settings.showConvenienceFee,
    showConvenienceFeeForm: cart.settings.showConvenienceFeeForm,
    selectedPaymentMode: cart.finalizePurchase.selectedPaymentMode,
    isRetrying: false,
    orderAmount: cart.finalizePurchase.orderAmount,
    convenienceFeeVersion: cart.finalizePurchase.convenienceFeeVersion,
    paymentAmount,
  };
};

export default connect(mapStateToProps)(CartConvenienceFee);
