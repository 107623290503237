import '../../../styles/payment-options.scss';

import PropTypes from 'prop-types';
import React from 'react';

import Loading from '../../../../../common/components/Loading';
import TOSModal from '../../initialize/TOSModal';
import DynamicQR from './../render-payment-option/DynamicQR';
import ErrorMessageBlock from './ErrorMessageBlock';

export default class UpiQr extends React.Component {
  constructor(props) {
    super(props);
    this.upiFormSubmit = React.createRef();
  }

  componentDidMount() {
    if (
      this.props.finalizePurchase.paymentOptions?.dynamic_qr_initiate_qr_init
    ) {
      this.props.handleDynamicQrV2(this.props.finalizePurchase.paymentOptions?.dynamic_qr_initiate_qr_init.submissionUri, 'desktop');
    }
  }

  renderUPIPaymentOption(paymentOptionData) {
    const { name, type, ...others } = paymentOptionData;
    const isDynamicQrFetching = this.props.finalizePurchase?.isDynamicQrFetching;
    const isErrorInDynamicQr = this.props.finalizePurchase?.errors?.isDynamicQrV2;
    const upiOptions = this.props.finalizePurchase.paymentGroups?.upi;
    const isUpiOptionAvailable = upiOptions.filter((item) => item.type == 'upi').length > 0;
    return (
      <>
        {this.props.finalizePurchase.paymentGroups && others.group === 'upi' ? (
            name === 'QR' ? ( // incase of dynamic QR
              <DynamicQR
                key={name}
                dynamicQrDetails={this.props.finalizePurchase.dynamicQrDetails}
                convenienceFeeVersion={this.props.finalizePurchase.convenienceFeeVersion}
                handleFetchConvenienceFeesForQR={() =>
                  this.props.handleFetchConvenienceFeesForQR()
                }
                pollForUPIAcceptance={(statusCheckUrl) =>
                  this.props.pollForUPIAcceptance(statusCheckUrl)
                }
                {...others}
              />
            ) : name === 'UPI' && paymentOptionData?.submissionUri ? (
              <div key={name}>
                {!isDynamicQrFetching && !isErrorInDynamicQr && (
                  <div className="horizontal-line-with-text">
                    <span>OR</span>
                  </div>
                )}
                <a
                  className="btn btn-block text-left push--top"
                  onClick={() => this.props.handleSelectPaymentOption({ name, type })}
                >
                  <span className={'payment-option-upi left'} />
                  <span className="soft--left left" style={{ lineHeight: '24px' }}>
                    {' '}
                    Enter UPI ID{' '}
                  </span>
                </a>
              </div>
            ) : name === 'QR_INIT' && isDynamicQrFetching ?
              <Loading /> :
              this.props.finalizePurchase?.isDynamicQrFetched && !isErrorInDynamicQr ? (
                <DynamicQR
                  key={name}
                  dynamicQrDetails={this.props.finalizePurchase?.dynamicQrDetailsV2}
                  convenienceFeeVersion={this.props.finalizePurchase.convenienceFeeVersion}
                  handleFetchConvenienceFeesForQR={() =>
                    this.props.handleFetchConvenienceFeesForQR()
                  }
                  pollForUPIAcceptance={(statusCheckUrl) =>
                    this.props.pollForUPIAcceptance(statusCheckUrl)
                  }
                  dynamicQrUri={this.props.finalizePurchase?.dynamicQrDetailsV2?.dynamicQrUri}
                  dynamicQrPollerTimeout={this.props.finalizePurchase?.dynamicQrDetailsV2?.dynamicQrPollerTimeout}
                  dynamicQrStatusCheckUrl={this.props.finalizePurchase?.dynamicQrDetailsV2?.dynamicQrStatusCheckUrl}
                  {...others}
                />
              ) : !isUpiOptionAvailable && (
                <ErrorMessageBlock message={isErrorInDynamicQr} />
                )
          ) : null
        }
      </>
    );
  }

  render() {
    const upiOptions = this.props.finalizePurchase.paymentGroups?.upi;
    return (
      <div>
        {upiOptions.map((item) =>
          this.renderUPIPaymentOption(item),
        )}
        <TOSModal />
      </div>
    );
  }
}

UpiQr.propTypes = {
  pollForUPIAcceptance: PropTypes.func,
  handleFetchConvenienceFeesForQR: PropTypes.func,
  finalizePurchase: PropTypes.object,
  paymentOptionGroup: PropTypes.string,
  handleSelectPaymentOption: PropTypes.func,
  showConvenienceFee: PropTypes.bool,
  handleDynamicQrV2: PropTypes.func,
};
