// Modals
export const MODAL_WIDTH = {
  SHORT: '320px',
  MID: '356px',
  LARGE: '400px',
  XL: '550px',
  XXL: '650px',
  FULL_WIDTH: '100vw',
};

// Carousel Slides
export const SLIDE_HEIGHT = {
  SHORT: 250,
  LARGE: 500,
  FOUR_FIFTH_SCREEN_HEIGHT: '80vh',
};
