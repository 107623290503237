import PropTypes from 'prop-types';
import React from 'react';

export default function formFieldsHandler(Component) {
  class FormFieldsHandler extends Component {
    constructor(props) {
      super(props);
    }

    // static getDerivedStateFromProps () {
    //   /*
    //     This is left empty intentionally.
    //     Same reason as componentDidMount
    //   */
    //   return null;
    // }

    UNSAFE_componentWillMount() {
      /*
        This is left empty intentionally.
        Same reason as componentDidMount
      */
    }

    componentDidMount() {
      /*
        This is left empty intentionally.

        This overides the param-passed Component's componentDidMount
        and makes Component's componentDidMount to be invoked only once.

        Component's componentDidMount is invoked first and then this is invoked.
      */
    }

    UNSAFE_componentWillReceiveProps() {
      /*
        This is left empty intentionally.
        Same reason as componentDidMount
      */
    }

    // shouldComponentUpdate () {
    //   /*
    //     This is left empty intentionally.
    //     Same reason as componentDidMount
    //   */
    //   return true;
    // }

    UNSAFE_componentWillUpdate() {
      /*
        This is left empty intentionally.
        Same reason as componentDidMount
      */
    }

    // getSnapshotBeforeUpdate () {
    //   /*
    //     This is left empty intentionally.
    //     Same reason as componentDidMount
    //   */
    //   return null;
    // }

    componentDidUpdate() {
      const { pageState: { inTraffic = true, isAsync = true } = {} } = this.props;

      if (!isAsync) {
        return;
      }

      const dom = this.form;
      if (!dom) {
        return;
      }

      // Disable elements from React Mounted div
      const inputs = dom.querySelectorAll('input:not([data-disabled=true])');
      const textareas = dom.querySelectorAll('textarea:not([data-disabled=true])');
      const selects = dom.querySelectorAll('select:not([data-disabled=true])');
      const buttons = dom.querySelectorAll('button:not([data-disabled=true])');

      // Disable elements from Modal div class "react-overlays-modal-portal" and "ReactModalPortal"
      const modalInputs = document.querySelectorAll(
        '.react-overlays-modal-portal input:not([data-disabled=true]), .ReactModalPortal input:not([data-disabled=true])',
      );
      const modalTextareas = document.querySelectorAll(
        '.react-overlays-modal-portal textarea:not([data-disabled=true]), .ReactModalPortal textarea:not([data-disabled=true])',
      );
      const modalSelects = document.querySelectorAll(
        '.react-overlays-modal-portal select:not([data-disabled=true]), .ReactModalPortal select:not([data-disabled=true])',
      );
      const modalButtons = document.querySelectorAll(
        '.react-overlays-modal-portal button:not([data-disabled=true]), .ReactModalPortal button:not([data-disabled=true])',
      );

      const formControls = [
        inputs,
        textareas,
        selects,
        buttons,
        modalInputs,
        modalTextareas,
        modalSelects,
        modalButtons,
      ];

      if (inTraffic) {
        formControls.map(this._handleDisable);
      } else {
        formControls.map(this._handleEnable);
      }
    }

    componentDidCatch() {
      /*
        This is left empty intentionally.
        Same reason as componentDidMount
      */
    }

    componentWillUnmount() {
      /*
        This is left empty intentionally.
        Same reason as componentDidMount
      */
    }

    _handleEnable(formElems) {
      for (let e = 0; e < formElems.length; e++) {
        formElems[e].removeAttribute('disabled');
      }
    }

    _handleDisable(formElems) {
      for (let e = 0; e < formElems.length; e++) {
        formElems[e].setAttribute('disabled', 'disabled');
      }
    }

    handleAsyncFormState(ref) {
      this.form = ref;
    }

    render() {
      return (
        <div ref={(c) => (this.baseRef = c)}>
          <Component
            {...this.props}
            handleAsyncFormState={(ref) => this.handleAsyncFormState(ref)}
          />
        </div>
      );
    }
  }

  FormFieldsHandler.propTypes = {
    pageState: PropTypes.object,
  };

  return FormFieldsHandler;
}
