import PropTypes from 'prop-types';
import React from 'react';

const FindUpi = ({ obj, selectedUPIOption }) => (
  <div className="soft--bottom">
    {obj.map((e, i) => {
      if (e.value == selectedUPIOption) {
        return (
          <div key={i}>
            <img className="soft--ends" src={e.imageUrl} alt="upi" width="80px" />
            <div className="push--left">
              <div>{e.content}</div>
            </div>
          </div>
        );
      }
    })}
  </div>
);

FindUpi.propTypes = {
  obj: PropTypes.array,
  selectedUPIOption: PropTypes.string,
};

export default FindUpi;
