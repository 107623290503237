/* globals INITIAL_STATE */
import { rupees } from '@instamojo/rupee';

import * as CartActionTypes from '../../../dashboard/cart/actions/CartActions';
import * as ActionTypes from '../actions/FinalizePurchaseActions';
import * as InitializePurchaseActionTypes from '../actions/InitializePurchaseActions';
import * as InstapayActionTypes from '../actions/InstapayActions';
import * as RefinePurchaseActionTypes from '../actions/RefinePurchaseActions';
import { UPIList } from '../config/Upi';
import { getPaymentOptionKey } from '../utils/FinalizePurchaseUtils';
import { reducedState } from '../utils/GlobalUtils';
import getCartInitialState from './finalize/cartInitialState';
import getInstapayInitialState from './finalize/instapayInitialState';
import getLinkInitialState from './finalize/linkInitialState';
import getOfferInitialState from './finalize/offerInitialState';
import sanitizePaymentOptions, {
  getPaymentGroups,
  resetPaymentOptions,
} from './finalize/paymentOptions';

export const CASH_ON_DELIVERY_ORDER_SUMMARY_STEP = 1;
export const CASH_ON_DELIVERY_OTP_VERIFICATION_STEP = 2;

function finalizePurchase(state = getInitialState(INITIAL_STATE), action) {
  switch (action.type) {
    case ActionTypes.HANDLE_SELECT_PAYMENT_OPTION: {
      const {
        payload: {
          selectedOption: { type, name, selectedSavedCard },
        },
      } = action;
      return {
        ...state,
        selectedPaymentMode: type,
        selectedPaymentObject: type === 'upi_qr' ? { type: 'upi_qr', name: 'UPI' } : state.paymentOptions[getPaymentOptionKey({ type, name })],
        isSelectedPaymentModeLoading:
          ['nb', 'card', 'wallet', 'paylater', 'saved_cards', 'bank_transfer', 'upi_qr'].indexOf(
            type,
          ) !== -1,
        selectedSavedCard,
      };
    }

    case ActionTypes.HANDLE_UPDATE_CONVENIENCE_FEE_OBJECT: {
      return {
        ...state,
        selectedPaymentObject: {
          ...state.selectedPaymentObject,
          convenienceFeeObject: {
            ...state.selectedPaymentObject.convenienceFeeObject,
            ...action.convenienceFeeData,
          },
        },
      };
    }

    case ActionTypes.HANDLE_FETCH_CONVENIENCE_FEES_REQUEST: {
      return {
        ...state,
        selectedPaymentObject: {
          ...state.selectedPaymentObject,
          convenienceFeeObject: {
            ...state.selectedPaymentObject.convenienceFeeObject,
            isFetching: true,
            showConvenienceFeeForm: true,
          },
        },
      };
    }

    case ActionTypes.HANDLE_RESET_QR_CONVENIENCE_FEE_DETAILS: {
      return {
        ...state,
        dynamicQrDetails: {},
        selectedPaymentMode: state.selectedPaymentMode === 'dynamic_qr' || state.selectedPaymentMode === 'dynamic_qr_initiate' ? state.selectedPaymentMode : '',
        finalIntentUri: '',
      };
    }

    case ActionTypes.HANDLE_RESET_UPI_PAYMENT_OPTION: {
      const { type, name } = action.resetToPaymentMode;
      return {
        ...state,
        selectedPaymentMode: type,
        selectedPaymentObject: type && name ? state.paymentOptions[getPaymentOptionKey({ type, name })] : {},
      };
    }

    case ActionTypes.PASS_INTENT_URI_WITH_CONVENIENCE_FEE: {
      return {
        ...state,
        finalIntentUri: action.finalUri,
        upiIntentAppCalled: action.app,
      };
    }

    case ActionTypes.TOGGLE_UPI_INTENT_WAIT_SCREEN: {
      return {
        ...state,
        upiIntentAppCalled: action.app,
        isUpiIntentPolling: action.status,
      };
    }

    case ActionTypes.HANDLE_FETCH_CONVENIENCE_FEES_SUCCESS: {
      return {
        ...state,
        selectedPaymentObject: {
          ...state.selectedPaymentObject,
          convenienceFeeObject: {
            ...state.selectedPaymentObject.convenienceFeeObject,
            isFetching: false,
            isFetched: true,
            ...action.response,
          },
        },
      };
    }

    case ActionTypes.HANDLE_FETCH_CONVENIENCE_FEES_FAILURE: {
      return {
        ...state,
        selectedPaymentObject: {
          ...state.selectedPaymentObject,
          convenienceFeeObject: {
            ...state.selectedPaymentObject.convenienceFeeObject,
            isFetching: false,
            isFetched: false,
            ...action.response,
          },
        },
      };
    }

    case ActionTypes.HANDLE_MANAGE_SAVED_CARDS: {
      const { mode, value, selectedSavedCard } = action.payload;
      // For handling showCardDeletionOption
      if (mode === 1) {
        return {
          ...state,
          showCardDeletionOption: value,
        };
      }
      // mode === 2 for showConfirmCardDeletionOption
      return {
        ...state,
        selectedSavedCard,
        showConfirmCardDeletionOption: value,
      };
    }

    case ActionTypes.HANDLE_DELETE_SAVED_CARD_SUCCESS: {
      const {
        selectedSavedCard: { fingerprint },
        paymentOptions: { saved_cards_savedcards: savedCards },
      } = state;
      const updatedSavedCardsObject = savedCards;
      updatedSavedCardsObject.submissionUris = updatedSavedCardsObject.submissionUris.filter(
        (savedCardItem) => savedCardItem.fingerprint !== fingerprint,
      );
      return {
        ...state,
        paymentOptions: {
          ...state.paymentOptions,
          saved_cards_savedcards: updatedSavedCardsObject,
        },
        selectedSavedCard: '',
        showCardDeletionOption: false,
        showConfirmCardDeletionOption: false,
      };
    }

    case ActionTypes.HANDLE_SELECT_BANK: {
      const {
        payload: { bankId },
      } = action;
      return {
        ...state,
        selectedBankId: bankId || '',
        errors: {},
      };
    }

    case ActionTypes.HANDLE_NEFT_SELECT_BANK: {
      const {
        payload: { bankId, bankUrl },
      } = action;
      return {
        ...state,
        selectedNeftBankId: bankId,
        selectedNeftBankUrl: bankUrl,
        errors: {},
      };
    }

    case ActionTypes.HANDLE_NEFT_BANK_ERROR: {
      return {
        ...state,
        errors: {
          selectedBank: 'Please Choose a Bank',
        },
      };
    }

    case ActionTypes.HANDLE_SELECT_WALLET: {
      const {
        payload: { walletId },
      } = action;
      return {
        ...state,
        selectedWalletId: walletId || '',
        errors: {},
      };
    }

    case ActionTypes.HANDLE_SELECT_PAY_LATER: {
      const {
        payload: { paylaterId },
      } = action;
      return {
        ...state,
        selectedPayLaterId: paylaterId || '',
        errors: {},
      };
    }

    case ActionTypes.HANDLE_PAY_LATER_PAYMENT_SUCCESS: {
      return {
        ...state,
        isPayLaterRequestSubmitted: true,
      };
    }

    case ActionTypes.HANDLE_PAY_LATER_PAYMENT_FAILURE: {
      return {
        ...state,
        errors: {
          selectedPayLaterOption: ['Please choose a Pay Later Option'],
        },
      };
    }

    case ActionTypes.HANDLE_BANK_PAYMENT_FAILURE: {
      return {
        ...state,
        errors: {
          selectedBank: ['Please Choose a Bank to Pay'],
        },
      };
    }

    case ActionTypes.HANDLE_BANK_PAYMENT_SUCCESS: {
      return {
        ...state,
        isBankPaymentRequestSubmitted: true,
      };
    }

    case ActionTypes.HANDLE_WALLET_PAYMENT_FAILURE: {
      return {
        ...state,
        errors: {
          selectedWallet: ['Please Choose a Wallet to Pay'],
        },
      };
    }

    case ActionTypes.HANDLE_WALLET_PAYMENT_SUCCESS: {
      return {
        ...state,
        isWalletPaymentRequestSubmitted: true,
      };
    }

    case ActionTypes.STORE_FINAL_PAYLOAD: {
      const { finalPayload } = action.payload;
      return {
        ...state,
        finalPayload,
      };
    }

    case ActionTypes.HANDLE_CHANGE_EMI_CARD_PROVIDER: {
      const { bankKey, paymentOption } = action.payload;
      const { emiList } = state.paymentOptions[getPaymentOptionKey(paymentOption)];
      const emiTenure = Object.keys(emiList[bankKey].rates)[0];
      return {
        ...state,
        selectedEMICardProvider: bankKey,
        selectedEMITenure: emiTenure,
        emiAmount: emiList[bankKey].rates[emiTenure],
      };
    }

    case ActionTypes.HANDLE_CHANGE_EMI_TENURE: {
      const { tenure, emiAmount } = action.payload;
      return {
        ...state,
        selectedEMITenure: tenure,
        emiAmount,
      };
    }

    case ActionTypes.HANDLE_SUBMIT_EMI_FORM_REQUEST: {
      return {
        ...state,
        isEMIFormSubmitting: true,
        isSelectedPaymentModeLoading: true,
      };
    }

    case ActionTypes.HANDLE_SUBMIT_EMI_FORM_SUCCESS: {
      return {
        ...state,
        selectedPaymentObject: {
          ...state.selectedPaymentObject,
          emiSubmissionUri: action.response.iframeUri,
        },
        isEMIFormSubmitted: true,
      };
    }

    case ActionTypes.HANDLE_CHANGE_SELECTED_EMI_PROVIDER: {
      return {
        ...state,
        isEMIFormSubmitting: false,
        isEMIFormSubmitted: false,
      };
    }

    case ActionTypes.HANDLE_UPI_OPTION: {
      return {
        ...state,
        selectedUPIOption: action.payload.option,
        upiPsp: renderPSP(action.payload.option),
        upiHandleError: '',
        showUPIList: false,
      };
    }

    case ActionTypes.HANDLE_UPI_PSP: {
      return {
        ...state,
        selectedUpiPSP: action.payload.psp
          ? action.payload.psp
          : state.upiPsp
          ? state.upiPsp[0]
          : '',
        upiHandleError: '',
      };
    }

    case ActionTypes.HANDLE_TOGGLE_LOADER: {
      return {
        ...state,
        isSelectedPaymentModeLoading: !state.isSelectedPaymentModeLoading,
      };
    }

    case ActionTypes.HANDLE_RESET_SELECTED_PAYMENT_MODE: {
      return {
        ...state,
        selectedPaymentMode: '',
        selectedPaymentModeName: '',
        selectedBankId: '',
        isBankPaymentRequestSubmitted: false,
        selectedWalletId: '',
        selectedPayLaterId: '',
        isWalletPaymentRequestSubmitted: false,
        isPayLaterRequestSubmitted: false,
        isEMIFormSubmitting: false,
        isEMIFormSubmitted: false,
        isUPIRequestSubmitted: false,
        upiHandle: '',
        upiHandleError: '',
        showNeftDetails: false,
        selectedNeftBankId: null,
        selectedNeftBankUrl: '',
        showUPIList: true,
        cashOnDeliveryDetails: {
          ...state.cashOnDeliveryDetails,
          codStep: CASH_ON_DELIVERY_ORDER_SUMMARY_STEP,
          isOTPLimitExceeded: false,
          isCODSubmitting: false,
        },
      };
    }

    case ActionTypes.HANDLE_CHANGE_UPI_HANDLE: {
      return {
        ...state,
        upiHandle: action.payload.handle,
        upiHandleError: '',
      };
    }

    case ActionTypes.HANDLE_SUBMIT_UPI_REQUEST: {
      return {
        ...state,
        isUPIRequestSubmitted: false,
        isUPIRequestSubmitting: true,
      };
    }

    case ActionTypes.HANDLE_SUBMIT_UPI_FAILURE: {
      return {
        ...state,
        upiHandleError: action.errors.virtualAddress,
        isUPIRequestSubmitting: false,
        selectedPaymentObject: {
          ...state.selectedPaymentObject,
          convenienceFeeObject: {
            ...state.selectedPaymentObject.convenienceFeeObject,
            showConvenienceFeeForm: false,
            isFetching: false,
            isFetched: false,
          },
        },
      };
    }

    case ActionTypes.HANDLE_SUBMIT_UPI_SUCCESS: {
      const { statusCode, paymentId, upiBank } = action.response;
      if (action.response?.html) {
        return {
          ...state,
          isUPIRequestSubmitted: true,
          isUPIRequestSubmitting: false,
          formHtmlText: action.response?.html,
          isUpiPopupVisible: true,
        };
      }
      if (statusCode !== 2) {
        return {
          ...state,
          upiHandleError: 'Sorry. Please try again!',
          isUPIRequestSubmitting: false,
        };
      }
      // considered a successful request
      return {
        ...state,
        upiBank,
        paymentId,
        isUPIRequestSubmitted: true,
        isUPIRequestSubmitting: false,
      };
    }

    case ActionTypes.SHOW_UPI_POPUP: {
      return {
        ...state,
        showUpiPopup: action.payload.flag,
      };
    }

    case ActionTypes.SHOW_UPI_LABEL_POPUP: {
      return {
        ...state,
        showUpiLabelPopup: action.payload.flag,
      };
    }

    case ActionTypes.HANDLE_SUBMIT_NEFT_SUCCESS: {
      const { accountNumber, bankName, ifscCode, beneficiaryName } = action.response;
      // considered a successful request
      return {
        ...state,
        neftDetails: {
          accountNumber,
          bankName,
          ifscCode,
          beneficiaryName,
        },
      };
    }

    case ActionTypes.SHOW_NEFT_CREDENTIALS: {
      return {
        ...state,
        showNeftDetails: action.payload.flag,
      };
    }

    case InitializePurchaseActionTypes.HANDLE_IP_CHANGE_INPUT: {
      if (!state.offer) {
        // CART
        return state;
      }

      const {
        payload: { changedData },
      } = action;
      const changedKey = Object.keys(changedData)[0];
      if (
        (!state.isLinkPayment &&
          !state.isInstapayPayment &&
          state.offer.variantAmountMap[changedKey]) ||
        changedKey === 'quantity'
      ) {
        return reducedState(state, changedData);
      }
      return state;
    }

    case InitializePurchaseActionTypes.HANDLE_APPLY_DISCOUNT_SUCCESS: {
      const {
        discount: { amountOff },
      } = action.response;
      return reducedState(state, { discountAmount: Number(amountOff) });
    }

    case InitializePurchaseActionTypes.HANDLE_REMOVE_DISCOUNT_SUCCESS: {
      return reducedState(state, { discountAmount: 0 });
    }

    case InitializePurchaseActionTypes.HANDLE_SET_TOTAL_AMOUNT: {
      const { totalAmount } = action.payload;
      let updateWith;
      if (totalAmount !== Math.round(totalAmount)) {
        updateWith = {
          ...state,
          totalAmount: totalAmount.toFixed(2),
        };
      }

      updateWith = {
        ...state,
        totalAmount,
      };

      return {
        ...updateWith,
        totalAmountFormatted: rupees(updateWith.totalAmount),
      };
    }

    case InitializePurchaseActionTypes.HANDLE_SUBMIT_INITIALIZE_PURCHASE_SUCCESS:
    case RefinePurchaseActionTypes.HANDLE_SUBMIT_REFINE_PURCHASE_SUCCESS: {
      const { details } = action.response;
      const paymentOptions = {
        ...state.paymentOptions,
        ...details,
      };

      const totalAmount = Number(details.totalAmount);
      const convenienceFeeVersion = String(details.convenienceFeeVersion);
      return {
        ...state,
        ...resetPaymentOptions,
        totalAmount,
        totalAmountFormatted: totalAmount
          ? rupees(totalAmount)
          : state.totalAmountFormatted,
        ...sanitizePaymentOptions(paymentOptions),
        paymentGroups: getPaymentGroups(paymentOptions.paymentOptions),
        convenienceFeeVersion,
      };
    }

    case InstapayActionTypes.HANDLE_SUBMIT_INSTAPAY_SUCCESS: {
      return {
        ...state,
        totalAmount: action.payload.amount,
        totalAmountFormatted: rupees(action.payload.amount),
      };
    }

    case CartActionTypes.HANDLE_SUBMIT_CART_SUCCESS: {
      const { totalAmount } = action.response.cart;
      return {
        ...state,
        totalAmount: Number(totalAmount),
        totalAmountFormatted: rupees(totalAmount),
      };
    }

    case CartActionTypes.HANDLE_CHECKOUT_CART_SUCCESS: {
      const paymentOptions = { ...action.response.details };
      return {
        ...state,
        ...resetPaymentOptions,
        ...sanitizePaymentOptions(paymentOptions),
        paymentGroups: getPaymentGroups(paymentOptions.paymentOptions),
      };
    }

    case ActionTypes.HANDLE_SEND_OTP_TO_BUYER_FAILURE: {
      return {
        ...state,
        errors: action.errors,
      };
    }

    case ActionTypes.HANDLE_OTP_LIMIT_EXCEEDED: {
      const { status: isOTPLimitExceeded } = action;
      return {
        ...state,
        cashOnDeliveryDetails: {
          ...state.cashOnDeliveryDetails,
          isOTPLimitExceeded,
        },
      };
    }

    case ActionTypes.HANDLE_SET_COD_RESEND_OTP_FLAG: {
      const { isOTPResent } = action;
      return {
        ...state,
        cashOnDeliveryDetails: {
          ...state.cashOnDeliveryDetails,
          isOTPResent,
        },
      };
    }

    case ActionTypes.HANDLE_SEND_OTP_TO_BUYER_SUCCESS: {
      let { number } = action.response;
      if (number.indexOf('+91') !== -1) {
        number = number.replace('+91', '');
      }
      return {
        ...state,
        cashOnDeliveryDetails: {
          ...state.cashOnDeliveryDetails,
          isOTPSent: true,
          codStep: CASH_ON_DELIVERY_OTP_VERIFICATION_STEP,
          otp: [],
          isPhoneNumberEditing: false,
        },
        finalPayload: {
          ...state.finalPayload,
          phone: number,
        },
        errors: {},
      };
    }

    case ActionTypes.HANDLE_COD_OTP_CHANGE: {
      const { otp } = action;
      return {
        ...state,
        errors: {},
        cashOnDeliveryDetails: {
          ...state.cashOnDeliveryDetails,
          isOTPLimitExceeded: false,
          isOTPResent: false,
          otp,
        },
      };
    }

    case ActionTypes.HANDLE_COD_OTP_VERIFICATION_REQUEST: {
      return {
        ...state,
        cashOnDeliveryDetails: {
          ...state.cashOnDeliveryDetails,
          isCODSubmitting: true,
        },
      };
    }

    case ActionTypes.HANDLE_PHONE_INPUT_CHANGE_FOR_COD: {
      const { phone } = action;
      return {
        ...state,
        cashOnDeliveryDetails: {
          ...state.cashOnDeliveryDetails,
          isOTPLimitExceeded: false,
        },
        finalPayload: {
          ...state.finalPayload,
          phone,
        },
        errors: {},
      };
    }

    case ActionTypes.HANDLE_PHONE_INPUT_EDIT_MODE_FOR_COD: {
      return {
        ...state,
        cashOnDeliveryDetails: {
          ...state.cashOnDeliveryDetails,
          isOTPSent: false,
          isPhoneNumberEditing: true,
        },
      };
    }

    case ActionTypes.HANDLE_COD_OTP_VERIFICATION_FAILURE: {
      const { errors } = action;
      return {
        ...state,
        errors: {
          ...state.errors,
          ...errors,
        },
        cashOnDeliveryDetails: {
          ...state.cashOnDeliveryDetails,
          isCODSubmitting: false,
        },
      };
    }

    case ActionTypes.HANDLE_COD_OTP_VERIFICATION_SUCCESS: {
      return {
        ...state,
        cashOnDeliveryDetails: {
          ...state.cashOnDeliveryDetails,
        },
      };
    }

    case ActionTypes.HANDLE_PAYMENT_GROUP_CARD_CLICK: {
      return {
        ...state,
        openedPaymentCardIndex: action.payload.cardIndex,
      };
    }

    case ActionTypes.HANDLE_FETCH_QR_CONVENIENCE_FEES_REQUEST: {
      return {
        ...state,
        dynamicQrDetails: {
          ...state.dynamicQrDetails,
          showConvenienceFeeForm: action.showConvenienceFeeForm,
          isFetching: true,
          isFetched: false,
        },
        selectedPaymentObject: (state.selectedPaymentObject?.type === 'dynamic_qr' || state.selectedPaymentObject?.type === 'dynamic_qr_initiate' || state.selectedPaymentObject?.type === 'upi_qr') ? state.selectedPaymentObject : {},
      };
    }

    case ActionTypes.HANDLE_FETCH_QR_CONVENIENCE_FEES_SUCCESS: {
      return {
        ...state,
        dynamicQrDetails: {
          ...state.dynamicQrDetails,
          isFetching: false,
          isFetched: true,
          ...action.response,
        },
        dynamicQrDetailsV2: {
          ...state.dynamicQrDetailsV2,
          ...action.response,
        },
      };
    }
    case ActionTypes.HANDLE_PAYPAL_SUCCESS_CALLBACK: {
      return {
        ...state,
        showPaypalSuccessPage: true,
      };
    }
    case ActionTypes.HANDLE_PAYPAL_SDK_ERROR: {
      return {
        ...state,
        showPaypalErrorBlock: true,
      };
    }
    case ActionTypes.SHOW_UPI_FIELD: {
      return {
        ...state,
        isUpiFieldShown: action.payload.flag,
      };
    }
    case ActionTypes.HANDLE_DYNAMIC_QR_V2_REQUEST: {
      return {
        ...state,
        isDynamicQrFetched: false,
        isDynamicQrFetching: true,
      };
    }
    case ActionTypes.HANDLE_DYNAMIC_QR_V2_SUCCESS: {
      return {
        ...state,
        isDynamicQrFetched: true,
        isDynamicQrFetching: false,
        dynamicQrDetailsV2: action.response,
      };
    }
    case ActionTypes.HANDLE_DYNAMIC_QR_V2_FAILURE: {
      return {
        ...state,
        isDynamicQrFetched: true,
        isDynamicQrFetching: false,
        errors: {
          isDynamicQrV2: 'We\'re facing issues with UPI Payments at the moment. Please try another payment method.',
        },
      };
    }
    default:
      return state;
  }
}

export default finalizePurchase;

export const baseInitialState = {
  form: {},
  paymentOptions: {},
  selectedPaymentMode: '',
  selectedSavedCard: '',
  showCardDeletionOption: false,
  showConfirmCardDeletionOption: false,
  selectedBankId: '',
  isBankPaymentRequestSubmitted: false,
  selectedWalletId: '',
  selectedPayLaterId: '',
  showNeftDetails: false,
  isWalletPaymentRequestSubmitted: false,
  isPayLaterRequestSubmitted: false,
  errors: {},
  finalPayload: {},
  selectedEMICardProvider: '',
  isEMIFormSubmitting: false,
  isEMIFormSubmitted: false,
  isSelectedPaymentModeLoading: false,
  upiHandle: '',
  showUPIList: true,
  neftDetails: {},
  convenienceFeeVersion: '',
  openedPaymentCardIndex: 0,
  paymentGroups: {},
  dynamicQrDetails: {},
  dynamicQrDetailsV2: {},
  isDynamicQrFetching: false,
  showPaypalSuccessPage: false,
  showPaypalErrorBlock: false,
};

const getInitialState = (state) => {
  const { should_retry: shouldRetry, response_type: responseType } = state;

  if (state.is_cart_enabled || (responseType == 'cart' && shouldRetry)) {
    // cart feature to be enabled only on instamojo.com
    if (
      ((!state.offer || (state.offer && state.offer.is_cartable)) && !state.is_embed) ||
      (responseType == 'cart' && shouldRetry && !state.retry_data.is_embed)
    ) {
      return getCartInitialState(state, baseInitialState);
    }
  }

  if (state.is_link_payment || (responseType == 'link' && shouldRetry)) {
    return getLinkInitialState(state, baseInitialState);
  }

  if (state.is_instapay_payment || (responseType == 'instapay' && shouldRetry)) {
    return getInstapayInitialState(state, baseInitialState);
  }

  if (state.offer || (responseType == 'product' && shouldRetry)) {
    return getOfferInitialState(state, baseInitialState);
  }

  return {};
};

const renderPSP = (option) => {
  const list = UPIList.filter((e) => {
    return e.value == option;
  });

  return list[0].psp ? list[0].psp : null;
};
