import PropTypes from 'prop-types';
import React from 'react';

class StatefulSubmitButton extends React.Component {
  render() {
    const { value, children, className, ...others } = this.props;
    return (
      <button
        type="submit"
        className={`${className} btn-block btn--stateful `}
        {...others}
      >
        {value ? <span>{value}</span> : <span>{children}</span>}
        <span>
          <span
            className="pace-loading"
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              margin: '0 10px 0 0',
            }}
          />
          <span>Please Wait...</span>
        </span>
      </button>
    );
  }
}

StatefulSubmitButton.defaultProps = {
  className: 'btn-green',
};

StatefulSubmitButton.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default StatefulSubmitButton;
