import { rupees } from '@instamojo/rupee';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  Form,
  FormFieldWithLabel as FormField,
  FormFieldWrapper as FormFieldsWrapper,
} from '../../../../../common/components/FormComponents';
import { Columns, Row } from '../../../../../common/components/Grid';
import { getCookie } from '../../../../../common/config/utils';
import {
  isNewWindowFormSubmissionSupportedByDevice as shouldSubmitInNewWindow,
  openNewWindowAndGetTarget,
} from '../../../../../common/config/utils';
import {
  handleBankPaymentRequest,
  handleFetchConvenienceFees,
  handleSelectBank,
  validateNetBanking,
} from '../../../actions/FinalizePurchaseActions';
import {
  isSubmitTargetForcedToSelf,
  redirectToPostPurchasePageAfterPaymentAttempt,
} from '../../../utils/FinalizePurchaseUtils';
import TnCText from '../TnCText';

class NetBanking extends React.Component {
  constructor(props) {
    super(props);
    this.bankFormSubmit = React.createRef();
    this.bankSubmitButton = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      isBankPaymentRequestSubmitted: prevIsBankPaymentRequestSubmitted,
      order,
      orderToken,
    } = this.props.finalizePurchase;

    const { isBankPaymentRequestSubmitted } = nextProps.finalizePurchase;

    if (
      !prevIsBankPaymentRequestSubmitted &&
      isBankPaymentRequestSubmitted &&
      this.bankFormSubmit.current
    ) {
      if (shouldSubmitInNewWindow && !isSubmitTargetForcedToSelf()) {
        const { windowObject, windowName } = openNewWindowAndGetTarget();
        this.bankFormSubmit.current.target = windowName;
        this.bankSubmitButton.current.disabled = true;
        redirectToPostPurchasePageAfterPaymentAttempt(windowObject, {
          order,
          orderToken,
        });
      }
      if (isSubmitTargetForcedToSelf()) {
        this.bankFormSubmit.current.target = '_self';
      }
      this.bankFormSubmit.current.submit();
    }
  }

  renderBankGrid({ bank, paymentOption }) {
    const { selectedBankId } = this.props.finalizePurchase;
    return (
      <Columns key={bank.id} className="small-6 grid__item end">
        <div
          onClick={() =>
            this.props.handleSelectBank({
              bankId: bank.id,
              paymentOption,
            })
          }
          className={classNames('grid__item--unit', {
            'bank-selected': bank.id === selectedBankId,
          })}
        >
          <div style={{ height: '40px', display: 'table', width: '100%' }}>
            <div
              style={{
                display: 'table-cell',
                verticalAlign: 'middle',
                textAlign: 'center',
              }}
            >
              <span
                className={`banks-sprite banks-bank-${bank.id}`}
                style={{ verticalAlign: 'middle' }}
              />
              <span className="bank-list__bank-name">{bank.name}</span>
            </div>
          </div>
        </div>
      </Columns>
    );
  }

  renderBankOption(bank) {
    return (
      <option key={bank.id} value={bank.id}>
        {bank.name}
      </option>
    );
  }

  render() {
    const {
      selectedBankId,
      errors,
      selectedPaymentObject: selectedPaymentOption,
      convenienceFeeVersion,
    } = this.props.finalizePurchase;

    const { nbList, nbGridList, totalAmount, submissionUri } = selectedPaymentOption;

    return (
      <div>
        <form
          ref={this.bankFormSubmit}
          action={submissionUri}
          method="POST"
          target="_parent"
        >
          <input
            type="hidden"
            name="csrfmiddlewaretoken"
            value={getCookie('csrftoken')}
          />
          <input type="hidden" name="bank" value={selectedBankId} />
        </form>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            this.props.handleBankPaymentRequest();
          }}
        >
          <FormFieldsWrapper>
            <FormField errors={errors.selectedBank}>
              <Row className="banks--grid soft--sides">
                {nbGridList.map((bank) =>
                  this.renderBankGrid({
                    bank,
                    paymentOption: selectedPaymentOption,
                  }),
                )}
              </Row>

              <select
                value={selectedBankId}
                onChange={(e) =>
                  this.props.handleSelectBank({
                    bankId: Number(e.target.value),
                    paymentOption: selectedPaymentOption,
                  })
                }
              >
                <option value="" disabled>
                  Choose another Bank
                </option>
                {nbList.map((bank) => this.renderBankOption(bank))}
              </select>
            </FormField>
            <FormField>
              {this.props.showConvenienceFee && convenienceFeeVersion == 'v2' ? (
                <input
                  className="btn-block btn-green"
                  style={{ marginBottom: 7 }}
                  type="button"
                  value="Confirm"
                  onClick={() => {
                    this.props
                      .validateNetBanking(true)
                      .then(() => {
                        this.props.handleFetchConvenienceFees();
                      })
                      .catch(() => {
                        /**/
                      });
                  }}
                />
              ) : null}
              <input
                ref={this.bankSubmitButton}
                className="btn-block btn-green"
                style={{
                  marginBottom: 7,
                  // This is required as UNSAFE_componentWillReceiveProps is using it.
                  display:
                    this.props.showConvenienceFee && convenienceFeeVersion == 'v2'
                      ? 'none'
                      : 'block',
                }}
                type="submit"
                value={`Checkout Rs. ${rupees(totalAmount)}`}
              />
              <TnCText />
            </FormField>
          </FormFieldsWrapper>
        </Form>
      </div>
    );
  }
}

NetBanking.propTypes = {
  finalizePurchase: PropTypes.object,
  showConvenienceFee: PropTypes.bool,
  handleFetchConvenienceFees: PropTypes.func,
  handleSelectBank: PropTypes.func,
  validateNetBanking: PropTypes.func,
  handleBankPaymentRequest: PropTypes.func,
};

export default connect(() => ({}), {
  handleSelectBank,
  handleBankPaymentRequest,
  validateNetBanking,
  handleFetchConvenienceFees,
})(NetBanking);
