import React from 'react';

const TrustIcons = () => (
  <div
    className="push-one-half--bottom soft-double--top payment-trust-image"
    style={{ height: 32, display: 'block', marginTop: '30px' }}
  />
);

export default TrustIcons;
