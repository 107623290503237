import PropTypes from 'prop-types';
import React from 'react';

class InputCheckbox extends React.Component {
  render() {
    const inputProps = { ...this.props };
    delete inputProps.label;
    delete inputProps.className;
    delete inputProps.style;
    delete inputProps.checkboxClassName;
    delete inputProps.checkboxStyle;
    return (
      <label className={this.props.className} style={this.props.style}>
        <input
          style={{ top: '-1px', position: 'relative', ...this.props.checkboxStyle }}
          type="checkbox"
          className={`push-half--right ${this.props.checkboxClassName}`}
          {...inputProps}
        />
        {this.props.label}
        {this.props.errors ? (
          <p className="text-red push-half--top">{this.props.errors}</p>
        ) : null}
      </label>
    );
  }
}

InputCheckbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  checkboxClassName: PropTypes.string,
  style: PropTypes.object,
  errors: PropTypes.array,
  checkboxStyle: PropTypes.object,
};

InputCheckbox.defaultProps = {
  checkboxClassName: '',
  checkboxStyle: {},
};

export default InputCheckbox;
