import { isPermissionDeferred, isPermissionDenied } from '../../rbac/utils';

// Block the request when permission is deferred or denied (or not available)
export const isApiRequestBlocked = (permissionName, useBlacklistingApproach = false) => {
  /**
   * Checks if an API request should be blocked according to permission.
   * By default uses a whitelisting approach.
   * @param {String} permissionName - Name of the permission.
   * @param {Boolean} useBlacklistingApproach - When true it switches to using a blacklisting approach.
   * @returns {Boolean} - true if request should be blocked, false otherwise.
   */
  return (
    isPermissionDeferred(permissionName) ||
    isPermissionDenied(permissionName, useBlacklistingApproach)
  );
};
