import PropTypes from 'prop-types';
import React from 'react';
import root from 'window-or-global';

import { isAndroidWebview, isRunningInIframe, isSmallDevice } from '../../..//common/config/utils';
import { LayoutStyles } from '../../..//dashboard//cart/config';
import Icon from '../../../common/components/Icon';
import Modal, { ModalClose, ModalContent } from '../../../common/components/Modal';
import WrappedContent from './payment-wrapper/WrappedContent';

const modalStyle = {};
if (isAndroidWebview) {
  modalStyle.position = 'relative';
}


const paymentWrapper = (Component) => {
  const PaymentWrapper = (props) => {
    const {
      settings: {
        isPaymentPopupOpen,
        isMerchantViewingOwnLink,
        isPaymentResponse,
        isCartOrder,
        isRetrying,
        isLinkPayment,
        isInstapayPayment,
        isEmbed,
        isIFrame,
      },
    } = props;

    const wrapInModal = !isEmbed;
    const document = root.document;
    const isMandatoryPayment = isRetrying
      ? isRetrying && !isRunningInIframe()
      : isLinkPayment || isInstapayPayment;

    // This is used so that we are able to close the payment form by displaying the modal close button.
    // We are providing this for payment forms in 'domains-and-mailbox' and 'subscription' now.
    // Since the payment form is rendered on an iframe here, we are using the referrer of this iframe
    // to determine the domain from which the payment was initiated and identify if we need to show the close button.
    // This is only if the payment form is running in a iframe.
    const isPaymentFormClosable =
      isRunningInIframe() &&
      (document.referrer.indexOf('domains-and-mailbox') !== -1 ||
        document.referrer.indexOf('subscription') !== -1);

    const backdropStyle = {
      background: isRunningInIframe() ? 'none' : 'rgba(241, 244, 242, 0.94)',
    };

    let modalContentContainerStyle = {
      marginTop: isMerchantViewingOwnLink ? '60px' : '48px',
      marginBottom: !props.settings.imBranding ? '42px' : '',
    };

    if ((isPaymentResponse || isRetrying) && isCartOrder) {
      modalContentContainerStyle = {
        ...modalContentContainerStyle,
        ...LayoutStyles,
      };
    }
    if (isAndroidWebview && document && document.getElementsByTagName('html')[0]) {
      document.getElementsByTagName('html')[0].style['overflow-y'] = 'auto';
    }
    if (wrapInModal) {
      if (!root.isPaymentsPopupReady && !isLinkPayment) {
        props.setOfferPagePaymentButtonClickListener();
      }

      return (
        <Modal
          aria-labelledby="payments-modal"
          backdropStyle={backdropStyle}
          show={isPaymentPopupOpen}
          modalStyle={modalStyle}
        >
          <WrappedContent {...props}>
            <ModalContent
              isClosable={false}
              handleFullScreenScroll={false}
              style={{ ...modalContentContainerStyle }}
            >
              <Component {...props} />
              {/* Enable closing the modal for non link payments and also for postPurchase running in Iframe */}
              {(!isPaymentResponse && !isMandatoryPayment) ||
              (isPaymentResponse && isRunningInIframe()) ||
              isPaymentFormClosable ? (
                <ModalClose
                  className="payments--close"
                  onRequestClose={() => {
                    props.handleTogglePaymentPopup();
                    props.setOfferPagePaymentButtonClickListener();

                    if (isRunningInIframe()) {
                      root.parent.postMessage('onRequestClose', '*');
                    }
                  }}
                  style={{
                    color: isSmallDevice() ? '#fff' : '#717D8B',
                  }}
                />
              ) : null}
            </ModalContent>
          </WrappedContent>
        </Modal>
      );
    }

    return (
      <WrappedContent {...props}>
        <div>
          <Component {...props} />
          {isEmbed && isIFrame ? (
            <div
              className="payments--close"
              style={{
                position: 'absolute',
                right: '50%',
                color: isSmallDevice()
                  ? '#fff'
                  : isLinkPayment || isInstapayPayment
                  ? '#2C475D'
                  : '#717D8B',
                cursor: 'pointer',
              }}
              onClick={() => root.parent.postMessage('onRequestClose', '*')}
            >
              <Icon className="ui-1_circle-remove_modal medium" />
            </div>
          ) : null}
        </div>
      </WrappedContent>
    );
  };

  PaymentWrapper.propTypes = {
    settings: PropTypes.object,
    handleTogglePaymentPopup: PropTypes.func,
    setOfferPagePaymentButtonClickListener: PropTypes.func,
  };

  return PaymentWrapper;
};

export default paymentWrapper;
