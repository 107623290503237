import Rupee from '@instamojo/rupee';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  Form,
  FormFieldWithLabel as FormField,
  FormFieldWrapper as FormFieldsWrapper,
} from '../../../../../common/components/FormComponents';
import { Columns, Row } from '../../../../../common/components/Grid';
import Icon from '../../../../../common/components/Icon';
import {
  handleChangeEMICardProvider,
  handleChangeEMITenure,
  handleChangeSelectedEMIProvider,
  handleSubmitEMIForm,
} from '../../../actions/FinalizePurchaseActions';
import { getEMIAmount, getTotalEMIAmount } from '../../../utils/FinalizePurchaseUtils';
import Card from './Card';

class EMI extends React.Component {
  onSelectEMIOption({ months, emiAmount, selectedEMICardProvider, initSubmissionUri }) {
    this.props.handleChangeEMITenure(months, emiAmount);
    this.props.handleSubmitEMIForm({
      selectedEMITenure: months,
      selectedEMICardProvider,
      initSubmissionUri,
    });
  }

  render() {
    const {
      selectedEMICardProvider,
      selectedEMITenure,
      isEMIFormSubmitting,
      isEMIFormSubmitted,
      emiAmount,
      selectedPaymentObject: selectedPaymentOption,
    } = this.props.finalizePurchase;

    const {
      emiList,
      submissionUri: initSubmissionUri,
      emiSubmissionUri,
      totalAmount,
    } = selectedPaymentOption;

    const EMISummaryRowClasses =
      'flex-space-between soft-half border-grey--bottom flush--bottom';

    return (
      <div>
        {isEMIFormSubmitting ? (
          isEMIFormSubmitted ? (
            <div>
              {!this.props.showConvenienceFeeForm ? (
                <div className="rounded border-grey push--bottom">
                  <p className={`${EMISummaryRowClasses} background-white rounded--top`}>
                    <span>{emiList[selectedEMICardProvider].name}</span>
                    <span>
                      <a onClick={() => this.props.handleChangeSelectedEMIProvider()}>
                        Change
                      </a>
                    </span>
                  </p>
                  <p className={EMISummaryRowClasses}>
                    <span className="text-light-grey">Duration</span>
                    <span>{selectedEMITenure} months</span>
                  </p>
                  <p className={EMISummaryRowClasses}>
                    <span className="text-light-grey">Interest</span>
                    <span>
                      {emiList[selectedEMICardProvider].rates[selectedEMITenure]}% p.a.
                    </span>
                  </p>
                  <p className={`${EMISummaryRowClasses} borderless--bottom`}>
                    <span className="text-light-grey">Installments</span>
                    <span>
                      <Rupee value={emiAmount} />
                    </span>
                  </p>
                </div>
              ) : null}
              <Card
                finalizePurchase={this.props.finalizePurchase}
                submissionUri={emiSubmissionUri}
              />
            </div>
          ) : null
        ) : (
          <Form>
            <FormFieldsWrapper>
              <FormField label="Select Your Card Provider">
                <select
                  value={selectedEMICardProvider}
                  onChange={(e) =>
                    this.props.handleChangeEMICardProvider({
                      bankKey: e.target.value,
                      paymentOption: selectedPaymentOption,
                    })
                  }
                >
                  {Object.keys(emiList).map((bankKey) => {
                    return (
                      <option key={bankKey} value={bankKey}>
                        {emiList[bankKey].name}
                      </option>
                    );
                  })}
                </select>
              </FormField>
              <FormField label="Select an EMI option">
                {Object.keys(emiList[selectedEMICardProvider].rates).map((months) => {
                  const rate = emiList[selectedEMICardProvider].rates[months];
                  const formattedEmiAmount = getEMIAmount(
                    totalAmount,
                    rate / 1200,
                    months,
                  );
                  const totalEmiAmount = getTotalEMIAmount(formattedEmiAmount, months);
                  return (
                    <Row
                      key={`${selectedEMICardProvider}_${months}`}
                      onClick={() =>
                        this.onSelectEMIOption({
                          months,
                          emiAmount: formattedEmiAmount,
                          selectedEMICardProvider,
                          initSubmissionUri,
                        })
                      }
                      className="flush--sides hard--sides pointer card soft--bottom push--bottom"
                      style={{ borderColor: '#3B96E0' }}
                    >
                      <Columns className="small-10">
                        <div>
                          <Rupee value={formattedEmiAmount} />
                          {` x ${months} Months`}
                        </div>
                        <div className="text-small">
                          Total <Rupee value={totalEmiAmount} /> {`@${rate}%`}
                        </div>
                      </Columns>
                      <Columns className="small-2 soft-half--top text-right soft-half--right">
                        <Icon className="arrows-1_minimal-right sm text-hyperlink" />
                      </Columns>
                    </Row>
                  );
                })}
              </FormField>
            </FormFieldsWrapper>
          </Form>
        )}
      </div>
    );
  }
}

EMI.propTypes = {
  finalizePurchase: PropTypes.object,
  showConvenienceFeeForm: PropTypes.object,

  handleChangeSelectedEMIProvider: PropTypes.func,
  handleChangeEMICardProvider: PropTypes.func,
  handleChangeEMITenure: PropTypes.func,
  handleSubmitEMIForm: PropTypes.func,
};

export default connect(() => ({}), {
  handleChangeSelectedEMIProvider,
  handleChangeEMICardProvider,
  handleChangeEMITenure,
  handleSubmitEMIForm,
})(EMI);
