import Rupee from '@instamojo/rupee';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Loading from '../../../../../common/components/Loading';
import OtpVerification from '../../../../../common/components/otp/OtpVerificiation';
import SubmitButton from '../../../../../common/components/SubmitButton';
import ia from '../../../../../common/config/Analytics';
import {
  handleCODOtpChange,
  handlePhoneInputChange,
  handlePhoneInputEditModeForCOD,
  handleSubmitOTP,
  handleValidatePhoneNumberAndSendOTP,
} from '../../../actions/FinalizePurchaseActions';
import {
  CASH_ON_DELIVERY_ORDER_SUMMARY_STEP,
  CASH_ON_DELIVERY_OTP_VERIFICATION_STEP,
} from '../../../reducers/FinalizePurchaseReducers';

class CashOnDelivery extends React.Component {
  componentDidMount() {
    const { cashOnDeliveryDetails: { codStep } = {} } = this.props.finalizePurchase;

    if (codStep === CASH_ON_DELIVERY_ORDER_SUMMARY_STEP) {
      ia.sendEvent('Initiated Order Confirmation', {
        source: 'COD',
      });
    }
  }

  validateSubmitOTPCodeForm = () => {
    const { otp } = this.props.finalizePurchase.cashOnDeliveryDetails;
    const otpValue = otp.join('');
    const { order, orderToken } = this.props.finalizePurchase;
    const orderDetails = { order, orderToken };
    this.props.handleSubmitOTP(orderDetails, otpValue);
    //Clear OTP field_list
    this.verifyOtpFormRef.reset();
  };

  handlePhoneInputEditMode = () => {
    this.props.handlePhoneInputEditModeForCOD();
  };

  handleTriggerResendOTP = () => {
    //Clear OTP field_list
    this.verifyOtpFormRef.reset();

    ia.sendEvent('Resent OTP', {
      source: 'COD',
    });

    const {
      finalPayload: { phone: buyerPhone } = {},
      order,
      orderToken,
    } = this.props.finalizePurchase;
    const orderDetails = { phone: buyerPhone, order, orderToken };
    this.props.handleValidatePhoneNumberAndSendOTP({
      orderDetails,
      updatePhoneNumber: false,
      resendOtp: true,
    });
  };

  handlePhoneChange = (phone) => {
    this.props.handlePhoneInputChange(phone);
  };

  handleValidatePhoneNumberAndSendOTP = (updatePhoneNumber) => {
    const {
      finalPayload: { phone: buyerPhone } = {},
      order,
      orderToken,
    } = this.props.finalizePurchase;
    const orderDetails = { phone: buyerPhone, order, orderToken };
    this.props.handleValidatePhoneNumberAndSendOTP({ orderDetails, updatePhoneNumber });
  };

  render() {
    const {
      selectedPaymentObject: selectedPaymentOption,
      cashOnDeliveryDetails,
      errors,
      finalPayload,
    } = this.props.finalizePurchase;
    const {
      totalAmount,
      itemTotal,
      shippingCharges,
      convenienceFees,
      convenienceFeesTax,
    } = selectedPaymentOption;
    const { phone: buyerPhone } = finalPayload;

    const {
      codStep,
      isOTPResent,
      isPhoneNumberEditing,
      otp,
      isOTPLimitExceeded,
      isCODSubmitting,
    } = cashOnDeliveryDetails;

    const verifyOtpTextData = {
      title: 'Verify with OTP',
      subTitle: 'We have sent an OTP to your phone number.',
      submissionText: 'Confirm and Place Order',
    };
    const CONVENIENCE_FEE_GST_PERCENTAGE = 18;
    if (isCODSubmitting) {
      return <Loading />;
    }
    return (
      <>
        {codStep === CASH_ON_DELIVERY_ORDER_SUMMARY_STEP ? (
          <>
            <div className="flex-space-between push--bottom">
              <p className="text-grey f14">
                Pay in cash at the time of delivery of product. No advance payment needed.
                An OTP will be sent to verify your mobile number.
              </p>
            </div>
            <hr style={{ borderBottomColor: '#DEE5EB' }} />
            <div>
              <h6>Order Summary</h6>
              <div className="flex-space-between text-grey push-half--bottom">
                <p className="text-left">Item Total</p>
                <p className="text-right">
                  <Rupee value={itemTotal} />
                </p>
              </div>
              <div className="flex-space-between text-grey push-half--bottom">
                <p className="text-left">Shipping Charges</p>
                {shippingCharges == 0 ? (
                  <p className="text-right">Free</p>
                ) : (
                  <p className="text-right">
                    <Rupee value={shippingCharges} />
                  </p>
                )}
              </div>
              {this.props.showConvenienceFee ? (
                <>
                  <div className="flex-space-between text-grey push-half--bottom">
                    <p className="text-left">Convenience Fee</p>
                    <p className="text-right">
                      <Rupee value={convenienceFees} />
                    </p>
                  </div>
                  <div className="flex-space-between text-grey push-half--bottom">
                    <p className="text-left">GST @ {CONVENIENCE_FEE_GST_PERCENTAGE}%</p>
                    <p className="text-right">
                      <Rupee value={convenienceFeesTax} />
                    </p>
                  </div>
                </>
              ) : null}
            </div>
            <hr style={{ borderBottomColor: '#DEE5EB' }} />
            <div className="flex-space-between push--bottom">
              <p className="text-left">Total Amount</p>
              <p className="text-right">
                <Rupee value={totalAmount} />
              </p>
            </div>
            {isOTPLimitExceeded ? (
              <div
                className="error-message-block soft f14 push--bottom"
                style={{ border: '1px solid #DF542F' }}
              >
                Maximum OTP requests limit reached. Please try again in 60 minutes.
              </div>
            ) : null}
            <div className="push--bottom">
              <SubmitButton
                onClick={() => {
                  this.handleValidatePhoneNumberAndSendOTP(false);
                }}
                value="Next: Verify with OTP"
                className={classNames('btn-green btn-block', {
                  disabled: isOTPLimitExceeded,
                })}
              />
            </div>
          </>
        ) : codStep === CASH_ON_DELIVERY_OTP_VERIFICATION_STEP ? (
          <>
            <OtpVerification
              verifyOtpTextData={verifyOtpTextData}
              phoneNumber={buyerPhone}
              otp={otp}
              errors={errors}
              isOTPLimitExceeded={isOTPLimitExceeded}
              isOTPResent={isOTPResent}
              isPhoneNumberEditable={true}
              isPhoneNumberEditing={isPhoneNumberEditing}
              verifyOtpFormRef={(ref) => (this.verifyOtpFormRef = ref)}
              handleValidatePhoneNumberAndSendOTP={(updatePhoneNumber) =>
                this.handleValidatePhoneNumberAndSendOTP(updatePhoneNumber)
              }
              handlePhoneChange={(phone) => this.handlePhoneChange(phone)}
              validateSubmitOTPCodeForm={() => this.validateSubmitOTPCodeForm()}
              handleOtpChange={(otpValue) => this.props.handleCODOtpChange(otpValue)}
              handleTriggerResendOTP={() => this.handleTriggerResendOTP()}
              handlePhoneInputEditMode={() => this.handlePhoneInputEditMode()}
            />
          </>
        ) : null}
      </>
    );
  }
}

CashOnDelivery.propTypes = {
  finalizePurchase: PropTypes.object.isRequired,
  showConvenienceFee: PropTypes.bool,
  cashOnDeliveryDetails: PropTypes.object,
  handleCODOtpChange: PropTypes.func,
  handleValidatePhoneNumberAndSendOTP: PropTypes.func,
  handlePhoneInputChange: PropTypes.func,
  handlePhoneInputEditModeForCOD: PropTypes.func,
  handleSubmitOTP: PropTypes.func,
};

export default connect(() => ({}), {
  handleValidatePhoneNumberAndSendOTP,
  handleCODOtpChange,
  handlePhoneInputChange,
  handlePhoneInputEditModeForCOD,
  handleSubmitOTP,
})(CashOnDelivery);
