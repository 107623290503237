import Rupee from '@instamojo/rupee';
import TooltipComponent from '@instamojo/tooltip-component';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import React from 'react';

import Icon from '../../../../../common/components/Icon';
import Loading from '../../../../../common/components/Loading';

// NOTE: that this is different from other payment options.
// There is no such button click for loading QR component
// As soon as the main payment options are rendered, this component is loaded and
// starts polling imojo for the status.

const DEFAULT_UPI_POLLING_TIMEOUT_LIMIT = 15 * 60 * 1000; // 15 minutes of time.
class DynamicQR extends React.Component {
  constructor(props) {
    super(props);
    this.upiPollID = null;
    this.upiPollTimeout = null;
    this.state = {
      isDynamicQRActive: true,
    };
  }
  componentDidMount() {
    // Set to a default of 15 minutes in cases where it does not come from backend
    const UPIPollingTimeout = this.props.dynamicQrPollerTimeout
      ? this.props.dynamicQrPollerTimeout
      : DEFAULT_UPI_POLLING_TIMEOUT_LIMIT;
    // We will call the convienceFeeVersion Api if version was v2
    if (this.props.convenienceFeeVersion == 'v2') {
      this.props.handleFetchConvenienceFeesForQR();
    }
    // We will start calling api on component mount hoping for payment
    // using QR :)
    this.upiPollID = this.props.pollForUPIAcceptance(this.props.dynamicQrStatusCheckUrl);
    this.upiPollTimeout = setTimeout(() => {
      if (this.upiPollID) {
        // we will clear the api call once the component is inactive for long time (15 minutes)
        // as payment is no more happening via qr :(
        this.setState({ isDynamicQRActive: false });
        clearInterval(this.upiPollID);
      }
    }, UPIPollingTimeout);
  }
  componentWillUnmount() {
    if (this.upiPollID) {
      // we will clear the api call once the component is unmounted
      // as payment is no more happening via qr :(
      clearInterval(this.upiPollID);
    }
    if (this.upiPollTimeout) {
      // clear the timeout so as to not trigger the updation of state once unmounted
      clearTimeout(this.upiPollTimeout);
    }
  }

  refreshQRPolling() {
    const UPIPollingTimeout = this.props.dynamicQrPollerTimeout
      ? this.props.dynamicQrPollerTimeout
      : DEFAULT_UPI_POLLING_TIMEOUT_LIMIT;
    this.setState({ isDynamicQRActive: true });
    this.upiPollID = this.props.pollForUPIAcceptance(this.props.dynamicQrStatusCheckUrl);
    this.upiPollTimeout = setTimeout(() => {
      if (this.upiPollID) {
        // we will clear the api call once the component is inactive for long time (15 minutes)
        // as payment is no more happening via qr :(
        this.setState({ isDynamicQRActive: false });
        clearInterval(this.upiPollID);
      }
    }, UPIPollingTimeout);
  }

  render() {
    const { dynamicQrDetails } = this.props;
    const { isDynamicQRActive } = this.state;
    // Todo to refactor this
    const totalAmount =
      this.props.convenienceFeeVersion == 'v2'
        ? dynamicQrDetails.totalAmount
        : this.props.totalAmount;
    return (
      <div className="center">
        {!isDynamicQRActive ? ( // show overlay only if not active
          <div className="dynamic-qr-inactive-overlay-container">
            <div className="dynamic-qr-inactive-overlay">
              <div
                onClick={() => this.refreshQRPolling()}
                className="dynamic-qr-refresh-container soft-half--top"
              >
                <Icon className="arrows-1_refresh-68 text-purple" />
                <span className="text-purple">Reload QR </span>
              </div>
            </div>
          </div>
        ) : null}
        <QRCode value={this.props.dynamicQrUri} size={200} />
        <div className="soft-quarter--ends">
          <p className="text-light-grey" style={{ fontSize: '18px' }}>
            Scan and pay by any UPI App
          </p>
          {totalAmount ? (
            <div>
              <span className="text-black text-bold" style={{ fontSize: '30px' }}>
                <Rupee value={totalAmount} />
              </span>
              {
                /* We will show the tooltip only for variable convenience fee */
                this.props.convenienceFeeVersion == 'v2' ? (
                  <TooltipComponent
                    overlay={
                      <div style={{ width: '200px' }}>
                        <div className="soft-quarter--ends">
                          <span>Amount</span>
                          <span className="right">
                            <Rupee value={dynamicQrDetails.amount} />
                          </span>
                        </div>
                        <div className="soft-quarter--ends">
                          <span>Convenience Fee</span>
                          <span className="right">
                            <Rupee value={dynamicQrDetails.convenienceFees} />
                          </span>
                        </div>
                        <div className="soft-quarter--ends">
                          <span>GST @ 18%</span>
                          <span className="right">
                            <Rupee value={dynamicQrDetails.convenienceFeesTax} />
                          </span>
                        </div>
                        <hr style={{ margin: '10px 0px' }} />
                        <div className="soft-quarter--ends">
                          <span>Payment Amount</span>
                          <span className="right">
                            <Rupee value={totalAmount} />
                          </span>
                        </div>
                      </div>
                    }
                    overlayStyle={{ opacity: 0.99 }}
                  >
                    <a className="tooltip-icon">
                      <Icon className="icon icon-info-circle push-half--left" />
                    </a>
                  </TooltipComponent>
                ) : null
              }
            </div>
          ) : (
            <div
              style={{
                maxHeight: '48px',
                minHeight: '48px',
                position: 'relative',
              }}
            >
              <Loading
                // This is required to allow smooth experience for the user before the QR amount is fetched in conv fee version2.
                style={{
                  height: '25px',
                  width: '25px',
                  borderRadius: '25px',
                  margin: '0px auto',
                  position: 'absolute',
                  left: '45%',
                  top: '25%',
                }}
              />
            </div>
          )}
          <div className="soft">
            <p className={'payment-option-upi_group_mono'} style={{ margin: 'auto' }} />
          </div>
        </div>
      </div>
    );
  }
}

DynamicQR.propTypes = {
  dynamicQrDetails: PropTypes.object,
  dynamicQrUri: PropTypes.string,
  dynamicQrStatusCheckUrl: PropTypes.string,
  dynamicQrPollerTimeout: PropTypes.number,
  pollForUPIAcceptance: PropTypes.func,
  convenienceFeeVersion: PropTypes.string,
  totalAmount: PropTypes.string,
  handleFetchConvenienceFeesForQR: PropTypes.func,
};

export default DynamicQR;
