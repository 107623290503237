/* globals MojoUser */
import Analytics from '../../../common/config/Analytics';
import ia from '../../../common/config/Analytics';
import { POST_API } from '../../../common/middlewares/form';
import { neftBankList } from '../config/Neft';
import { getPaymentOptionKey } from '../utils/FinalizePurchaseUtils';
import { invokeMixpanelEvent, MIXPANEL_PAYMENT_EVENT } from '../utils/PaymentUtils';
import { handleAwaitingPaymentCompletion } from './SettingsActions';

const getStore = (state) => (state.cart ? state.cart : state);

export const HANDLE_SELECT_PAYMENT_OPTION = 'HANDLE_SELECT_PAYMENT_OPTION';
export const handleSelectPaymentOption = ({ type, name, selectedSavedCard }) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: HANDLE_SELECT_PAYMENT_OPTION,
    payload: {
      selectedOption: {
        type,
        name,
        selectedSavedCard,
      },
    },
  });

  const simulateLoadingWithWait = ['nb', 'wallet', 'paylater', 'upi_qr'];
  if (simulateLoadingWithWait.indexOf(type) !== -1) {
    setTimeout(() => {
      dispatch(handleHideLoader());
    }, 600);
  }

  if (type === 'bank_transfer') {
    dispatch(
      handleSubmitNEFT({
        paymentOption: { type, name, selectedSavedCard },
      }),
    );
  }

  const store = getStore(getState());
  try {
    Analytics.sendEvent('Selected Payment Mode', {
      payment_mode: name,
      buyer_id: MojoUser ? MojoUser.id : null,
      order_id: store.settings.isRetrying
        ? store.retry.finalizePurchase.order
        : store.finalizePurchase.order,
      seller_username: store.settings.seller.username,
    });
  } catch (e) {
    /* continue regardless of error */
  }
};

export const TOGGLE_UPI_INTENT_WAIT_SCREEN = 'TOGGLE_UPI_INTENT_WAIT_SCREEN';
export const toggleUPIIntentWaitScreen = (status, app = '') => ({
  type: TOGGLE_UPI_INTENT_WAIT_SCREEN,
  status,
  app,
});

export const HANDLE_MANAGE_SAVED_CARDS = 'HANDLE_MANAGE_SAVED_CARDS';
export const handleManageSavedCards = (payload) => ({
  type: HANDLE_MANAGE_SAVED_CARDS,
  payload,
});

export const HANDLE_DELETE_SAVED_CARD_REQUEST = 'HANDLE_DELETE_SAVED_CARD_REQUEST';
export const HANDLE_DELETE_SAVED_CARD_SUCCESS = 'HANDLE_DELETE_SAVED_CARD_SUCCESS';
export const HANDLE_DELETE_SAVED_CARD_FAILURE = 'HANDLE_DELETE_SAVED_CARD_FAILURE';
export const handleDeleteSavedCard = (cardToBeDeleted) => ({
  [POST_API]: {
    types: [
      HANDLE_DELETE_SAVED_CARD_REQUEST,
      HANDLE_DELETE_SAVED_CARD_SUCCESS,
      HANDLE_DELETE_SAVED_CARD_FAILURE,
    ],
    endpoint: cardToBeDeleted.deletionUri,
    payload: () => {
      const { signature, orderId, cardUri } = cardToBeDeleted;
      return {
        signature,
        orderId,
        cardUri,
      };
    },
  },
});

export const HANDLE_SELECT_WALLET = 'HANDLE_SELECT_WALLET';
export const handleSelectWallet = ({ walletId, paymentOption }) => (
  dispatch,
  getState,
) => {
  const store = getStore(getState());
  const { isRetrying } = store.settings;
  const {
    finalizePurchase: { paymentOptions, order: order },
    settings: {
      seller: { username },
    },
  } = isRetrying ? store.retry : store;

  const { walletList } = paymentOptions[getPaymentOptionKey(paymentOption)];
  const getWallet = walletList.find((wallet) => wallet.id === walletId);

  try {
    Analytics.sendEvent('Selected Wallet Provider', {
      bank_name: getWallet ? getWallet.name : '',
      buyer_id: MojoUser ? MojoUser.id : null,
      order_id: order,
      seller_username: username,
    });
  } catch (e) {
    /* continue regardless of error */
  }

  dispatch({
    type: HANDLE_SELECT_WALLET,
    payload: {
      walletId,
    },
  });
};

export const HANDLE_SELECT_BANK = 'HANDLE_SELECT_BANK';
export const handleSelectBank = ({ bankId, paymentOption }) => (dispatch, getState) => {
  const store = getStore(getState());

  const { isRetrying } = store.settings;

  const {
    finalizePurchase: { paymentOptions, order: orderId },
    settings: {
      seller: { username },
    },
  } = isRetrying ? store.retry : store;

  const { nbList } = paymentOptions[getPaymentOptionKey(paymentOption)];
  const getBank = nbList.find((bank) => bank.id === bankId);

  try {
    Analytics.sendEvent('Selected Netbanking Bank', {
      bank_name: getBank ? getBank.name : '',
      buyer_id: MojoUser ? MojoUser.id : null,
      order_id: orderId,
      seller_username: username,
    });
  } catch (e) {
    /* continue regardless of error */
  }

  dispatch({
    type: HANDLE_SELECT_BANK,
    payload: {
      bankId,
    },
  });
};

export const handleNeftBankOption = (bankId, username, order) => (dispatch) => {
  Analytics.sendEvent('Selected NEFT Bank', {
    bank_name: fetchOptionfromId(bankId, 'name'),
    seller_username: username,
    order_id: order,
  });
  const bankUrl = fetchOptionfromId(bankId, 'url');
  dispatch(handleSelectNeftBank({ bankId, bankUrl }));
};

export function fetchOptionfromId(value, option) {
  for (let i = 0; i < neftBankList.length; i++) {
    if (neftBankList[i].id == value) {
      return neftBankList[i][option];
    }
  }
}

export const HANDLE_NEFT_SELECT_BANK = 'HANDLE_NEFT_SELECT_BANK';
export const handleSelectNeftBank = ({ bankId, bankUrl }) => ({
  type: HANDLE_NEFT_SELECT_BANK,
  payload: {
    bankId,
    bankUrl,
  },
});

export const HANDLE_BANK_PAYMENT_FAILURE = 'HANDLE_BANK_PAYMENT_FAILURE';
const handleBankPaymentFailure = () => ({
  type: HANDLE_BANK_PAYMENT_FAILURE,
});

export const PASS_INTENT_URI_WITH_CONVENIENCE_FEE =
  'PASS_INTENT_URI_WITH_CONVENIENCE_FEE';
export const passIntentWithConvenienceFee = (finalUri, app = '') => ({
  type: PASS_INTENT_URI_WITH_CONVENIENCE_FEE,
  finalUri,
  app,
});

export const HANDLE_BANK_PAYMENT_SUCCESS = 'HANDLE_BANK_PAYMENT_SUCCESS';
const handleBankPaymentSuccess = () => ({
  type: HANDLE_BANK_PAYMENT_SUCCESS,
});

export const validateNetBanking = (reportFailureOnly = false) => (dispatch, getState) => {
  const store = getStore(getState());
  const { selectedBankId } = store.settings.isRetrying
    ? store.retry.finalizePurchase
    : store.finalizePurchase;
  return new Promise((resolve, reject) => {
    if (!selectedBankId) {
      return reject(dispatch(handleBankPaymentFailure()));
    }
    if (reportFailureOnly) {
      // This is to prevent emitting HANDLE_BANK_PAYMENT_SUCCESS as this opens a new window for processing Payment
      return resolve();
    }
    return resolve(dispatch(handleBankPaymentSuccess()));
  });
};

export const handleBankPaymentRequest = () => (dispatch, getState) => {
  const store = getStore(getState());
  const { selectedBankId } = store.settings.isRetrying
    ? store.retry.finalizePurchase
    : store.finalizePurchase;

  const promiseList = [];

  const validationResult = dispatch(validateNetBanking());

  promiseList.push(validationResult);

  if (selectedBankId) {
    promiseList.push(dispatch(handleAwaitingPaymentCompletion()));
  }

  return Promise.all(promiseList).catch(() => {
    /**/
  });
};

export const HANDLE_WALLET_PAYMENT_SUCCESS = 'HANDLE_WALLET_PAYMENT_SUCCESS';
const handleWalletPaymentSuccess = () => ({
  type: HANDLE_WALLET_PAYMENT_SUCCESS,
});

export const HANDLE_WALLET_PAYMENT_FAILURE = 'HANDLE_WALLET_PAYMENT_FAILURE';
const handleWalletPaymentFailure = () => ({
  type: HANDLE_WALLET_PAYMENT_FAILURE,
});

export const validateWalletPaymentRequest = (reportFailureOnly = false) => (
  dispatch,
  getState,
) => {
  const store = getStore(getState());
  const { selectedWalletId } = store.settings.isRetrying
    ? store.retry.finalizePurchase
    : store.finalizePurchase;
  return new Promise((resolve, reject) => {
    if (!selectedWalletId) {
      return reject(dispatch(handleWalletPaymentFailure()));
    }
    if (reportFailureOnly) {
      // This is to prevent emitting HANDLE_WALLET_PAYMENT_SUCCESS as this opens a new window for processing Payment
      return resolve();
    }
    return resolve(dispatch(handleWalletPaymentSuccess()));
  });
};

export const handleWalletPaymentRequest = () => (dispatch, getState) => {
  const store = getStore(getState());
  const { selectedWalletId } = store.settings.isRetrying
    ? store.retry.finalizePurchase
    : store.finalizePurchase;

  const promiseList = [];

  const validationResult = dispatch(validateWalletPaymentRequest());

  promiseList.push(validationResult);

  if (selectedWalletId) {
    promiseList.push(dispatch(handleAwaitingPaymentCompletion()));
  }

  return Promise.all(promiseList).catch(() => {
    /**/
  });
};

/* Actions for PayLater Payment Type */
export const HANDLE_SELECT_PAY_LATER = 'HANDLE_SELECT_PAY_LATER';
export const handleSelectPayLaterOption = ({ paylaterId, paymentOption }) => (
  dispatch,
  getState,
) => {
  const store = getStore(getState());
  const { isRetrying } = store.settings;

  const {
    finalizePurchase: { paymentOptions, order: order },
    settings: {
      seller: { username },
    },
  } = isRetrying ? store.retry : store;

  const { paylaterList } = paymentOptions[getPaymentOptionKey(paymentOption)];
  const getPaylaterType = paylaterList.find((type) => type.id === paylaterId);
  try {
    Analytics.sendEvent('Selected Paylater Provider', {
      bank_name: getPaylaterType ? getPaylaterType.name : '',
      buyer_id: MojoUser ? MojoUser.id : null,
      order_id: order,
      seller_username: username,
    });
  } catch (e) {
    /* continue regardless of error */
  }

  dispatch({
    type: HANDLE_SELECT_PAY_LATER,
    payload: {
      paylaterId,
    },
  });
};

export const HANDLE_PAY_LATER_PAYMENT_SUCCESS = 'HANDLE_PAY_LATER_PAYMENT_SUCCESS';
const handlePayLaterPaymentSuccess = () => ({
  type: HANDLE_PAY_LATER_PAYMENT_SUCCESS,
});

export const HANDLE_PAY_LATER_PAYMENT_FAILURE = 'HANDLE_PAY_LATER_PAYMENT_FAILURE';
const handlePayLaterPaymentFailure = () => ({
  type: HANDLE_PAY_LATER_PAYMENT_FAILURE,
});

export const validatePayLaterPaymentRequest = (reportFailureOnly = false) => (
  dispatch,
  getState,
) => {
  const store = getStore(getState());
  const { selectedPayLaterId } = store.settings.isRetrying
    ? store.retry.finalizePurchase
    : store.finalizePurchase;
  return new Promise((resolve, reject) => {
    if (!selectedPayLaterId) {
      return reject(dispatch(handlePayLaterPaymentFailure()));
    }
    if (reportFailureOnly) {
      // This is to prevent emitting HANDLE_PAY_LATER_PAYMENT_SUCCESS as this opens a new window for processing Payment
      return resolve();
    }
    return resolve(dispatch(handlePayLaterPaymentSuccess()));
  });
};

export const handlePayLaterPaymentRequest = () => (dispatch, getState) => {
  const store = getStore(getState());
  const { selectedPayLaterId } = store.settings.isRetrying
    ? store.retry.finalizePurchase
    : store.finalizePurchase;

  const promiseList = [];

  const validationResult = dispatch(validatePayLaterPaymentRequest());

  promiseList.push(validationResult);

  if (selectedPayLaterId) {
    promiseList.push(dispatch(handleAwaitingPaymentCompletion()));
  }

  return Promise.all(promiseList).catch(() => {
    /**/
  });
};

/* END */

export const STORE_FINAL_PAYLOAD = 'STORE_FINAL_PAYLOAD';
export const storeFinalPayload = (finalPayload) => ({
  type: STORE_FINAL_PAYLOAD,
  payload: {
    finalPayload,
  },
});

export const HANDLE_CHANGE_EMI_CARD_PROVIDER = 'HANDLE_CHANGE_EMI_CARD_PROVIDER';
export const handleChangeEMICardProvider = ({ bankKey, paymentOption }) => ({
  type: HANDLE_CHANGE_EMI_CARD_PROVIDER,
  payload: {
    bankKey,
    paymentOption,
  },
});

export const HANDLE_CHANGE_EMI_TENURE = 'HANDLE_CHANGE_EMI_TENURE';
export const handleChangeEMITenure = (tenure, emiAmount) => ({
  type: HANDLE_CHANGE_EMI_TENURE,
  payload: {
    tenure,
    emiAmount,
  },
});

export const HANDLE_SUBMIT_EMI_FORM_REQUEST = 'HANDLE_SUBMIT_EMI_FORM_REQUEST';
export const HANDLE_SUBMIT_EMI_FORM_SUCCESS = 'HANDLE_SUBMIT_EMI_FORM_SUCCESS';
export const HANDLE_SUBMIT_EMI_FORM_FAILURE = 'HANDLE_SUBMIT_EMI_FORM_FAILURE';
export const handleSubmitEMIForm = ({
  selectedEMITenure,
  selectedEMICardProvider,
  initSubmissionUri,
}) => ({
  [POST_API]: {
    types: [
      HANDLE_SUBMIT_EMI_FORM_REQUEST,
      HANDLE_SUBMIT_EMI_FORM_SUCCESS,
      HANDLE_SUBMIT_EMI_FORM_FAILURE,
    ],
    endpoint: initSubmissionUri,
    payload: {
      emi_tenure: selectedEMITenure,
      emi_bank: selectedEMICardProvider,
    },
  },
});

export const HANDLE_CHANGE_SELECTED_EMI_PROVIDER = 'HANDLE_CHANGE_SELECTED_EMI_PROVIDER';
export const handleChangeSelectedEMIProvider = () => ({
  type: HANDLE_CHANGE_SELECTED_EMI_PROVIDER,
});

export const HANDLE_TOGGLE_LOADER = 'HANDLE_TOGGLE_LOADER';
export const handleHideLoader = () => ({
  type: HANDLE_TOGGLE_LOADER,
});

export const HANDLE_RESET_SELECTED_PAYMENT_MODE = 'HANDLE_RESET_SELECTED_PAYMENT_MODE';
export const handleResetSelectedPaymentMode = () => (dispatch) => {
  if (upiPollId) {
    clearInterval(upiPollId);
    upiPollId = null;
  }
  dispatch({
    type: HANDLE_RESET_SELECTED_PAYMENT_MODE,
  });
};

export const HANDLE_CHANGE_UPI_HANDLE = 'HANDLE_CHANGE_UPI_HANDLE';
export const handleChangeUPIHandle = (handle) => ({
  type: HANDLE_CHANGE_UPI_HANDLE,
  payload: {
    handle,
  },
});

import LGTM from 'lgtm';
const upiHandleValidator = LGTM.validator()
  .validates('virtualAddress')
  .required('Please enter your virtual UPI address')
  .using(
    (addr) =>
      addr.match(/^[\w.-]{3,81}@[a-zA-Z]{3,18}$/),
    'Please enter a valid UPI address',
  )
  .build();

export const validateUPIHandle = () => (dispatch, getState) => {
  const store = getStore(getState());
  const finalizePurchase = store.settings.isRetrying
    ? store.retry.finalizePurchase
    : store.finalizePurchase;
  const upiHandle = finalizePurchase.upiHandle.trim().split('@')[0];

  return new Promise((resolve, reject) => {
    upiHandleValidator
      .validate({
        virtualAddress:
          finalizePurchase.selectedUpiPSP && finalizePurchase.selectedUpiPSP !== 'others'
            ? `${upiHandle}${finalizePurchase.selectedUpiPSP}`
            : finalizePurchase.upiHandle,
      })
      .then((result) => {
        if (!result.valid) {
          dispatch({
            type: HANDLE_SUBMIT_UPI_FAILURE,
            errors: result.errors,
          });
          return reject();
        }
        return resolve();
      });
  });
};

export const HANDLE_SUBMIT_UPI_REQUEST = 'HANDLE_SUBMIT_UPI_REQUEST';
export const HANDLE_SUBMIT_UPI_SUCCESS = 'HANDLE_SUBMIT_UPI_SUCCESS';
export const HANDLE_SUBMIT_UPI_FAILURE = 'HANDLE_SUBMIT_UPI_FAILURE';
export const handleSubmitUPI = ({ paymentOption }) => (
  dispatch,
  getState,
) => {
  const store = getStore(getState());
  const finalizePurchase = store.settings.isRetrying
    ? store.retry.finalizePurchase
    : store.finalizePurchase;
  const upiHandle = finalizePurchase.upiHandle.trim().split('@')[0];

  Analytics.sendEvent('Input UPI VPA', {
    vpa_code:
      finalizePurchase.selectedUpiPSP && finalizePurchase.selectedUpiPSP !== 'others'
        ? `${upiHandle}${finalizePurchase.selectedUpiPSP}`
        : finalizePurchase.upiHandle,
    order_id: store.settings.isRetrying
      ? store.retry.finalizePurchase.order
      : store.finalizePurchase.order,
  });

  const { submissionUri } = finalizePurchase.paymentOptions[
    getPaymentOptionKey(paymentOption)
  ];

  dispatch({
    [POST_API]: {
      types: [
        HANDLE_SUBMIT_UPI_REQUEST,
        HANDLE_SUBMIT_UPI_SUCCESS,
        HANDLE_SUBMIT_UPI_FAILURE,
      ],
      endpoint: submissionUri,
      payload: () => ({
        virtualAddress:
          finalizePurchase.selectedUpiPSP && finalizePurchase.selectedUpiPSP !== 'others'
            ? `${upiHandle}${finalizePurchase.selectedUpiPSP}`
            : finalizePurchase.upiHandle,
      }),
      validator: upiHandleValidator,
      toValidate: () => ({
        virtualAddress:
          finalizePurchase.selectedUpiPSP && finalizePurchase.selectedUpiPSP !== 'others'
            ? `${upiHandle}${finalizePurchase.selectedUpiPSP}`
            : finalizePurchase.upiHandle,
      }),
      onSuccess: (response) => {
        const { statusCode, statusCheckUrl } = response;
        if (statusCode === 2 && statusCheckUrl) {
          dispatch(pollForUPIAcceptance(statusCheckUrl));
        }
      },
      failureActionErrors: (response) => response.errors,
    },
  });
};

import { CALL_API } from '../../../common/middlewares/api';

let upiPollId;
export const pollForUPIAcceptance = (statusCheckUrl) => (dispatch) => {
  upiPollId = setInterval(() => {
    dispatch({
      [CALL_API]: {
        types: ['__', '__', '__'],
        blocking: false,
        endpoint: statusCheckUrl,
        onSuccess: (response) => {
          if (response.statusCode !== 2) {
            // success
            clearInterval(upiPollId);
            window.location.href = response.finalizeUpiSuccessUri;
          }
        },
      },
    });
  }, 2000);
  return upiPollId;
};

export const getUPIIntentStatusCheck = (statusCheckUrl) => {
  return {
    [CALL_API]: {
      types: ['__', '__', '__'],
      endpoint: statusCheckUrl,
      onSuccess: (response) => {
        if (response.statusCode !== 2) {
          // success
          clearInterval(upiPollId);
          window.location.href = response.finalizeUpiSuccessUri;
        }
      },
    },
  };
};

export const HANDLE_SUBMIT_NEFT_REQUEST = 'HANDLE_SUBMIT_NEFT_REQUEST';
export const HANDLE_SUBMIT_NEFT_SUCCESS = 'HANDLE_SUBMIT_NEFT_SUCCESS';
export const HANDLE_SUBMIT_NEFT_FAILURE = 'HANDLE_SUBMIT_NEFT_FAILURE';
export const handleSubmitNEFT = ({ paymentOption }) => (dispatch, getState) => {
  const store = getStore(getState());
  const finalizePurchase = store.settings.isRetrying
    ? store.retry.finalizePurchase
    : store.finalizePurchase;

  const { submissionUri } = finalizePurchase.paymentOptions[
    getPaymentOptionKey(paymentOption)
  ];

  dispatch({
    [POST_API]: {
      types: [
        HANDLE_SUBMIT_NEFT_REQUEST,
        HANDLE_SUBMIT_NEFT_SUCCESS,
        HANDLE_SUBMIT_NEFT_FAILURE,
      ],
      endpoint: submissionUri,
      payload: () => ({}),
      onSuccess: () => {
        dispatch(handleHideLoader());
      },
      failureActionErrors: (response) => response,
    },
  });
};

export const HANDLE_UPI_OPTION = 'HANDLE_UPI_OPTION';
export const handleUPIOptions = (option) => ({
  type: HANDLE_UPI_OPTION,
  payload: {
    option,
  },
});

export const HANDLE_UPI_PSP = 'HANDLE_UPI_PSP';
export const setUpiPsp = (psp = false) => ({
  type: HANDLE_UPI_PSP,
  payload: {
    psp,
  },
});

export const HANDLE_SHOW_ADD_BENEFICIARY_INFO = 'HANDLE_SHOW_ADD_BENEFICIARY_INFO';
export const beneficiaryInfo = (flag, otherBankNeft = false) => ({
  type: HANDLE_SHOW_ADD_BENEFICIARY_INFO,
  payload: {
    flag,
    otherBankNeft,
  },
});

export const SHOW_UPI_POPUP = 'SHOW_UPI_POPUP';
export const handleShowUpiPopup = (flag) => ({
  type: SHOW_UPI_POPUP,
  payload: {
    flag,
  },
});

export const SHOW_NEFT_CREDENTIALS = 'SHOW_NEFT_CREDENTIALS';
export const showNeftCredentials = (flag) => ({
  type: SHOW_NEFT_CREDENTIALS,
  payload: {
    flag,
  },
});

export const SHOW_UPI_LABEL_POPUP = 'SHOW_UPI_LABEL_POPUP';
export const handleShowUpiLabelPopup = (flag) => ({
  type: SHOW_UPI_LABEL_POPUP,
  payload: {
    flag,
  },
});

export const HANDLE_NEFT_BANK_ERROR = 'HANDLE_NEFT_BANK_ERROR';
export const handleNeftBankError = () => ({
  type: HANDLE_NEFT_BANK_ERROR,
});

export const HANDLE_UPDATE_CONVENIENCE_FEE_OBJECT =
  'HANDLE_UPDATE_CONVENIENCE_FEE_OBJECT';
export const handleUpdateConvenienceFeeObject = (convenienceFeeData) => ({
  type: HANDLE_UPDATE_CONVENIENCE_FEE_OBJECT,
  convenienceFeeData,
});

// This is to handle the 'back' action from the conv. fee v2 screen.
export const HANDLE_RESET_QR_CONVENIENCE_FEE_DETAILS = 'HANDLE_RESET_QR_CONVENIENCE_FEE_DETAILS';
export const handleResetQrConvenienceFeeDetails = () => ({
  type: HANDLE_RESET_QR_CONVENIENCE_FEE_DETAILS,
});

// This is to handle the 'back' action from the conv. fee v2 screen. We want to show the payment
// options screen in case of UPI (collect pay) payment in a scenario where UPI Intent is outside.
export const HANDLE_RESET_UPI_PAYMENT_OPTION = 'HANDLE_RESET_UPI_PAYMENT_OPTION';
export const handleResetUPIPaymentOption = (resetToPaymentMode = {}) => ({
  type: HANDLE_RESET_UPI_PAYMENT_OPTION,
  resetToPaymentMode,
});

export const HANDLE_FETCH_CONVENIENCE_FEES_REQUEST =
  'HANDLE_FETCH_CONVENIENCE_FEES_REQUEST';
export const HANDLE_FETCH_CONVENIENCE_FEES_SUCCESS =
  'HANDLE_FETCH_CONVENIENCE_FEES_SUCCESS';
export const HANDLE_FETCH_CONVENIENCE_FEES_FAILURE =
  'HANDLE_FETCH_CONVENIENCE_FEES_FAILURE';
export const handleFetchConvenienceFees = () => (dispatch, getState) => {
  const store = getStore(getState());
  const finalizePurchase = store.settings.isRetrying
    ? store.retry.finalizePurchase
    : store.finalizePurchase;

  dispatch({
    [CALL_API]: {
      types: [
        HANDLE_FETCH_CONVENIENCE_FEES_REQUEST,
        HANDLE_FETCH_CONVENIENCE_FEES_SUCCESS,
        HANDLE_FETCH_CONVENIENCE_FEES_FAILURE,
      ],
      endpoint: constructConvenienceFeeEndpoint(finalizePurchase),
      failureActionErrors: (response) => response,
      onSuccess: (response, state) => {
        const { totalAmount: amount, convenienceFeesVersion: conv_fee_version } =
          response || {};

        const mixpanelData = {
          amount,
          conv_fee_version,
        };

        invokeMixpanelEvent(
          MIXPANEL_PAYMENT_EVENT.ENTERED_INSTRUMENT_DETAILS,
          mixpanelData,
          state,
        );
      },
    },
  });
};

const constructConvenienceFeeEndpoint = (finalizePurchase) => {
  let endpoint = '/webapi/instrument-convenience-fee/';

  const {
    order,
    selectedBankId,
    selectedWalletId,
    selectedPayLaterId,
    selectedPaymentMode,
  } = finalizePurchase;

  if (selectedPaymentMode === 'nb') {
    endpoint = `${endpoint}nb/${order}/${selectedBankId}/`;
  } else if (selectedPaymentMode === 'wallet') {
    endpoint = `${endpoint}wallet/${order}/${selectedWalletId}/`;
  } else if (selectedPaymentMode === 'paylater') {
    endpoint = `${endpoint}paylater/${order}/${selectedPayLaterId}/`;
  } else if (selectedPaymentMode === 'upi') {
    endpoint = `${endpoint}upi/${order}/0/`;
  }
  return endpoint;
};

export const codPhoneValidator = LGTM.validator()
  .validates('phone')
  .required('Please enter your 10-digit mobile number.')
  .using(
    (phone) => /^[0-9 ()+/-]{10,}$/.test(phone),
    'Please enter a valid 10-digit Indian mobile number.',
  )
  .build();

export const codOtpValidator = LGTM.validator()
  .validates('otp')
  .required('Enter the 4 digit OTP sent on your mobile number')
  .using((otp) => otp.length === 4, 'Invalid OTP. A Valid OTP is 4 digits long.')
  .build();

export const HANDLE_SEND_OTP_TO_BUYER_REQUEST = 'HANDLE_SEND_OTP_TO_BUYER_REQUEST';
export const HANDLE_SEND_OTP_TO_BUYER_SUCCESS = 'HANDLE_SEND_OTP_TO_BUYER_SUCCESS';
export const HANDLE_SEND_OTP_TO_BUYER_FAILURE = 'HANDLE_SEND_OTP_TO_BUYER_FAILURE';
export const handleValidatePhoneNumberAndSendOTP = ({
  orderDetails,
  updatePhoneNumber,
  resendOtp = false,
}) => {
  let { phone } = orderDetails;
  const { order, orderToken } = orderDetails;

  if (phone.indexOf('+91') !== -1) {
    phone = phone.replace('+91', '');
  }
  return {
    [POST_API]: {
      endpoint: '/webapi/settings/phone_verification/send_otp_to_buyer/',
      types: [
        HANDLE_SEND_OTP_TO_BUYER_REQUEST,
        HANDLE_SEND_OTP_TO_BUYER_SUCCESS,
        HANDLE_SEND_OTP_TO_BUYER_FAILURE,
      ],
      payload: {
        order_id: order,
        order_token: orderToken,
        save_number: true,
        phone_number: updatePhoneNumber ? phone : null,
      },
      toValidate: { phone },
      validator: codPhoneValidator,
      onSuccess: (response, state, dispatch) => {
        ia.sendEvent('Initiated OTP Verification', {
          source: 'COD',
        });
        if (resendOtp) {
          dispatch(handleSetCODResendOTPFlag(resendOtp));
          setTimeout(() => {
            dispatch(handleSetCODResendOTPFlag(!resendOtp));
          }, 3000);
        }
      },
      failureActionErrors: (response, state, dispatch) => {
        if (response.error === 'Too many requests') {
          dispatch(handleOTPLimitExceeded(true));
          return { otp: [''] };
        }
      },
    },
  };
};

export const HANDLE_OTP_LIMIT_EXCEEDED = 'HANDLE_OTP_LIMIT_EXCEEDED';
export const handleOTPLimitExceeded = (status) => ({
  type: HANDLE_OTP_LIMIT_EXCEEDED,
  status,
});

export const HANDLE_SET_COD_RESEND_OTP_FLAG = 'HANDLE_SET_COD_RESEND_OTP_FLAG';
export const handleSetCODResendOTPFlag = (isOTPResent) => ({
  type: HANDLE_SET_COD_RESEND_OTP_FLAG,
  isOTPResent,
});

export const HANDLE_SUBMIT_CASH_ON_DELIVERY_REQUEST =
  'HANDLE_SUBMIT_CASH_ON_DELIVERY_REQUEST';
export const HANDLE_SUBMIT_CASH_ON_DELIVERY_SUCCESS =
  'HANDLE_SUBMIT_CASH_ON_DELIVERY_SUCCESS';
export const HANDLE_SUBMIT_CASH_ON_DELIVERY_FAILURE =
  'HANDLE_SUBMIT_CASH_ON_DELIVERY_FAILURE';
export const handleCashOnDeliverySubmit = (submissionUri, postpaidMethodId) => {
  return {
    [POST_API]: {
      endpoint: submissionUri,
      types: [
        HANDLE_SUBMIT_CASH_ON_DELIVERY_REQUEST,
        HANDLE_SUBMIT_CASH_ON_DELIVERY_SUCCESS,
        HANDLE_SUBMIT_CASH_ON_DELIVERY_FAILURE,
      ],
      payload: {
        postpaid: postpaidMethodId,
      },
      onSuccess: (response) => {
        window.location.href = response.redirectUrl;
      },
    },
  };
};

export const HANDLE_COD_OTP_VERIFICATION_REQUEST = 'HANDLE_COD_OTP_VERIFICATION_REQUEST';
export const HANDLE_COD_OTP_VERIFICATION_FAILURE = 'HANDLE_COD_OTP_VERIFICATION_FAILURE';
export const HANDLE_COD_OTP_VERIFICATION_SUCCESS = 'HANDLE_COD_OTP_VERIFICATION_SUCCESS';
export const handleSubmitOTP = (orderDetails, otp) => {
  const { order, orderToken } = orderDetails;
  return {
    [POST_API]: {
      endpoint: '/webapi/settings/phone_verification/verify_otp_for_buyer/',
      types: [
        HANDLE_COD_OTP_VERIFICATION_REQUEST,
        HANDLE_COD_OTP_VERIFICATION_SUCCESS,
        HANDLE_COD_OTP_VERIFICATION_FAILURE,
      ],
      payload: { otp, order_id: order, order_token: orderToken },
      toValidate: { otp },
      validator: codOtpValidator,
      onSuccess: (response, state, dispatch) => {
        const store = getStore(state);
        const { selectedPaymentObject } = store.finalizePurchase;
        const { submissionUri, postpaidMethodId } = store.finalizePurchase.paymentOptions[
          getPaymentOptionKey(selectedPaymentObject)
        ];
        dispatch(handleCashOnDeliverySubmit(submissionUri, postpaidMethodId));
      },
      failureActionErrors: (response) => {
        return {
          otp: [
            response.error && Number(response.error) === 1
              ? 'Incorrect OTP'
              : 'Something went wrong',
          ],
        };
      },
    },
  };
};

export const HANDLE_COD_OTP_CHANGE = 'HANDLE_COD_OTP_CHANGE';
export const handleCODOtpChange = (otp) => {
  return {
    type: HANDLE_COD_OTP_CHANGE,
    otp,
  };
};

export const HANDLE_PHONE_INPUT_CHANGE_FOR_COD = 'HANDLE_PHONE_INPUT_CHANGE_FOR_COD';
export const handlePhoneInputChange = (phone) => {
  return {
    type: HANDLE_PHONE_INPUT_CHANGE_FOR_COD,
    phone,
  };
};

export const HANDLE_PHONE_INPUT_EDIT_MODE_FOR_COD =
  'HANDLE_PHONE_INPUT_EDIT_MODE_FOR_COD';
export const handlePhoneInputEditModeForCOD = () => {
  return {
    type: HANDLE_PHONE_INPUT_EDIT_MODE_FOR_COD,
  };
};

export const HANDLE_FETCH_QR_CONVENIENCE_FEES_REQUEST =
  'HANDLE_FETCH_QR_CONVENIENCE_FEES_REQUEST';
export const HANDLE_FETCH_QR_CONVENIENCE_FEES_SUCCESS =
  'HANDLE_FETCH_QR_CONVENIENCE_FEES_SUCCESS';
export const HANDLE_FETCH_QR_CONVENIENCE_FEES_FAILURE =
  'HANDLE_FETCH_QR_CONVENIENCE_FEES_FAILURE';
// We will pass a showConvenienceFeeForm param to differentiate in behaviour between web and android.
export const handleFetchConvenienceFeesForQR = (showConvenienceFeeForm = false) => (dispatch, getState) => {
  const baseEndPoint = '/webapi/instrument-convenience-fee/';
  const store = getStore(getState());
  const finalizePurchase = store.settings.isRetrying
    ? store.retry.finalizePurchase
    : store.finalizePurchase;
  const { order } = finalizePurchase;

  dispatch({
    [CALL_API]: {
      types: [
        HANDLE_FETCH_QR_CONVENIENCE_FEES_REQUEST,
        HANDLE_FETCH_QR_CONVENIENCE_FEES_SUCCESS,
        HANDLE_FETCH_QR_CONVENIENCE_FEES_FAILURE,
      ],
      requestTypeActionProps: {
        showConvenienceFeeForm,
      },
      endpoint: `${baseEndPoint}qr/${order}/1`,
    },
  });
};

export const HANDLE_SUBMIT_UPI_INTENT_REQUEST = 'HANDLE_SUBMIT_UPI_INTENT_REQUEST';
export const HANDLE_SUBMIT_UPI_INTENT_SUCCESS = 'HANDLE_SUBMIT_UPI_INTENT_SUCCESS';
export const HANDLE_SUBMIT_UPI_INTENT_FAILURE = 'HANDLE_SUBMIT_UPI_INTENT_FAILURE';
export const handleSubmitUPIIntent = (orderId, orderToken) => ({
  [POST_API]: {
    endpoint: '/webapi/upi-intent/',
    types: [
      HANDLE_SUBMIT_UPI_INTENT_REQUEST,
      HANDLE_SUBMIT_UPI_INTENT_SUCCESS,
      HANDLE_SUBMIT_UPI_INTENT_FAILURE,
    ],
    payload: {
      orderId,
      orderToken,
    },
  },
});

export const HANDLE_PAYMENT_GROUP_CARD_CLICK = 'HANDLE_PAYMENT_GROUP_CARD_CLICK';
export const handlePaymentGroupCardClick = (cardIndex) => ({
  type: HANDLE_PAYMENT_GROUP_CARD_CLICK,
  payload: {
    cardIndex,
  },
});

export const HANDLE_PAYPAL_SUCCESS_CALLBACK = 'HANDLE_PAYPAL_SUCCESS_CALLBACK';
export const handlePaypalSuccessfulCallback = () => ({
  type: HANDLE_PAYPAL_SUCCESS_CALLBACK,
});

export const HANDLE_PAYPAL_SDK_ERROR = 'HANDLE_PAYPAL_SDK_ERROR';
export const handlePaypalSDKError = () => ({
  type: HANDLE_PAYPAL_SDK_ERROR,
});

export const SHOW_UPI_FIELD = 'SHOW_UPI_FIELD';
export const handleShowUpiField = (flag) => ({
  type: SHOW_UPI_FIELD,
  payload: {
    flag,
  },
});

export const HANDLE_DYNAMIC_QR_V2_REQUEST = 'HANDLE_DYNAMIC_QR_V2_REQUEST';
export const HANDLE_DYNAMIC_QR_V2_SUCCESS = 'HANDLE_DYNAMIC_QR_V2_SUCCESS';
export const HANDLE_DYNAMIC_QR_V2_FAILURE = 'HANDLE_DYNAMIC_QR_V2_FAILURE';
export const handleDynamicQrV2 = (submissionURI, deviceType) => ({
  [POST_API]: {
    endpoint: `${submissionURI}`,
    types: [
      HANDLE_DYNAMIC_QR_V2_REQUEST,
      HANDLE_DYNAMIC_QR_V2_SUCCESS,
      HANDLE_DYNAMIC_QR_V2_FAILURE,
    ],
    failureActionErrors: (response) => response,
    payload: {
      device_type: deviceType,
    },
  },
});
