import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import {
  FormFieldLabel,
  FormFieldWithLabel,
} from '../../../../common/components/FormComponents';
import { Columns, Row } from '../../../../common/components/Grid';

const DiscountCode = ({
  discountCode,
  isDiscountEditable,
  isDiscountBeingEdited,
  isDiscountApplied,
  errors,
  shouldShowDiscountCode,
  onChangeInput,
  handleApplyDiscount,
  handleRemoveDiscount,
  showDiscountCodeBox,
}) => {
  const postfixClassNames = 'postfix flush';

  return shouldShowDiscountCode ? (
    isDiscountBeingEdited ? (
      <FormFieldWithLabel errors={errors.discountCode}>
        <FormFieldLabel
          className={classNames('transition-color', { 'text-green': isDiscountApplied })}
        >
          {isDiscountApplied ? 'Discount Applied' : 'Discount Code'}
        </FormFieldLabel>
        <Row className="collapse postfix-radius">
          <Columns className="small-9">
            <input
              type="text"
              value={discountCode}
              disabled={!isDiscountEditable}
              onChange={(e) => onChangeInput({ discountCode: e.target.value })}
            />
          </Columns>
          <Columns className="small-3">
            {!isDiscountApplied ? (
              <a
                className={classNames(postfixClassNames, 'text-blue')}
                onClick={() => handleApplyDiscount()}
              >
                Apply
              </a>
            ) : (
              <a
                className={classNames(postfixClassNames, 'text-red')}
                onClick={() => handleRemoveDiscount()}
              >
                Remove
              </a>
            )}
          </Columns>
        </Row>
      </FormFieldWithLabel>
    ) : (
      <p className="push--bottom">
        <a className="text-underline" onClick={() => showDiscountCodeBox()}>
          I have a Discount Code
        </a>
      </p>
    )
  ) : null;
};

DiscountCode.propTypes = {
  shouldShowDiscountCode: PropTypes.bool,
  discountCode: PropTypes.string,
  isDiscountEditable: PropTypes.bool,
  isDiscountApplied: PropTypes.bool,
  isDiscountBeingEdited: PropTypes.bool,
  errors: PropTypes.object,
  onChangeInput: PropTypes.func,
  handleApplyDiscount: PropTypes.func,
  showDiscountCodeBox: PropTypes.func,
  handleRemoveDiscount: PropTypes.func,
};

export default DiscountCode;
