export const replaceSpacesBy = (string, char) => {
  return string.replace(new RegExp(' ', 'g'), char);
};

export const isInstamojoDomain = (url = '') => {
  // Checks if the URL starts with either 'http://' or 'https://'
  const hasValidUrlProtocal =  ['http://', 'https://'].some(protocol => url.toLowerCase().startsWith(protocol));
  if (!hasValidUrlProtocal) {
    return false;
  }
  let hostname = '';
  try {
    const parsedUrl = new URL(url);
    hostname = parsedUrl.hostname;
  } catch (error) {
    return false;
  }
  // Check if the updated URL has a Instamojo domain
  return ['instamojo.com', 'mojo.page'].some(domain => hostname.endsWith(domain));
};

export const stripLeadingAndTrailingSlash = (text) => {
  return text.replace(/^\/|\/$/g, '');
};
