import '../../../../common/styles/React-Collapse.scss';

import Rupee, { rupees } from '@instamojo/rupee';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Collapse from 'react-collapse';

class ConvenienceFeeCard extends React.Component {
  constructor() {
    super();
    this.state = {
      isDetailViewOpen: false,
    };
  }

  handleCardClick() {
    this.setState({ isDetailViewOpen: !this.state.isDetailViewOpen });
  }

  render() {
    const arrowClassName = classNames(
      'nc-icon-outline',
      'sm',
      'push--right',
      'text-light-grey',
      {
        'arrows-1_minimal-right': !this.state.isDetailViewOpen,
        'arrows-1_minimal-down': this.state.isDetailViewOpen,
      },
    );

    return (
      <div
        className="clearfix openable--actionbar hard--sides"
        onClick={() => this.handleCardClick()}
      >
        <div className="columns small-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="flex--start-center">
            <i className={arrowClassName} />
            <div className="flex-1" style={{ width: '100%' }}>
              <div className="text-light-grey">
                <span>Convenience fee</span>
                <span className="right">
                  <Rupee value={rupees(this.props.convenienceFeesAndTax)} />
                </span>
              </div>
            </div>
          </div>
          <Collapse isOpened={this.state.isDetailViewOpen}>
            <div className="soft-quarter--top">
              <div
                className="text-light-grey"
                style={{
                  borderLeft: '1px solid rgba(147, 160, 175, 0.3)',
                  borderRight: '1px solid rgba(147, 160, 175, 0.3)',
                  padding: '0px 10px',
                  margin: '8px 5px',
                }}
              >
                <p>
                  Fee<span className="right"> {this.props.convenienceFees} </span>
                </p>
                <p>
                  GST @ 18%
                  <span className="right"> {this.props.convenienceFeesTax} </span>
                </p>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    );
  }
}

ConvenienceFeeCard.propTypes = {
  convenienceFeesAndTax: PropTypes.string,
  convenienceFees: PropTypes.string,
  convenienceFeesTax: PropTypes.string,
};

export default ConvenienceFeeCard;
