import PropTypes from 'prop-types';
import React from 'react';

import { Columns, Row } from '../../../../common/components/Grid';

export default class FinalizeHeader extends React.Component {
  // renderPromotionBanner () {
  //   const {
  //     wallet_wallets: {
  //       walletList,
  //     } = {},
  //   } = this.props.finalizePurchase.paymentOptions;
  //
  //   const isFreeChargeEnabled = walletList ? walletList.filter(walletObject => walletObject.name == 'Freecharge').length == 1 : false;
  //   return (
  //     isFreeChargeEnabled ?
  //     <div
  //       className="soft--bottom text-green text-center"
  //       style={{ fontSize: '13px', lineHeight: '18px' }}
  //     >
  //       <div>Use FreeCharge Wallet & Get 10% cashback!</div>
  //       <a
  //         href="https://support.instamojo.com/hc/en-us/articles/211933269"
  //         style={{ color: '#2FBE6D' }}
  //         target="_blank"
  //         rel='noopener noreferrer'
  //       >
  //         <span style={{ borderBottom: '2px solid #2FBE6D' }}>Read More</span>
  //       </a>
  //     </div> :
  //     null
  //   );
  // }

  render() {
    const {
      selectedPaymentMode,
      paymentOptions,
      gatewayDowntimeMessage,
    } = this.props.finalizePurchase;
    return (
      <div>
        {!selectedPaymentMode && Object.keys(paymentOptions).length ? (
          <div>
            {gatewayDowntimeMessage ? (
              <div
                className="soft--bottom push--bottom"
                style={{ borderBottom: '1px solid #eee' }}
              >
                <Row className="flex--start-center">
                  <Columns className="small-2">
                    <i
                      className="nc-icon-outline ui-3_alert red push-half--left"
                      style={{ fontSize: '32px' }}
                    />
                  </Columns>
                  <Columns className="small-10">
                    <p className="text-justify" style={{ fontSize: '12px' }}>
                      {gatewayDowntimeMessage}
                    </p>
                  </Columns>
                </Row>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

FinalizeHeader.propTypes = {
  finalizePurchase: PropTypes.object,
  handleSelectPaymentOption: PropTypes.func,
};
