import PropTypes from 'prop-types';
import React from 'react';

import PaymentGroupsWrapper from './PaymentGroupsWrapper';
import PaymentOptions from './PaymentOptions';
import RenderPaymentOption from './RenderPaymentOption';

class PaymentOptionsWrapper extends React.Component {
  renderSelecedPaymentOption() {
    // NOTE: This is just a helper so that we don't repeat this component many times
    // here.
    return (
      <RenderPaymentOption
        finalizePurchase={this.props.finalizePurchase}
        username={this.props.username}
        showConvenienceFee={this.props.showConvenienceFee}
        handleResetSelectedPaymentMode={() => this.props.handleResetSelectedPaymentMode()}
        handlePaypalSuccessfulCallback={() => this.props.handlePaypalSuccessfulCallback()}
        handlePaypalSDKError={() => this.props.handlePaypalSDKError()}
        pollForUPIAcceptance={(statusCheckUrl) =>
          this.props.pollForUPIAcceptance(statusCheckUrl)
        }
        handleSelectPaymentOption={(selectedPaymentDetails) =>
          this.props.handleSelectPaymentOption(selectedPaymentDetails)
        }
        handleFetchConvenienceFeesForQR={() =>
          this.props.handleFetchConvenienceFeesForQR()
        }
        handleDynamicQrV2={(submissionUrl, deviceType) => this.props.handleDynamicQrV2(submissionUrl, deviceType)}
      />
    );
  }

  render() {
    /* For every paymentOption we have field `group` which can have values set
    to `upi` for (UPI and dynamic_qr) and  `non_upi` for others.
    We have three cases in this flow:
    1. When `paymentsOptions = []`, we render null.
    2. Payment options contains dynamic_qr:
      a. We have both UPI and dynamic_qr present in payment Options.
        We group payment options we will get paymentGroups not null(We have
        this set only when there is dynamic_qr in the payment options) -> we will render
        two groups with dotted border.
      b. We have dynamic_qr in payment options without upi in payment options.
        We group payment options we will get paymentGroups still not null(as we have
        dynamic_qr in the payment options) -> We will render two groups with dotted border
        (first group: only qr; second group: rest all options).
      c. We have only dynamic_qr and no other payment options.
        We group payment options we will get paymentGroups still not null -> We will render only qr
        in dotted border.
    3. We don't have dynamic_qr as a payment option.
      In this case paymentGroups will be null and it falls back on the normal flow.
      Note: This will be without dotted border. So any user without dynamic qr plan, he sees no
      difference in the UI in payment options page.
    */

    const {
      paymentOptions,
      paymentGroups,
      selectedPaymentMode,
      dynamicQrDetails: {
        showConvenienceFeeForm: showUpiIntentConvenienceFeeForm,
      } = {},
    } = this.props.finalizePurchase;

    if (!Object.keys(paymentOptions).length) {
      return null;
    }
    return (
      <div>
        {paymentGroups ? (
          <PaymentGroupsWrapper
            username={this.props.username}
            finalizePurchase={this.props.finalizePurchase}
            handlePaymentGroupCardClick={(index) =>
              this.props.handlePaymentGroupCardClick(index)
            }
            handleManageSavedCards={(payload) =>
              this.props.handleManageSavedCards(payload)
            }
            handleDeleteSavedCard={(cardToBeDeleted) =>
              this.props.handleDeleteSavedCard(cardToBeDeleted)
            }
            handleFetchConvenienceFeesForQR={() =>
              this.props.handleFetchConvenienceFeesForQR()
            }
            pollForUPIAcceptance={(statusCheckUrl) =>
              this.props.pollForUPIAcceptance(statusCheckUrl)
            }
            handleSelectPaymentOption={(selectedPaymentDetails) =>
              this.props.handleSelectPaymentOption(selectedPaymentDetails)
            }
            handleResetSelectedPaymentMode={() =>
              this.props.handleResetSelectedPaymentMode()
            }
            renderSelecedPaymentOption={() => this.renderSelecedPaymentOption()}
            handlePaypalSuccessfulCallback={() =>
              this.props.handlePaypalSuccessfulCallback()
            }
            handlePaypalSDKError={() => this.props.handlePaypalSDKError()}
          />
        ) : (selectedPaymentMode || showUpiIntentConvenienceFeeForm) ? (
          this.renderSelecedPaymentOption()
        ) : (
          <PaymentOptions
            finalizePurchase={this.props.finalizePurchase}
            showConvenienceFee={this.props.showConvenienceFee}
            handleSelectPaymentOption={(selectedPaymentDetails) =>
              this.props.handleSelectPaymentOption(selectedPaymentDetails)
            }
            handleManageSavedCards={(payload) =>
              this.props.handleManageSavedCards(payload)
            }
            handleDeleteSavedCard={(cardToBeDeleted) =>
              this.props.handleDeleteSavedCard(cardToBeDeleted)
            }
          />
        )}
      </div>
    );
  }
}

PaymentOptionsWrapper.propTypes = {
  username: PropTypes.string,
  showConvenienceFee: PropTypes.bool,
  handleResetSelectedPaymentMode: PropTypes.func,
  finalizePurchase: PropTypes.object,
  handleSelectPaymentOption: PropTypes.func,
  handleManageSavedCards: PropTypes.func,
  handleDeleteSavedCard: PropTypes.func,
  pollForUPIAcceptance: PropTypes.func,
  handleFetchConvenienceFeesForQR: PropTypes.func,
  handlePaymentGroupCardClick: PropTypes.func,
  handlePaypalSuccessfulCallback: PropTypes.func,
  handlePaypalSDKError: PropTypes.func,
  handleDynamicQrV2: PropTypes.func,
};

export default PaymentOptionsWrapper;
