import '../../../../common/styles/SmartLinkBanner.scss';

import ProfileImage from '@instamojo/profile-image';
import Rupee from '@instamojo/rupee';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import ia from '../../../../common/config/Analytics';
import { getFullName, getUrlParameterByName } from '../../../../common/config/utils';
import ConvenienceFeesAccordion from '../convenience-fee/ConvenienceFeesAccordion';

const LinksConvenienceFeesAccordion = ({
  amount,
  convenienceFeeVersion,
  isFinalizeFormOpen,
}) => (
  <div className="text-medium">
    {convenienceFeeVersion != 'v2' || !isFinalizeFormOpen ? (
      <div>
        <span>Amount</span>
        <span className="right">
          <Rupee value={amount} />
        </span>
      </div>
    ) : null}
    <ConvenienceFeesAccordion />
  </div>
);

LinksConvenienceFeesAccordion.propTypes = {
  amount: PropTypes.string,
  convenienceFeeVersion: PropTypes.string,
  isFinalizeFormOpen: PropTypes.bool,
};

const LinksMerchantHeader = ({
  sellerAvatar,
  seller,
  title,
  totalAmountFormatted,
  showDeferred,
  showConvenienceFee,
  showConvenienceFeeForm,
  showConvenienceFeeOnRetry,
  isAwaitingPaymentCompletion,
  isInstapayPayment,
  onChangeInstapayForm,
  smartlinksHeader,
  hasSmartPricing,
  convenienceFeeVersion,
  isFinalizeFormOpen,
  ...props
}) => {
  const backgroundStyle =
    smartlinksHeader && smartlinksHeader.colour
      ? { backgroundColor: smartlinksHeader.colour }
      : { backgroundColor: '#617389' };
  let description =
    smartlinksHeader && smartlinksHeader.description ? smartlinksHeader.description : '';
  if (description) {
    description = description.split('\n').map((item, key) => {
      return <div key={key}>{item}</div>;
    });
  }

  const hasParams = getUrlParameterByName('source');
  useEffect(() => {
    if (hasParams === 'whatsapp') {
      ia.sendEvent('Clicked Item', {
        item_name: 'pay_now',
        source: 'whatsapp_buyer_pending',
      });
    }
  }, []);

  return (
    <div className={classNames('payments-header', sellerAvatar ? 'has--avatar' : null)}>
      <div
        className="soft-one-half border-radius banner-background"
        style={backgroundStyle}
      >
        <div className="media__img payments-header--avatar-container">
          <ProfileImage
            className="payments-header--avatar"
            height={40}
            width={40}
            alt={getFullName(seller)}
            src={showDeferred ? sellerAvatar : ''}
          />
        </div>
        <div className="banner-text">
          <p className="flush--bottom text-white">Paying to</p>
          <div className="flush--bottom text-white">{getFullName(seller)}</div>
        </div>
      </div>

      {title && totalAmountFormatted && !props.isEditing && !showConvenienceFeeForm ? (
        <div className="soft--sides soft--top" style={{ background: '#fff' }}>
          <p className="flush--bottom text-payments-grey">
            Purpose of Payment
            {isInstapayPayment && !props.isRAP && !isAwaitingPaymentCompletion ? (
              <a
                className="right"
                style={{ fontSize: '16px' }}
                onClick={() => onChangeInstapayForm()}
              >
                Change
              </a>
            ) : null}
          </p>
          <p className="push--bottom break-long-words">{title}</p>
          {
            //Show description only when cover image is not there. (Otherwise show on the payments page)
            description && !smartlinksHeader.coverImage && !hasSmartPricing ? (
              <div>
                <p className="flush--bottom text-payments-grey">Description</p>
                <div
                  className="push--bottom break-long-words"
                  style={{ lineHeight: 1.6 }}
                >
                  {description}
                </div>
              </div>
            ) : null
          }
          {showConvenienceFee ? (
            <LinksConvenienceFeesAccordion
              amount={totalAmountFormatted}
              convenienceFeeVersion={convenienceFeeVersion}
              isFinalizeFormOpen={isFinalizeFormOpen}
            />
          ) : (
            <div>
              <p className="flush--bottom text-payments-grey">Amount</p>
              <p className="flush--bottom">
                <Rupee value={totalAmountFormatted} />
              </p>
            </div>
          )}
        </div>
      ) : null}
      {showConvenienceFeeOnRetry && !showConvenienceFeeForm ? (
        <div className="soft--sides soft--top background-white">
          <LinksConvenienceFeesAccordion
            amount={totalAmountFormatted}
            convenienceFeeVersion={convenienceFeeVersion}
            isFinalizeFormOpen={isFinalizeFormOpen}
          />
        </div>
      ) : null}
    </div>
  );
};

LinksMerchantHeader.propTypes = {
  isEditing: PropTypes.bool,
  isRAP: PropTypes.bool,
  sellerAvatar: PropTypes.string,
  seller: PropTypes.object,
  title: PropTypes.string,
  totalAmountFormatted: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showDeferred: PropTypes.bool,
  showConvenienceFee: PropTypes.bool,
  showConvenienceFeeForm: PropTypes.bool,
  showConvenienceFeeOnRetry: PropTypes.bool,
  isAwaitingPaymentCompletion: PropTypes.bool,
  isInstapayPayment: PropTypes.bool,
  smartlinksHeader: PropTypes.object,
  onChangeInstapayForm: PropTypes.func,
  hasSmartPricing: PropTypes.bool,
  convenienceFeeVersion: PropTypes.string,
  isFinalizeFormOpen: PropTypes.bool,
};

const mapStateToProps = ({
  settings: {
    showConvenienceFee,
    isRetrying,
    isFinalizeFormOpen,
    isAwaitingPaymentCompletion,
  },
}) => ({
  showConvenienceFeeOnRetry: !!(isRetrying && showConvenienceFee && isFinalizeFormOpen),
  showConvenienceFee,
  isAwaitingPaymentCompletion,
});

export default connect(mapStateToProps)(LinksMerchantHeader);
