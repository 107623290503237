function getItemAnalytics(items) {
  return {
    num_products: items.length,
    num_items: items.reduce((total, item) => {
      total = total + item.quantity;
      return total;
    }, 0),
  };
}

export default getItemAnalytics;
