import * as ActionTypes from '../actions/PageStateActions';

const initialPageState = {
  inTraffic: false,
  isAsync: false,
  scrollToFirstError: false,
  isAppOffline: false,
  isContentOffline: false,
  isRequestTimeout: false,
  isContentAreaBackgroundShown: true,
  isServiceUnavailable: false,
  isPageForbidden: false,
  isPageHeaderFixed: false,
};

export function pageState(state = initialPageState, action) {
  switch (action.type) {
    case ActionTypes.ENABLE_PAGE_STATE_ASYNC:
      return {
        ...state,
        isAsync: true,
        isServiceUnavailable: false,
      };

    case ActionTypes.DISABLE_ACTION_ITEMS:
      return {
        ...state,
        inTraffic: true,
      };

    case ActionTypes.ENABLE_ACTION_ITEMS:
      return {
        ...state,
        inTraffic: false,
      };

    case ActionTypes.DISABLE_PAGE_STATE_ASYNC:
      return {
        ...state,
        isAsync: false,
      };

    case ActionTypes.MARK_PAGE_NOT_FOUND:
      return {
        ...state,
        isPageNotFound: true,
      };

    case ActionTypes.MARK_PAGE_FORBIDDEN:
      return {
        ...state,
        isPageForbidden: true,
      };

    case ActionTypes.MARK_PAGE_NOT_FORBIDDEN:
      return {
        ...state,
        isPageForbidden: false,
      };

    case ActionTypes.MARK_SERVICE_UNAVAILABLE:
      return {
        ...state,
        isServiceUnavailable: true,
      };

    case ActionTypes.MARK_PAGE_FOUND:
      return {
        ...state,
        isPageNotFound: false,
      };

    case ActionTypes.SCROLL_TO_FIRST_ERROR:
      return {
        ...state,
        scrollToFirstError: true,
      };

    case ActionTypes.UNMARK_SCROLL_TO_FIRST_ERROR_FLAG:
      return {
        ...state,
        scrollToFirstError: false,
      };

    case ActionTypes.MARK_APP_OFFLINE: {
      return {
        ...state,
        isAppOffline: true,
      };
    }

    case ActionTypes.MARK_APP_ONLINE: {
      return {
        ...state,
        isAppOffline: false,
      };
    }

    case ActionTypes.MARK_CONTENT_ONLINE: {
      return {
        ...state,
        isContentOffline: false,
      };
    }

    case ActionTypes.MARK_CONTENT_OFFLINE: {
      return {
        ...state,
        isContentOffline: true,
      };
    }

    case ActionTypes.MARK_REQUEST_TIMEOUT: {
      return {
        ...state,
        isRequestTimeout: true,
      };
    }

    case ActionTypes.MARK_REQUEST_SUCCESSFUL: {
      return {
        ...state,
        isRequestTimeout: false,
      };
    }

    case ActionTypes.TOGGLE_CONTENT_AREA_BACKGROUND:
      return {
        ...state,
        isContentAreaBackgroundShown: action.status,
      };

    case ActionTypes.TOGGLE_PAGE_HEADER_FIXED: {
      return {
        ...state,
        isPageHeaderFixed: action.status,
      };
    }

    default:
      return state;
  }
}
