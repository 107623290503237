import React from 'react';

import Icon from '../../../../common/components/Icon';

const PaymentUnavailable = () => {
  return (
    <div className="payments-form">
      <div className="soft center">
        <Icon
          className="ui-2_alert-circle-"
          style={{ fontSize: 48, margin: '10px 0 20px', color: '#E83C58' }}
        />
        <p>Payments are unavailable.</p>
      </div>
    </div>
  );
};

export default PaymentUnavailable;
