import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import root from 'window-or-global';

const BrandFooter = ({ settings, type }) => {
  const { imBranding, isAnchorText } = settings;
  const footerText = isAnchorText ? 'Payments powered by' : 'Powered by';
  return imBranding ? (
    <div
      className={classnames('text-center soft-one-half--ends', {
        'text-black': type === 'light',
      })}
      style={{ opacity: 0.5 }}
    >
      {footerText}
      &nbsp;
      <a
        href={`https://www.instamojo.com/?utm_source=${root.location.hostname}&utm_campaign=powered_by_instamojo&utm_medium=payment_form`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          style={{
            height: 21,
            width: 100,
            display: 'inline-block',
            verticalAlign: 'middle',
          }}
          className="instamojo-logo-colored"
          title="Payment Gateway provided by Instamojo"
        />
      </a>
    </div>
  ) : (
    <span />
  );
};

BrandFooter.propTypes = {
  settings: PropTypes.object,
  type: PropTypes.string,
};

export default BrandFooter;
