import { createAction } from 'redux-actions';
import root from 'window-or-global';

import Analytics from '../../../common/config/Analytics';
import { CALL_API } from '../../../common/middlewares/api';
import { POST_API } from '../../../common/middlewares/form';
import { storeFinalPayload } from '../../../payments/payments/actions/FinalizePurchaseActions';
import { unbuiltInitializeFormValidator } from '../../../payments/payments/actions/initialize/validator';
import undoCasingCustomFieldsErrors from '../../../payments/payments/actions/refine/undoCasingCustomFieldsErrors';
import getCartModifyAnalytics from '../analytics/getCartModifyAnalytics';
import getItemAnalytics from '../analytics/getItemAnalytics';
import cleanupItemsPendingRemoval from './helpers/cleanupItemsPendingRemoval';
import getCartIdPendingRemoval from './helpers/getCartIdPendingRemoval';
import getRemoveItemFromCartEndpoint from './helpers/getRemoveItemFromCartEndpoint';
import getUsernameFromStore from './helpers/getUsernameFromStore';
import pendingRemovalTimers from './helpers/pendingRemovalTimers';
import reduceCartItemProps from './helpers/reduceCartItemProps';
import TABS from './helpers/tabs';

export const HANDLE_INIT_CART_ERROR = 'HANDLE_INIT_CART_ERROR';
export const handleInitCartError = (payload) =>
  createAction(HANDLE_INIT_CART_ERROR)(payload);

export const HANDLE_FETCH_CART_REQUEST = 'HANDLE_FETCH_CART_REQUEST';
export const HANDLE_FETCH_CART_SUCCESS = 'HANDLE_FETCH_CART_SUCCESS';
export const HANDLE_FETCH_CART_FAILURE = 'HANDLE_FETCH_CART_FAILURE';
export const handleFetchCart = () => ({
  [CALL_API]: {
    endpoint: (store) => `/cart/${getUsernameFromStore(store)}/show-cart/`,
    types: [
      HANDLE_FETCH_CART_REQUEST,
      HANDLE_FETCH_CART_SUCCESS,
      HANDLE_FETCH_CART_FAILURE,
    ],
  },
});

export const HANDLE_ADD_TO_CART_REQUEST = 'HANDLE_ADD_TO_CART_REQUEST';
export const HANDLE_ADD_TO_CART_SUCCESS = 'HANDLE_ADD_TO_CART_SUCCESS';
export const HANDLE_ADD_TO_CART_FAILURE = 'HANDLE_ADD_TO_CART_FAILURE';
const addToCart = ({ slug, payload = {}, viaStore = false, analytics = {} }) => ({
  [POST_API]: {
    types: ['__', HANDLE_ADD_TO_CART_SUCCESS, HANDLE_ADD_TO_CART_FAILURE],
    successTypeActionProps: {
      slug,
    },
    endpoint: (store) => `/cart/${getUsernameFromStore(store)}/${slug}/add-to-cart/`,
    payloadAsIs: true,
    payload,
    failureActionErrors: (response) => response.errors,
    onFailure: (response, store) => {
      if (viaStore) {
        // was directly carted from online store
        const errors = response.errors[Object.keys(response.errors)[0]];
        const error = Array.isArray(errors) ? errors[0] : errors;
        root.location.href = `/${getUsernameFromStore(
          store,
        )}/${slug}/?error=${encodeURIComponent(error)}`;
      }
    },
    onSuccess: (response, store) => {
      /* globals ga */
      ga((tracker) => {
        Analytics.sendEvent('Added Product to Cart', {
          ...getCartModifyAnalytics({ slug, response, store, tracker }),
          ...analytics,
        });
      });
    },
  },
});

export const handleAddToCart = (props) => (dispatch, store) => {
  dispatch({
    type: HANDLE_ADD_TO_CART_REQUEST,
    payload: {
      slug: props.slug,
    },
  });

  cleanupItemsPendingRemoval(store()).then(() => {
    dispatch(addToCart(props));
  });
};

export const HANDLE_CART_GLOBAL_ERROR = 'HANDLE_CART_GLOBAL_ERROR';
export const HANDLE_EDIT_CART_ITEM_QUANTITY_REQUEST =
  'HANDLE_EDIT_CART_ITEM_QUANTITY_REQUEST';
export const HANDLE_EDIT_CART_ITEM_QUANTITY_SUCCESS =
  'HANDLE_EDIT_CART_ITEM_QUANTITY_SUCCESS';
export const HANDLE_EDIT_CART_ITEM_QUANTITY_FAILURE =
  'HANDLE_EDIT_CART_ITEM_QUANTITY_FAILURE';
export const handleEditItemQuantityInCart = ({ id, quantity }) => ({
  [POST_API]: {
    endpoint: (store) => `/cart/${getUsernameFromStore(store)}/edit-cart/`,
    payload: {
      id,
      quantity,
    },
    types: [
      HANDLE_EDIT_CART_ITEM_QUANTITY_REQUEST,
      HANDLE_EDIT_CART_ITEM_QUANTITY_SUCCESS,
      HANDLE_EDIT_CART_ITEM_QUANTITY_FAILURE,
    ],
    requestTypeActionProps: {
      id,
    },
    failureTypeActionProps: {
      id,
    },
    failureActionErrors: (response) => {
      const itemStillInCart = response.cart.offers.find((offer) => offer.id === id);
      if (!itemStillInCart) {
        return response;
      }

      // when the `errors` key contains __all__, push it to global error handling mechanism
      if (response.errors.All_) {
        return {
          ...response,
          errors: [],
        };
      }

      return {
        ...response,
        errors: [`The seller has only ${itemStillInCart.quantity} of these available.`],
      };
    },
    onFailure: (response, state, dispatch) => {
      if (response.errors.All_) {
        dispatch({
          type: HANDLE_CART_GLOBAL_ERROR,
          payload: {
            error: response.errors.All_,
          },
        });
      }
    },
  },
});

export const HANDLE_REMOVE_ITEM_FROM_CART_REQUEST =
  'HANDLE_REMOVE_ITEM_FROM_CART_REQUEST';
export const HANDLE_REMOVE_ITEM_FROM_CART_SUCCESS =
  'HANDLE_REMOVE_ITEM_FROM_CART_SUCCESS';
export const HANDLE_REMOVE_ITEM_FROM_CART_FAILURE =
  'HANDLE_REMOVE_ITEM_FROM_CART_FAILURE';
const removeItemFromCart = ({ id, slug, analytics }) => ({
  [POST_API]: {
    endpoint: (store) => getRemoveItemFromCartEndpoint(store),
    payload: {
      id,
    },
    types: [
      '__',
      HANDLE_REMOVE_ITEM_FROM_CART_SUCCESS,
      HANDLE_REMOVE_ITEM_FROM_CART_FAILURE,
    ],
    successTypeActionProps: {
      slug,
    },
    failureTypeActionProps: {
      id,
    },
    onSuccess: (response, store) => {
      delete pendingRemovalTimers[id];

      ga((tracker) => {
        Analytics.sendEvent('Removed Product from Cart', {
          ...getCartModifyAnalytics({ slug, response, store, tracker }),
          ...analytics,
        });
      });
    },
    onFailure: () => delete pendingRemovalTimers[id],
    failureActionErrors: (response) => response,
  },
});

export const handleRemoveItemFromCart = ({ id, slug, analytics = {} }) => (
  dispatch,
  store,
) => {
  const itemFromStore = store().cart.items.find((item) => item.id === id);
  pendingRemovalTimers[id] = {
    slug,
    item: reduceCartItemProps(itemFromStore),
  };

  pendingRemovalTimers[id].timer = setTimeout(
    () => dispatch(removeItemFromCart({ id, slug, analytics })),
    5000,
  );

  dispatch({
    type: HANDLE_REMOVE_ITEM_FROM_CART_REQUEST,
    payload: {
      id,
    },
  });
};

export const HANDLE_SUBMIT_CART_REQUEST = 'HANDLE_SUBMIT_CART_REQUEST';
export const HANDLE_SUBMIT_CART_SUCCESS = 'HANDLE_SUBMIT_CART_SUCCESS';
export const HANDLE_SUBMIT_CART_FAILURE = 'HANDLE_SUBMIT_CART_FAILURE';
const submitCart = () => ({
  [POST_API]: {
    endpoint: (store) => `/cart/${getUsernameFromStore(store)}/validate/`,
    payload: {},
    types: ['__', HANDLE_SUBMIT_CART_SUCCESS, HANDLE_SUBMIT_CART_FAILURE],
    failureActionErrors: (response) => response,
    onSuccess: (response, store) => {
      ga((tracker) => {
        /* global MojoUser */
        Analytics.sendEvent('Checked Out Cart', {
          cart_amount: response.cart ? response.cart.totalAmount : 0,
          ga_client_id: tracker.get('clientId'),
          seller_username: getUsernameFromStore(store),
          self_flag: MojoUser ? MojoUser.username === getUsernameFromStore(store) : false,
          ...getItemAnalytics(response.cart ? response.cart.offers : []),
        });
      });
    },
  },
});

export const handleSubmitCart = () => (dispatch, store) => {
  dispatch({
    type: HANDLE_SUBMIT_CART_REQUEST,
  });

  cleanupItemsPendingRemoval(store()).then(() => {
    dispatch(submitCart());
  });
};

export const HANDLE_SUBMIT_BUYER_DETAILS_SUCCESS = 'HANDLE_SUBMIT_BUYER_DETAILS_SUCCESS';
export const handleSubmitBuyerDetailsCart = () => ({
  [POST_API]: {
    endpoint: (store) => `/cart/${getUsernameFromStore(store)}/checkout/`,
    validator: () => unbuiltInitializeFormValidator().build(),
    toValidate: ({ cart }) => {
      const { name, email, phone } = cart.shipping.form;
      return {
        name,
        email,
        phone,
      };
    },
    payload: ({ cart }) => {
      // NOTE unable to use reducer APIs here due to circular dep
      const { name, email, phone } = cart.shipping.form;
      return {
        name,
        email,
        phone,
      };
    },
    types: [HANDLE_CHECKOUT_CART_REQUEST, '__', HANDLE_CHECKOUT_CART_FAILURE],
    failureActionErrors: (response) => response,
    onFailure: (response, state, dispatch) => {
      if (response.isCartInvalid) {
        dispatch({
          type: HANDLE_TOGGLE_CART_OPEN_TAB,
          payload: {
            tab: TABS.CART,
          },
        });
      }
    },
    onSuccess: (response, state, dispatch) => {
      if (response.details) {
        // the cart can be paid/claimed for
        if (Number(response.details.totalAmount)) {
          // but there are shipping details to be filled in
          dispatch({
            type: HANDLE_SUBMIT_BUYER_DETAILS_SUCCESS,
          });
        }

        // take me to payments
        dispatch({
          type: HANDLE_CHECKOUT_CART_SUCCESS,
          response,
        });
      } else {
        // paid cart with shipping required
        // storing buyer details in the final payload
        const { shipping: { form: codBuyerDetails = {} } = {} } = state.cart;
        dispatch(storeFinalPayload(codBuyerDetails));
        dispatch({
          type: HANDLE_SUBMIT_BUYER_DETAILS_SUCCESS,
        });
      }
    },
  },
});

export const HANDLE_CHECKOUT_CART_REQUEST = 'HANDLE_CHECKOUT_CART_REQUEST';
export const HANDLE_CHECKOUT_CART_SUCCESS = 'HANDLE_CHECKOUT_CART_SUCCESS';
export const HANDLE_CHECKOUT_CART_FAILURE = 'HANDLE_CHECKOUT_CART_FAILURE';
export const handleCheckoutCart = () => ({
  [POST_API]: {
    endpoint: (store) => `/cart/${getUsernameFromStore(store)}/checkout/`,
    validator: ({ cart }) => {
      let validator = unbuiltInitializeFormValidator();
      const { customFields } = cart.shipping.offer;

      Object.keys(customFields).forEach((field) => {
        validator = validator
          .validates(field)
          .required(customFields[field].validationMessage);

        if (
          customFields[field].hasCustomValidation &&
          customFields[field].customValidation &&
          customFields[field].customValidationMessage
        ) {
          //This adds the custom validation
          validator.using(
            (value) => customFields[field].customValidation(value),
            customFields[field].customValidationMessage,
          );
        }
      });
      return validator.build();
    },
    toValidate: ({ cart }) => {
      const { name, email, phone, customFields = {} } = cart.shipping.form;
      return {
        name,
        email,
        phone,
        ...customFields,
      };
    },
    payload: ({ cart }) => {
      // NOTE unable to use reducer APIs here due to circular dep
      const { name, email, phone, customFields = {} } = cart.shipping.form;
      return {
        name,
        email,
        phone,
        ...customFields,
      };
    },
    types: [
      HANDLE_CHECKOUT_CART_REQUEST,
      HANDLE_CHECKOUT_CART_SUCCESS,
      HANDLE_CHECKOUT_CART_FAILURE,
    ],
    failureActionErrors: (response) => {
      const { errors } = response;
      /**
       * the keys sent from the backend will be camelCased by the middleware
       * since we have hardcoded the shipping keys to be snakeCased in the reducer,
       * need to make an exception here for shipping fields
       */
      return undoCasingCustomFieldsErrors({ errors });
    },
    onFailure: (response, state, dispatch) => {
      if (response.isCartInvalid) {
        dispatch({
          type: HANDLE_TOGGLE_CART_OPEN_TAB,
          payload: {
            tab: TABS.CART,
          },
        });
      }

      if (response.errors) {
        const { name, email, phone } = response.errors;
        if (name || email || phone) {
          dispatch({
            type: HANDLE_TOGGLE_CART_OPEN_TAB,
            payload: {
              tab: TABS.BUYER_DETAILS,
            },
          });
        }
      }
    },
  },
});

export const HANDLE_UNDO_REMOVE_ITEM_FROM_CART = 'HANDLE_UNDO_REMOVE_ITEM_FROM_CART';
export const handleUndoRemoveItemFromCart = (id) => (dispatch) => {
  clearTimeout(pendingRemovalTimers[id].timer);

  delete pendingRemovalTimers[id];

  dispatch({
    type: HANDLE_UNDO_REMOVE_ITEM_FROM_CART,
    payload: {
      id,
    },
  });
};

export const HANDLE_ON_CHANGE_SHIPPING = 'HANDLE_ON_CHANGE_SHIPPING';
export const handleOnChangeShipping = (payload) => ({
  type: HANDLE_ON_CHANGE_SHIPPING,
  payload,
});

export const HANDLE_TOGGLE_CART_OPEN_TAB = 'HANDLE_TOGGLE_CART_OPEN_TAB';
export const handleToggleOpenTab = (tab, isOpen) => (dispatch, getState) =>
  dispatch({
    type: HANDLE_TOGGLE_CART_OPEN_TAB,
    payload: {
      tab,
      isOpen,
      currentTab: getState().cart.openTab,
    },
  });

export const HANDLE_TOGGLE_CART_MODAL = 'HANDLE_TOGGLE_CART_MODAL';
export const handleToggleCartModal = () => (dispatch, store) => {
  dispatch({
    type: HANDLE_TOGGLE_CART_MODAL,
  });

  dispatch({
    type: HANDLE_FETCH_CART_REQUEST,
  });

  cleanupItemsPendingRemoval(store()).then(() => {
    dispatch(handleFetchCart());
  });
};

export const HANDLE_CLEAR_FAULTY_CART_REQUEST = 'HANDLE_CLEAR_FAULTY_CART_REQUEST';
export const HANDLE_CLEAR_FAULTY_CART_SUCCESS = 'HANDLE_CLEAR_FAULTY_CART_SUCCESS';
export const HANDLE_CLEAR_FAULTY_CART_FAILURE = 'HANDLE_CLEAR_FAULTY_CART_FAILURE';
export const handleClearFaultyCart = () => ({
  [POST_API]: {
    endpoint: (store) => `/cart/${getUsernameFromStore(store)}/delete-faulty-cart/`,
    types: [
      HANDLE_CLEAR_FAULTY_CART_REQUEST,
      HANDLE_CLEAR_FAULTY_CART_SUCCESS,
      HANDLE_CLEAR_FAULTY_CART_FAILURE,
    ],
    payload: {},
  },
});

export const HANDLE_REMOVE_GLOBAL_CART_ERROR = 'HANDLE_REMOVE_GLOBAL_CART_ERROR';
export const handleRemoveGlobalCartError = () => ({
  type: HANDLE_REMOVE_GLOBAL_CART_ERROR,
});

export { getCartIdPendingRemoval, TABS };
