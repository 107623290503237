import '../../../styles/auto-complete.scss';

import PropTypes from 'prop-types';
import Mentions from 'rc-mentions';
import React from 'react';

import ia from '../../../../../common/config/Analytics';
import { UPIAutosuggestionList } from '../../../config/Upi';

const { Option } = Mentions;
export class Autocomplete extends React.PureComponent {
  filterOption = (input, { key, value, appName }) => {
    const topSuggestionLimit = 5;

    if (!input) {
      return key < topSuggestionLimit;
    } else {
      return (
        value.substr(0, input.length).toUpperCase() === input.toUpperCase() ||
        appName.substr(0, input.length).toUpperCase() === input.toUpperCase()
      );
    }
  };

  render() {
    const {
      selectedPaymentObject: selectedPaymentOption,
      upiHandle,
      isUPIRequestSubmitting,
      isUPIRequestSubmitted,
    } = this.props.finalizePurchase;
    return (
      <Mentions
        rows={0}
        /*
        Due to inconsistent  behavior of disabled attribute of textarea element,
        textarea will be disabled using css id selector
        */
        id={
          isUPIRequestSubmitting || isUPIRequestSubmitted ? 'upi-disabled-textarea' : ''
        }
        disabled={isUPIRequestSubmitting || isUPIRequestSubmitted}
        value={upiHandle}
        onChange={(value) => this.props.handleChangeUPIHandle(value.split(' ').join(''))}
        filterOption={this.filterOption}
        onSelect={(PSP) => {
          ia.sendEvent('Selected UPI Bank', {
            bank_name: PSP.id,
            seller_username: this.props.username,
            order_id: this.props.finalizePurchase.order,
            action: 'update_vpa_string',
          });
          ia.sendEvent('Input UPI VPA', {
            is_autocompleted: true,
          });
          this.props.handleChangeUPIHandle(upiHandle.split('@')[0] + '@' + PSP.value);
        }}
        /*
        As textbox is present inside this component,
        to prevent the behavior of 'Enter' key, onPressEnter props is passed.
        */
        onPressEnter={(e) => {
          e.preventDefault();
          this.props.showConvenienceFee &&
          this.props.finalizePurchase.convenienceFeeVersion == 'v2'
            ? this.props
                .validateUPIHandle()
                .then(() => this.props.handleFetchConvenienceFees())
                .catch(() => {
                  /**/
                })
            : this.props.handleSubmitUPI({
                paymentOption: selectedPaymentOption,
              });
        }}
        autoFocus
      >
        {UPIAutosuggestionList.map((upi, index) => (
          <Option value={upi.id} id={upi.name} key={index} appName={upi.appName}>
            <div className="list--hover soft-half pointer">
              <div className="upi-suggestion-grid">
                <div className="margin-top--5">
                  <div className={`${upi.classNameTop}`}>&nbsp;@{upi.id}</div>
                </div>
                <div className="text-align-right">
                  <img
                    className={`rc-mentions-dropdown-icons-align-right padding-top-image ${upi.imageClassNames}`}
                    width="68px"
                    src={upi.imgSrc}
                    alt={upi.altText}
                  />
                </div>
              </div>
            </div>
          </Option>
        ))}
      </Mentions>
    );
  }
}

Autocomplete.propTypes = {
  finalizePurchase: PropTypes.object,
  handleChangeUPIHandle: PropTypes.func,
  showConvenienceFee: PropTypes.bool,
  validateUPIHandle: PropTypes.func,
  handleFetchConvenienceFees: PropTypes.func,
  handleSubmitUPI: PropTypes.func,
  username: PropTypes.string,
};

export default Autocomplete;
